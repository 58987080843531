import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  AllThreadAttachmentModel,
  DocumentModel,
  isImageByExtension,
  ITablePagination,
  ThreadAttachmentModel,
} from '@atlas-workspace/shared/models';
import { RestThreadsService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';

import closeIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/unit_close.svg';

interface ThreadAttachmentGroup {
  divider: string;
  attachments?: AllThreadAttachmentModel[];
}

@UntilDestroy()
@Component({
  selector: 'atl-thread-attachments',
  templateUrl: './thread-attachments.component.html',
  styleUrls: ['./thread-attachments.component.scss'],
})
export class ThreadAttachmentsComponent implements OnInit {
  @Input() private threadId!: number;
  @Input() public modalRef!: NgbModalRef;
  @Input() public isClient = false;

  public attachmentsGroups: ThreadAttachmentGroup[] = [];
  public searchParam = '';
  public form!: FormGroup;
  public isAttachments!: boolean;
  public isLoading = true;

  public defaultPagination: ITablePagination = {
    currentPage: 1,
    pageItems: 20,
    totalCount: 0,
    totalPages: 1,
  };
  private pagination: ITablePagination | undefined;
  public readonly closeIcon = closeIcon;
  public readonly tooltipDelay = 500;

  private readonly searchInterval = 300;
  public readonly maxNameLength = 50;

  public noResult = {
    icon: 'assets/glass-no-result.svg',
    title: 'ProjectList.Empty_State.No_results',
    description: 'Projects.Search.Not_Found',
  };

  constructor(
    private readonly modalHelpersService: ModalHelpersService,
    private readonly restThreadsService: RestThreadsService,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getThreadAttachments();
    this.initSearchForm();
    this.subscribeToPagination();
  }

  private initSearchForm(): void {
    this.form = this.fb.group({ search: new FormControl('') });

    this.form
      .get('search')
      ?.valueChanges.pipe(debounceTime(this.searchInterval), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((val) => {
        this.searchParam = val;
        this.restThreadsService
          .getThreadAttachments(this.threadId, this.searchParam, this.defaultPagination)
          .pipe(untilDestroyed(this))
          .subscribe((attachments) => {
            this.attachmentsGroups = [];
            this.setIsImageFlag(attachments);
            this.groupAttachmentsByMonthYear(attachments);
          });
      });
  }

  private subscribeToPagination(): void {
    this.restThreadsService.attachmentsPagination$.pipe(untilDestroyed(this)).subscribe((pagination) => {
      if (pagination) this.pagination = pagination;
    });
  }

  getThreadAttachments(): void {
    this.restThreadsService
      .getThreadAttachments(this.threadId, this.searchParam, this.defaultPagination)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((attachments) => {
        this.isAttachments = !!attachments.length;
        this.setIsImageFlag(attachments);
        this.groupAttachmentsByMonthYear(attachments);
      });
  }

  private groupAttachmentsByMonthYear(attachments: AllThreadAttachmentModel[]): void {
    const groups: ThreadAttachmentGroup[] = [];
    let prevMonthYear = '';

    attachments.forEach((attachment) => {
      const date = new Date(attachment.createdAt);
      const monthYear = dayjs(date).format('MMMM YYYY');

      if (prevMonthYear !== monthYear) {
        groups.push({
          divider: monthYear,
          attachments: [attachment],
        });
        prevMonthYear = monthYear;
      } else {
        const group = groups[groups.length - 1];
        group.attachments?.push(attachment);
      }
    });
    this.attachmentsGroups = [...this.attachmentsGroups, ...groups];
    this.isLoading = false;
  }

  public close(): void {
    this.modalRef?.close();
  }

  openFilePreview(file: ThreadAttachmentModel): void {
    const document: Partial<DocumentModel> = {
      fileExtension: <any>file.extension,
      fileSize: <number>file.size,
      name: file.name,
      fileName: {
        downloadUrl: file.fileName.downloadUrl,
        url: file.fileName.url,
      },
      createdAt: file.uploadDate
    };
    this.modalHelpersService.previewDocument(document as DocumentModel, true, true);
  }

  public loadMore(last: boolean): void {
    if (last) {
      if (this.pagination?.currentPage) {
        this.pagination.currentPage++;
        if (this.pagination?.currentPage > this.pagination?.totalPages) {
          return;
        }
      }
      this.restThreadsService;
      this.restThreadsService
        .getThreadAttachments(this.threadId, this.searchParam, this.pagination)
        .pipe(untilDestroyed(this))
        .subscribe((attachments) => {
          this.setIsImageFlag(attachments);
          this.groupAttachmentsByMonthYear(attachments);
        });
    }
  }

  public onClearInput(): void {
    this.isLoading = true;
    this.form.get('search')?.setValue('');
  }

  private setIsImageFlag(_attachments: AllThreadAttachmentModel[]): void {
    _attachments.forEach((atch) => {
      if (atch.file.extension) {
        atch.file.isImage = isImageByExtension(atch.file.extension);
      }
    });
  }
}
