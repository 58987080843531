import { Injectable } from '@angular/core';
import { ImmutableSelector, MeetingsModel } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import {
  AddMeeting,
  MeetingsSetPagination,
  MeetingsSetTotalFirstLoading,
  RemoveMeeting,
  SetMeeting,
  SetMeetings,
  SetMeetingsCounters,
  SetMeetingsTodayCounter,
} from './meetings.action';
import { MeetingsStateModel } from './meetings.model';

@State({
  name: 'meetingsState',
  defaults: defaultState.meetingsState,
})
@Injectable()
export class MeetingsState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: MeetingsStateModel): MeetingsStateModel {
    return state;
  }

  @Action(SetMeetings)
  setUnits(ctx: StateContext<MeetingsStateModel>, { meetings }: SetMeetings): void {
    ctx.setState(patch<MeetingsStateModel>({ meetings }));
  }

  @Action(SetMeeting)
  setUnit(ctx: StateContext<MeetingsStateModel>, { meeting }: SetMeeting): void {
    ctx.setState(
      patch<MeetingsStateModel>({
        meetings: updateItem<MeetingsModel>((m) => m?.id === meeting.id, meeting),
      })
    );
  }

  @Action(AddMeeting)
  addUnit(ctx: StateContext<MeetingsStateModel>, { length }: AddMeeting): void {
    const state = ctx.getState();
    const add: number = length || 1;
    ctx.setState(
      patch<MeetingsStateModel>({
        totalCount: state.totalCount + add,
      })
    );
  }

  @Action(MeetingsSetPagination)
  setPagination(ctx: StateContext<MeetingsStateModel>, { pagination }: MeetingsSetPagination): void {
    ctx.setState(patch<MeetingsStateModel>({ pagination }));
  }

  @Action(MeetingsSetTotalFirstLoading)
  setTotalFirstLoading(
    ctx: StateContext<MeetingsStateModel>,
    { firstLoad, totalCount }: MeetingsSetTotalFirstLoading
  ): void {
    ctx.setState(patch<MeetingsStateModel>({ firstLoad, totalCount }));
  }

  @Action(SetMeetingsTodayCounter)
  setMeetingsTodayCounter(ctx: StateContext<MeetingsStateModel>, todayMeetingsCount: number): void {
    // @ts-ignore
    ctx.setState(patch<MeetingsStateModel>(todayMeetingsCount));
  }

  @Action(RemoveMeeting)
  @ImmutableSelector()
  removeUnits(ctx: StateContext<MeetingsStateModel>, { ids }: RemoveMeeting): void {
    const state = ctx.getState();
    const meetings = state.meetings?.filter((u) => !ids.some((id) => u.id === id));
    ctx.setState(
      patch<MeetingsStateModel>({
        meetings,
        totalCount: state.totalCount - ids.length,
      })
    );
  }

  @Action(SetMeetingsCounters)
  setCounters(ctx: StateContext<MeetingsStateModel>, { counters }: SetMeetingsCounters): void {
    ctx.setState(patch<MeetingsStateModel>({ counters }));
  }
}
