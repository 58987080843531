import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { IInviteMember, IMembers, IMemberUpdates, MembersModel } from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthAdminService } from '../auth/auth-admin.service';

@Injectable()
export class AdminMembersService {
  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private authAdminService: AuthAdminService
  ) {}

  public getMembers(sort = 'default', search = ''): Observable<MembersModel[]> {
    let params = new HttpParams().append('sort_by', sort);
    if (search) {
      params = params.append('search', search);
    }
    return this.http
      .get<{ data: { members: IMembers[] } }>(
        `${this.environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/members`,
        { params }
      )
      .pipe(
        map((res) => res.data.members),
        map((data) => plainToClass(MembersModel, data))
      );
  }

  public inviteMember(member: IInviteMember): Observable<MembersModel> {
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/members`, member)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(MembersModel, data))
      );
  }

  public deleteMember(memberId: number): Observable<string> {
    return this.http
      .delete(`${this.environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/members/${memberId}`)
      .pipe(map((res: any) => <string>res?.message));
  }

  // any because the server returns an empty response
  public resendInvite(memberId: number): Observable<any> {
    return this.http.patch(
      `${this.environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/admin_data/${memberId}/resend_invitation`,
      null
    );
  }

  public updateMember(member: IMemberUpdates, memberId: number): Observable<MembersModel> {
    return this.http
      .put(
        `${this.environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/members/${memberId}`,
        member
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(MembersModel, data))
      );
  }

  public getCompanyRoles(): Observable<string[]> {
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/admin_data/company_roles`)
      .pipe(map((res: any) => <string[]>res.data.company_roles));
  }

  public transferOwnership(memberId: number): Observable<MembersModel> {
    return this.http
      .post(
        `${this.environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/members/${memberId}/transfer_ownership`,
        null
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(MembersModel, data))
      );
  }

  public getMember(memberId: number): Observable<MembersModel> {
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/members/${memberId}`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(MembersModel, data))
      );
  }
}
