import { Blockable } from '@agilie/angular-helpies/decorators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapterService } from '@atlas-workspace/shared/form';
import {
  acceptedGlobalExtensions,
  ChangeRequestModel,
  ChangeRequestOfferModel,
  IVersionList,
} from '@atlas-workspace/shared/models';
import { ChangeRequestService } from '@atlas-workspace/shared/service';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Component({
  selector: 'atl-create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss'],
  providers: [ChangeRequestService],
})
export class CreateOfferComponent implements OnInit {
  @Input() private readonly projectId!: number;
  @Input() public changeRequest!: ChangeRequestModel;
  @Input() private modalRef!: NgbModalRef;
  @Input() private offer?: ChangeRequestOfferModel;
  @Input() private isFloors!: boolean;

  @Output() private readonly createOffer = new EventEmitter<ChangeRequestOfferModel>();

  public form!: FormGroup;
  public readonly descriptionMaxLength = 800;
  public readonly separatorLimit = '100000000000';
  public readonly acceptedExtensions = acceptedGlobalExtensions;
  public isLoading = false;
  public versionList: IVersionList[] = [];
  public today: NgbDateStruct | null = this.dateAdapter.fromModel(this.dateAdapter.currentDate());

  constructor(
    private fb: FormBuilder,
    private changeRequestService: ChangeRequestService,
    private dateAdapter: DateAdapterService,
  ) {}

  ngOnInit(): void {
    this.createVersionList();
    this.initForm();
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  private createVersionList(): void {
    if (!this.isFloors) return;
    const versions = this.changeRequest.floorDrawVersions.map((f) => f.versionNumber);
    if (!versions.includes(1)) {
      versions.push(1);
    }
    this.versionList = Array.from(new Set(versions))
      .sort()
      .map<IVersionList>((item) => {
        const obj = {
          version: item,
          creator: '',
          date: '',
        };
        const v = this.changeRequest.floorDrawVersions.filter((f) => f.versionNumber === item);
        if (v?.length) {
          obj.creator = v[0].creator.name;
          obj.date = v[0].createdAt;
        }
        return obj;
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      version: [null, Validators.required],
      offerTitle: ['', [Validators.maxLength(50), Validators.required]],
      offerDescription: ['', Validators.maxLength(this.descriptionMaxLength)],
      offerPrice: ['', Validators.required],
      offerExpiration: [''],
      offerAttachments: [[]],
    });

    if (this.offer) {
      this.setForm();
    }
  }

  private setForm(): void {
    this.form.patchValue({
      offerTitle: this.offer?.title,
      offerDescription: this.offer?.description,
      offerPrice: this.offer?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
    });
  }

  @Blockable() public onSubmit(): void {
    this.isLoading = true;
    const body = this.preparationBody();

    this.changeRequestService
      .createChangeRequestOffer(this.projectId.toString(), this.changeRequest.id, body)
      .pipe(take(1))
      .subscribe((offer) => {
        this.createOffer.emit(offer);
        this.closeModal();
        this.isLoading = false;
      });
  }

  private preparationBody(): FormData {
    const body = new FormData();
    const info = this.form.getRawValue();
    const expirationDate = this.dateAdapter.toModelYYYYMMDD(info.offerExpiration);
    const preparedPrice = info.offerPrice.replace(/\D/g, '');

    body.append('change_request_offer[version_number]', info.version);
    body.append('change_request_offer[title]', info.offerTitle);
    body.append('change_request_offer[description]', info.offerDescription);
    body.append('change_request_offer[price]', preparedPrice);
    info.offerAttachments.forEach((file: File) => {
      body.append('change_request_offer[file_resources_attributes][][filename]', file, file.name);
    });

    if (info.offerExpiration) {
      body.append(`change_request_offer[expiration_date]`, expirationDate);
    }

    return body;
  }
}
