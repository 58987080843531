import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthAdminService } from '@atlas-workspace/shared/service';
import { HttpAuthInterceptor, NotFoundPageComponent } from '@atlas-workspace/shared/ui';

import { AuthAdminGuard } from './modules/shared/guards/auth-admin.guard';

export const appRoutes: Routes = [
  {
    path: 'base-layout',
    loadChildren: () =>
      import('./modules/base-layout/base-layout.module').then((m) => m.BaseLayoutModule),
    canActivate: [AuthAdminGuard],
  },
  {
    path: 'full-layout',
    loadChildren: () =>
      import('./modules/full-layout/full-layout.module').then((m) => m.FullLayoutModule),
    canActivate: [AuthAdminGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthAdminModule),
  },
  {
    path: '404',
    component: NotFoundPageComponent,
  },
  {
    path: '**',
    redirectTo: '/base-layout/home/view',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [
    AuthAdminGuard,
    AuthAdminService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
  ],
})
export class AppRoutingModule {}
