export const I18N_VALUES: { [key: string]: { [key: string]: string[] } } = {
  en: {
    weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
  no: {
    weekdays: ['M', 'T', 'O', 'T', 'F', 'L', 'S'],
    months: [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
  },
  sv: {
    weekdays: ['M', 'T', 'O', 'T', 'F', 'L', 'S'],
    months: [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ],
  },
};
