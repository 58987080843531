import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileModel, getExtension, ImageModel } from '@atlas-workspace/shared/models';
import { ToasterService } from '@atlas-workspace/shared/service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import * as mime from 'mime';

interface PlanPreview {
  fileName: {
    downloadUrl: string;
    url: string;
    w260: {
      download_url: string;
      url: string;
    };
  };
  name: string;
  preview: {
    download_url: string;
    url: string;
  };

  fullImage: {
    download_url: string;
    url: string;
  };
  type: string;
}

@Component({
  selector: 'atl-newest-input-file-drag-drop',
  templateUrl: './newest-input-file-drag-drop.component.html',
  styleUrls: ['./newest-input-file-drag-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewestInputFileDragDropComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewestInputFileDragDropComponent implements ControlValueAccessor, OnChanges {
  public isDraggedOver = false;
  public editFiles: (FileModel | ImageModel)[] = [];
  public _maxUploadedFilesSize = 30000000;
  public imagePreview: string | ArrayBuffer | null = null;

  public inputAcceptString = '';

  @Input() disabled = false;
  @Input() multiple = false;
  @Input() customTitle = false;

  @Input() set format(value: string | Array<string>) {
    if (typeof value === 'string') {
      this.inputAcceptString = value;
    } else {
      this.inputAcceptString = (value || []).filter((mType) => mType).join(', ');
    }
  }

  @Output() converterHandler = new EventEmitter<File>();

  @ViewChild('uploadInput') uploadInput!: ElementRef<HTMLElement>;

  private onChange!: (value: (File | ImageModel)[] | null | undefined) => void;

  private onTouched!: () => void;

  constructor(
    private cdr: ChangeDetectorRef,
    private toasterService: ToasterService,
    private translateService: TranslateService
  ) {}

  ngOnChanges({ editFiles }: SimpleChanges): void {
    if (editFiles && editFiles.currentValue) {
      this.editFiles = cloneDeep(editFiles.currentValue);
    }
  }

  clear(): void {
    if (this.disabled) return;
    this.imagePreview = null;
    this.onChange(null);
  }

  writeValue(value: (File | ImageModel)[] | PlanPreview = []): void {
    if ((value as PlanPreview)?.fileName) {
      const url = (value as PlanPreview).fullImage?.url || (value as PlanPreview).preview.url;
      this.imagePreview = url;
      return;
    }
    if (value) {
      this.setImagePreview((value as (File | ImageModel)[])[0] as File);
    }
    this.cdr.detectChanges();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleUpload(): void {
    if (this.disabled) {
      return;
    }
    this.uploadInput.nativeElement.click();
  }

  selectedFile(event: Event): void {
    if (event.target) {
      const target = event.target as HTMLInputElement;
      const files: File[] = Array.from(target.files as FileList);
      this.loadValidFiles(files);
      target.value = '';
      target.files = null;
    }
  }

  handleDragOver(event: Event): void {
    this.stopDefault(event);
    this.isDraggedOver = true;
  }

  handleDragEnter(): void {
    this.isDraggedOver = true;
  }

  handleDragLeave(): void {
    this.isDraggedOver = false;
  }

  handleDrop(event: DragEvent): void {
    if (this.disabled) {
      this.isDraggedOver = false;
      return;
    }
    this.stopDefault(event);
    this.isDraggedOver = false;
    if (event.dataTransfer?.files?.length) {
      const files: File[] = Array.from(event.dataTransfer.files);
      this.loadValidFiles(files);
    }
  }

  stopDefault(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  private loadValidFiles(files: File[]): void {
    if (this.checkFileSize(files)) {
      if (this.filterByFormat(files).includes(undefined)) return;
      files = this.filterByFormat(files) as File[];
      if (!files.length) {
        this.toasterService.openErrorToast(
          this.translateService.instant('Shared.Description.Please_select_format') + ' ' + this.inputAcceptString
        );
        return;
      }
      this.onChange(files);
      this.setImagePreview(files[0]);
    } else {
      const validFiles: File[] = files.filter((x) => x.size <= this.maxUploadedFilesSize);
      if (validFiles.length) {
        this.onChange(validFiles);
        return;
      }
      this.toasterService.openErrorToast(
        this.translateService.instant('Description.File_too_big', {
          value: 30,
        })
      );
    }
  }

  filterByFormat(files: File[]): (File | undefined)[] {
    if (this.inputAcceptString) {
      files = files.filter((file) => {
        let fileType: string;
        if (!file.type) {
          fileType = getExtension(file.name);
        } else {
          fileType = file.type;
        }
        return (
          this.inputAcceptString.includes(String(mime.getExtension(fileType))) ||
          this.inputAcceptString.includes(fileType)
        );
      });
      return this.pdfToImage(files);
    }
    return this.pdfToImage(files);
  }

  pdfToImage(files: File[]): (File | undefined)[] {
    return files.map((file) => {
      if (file.type.includes('pdf')) {
        this.converterHandler.emit(file);
        return undefined;
      }
      return file;
    });
  }

  setImagePreview(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      this.imagePreview = reader.result;
      this.cdr.markForCheck();
    };
  }

  private checkFileSize(files: File[]): boolean {
    return files.every((file) => file.size <= this.maxUploadedFilesSize);
  }

  get maxUploadedFilesSize(): number {
    return this._maxUploadedFilesSize;
  }
}
