import { ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '@atlas-workspace/shared/pipes';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DefaultModalModule } from '../default-modal/default-modal.module';
import { AdminFullScreenComponent } from './admin-full-screen.component';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FirmPrefixPipeModule } from '@atlas-workspace/shared/pipes';
import { SharedDirectivesModule, SwiperDirectiveModule } from '@atlas-workspace/shared/directives';
import {
  AutoResizeModule,
  DefaultDropdownModule,
  FirmIconComponent,
  HeaderIconComponent
} from '@atlas-workspace/shared/ui';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [AdminFullScreenComponent],
  exports: [AdminFullScreenComponent],
  imports: [
    CommonModule,
    DefaultModalModule,
    AutoResizeModule,
    SharedPipesModule,
    DefaultDropdownModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FirmPrefixPipeModule,
    SharedDirectivesModule,
    TranslateModule,
    NgbTooltipModule,
    SwiperDirectiveModule,
    HeaderIconComponent,
    FirmIconComponent
  ],
})
export class AdminFullScreenModule {}
