import { imagePngMime, PositionedFile } from '@atlas-workspace/shared/models';
import * as pdfjsLib from 'pdfjs-dist';
//@ts-ignore
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

export async function extractFirstPdfPageAsImage(file: PositionedFile): Promise<File> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onerror = (error) => reject(error);

      fileReader.onload = async (e) => {
        const arrayBuffer = <ArrayBuffer>e.target!.result;

        const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;

        const page = await pdf.getPage(1);

        // Pixels Per Inch / Dots Per Inch
        // Real scale to save natural dimensions of pdf page (in pixels).
        const scale = 300 / 72;

        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d')!;
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context, viewport }).promise;

        canvas.toBlob((blob) => {
          if (blob) {
            const originalFilename = file.name.split('.').slice(0, -1).join('.');
            const imageFile = new File([blob], `${originalFilename}.png`, { type: imagePngMime });
            resolve(imageFile);
          } else {
            reject(new Error('Failed to create Blob from canvas.'));
          }
        }, imagePngMime, 1.0);
      };
    } catch (err) {
      reject(err);
    }
  });
}
