import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CombinedOffersListModel, CreatedCombinedOfferModel, offerStatusList, UnitModel,
} from '@atlas-workspace/shared/models';
import {ChangeRequestService} from '@atlas-workspace/shared/service';
import { UntilDestroy } from '@ngneat/until-destroy';
import {take} from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: 'atl-sub-offer-list',
  templateUrl: './sub-offer-list.component.html',
  styleUrls: ['./sub-offer-list.component.scss'],
})
export class SubOfferListComponent {
  @Input() projectId!: string;
  @Input() offers!: CombinedOffersListModel[];
  @Input() unitId!: number;
  @Input() unit!: UnitModel;

  @Output() updateOffersList = new EventEmitter<CombinedOffersListModel[]>();

  public readonly statusClasses = offerStatusList;
  public selectOffer?: CreatedCombinedOfferModel;

  constructor(
    private changeRequestService: ChangeRequestService,
  ) {}

  public getOffer(offer: CombinedOffersListModel): void {
    this.changeRequestService.changeRequestsCombinedOffer(this.projectId, offer.id).pipe(take(1)).subscribe(value => {
      this.selectOffer = value;
    });
  }

  public getChangeRequestsCombinedOffersList(unselect = false): void {
    this.changeRequestService
      .changeRequestsCombinedOffersList(this.projectId.toString(), this.unitId)
      .pipe(take(1))
      .subscribe((value) => {
        this.offers = value;
        this.updateOffersList.emit(this.offers);
        if (unselect) {
          this.returnToOffers();
        }
      });
  }

  public returnToOffers(): void {
    this.selectOffer = undefined;
  }
}
