/* eslint-disable */
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';


@Injectable({
  providedIn: 'root',
})
export class PicarioService {

  private fluentXpoUrlFactory: any;
  private w = window as any;
  constructor(@Inject('ENVIRONMENT') private environment: IEnvironment) {
    this.initializeSdk();
  }
  private readonly sceneUrl = this.environment.picarioSceneUrl;
  // ToDO: add typescrip typing from SDK instead of window usage.
  private initializeSdk(): void {
    if (typeof this.w.FluentXpoUrlFactory !== 'undefined') {
      this.fluentXpoUrlFactory = new this.w.FluentXpoUrlFactory();
    } else {
      console.error('Picario SDK not loaded');
    }
  }

  public generateImageUrl(options: {
    scene: string;
    colors?: { id: string, colorNr: number }[];
    width?: number;
    height?: number;
    format?: string;
    designArr?: any[];
    colorNr?: number
  }): string | null {
    if (!this.fluentXpoUrlFactory) {
      console.error('Picario SDK not initialized');
      return null;
    }

    const generator = new this.w.XpoUrlGenerator();
    const fluentUrlGenerator = this.fluentXpoUrlFactory.createFluentUrlGenerator(
      generator,
      this.w.UrlGeneratorModule.FluentXpoUrlType.Image
    ).setAbsoluteUrl(this.sceneUrl);

    fluentUrlGenerator
      .setPrimaryKey(options.scene)
      .setImageType(this.w.UrlGeneratorModule.XpoUrlImageTypes[options.format || 'Jpg'])
      .setEntityType(this.w.UrlGeneratorModule.XpoUrlFileTypes.Scene)
      .setWidth(1920)
      .setHeight(1080)
    if (options.colors?.length) {
      options.colors.forEach((color) => {
        fluentUrlGenerator.addObject((colorObg: any) => {
          colorObg.color(color.id);
          colorObg.setIndex(color.colorNr);
        });
      });
    }

    if (options.designArr) {
      options.designArr.forEach((design) => {
        if (!design.related_scene_id) {
          return;
        }
        fluentUrlGenerator.addObject((designObj: any) => {
          designObj
            .design(design.referenceId)
            .setWidth(design.designOptions.width)
            .setHeight(design.designOptions.height)
            .setRepeat(design.designOptions.repeat)
            .setPlacingPointX(design.designOptions.placingPointX)
            .setPlacingPointY(design.designOptions.placingPointY);
          designObj.setIndex(design.nr);
        });
      });
    }

    const url = fluentUrlGenerator.getUrl();
    return url;
  }

  
  public generateImageOverlayUrl(
    options: {
      width?: number;
      height?: number;
      format?: string;
      overlay?: string;
      designArr?: any[]
    }, subsceneId?: number,
    color?: { id: string, nr: number }
  ): string | null {
    if (!this.fluentXpoUrlFactory) {
      console.error('Picario SDK not initialized');
      return null;
    }
    const generator = new this.w.XpoUrlGenerator();
    const fluentUrlGenerator = this.fluentXpoUrlFactory.createFluentUrlGenerator(
      generator,
      this.w.UrlGeneratorModule.FluentXpoUrlType.Image
    ).setAbsoluteUrl(this.sceneUrl);

    if (options.designArr) {
      options.designArr.forEach((design) => {
        if (!design.id || design.related_scene_id !== subsceneId) {
          return;
        }
        fluentUrlGenerator.addObject((designObj: any) => {
          designObj
            .design(design.referenceId)
            .setWidth(design.designOptions.width)
            .setHeight(design.designOptions.height)
            .setRepeat(design.designOptions.repeat)
            .setPlacingPointX(design.designOptions.placingPointX)
            .setPlacingPointY(design.designOptions.placingPointY);
        });
      });
    }

    if (color) {
      fluentUrlGenerator.addObject((colorObg: any) => {
        colorObg.color(color.id);
        colorObg.setIndex(color.nr);
      });
    }
    const url = fluentUrlGenerator
      .setPrimaryKey(options.overlay)
      .setImageType(options.format)
      .setEntityType(this.w.UrlGeneratorModule.XpoUrlFileTypes.Scene)
      .setWidth(options.width || 1024)
      .getUrl();

    return url;
  }



}