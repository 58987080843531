import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { FirmPrefixPipeModule,SharedPipesModule  } from '@atlas-workspace/shared/pipes';
import {
  AutoResizeModule,
  DefaultDropdownModule, FirmIconComponent,
  NewestDocumentCardComponent,
  ShadowDomComponent,
} from '@atlas-workspace/shared/ui';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { DefaultModalModule } from '../default-modal/default-modal.module';
import {  PreviewModalComponent } from './preview-modal.component';

@NgModule({
  declarations: [PreviewModalComponent],
  exports: [PreviewModalComponent],
  imports: [
    CommonModule,
    DefaultModalModule,
    AutoResizeModule,
    SharedPipesModule,
    DefaultDropdownModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FirmPrefixPipeModule,
    SharedDirectivesModule,
    TranslateModule,
    ShadowDomComponent,
    NgbTooltipModule,
    NewestDocumentCardComponent,
    FirmIconComponent
   ],
})
export class PreviewModalModule {}
