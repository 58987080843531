<section
  class="thread__section h-100"
  atlFileDragDrop
  (filesChangeEmiter)="droppedFiles = $event"
  (hovered)="isDragDropSectionHovered = $event"
>
  <div *ngIf="isDragDropSectionHovered" class="dragover-overlay"></div>

  <div class="thread__header">
    <div class="thread__header-title fz-16 lh-24 fw-500">{{ 'Shared.Entity.New_message' | translate }}</div>
    <div
      data-cy="cy-close-btn-thread-creation"
      class="thread__header-close cursor-pointer"
      (click)="createDraft()"
      [ngbTooltip]="'Alt.Close' | translate"
      [openDelay]="tooltipDelay"
      placement="bottom"
      triggers="hover touchstart"
    >
      <img src="assets/close-icon.svg" alt="Close the modal" />
    </div>
  </div>
  <form [formGroup]="form" class="thread">
    <div
      *ngIf="form.controls.project as control"
      #projectPickerWrapperRef
      class="thread__search-wrapper thread__form-control"
      [class.disabled]="!pickerProjects?.length"
    >
      <label class="thread__label fz-14 lh-24 fw-400 mr-8">{{ 'Entity.Project' | translate | titlecase }}:</label>
      <div
        class="thread__select-units cursor-pointer w-100 br-4 fz-14 lh-24 fw-400 ellipsis"
        data-project-outside-click-area
        (click)="openProjectsSelection()"
      >
        <ng-template [ngIf]="isProjectControlSearchable">
          <atl-input class="thread__form-control-input" [formControl]="projectSearchControl"></atl-input>
        </ng-template>
        <ng-template [ngIf]="!isProjectControlSearchable">
          <span
            *ngIf="control.value?.name; else addProjectTemplate"
            class="selected ellipsis"
            data-project-outside-click-area
            >{{ control.value.name }}</span
          >
        </ng-template>
      </div>
      <ng-template #addProjectTemplate>
        <div class="no-selected" data-project-outside-click-area>{{ 'Entity.Choose_project' | translate }}</div>
      </ng-template>
    </div>
    <div
      *ngIf="scope !== eThreadScopes.Unit"
      #unitsPickerWrapperRef
      class="thread__search-wrapper thread__form-control"
      [class.disabled]="
        (scope === eThreadScopes.Global && !form.controls.project?.value) ||
        (scope === eThreadScopes.Project && !projectId)
      "
    >
      <label class="thread__label fz-14 lh-24 fw-400 mr-8">{{ 'Shared.Messages.Send_to' | translate }}:</label>
      <div
        #unitDropdown='ngbDropdown'
        ngbDropdown
        class="position-relative w-100"
        [autoClose]="true"
        [class.pointer-events-none]="disabledUnitSelect"
        (openChange)="openChangeHandler($event, unitDropdown)"
        placement="bottom"
      >
        <div
        data-cy="cy-add-unit-thread-creation"
        class="thread__select-units cursor-pointer w-100 br-4 fz-14 lh-24 fw-400"
        [class.active]="unitDropdown._open"
        data-units-outside-click-area
        ngbDropdownToggle
        id='unit-dropdown'
        >
          <ng-container *ngIf="selectedUnits.length">
            <ng-container *ngIf="selectedUnits.length <= showUnitsCount; else unitCounter">
              <div
                *ngFor="let item of selectedUnits"
                class="selected chips"
                data-units-outside-click-area
              >
                {{item.identifier}}
                <img (click)="removeSelectItem($event, item)" *ngIf="unitDropdown._open" class="ml-8 cursor-pointer" src="assets/multiple_remove_icon.svg" alt="remove">
              </div>
            </ng-container>
            <ng-template #unitCounter>
              <div
                class="selected chips"
                data-units-outside-click-area
              >
                {{ selectedUnits.length }} {{ (typeSelected ? 'Shared.Entity.Units' : 'Shared.Entity.Unit_groups') | translate }}
              </div>
            </ng-template>

          </ng-container>

          <atl-input
            #unitsInput
            class="thread__form-control-input"
            [placeholder]="!selectedUnits.length ? 'Entity.Add_unit_s' : ''"
            [formControl]="unitsSearchControl"
            [hasDoubleSpace]="false"
            tabindex="0"
            (click)="inputHandler($event, unitDropdown)"
          ></atl-input>
        </div>
        <atl-create-thread-units-modal
          class="select-units-dropdown"
          ngbDropdownMenu
          aria-labelledby='unit-dropdown'
          [setProjectId]="popupProjectId"
          [selectedUnits]="selectedUnits"
          [onSearch]="unitSearch"
          (selectHandler)="unitSelectHandler($event)"
        ></atl-create-thread-units-modal>
      </div>
      <ng-template #addUnitsTemplate>
        <div class="no-selected" data-units-outside-click-area>{{ 'Entity.Add_unit_s' | translate }}</div>
      </ng-template>
    </div>
    <div class="thread__form-control">
      <label class="thread__label fz-14 lh-24 fw-400 mr-8">{{ 'Shared.Messages.Subject' | translate }}:</label>
      <atl-input
        class="thread__form-control-input subject"
        [placeholder]="'Shared.Messages.Type_subject'"
        formControlName="subject"
        tabindex="0"
      ></atl-input>
    </div>

    <atl-mentions-text-editor
      #editorElementRef
      #editorComponentRef
      class="thread__editor flex-grow-1 mt-6"
      [control]="form.controls.body"
      [apiKey]="apiKey"
      [config]="editorInitConfig"
      [projectId]="scope === eThreadScopes.Global ? form.controls.project.value?.projectId : projectId"
      [units]="form.controls.units?.value"
      [isCreationModal]="true"
      [mentionUsersPerView]="scope === eThreadScopes.Global || scope === eThreadScopes.Unit ? 3 : mentionUsersPerView"
      (getContentHandler)="cdRef.detectChanges()"
      (isContentEmpty)="checkIsButtonDisabled($event)"
      (submitHandler)="submitForm()"
      (setFile)="setFileToDocuments($event)"
      (onClickEditor)="onClickEditor()"
    ></atl-mentions-text-editor>

    <atl-text-editor-multi-file-upload
      class="thread__file-upload"
      #fileRef
      #fileComponent
      [removeTitle]="'File'"
      [format]="acceptedExtensions"
      [maxUploadedFiles]="maxUploadedFiles"
      [maxUploadedFilesSize]="maxUploadedFilesSize"
      [multiple]="true"
      [enforceSelection]="droppedFiles"
      formControlName="document"
      [autoSave]="autoSave"
    >
      <ng-container ngProjectAs="otherPanel">
        <div class="d-inline-flex align-items-center">
          <atl-titled-checkbox
            class="mr-16"
            (changeEvent)="setSendSMS($event)"
            [checkboxSize]="16"
            [checkboxLabel]="'Threads.Also_send_as_SMS' | translate"
          >
          </atl-titled-checkbox>

          <button class="d-none button-red remove-button mr-8 d-flex align-items-center" (click)="removeDraftModal()">
            <img src="/assets/trash-attachment-red.svg" alt="Remove" />
          </button>
          <button
            [disabled]="!isSubmitEnabled"
            class="newest-button newest-button-sm thread__send-button"
            (click)="submitForm()"
            [ngbTooltip]="!isSubmitEnabled ? disabledSendTooltip : helperTooltip"
            [disableTooltip]="isMobileView && isSubmitEnabled"
            tooltipClass="submit-tooltip"
            triggers="hover touchstart"
          >
            <atl-custom-icon class="mr-8" [icon]="threadSendIcon"></atl-custom-icon>
            <span>{{ 'Shared.Button.Send' | translate }}</span>
            <span *ngIf="form.value.units?.length > 1" class="ml-2 lowercase">
              {{ '(' + form.value.units?.length + ' ' }}
              <span class="messages-text">{{ ('Title.Messages' | translate) + ')' }}</span>
            </span>
          </button>
        </div>
      </ng-container>
    </atl-text-editor-multi-file-upload>
  </form>
</section>

<ng-template #disabledSendTooltip>
  <div>{{ 'Shared.Reclamation.Required_fields' | translate }}</div>
  <ul>
    <li *ngIf="scope === eThreadScopes.Global">{{ 'Entity.Project_Name' | translate }}</li>
    <li>{{ 'Shared.Entity.To' | translate }}</li>
    <li>{{ 'Shared.Messages.Subject' | translate }}</li>
    <li>{{ 'Shared.Title.Message' | translate }}</li>
  </ul>
</ng-template>

<ng-template #helperTooltip>
  <ng-container *ngIf="isMac; else othersOsTooltip">
    <div>
      <ng-template [ngTemplateOutlet]="sendTitleTemplate"></ng-template>
      <div class="d-flex align-items-center justify-content-center">
        <span class="mr-4 tooltip-key"><img src="/assets/cmd-white-icon.svg" alt="cmd" /></span>
        <span class="tooltip-key">Enter</span>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #othersOsTooltip>
  <div>
    <ng-template [ngTemplateOutlet]="sendTitleTemplate"></ng-template>
    <div>
      <span class="mr-4 tooltip-key">Ctrl</span>
      <span class="tooltip-key">Enter</span>
    </div>
  </div>
</ng-template>

<ng-template #sendTitleTemplate>
  <div [ngPlural]="form.value.units?.length">
    <ng-template ngPluralCase="=1">
      <div class="mb-4 white-space-nowrap">{{ 'Shared.Entity.Send_message' | translate }}</div>
    </ng-template>
    <ng-template ngPluralCase="other">
      <div class="mb-4 white-space-nowrap">
        {{ 'Shared.Entity.Send_multiple_messages' | translate: { count: form.value.units?.length } }}
      </div>
    </ng-template>
  </div>
</ng-template>
