<ng-container *ngIf="!isLoading; else spinnerBlock">
  <section class="thread__projects v-scrollbar h-100" atlClickOutside (clickOutside)="onClickOutside($event)">
    <ng-container *ngIf="(hasSearchableProjects && search) || !search || isLoading; else noSearchResultTemplate">
      <ng-container *ngFor="let item of projects">
        <div
          *ngIf="item.visible"
          class="thread__projects-item cursor-pointer fz-14 lh-24 fw-400 ellipsis br-4"
          [class.selected]="item.selected"
          (click)="check(item)"
        >
          <span class="ellipsis">{{ item.name }}</span>
        </div>
      </ng-container>
    </ng-container>
  </section>
</ng-container>

<ng-template #noSearchResultTemplate>
  <div class="ellipsis fz-14 lh-24 fw-400 ml-8 mt-4">
    <span class="ellipsis">{{ 'Shared.No_search_result' | translate }} "{{ search | truncate: 40 }}"</span>
  </div>
</ng-template>

<ng-template #spinnerBlock>
  <div class="h-100 d-flex align-items-center justify-content-center">
    <span class="custom-spinner custom-spinner-24"></span>
  </div>
</ng-template>