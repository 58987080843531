import {Injectable} from '@angular/core';
import {
  GetReclamationsProjectProps,
  IReclamationData,
  ITablePagination,
  ReclamationsModel
} from '@atlas-workspace/shared/models';
import {DataTableHelperService, ReclamationAdminService} from "@atlas-workspace/shared/service";
import {Select, Store} from '@ngxs/store';
import {plainToClass} from "class-transformer";
import {cloneDeep} from "lodash";
import {Observable} from 'rxjs';
import {map, share, tap} from "rxjs/operators";

import {defaultState} from '../default-state'
import {
  AddReclamationProject,
  ReclamationProjectSetPagination,
  ReclamationProjectSetTotalFirstLoading,
  ReclamationsProjectState,
  ReclamationsProjectStateModel,
  RemoveReclamationProject,
  SetReclamationProject,
  SetReclamationsProject
} from './index';


@Injectable({
  providedIn: 'root',
})
export class ReclamationsProjectFacadeService {
  @Select(ReclamationsProjectState.selectStateData) private _reclamationsProject$!: Observable<ReclamationsProjectStateModel>;

  constructor(
    private reclamationService: ReclamationAdminService,
    private store: Store,
    private dataTableHelper: DataTableHelperService
  ) {}

  get reclamationsProject(): Observable<ReclamationsProjectStateModel> {
    return this._reclamationsProject$.pipe(share());
  }

  public getReclamationsProject(
    {
      projectId,
      sort,
      search,
      byUser,
      hasMessages,
      paginate,
      unitId,
      selectedFilters,
      protocolsCounters
    }: GetReclamationsProjectProps
  ): Observable<IReclamationData> {
    return this.reclamationService.getReclamationsListState(projectId, sort, search, byUser, hasMessages, paginate, unitId, selectedFilters, protocolsCounters).pipe(
      tap((returnData) => {
        const pagination = this.dataTableHelper.getPagination(returnData);

        if (this.store.snapshot().reclamationsProjectState.firstLoad) {
          this.store.dispatch(new ReclamationProjectSetTotalFirstLoading(false, returnData.body.data.total_count));
        }

        this.setPagination(pagination);
      }),
      map((res) => res.body.data),
      tap((data) => {
        data.reclamations = data.reclamations.map((x: any) => plainToClass(ReclamationsModel, x));
      }),
      tap((returnData) => {
        if (this.store.snapshot().reclamationsProjectState.pagination.currentPage === 1 && sort === 'last_message_desc' && !search) {
          this.setReclamationsProject(returnData.reclamations);
        }
      })
    );
  }

  setReclamationsProject(reclamations: ReclamationsModel[]): void {
    this.store.dispatch(new SetReclamationsProject(cloneDeep(reclamations)));
  }

  setChangeReclamationsProject(reclamation: ReclamationsModel): void {
    this.store.dispatch(new SetReclamationProject(reclamation));
  }

  addCreateReclamationsProject(reclamations: ReclamationsModel): void {
    this.store.dispatch(new AddReclamationProject(reclamations));
  }

  removeReclamationsProjectLocal(ids: number[]): void {
    this.store.dispatch(new RemoveReclamationProject(ids));
  }

  setPagination(pagination: ITablePagination = defaultState.updatesState.pagination): void {
    this.store.dispatch(new ReclamationProjectSetPagination(pagination));
  }
}
