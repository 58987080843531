import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdminMember,
  AdminModel,
  FirmModel,
  IAdmin,
  IAdminMember,
  IAdminProfileTransfer,
  ICreateFirm,
  IFirmModel,
  ITablePagination,
} from '@atlas-workspace/shared/models';
import { DataTableHelperService } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FirmService {
  constructor(private http: HttpClient, private readonly tableService: DataTableHelperService) {}

  getFirms(): Observable<FirmModel[]> {
    return this.http.get<{ data: { firms: IFirmModel[] } }>(environment.apiBaseUrl + 'api/v1/firms').pipe(
      map((res) => res?.data?.firms),
      map((firms) => plainToClass(FirmModel, firms))
    );
  }

  getFirmAdmins(firmId: string, pagination?: ITablePagination): Observable<AdminModel[]> {
    let params: HttpParams = this.tableService.paramsHandler('', 'default', pagination);
    params = params.set('firm_id', firmId);
    return this.http
      .get<{ data: { main_admins: IAdmin[] } }>(environment.apiBaseUrl + 'api/v1/main_admins', {
        params: params,
      })
      .pipe(
        map((res) => res?.data?.main_admins),
        map((data) => plainToClass(AdminModel, data))
      );
  }

  getInfoAboutFirm(firmId: string): Observable<FirmModel> {
    return this.http.get<{ data: IFirmModel }>(environment.apiBaseUrl + 'api/v1/firms/' + firmId).pipe(
      map((res) => res?.data),
      map((data) => plainToClass(FirmModel, data))
    );
  }

  getFirmMember(firmId: number, search: string, currentUserEmail = ''): Observable<AdminMember[]> {
    const encodedSearch = encodeURIComponent(search);
    const encodedEmail = encodeURIComponent(currentUserEmail);
    return this.http
      .get<{ data: { members: IAdminMember[] } }>(
        environment.apiBaseUrl +
          `api/v1/firms/${firmId}/members?search=${encodedSearch}&ignore_user_by_email=${encodedEmail}`
      )
      .pipe(
        map((res) => res?.data?.members),
        map((members) => plainToClass(AdminMember, members))
      );
  }

  transferPermissionMember(firmId: number, memberId: number): Observable<AdminModel> {
    return this.http
      .post(environment.apiBaseUrl + `/api/v1/firms/${firmId}/members/${memberId}/transfer_ownership`, {})
      .pipe(map((admin) => plainToClass(AdminModel, admin)));
  }

  createFirm(firm: ICreateFirm): Observable<FirmModel> {
    return this.http.post(`${environment.apiBaseUrl}/api/v1/firms`, firm).pipe(
      map((res: any) => res?.data),
      map((data) => plainToClass(FirmModel, data))
    );
  }

  transferRoleInProject(firmId: number, body: IAdminProfileTransfer): Observable<unknown> {
    return this.http.post(environment.apiBaseUrl + 'api/v1/projects/transfer_ownership', body, {
      params: {
        firm_id: firmId.toString(),
      },
    });
  }
}
