<atl-ngb-modal-header [title]="title" (closeModalEvent)="closeModal()"></atl-ngb-modal-header>

<div class="combine-offer v-scrollbar" tabindex="0">
  <div class="combine-offer__header">
    <p class="fz-16 lh-24 word-break mb-24">
      {{ 'Shared.Change_request.Combine_Offer.Description' | translate }}
    </p>
    <div *ngIf="groupList.length > 1" class="d-flex flex-column w-100" [class.mb-24]="combine">
      <span class="fz-16 lh-24 fw-600 mb-16">
        {{ 'Shared.Change_request.Combine_Offer.Group_select.Title' | translate }}
      </span>
      <atl-drop-down-writing
        class="newest"
        [formControl]="groupControl"
        [listOfItems]="groupList"
        [bindLabel]="'title'"
        [bindValue]="'id'"
        [placeholder]="'Shared.Change_request.Combine_Offer.Group_select.Placeholder' | translate"
        position="auto"
        appendTo="body"
      ></atl-drop-down-writing>
    </div>
  </div>
  <div *ngIf="combine" class="combine-offer__request-list">
    <span class="fz-16 lh-24 fw-600 mb-16 pl-12">
      {{ 'Shared.Change_request.Combine_Offer.Group_select.Summary' | translate }}
    </span>

    <div class="d-flex flex-column align-items-center w-100">
      <div
        *ngFor="let request of combine.combinedOffer.subOffersAttributes"
        class="combine-offer__accordion"
        [class.open]="request.open"
      >
        <div class="header">
          <span class="header__title">
            <img
              class="mr-8 check"
              [ngClass]="{valid: request.validator, 'cursor-pointer': !request.validator || !request.declined}"
              src="assets/success-white.svg"
              alt="check"
              [ngbTooltip]="buttonTooltip"
              [disableTooltip]="request.validator || request.declined"
              placement="top"
              triggers="hover touchstart"
            />
            {{ request.identifier | noProjectSegmentInChangeRequestIdentifier }}
            <img
              *ngIf="request.crDescription"
              (click)="showComment(request.crDescription)"
              class="ml-8 check cursor-pointer"
              src="assets/info-icon-16.svg"
              alt="info"
              [ngbTooltip]="'Shared.Change_request.Combine_Offer.Show_customer_description' | translate"
              placement="top"
              triggers="hover touchstart"
            />
          </span>

          <span
            *ngIf="request.declined"
            class="declined"
            [ngbTooltip]="'Shared.Change_request.Combine_Offer.Decline__tooltip' | translate"
            placement="top"
            triggers="hover touchstart"
          >
            {{'Shared.Change_request.Status.Declined' | translate}}
          </span>

          <input
            *ngIf="!request.declined"
            class="header__input"
            type="text"
            placeholder="{{'Shared.Entity.Add_price' | translate}} ({{'Button.Required' | translate}})"
            [(ngModel)]="request.price"
            [mask]="mask"
            suffix=",-"
            [separatorLimit]="separatorLimit"
            thousandSeparator=" "
          />

          <span class="header__arrow" [class.open]="request.open" (click)="request.toggle()">
            <img src="/assets/arrow-gray-down.svg" alt="arrow" />
          </span>

          <ng-template #buttonTooltip>
            <p class="tooltip-title">{{ 'Shared.Reclamation.Required_fields' | translate }}</p>
            <ul class="tooltip-ul">
              <li *ngIf="!request.price">{{ 'Entity.Price' | translate }}</li>
              <li *ngIf="!request.versionNumber">{{ 'Shared.Change_request.Combine_Offer.Label.Floor_plan_version' | translate }}</li>
            </ul>
          </ng-template>
        </div>
        <div class="main">
          <label class="main__label mt-8">{{ 'Shared.Reclamation.Input.Description.Label' | translate }} ({{'Shared.Button.Optional' | translate}})</label>
          <textarea
            [(ngModel)]="request.description"
            [disabled]="request.declined"
            atlReplaceInput
            [placeholder]="'Shared.Entity.Add_description' | translate"
            class="main__textarea v-scrollbar"
            (keydown.enter)="stopEvent($event)"
          ></textarea>
          <label class="main__label mt-16">
            {{'Shared.Change_request.Combine_Offer.Label.Floor_plan_version' | translate}} ({{'Button.Required' | translate}})
          </label>
          <atl-drop-down-writing
            class="newest"
            [(ngModel)]="request.versionNumber"
            [disabled]="request.declined"
            [customItemView]="true"
            [customLabelView]="true"
            [customLabelTranslateView]="true"
            [listOfItems]="request.versionList"
            [bindValue]="'version'"
            [placeholder]="'Shared.Entity.Select_version'"
            position="auto"
          >
            <ng-template atlInputTemplate let-item="item">
              <div class="d-flex align-items-center justify-content-between">
                <span class="w-50 d-flex align-items-center text-black-960">
                  {{ 'Shared.Entity.Version' | translate }} {{ item.version }}
                </span>
                <span *ngIf="item.date" class="w-50 d-block ellipsis text-black-810">
                  {{ 'Shared.Entity.Created' | translate }} {{ item.date | date: 'dd.MM.yyyy' }}
                </span>
                <span *ngIf="item.url" class="d-flex align-items-center" (click)="previewFloorPlanVersion($event, item)">
                  <img src="assets/preview-eye.svg" class="mr-4" alt="eye">
                  <span class="fz-12 lh-16 fw-400 text-blue-980">{{ 'Entity.Preview' | translate }}</span>
                </span>
              </div>
            </ng-template>
            <ng-template atlInputTranslateTemplate let-item="item">
              <div class="d-flex align-items-center justify-content-between">
                <span class="d-flex align-items-center fz-16">
                  {{ 'Shared.Entity.Version' | translate }} {{ item.version }}
                </span>
              </div>
            </ng-template>
          </atl-drop-down-writing>
          <div class="mt-16">
            <atl-document-drag-and-drop
              [(ngModel)]="request.fileResourcesAttributes"
              [disabled]="request.declined"
              [newestViewImage]="true"
              label="{{'Shared.Messages.Attachments' | translate}} ({{'Shared.Button.Optional' | translate}})"
              [format]="acceptedExtensions"
              [asUploadButton]="true"
              [maxUploadedFiles]="5"
              [multiple]="true"
            >
            </atl-document-drag-and-drop>
          </div>
        </div>
        <div *ngIf="!request.declined" class="combine-offer__footer">
          <button (click)="declineModal(request)" class="newest-button newest-button-sm button-red">
            {{'Shared.Change_request.Combine_Offer.Decline_change_request' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="combine-offer__header">
      <div class="combine-offer__total">
        <span class="combine-offer__total-title">
          {{ 'Shared.Change_request.Combine_Offer.Total_changes' | translate }}
        </span>
        <span class="combine-offer__total-title cost"> {{ combine.combinedOffer.totalOffers | customDecimal }},- </span>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Auth.Create.Price.Mark-up.Input.VAT.Title' | translate }} ({{
            combine.combinedOffer.pricingComponents.vat
          }}%)

          <button
            (click)="editPriceSystemModal(true, combine.combinedOffer.pricingComponents.vat)"
            class="newest-button newest-button-sm newest-button--outline ml-8"
          >
            {{ 'Shared.Button.Edit' | translate }} %
          </button>
        </span>
        <span class="cost"> {{ combine.combinedOffer.calcVat | customDecimal }},- </span>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Input.Mark-up' | translate }} ({{ combine.combinedOffer.pricingComponents.markup.value }}%)
          <button
            (click)="editPriceSystemModal(false, combine.combinedOffer.pricingComponents.markup.value)"
            class="newest-button newest-button-sm newest-button--outline ml-8"
          >
            {{ 'Shared.Button.Edit' | translate }} %
          </button>
        </span>
        <span class="cost mr-8"> {{ combine.combinedOffer.calcMarkup | customDecimal }},- </span>
        <atl-custom-checkbox
          [(ngModel)]="combine.combinedOffer.pricingComponents.markup.includeInTotal"
          sizeInput="small"
          uniqID="markup"
          class="newest-checkbox"
          [ngbTooltip]="'Tooltip.Visible_for_buyer' | translate"
          placement="left"
          [disableTooltip]="!combine.combinedOffer.pricingComponents.markup.includeInTotal"
          triggers="hover touchstart"
        ></atl-custom-checkbox>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Shared.Change_request.Combine_Offer.Calculation_cost' | translate }}
        </span>
        <input
          class="header__input"
          type="text"
          placeholder="{{'Shared.Entity.Add_price' | translate}} ({{'Button.Required' | translate}})"
          [(ngModel)]="combine.combinedOffer.pricingComponents.calculationCost.value"
          [mask]="mask"
          suffix=",-"
          [separatorLimit]="separatorLimit"
          thousandSeparator=" "
        />
        <atl-custom-checkbox
          [(ngModel)]="combine.combinedOffer.pricingComponents.calculationCost.includeInTotal"
          sizeInput="small"
          uniqID="calculationCost"
          class="newest-checkbox"
          [ngbTooltip]="'Tooltip.Visible_for_buyer' | translate"
          placement="left"
          [disableTooltip]="!combine.combinedOffer.pricingComponents.calculationCost.includeInTotal"
          triggers="hover touchstart"
        ></atl-custom-checkbox>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Shared.Change_request.Combine_Offer.Administration_costs' | translate }}
        </span>
        <input
          class="header__input"
          type="text"
          placeholder="{{'Shared.Entity.Add_price' | translate}} ({{'Button.Required' | translate}})"
          [(ngModel)]="combine.combinedOffer.pricingComponents.administrationCost.value"
          [mask]="mask"
          suffix=",-"
          [separatorLimit]="separatorLimit"
          thousandSeparator=" "
        />
        <atl-custom-checkbox
          [(ngModel)]="combine.combinedOffer.pricingComponents.administrationCost.includeInTotal"
          sizeInput="small"
          uniqID="administrationCost"
          class="newest-checkbox"
          [ngbTooltip]="'Tooltip.Visible_for_buyer' | translate"
          placement="left"
          [disableTooltip]="!combine.combinedOffer.pricingComponents.administrationCost.includeInTotal"
          triggers="hover touchstart"
        ></atl-custom-checkbox>
      </div>
      <ng-container *ngFor="let item of combine.combinedOffer.pricingComponents.additionalCosts; index as i">
        <div class="header mt-8">
          <span class="header__title d-flex min-w-0">
            <span
              #costName
              class="d-block ellipsis"
              [ngbTooltip]="item.name"
              [disableTooltip]="costName.offsetWidth >= costName.scrollWidth"
              triggers="hover touchstart"
            >
              {{ item.name }}
            </span>
            <button (click)="openAddCostModal(i, item)" class="header__custom header__custom-edit ml-8">
              <img src="assets/edit-gray.svg" alt="edit" />
            </button>
            <button
              (click)="combine.combinedOffer.pricingComponents.removeAdditionalCosts(i)"
              class="header__custom header__custom-remove"
            >
              <img src="assets/trash-icon-sm.svg" alt="remove" />
            </button>
          </span>
          <span class="cost"> {{ item.value | customDecimal }},- </span>
        </div>
      </ng-container>
      <div class="header mt-8">
        <span (click)="openAddCostModal()" class="header__button">
          <img class="mr-8" src="assets/plus-icon-v2.svg" alt="plus icon" />
          {{ 'Shared.Change_request.Combine_Offer.Add_costs' | translate }}
        </span>
      </div>

      <div class="combine-offer__total border-top mt-8">
        <span class="combine-offer__total-title">
          {{ 'Shared.Title.Total_price' | translate }}
        </span>
        <span class="combine-offer__total-title cost"> {{ combine.combinedOffer.total | customDecimal }},- </span>
      </div>

      <div class="mt-24">
        <label for="offer-expiration" class="mb-16 d-flex fz-16 lh-24 fw-600">
          {{ 'Title.Offer_expiration' | translate }} ({{'Shared.Button.Optional' | translate}})
        </label>
        <atl-input-date-time-picker
          id="offer-expiration"
          [(ngModel)]="combine.combinedOffer.expirationDate"
          [placeholder]="'Shared.Input.Select.Date' | translate"
          [min]="today"
          [firstDayOfWeek]="1"
        >
        </atl-input-date-time-picker>
      </div>
      <div class="mt-24">
        <atl-document-drag-and-drop
          class="offer-attachment"
          [(ngModel)]="combine.combinedOffer.fileResourcesAttributes"
          [newestViewImage]="true"
          label="{{'Shared.Messages.Attachments' | translate}} ({{'Shared.Button.Optional' | translate}})"
          [format]="acceptedExtensions"
          [asUploadButton]="true"
          [maxUploadedFiles]="5"
          [multiple]="true"
        >
        </atl-document-drag-and-drop>
      </div>
    </div>
  </div>
</div>

<atl-ngb-modal-footer>
  <div class="w-100 d-flex justify-content-end">
    <button (click)="closeModal()" class="newest-button button-gray fz-16 mr-16 br-32">
      {{ 'Button.Cancel' | translate }}
    </button>
    <atl-button-loader
      [newest]="true"
      (clickButton)="sendOffer()"
      [disabled]="!combine?.combinedOffer?.combineValidators"
      [loading]="loading"
      label="Shared.Change_request.Combine_Offer.Button"
      [showTooltip]="combine && !combine?.combinedOffer?.combineValidators"
      [tooltip]="buttonTooltip"
    ></atl-button-loader>
  </div>
</atl-ngb-modal-footer>

<ng-template #buttonTooltip>
  <p class="tooltip-title">{{ 'Shared.Reclamation.Required_fields' | translate }}:</p>
  <ul class="tooltip-ul">
    <ng-container *ngFor="let subOffer of combine?.combinedOffer.subOffersAttributes">
      <li *ngIf="!subOffer.declined">
        <span>{{ subOffer.identifier | noProjectSegmentInChangeRequestIdentifier }} · </span>
        <span>{{ 'Entity.Price' | translate }} · </span>
        <span>{{ 'Shared.Change_request.Combine_Offer.Label.Floor_plan_version' | translate }}</span>
      </li>
    </ng-container>
    <li>{{ 'Shared.Change_request.Combine_Offer.Calculation_cost' | translate }}</li>
    <li>{{ 'Shared.Change_request.Combine_Offer.Administration_costs' | translate }}</li>
  </ul>
</ng-template>
