import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableModule } from '@atlas-workspace/data-table';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedFormModule } from '@atlas-workspace/shared/form';
import {
  FirmIconComponent, HeaderIconComponent,
  NotFoundModule,
  SharedTableComponent,
  TableSortModule,
  TooltipComponent
} from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ThreadViewRecipientsComponent } from './thread-view-recipients.component';

@NgModule({
  declarations: [ThreadViewRecipientsComponent],
  exports: [ThreadViewRecipientsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedFormModule,
    SharedDirectivesModule,
    DataTableModule,
    TableSortModule,
    NotFoundModule,
    NgbTooltipModule,
    TooltipComponent,
    FirmIconComponent,
    SharedTableComponent,
    HeaderIconComponent
  ],
})
export class ThreadViewRecipientsModule {}
