import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FileDragNDropModule } from '@atlas-workspace/shared/directives';
import { TranslateModule } from '@ngx-translate/core';

import { InputFileDragDropComponent } from '../input-file-drag-drop/input-file-drag-drop.component';

@Component({
  selector: 'atl-newest-drag-and-drop',
  templateUrl: './newest-drag-and-drop.component.html',
  styleUrls: ['./newest-drag-and-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewestDragAndDropComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, FileDragNDropModule, TranslateModule],
})
export class NewestDragAndDropComponent extends InputFileDragDropComponent implements ControlValueAccessor {
  dragLeave(event: DragEvent): void {
    this.stopDefault(event);
    const path = (event as any)?.target?.classList.contains('input-file__wrapper');
    if (path) {
      this.dragleaveHandle.emit();
    }
  }

  dropFile(event: DragEvent): void {
    this.stopDefault(event);
    if (event.dataTransfer?.files?.length) {
      const files: File[] = Array.from(event.dataTransfer.files);
      this.loadValidFiles(files);
    }
  }
}
