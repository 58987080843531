/* eslint-disable */
import {
  CDK_ROW_TEMPLATE,
  CdkFooterRow,
  CdkFooterRowDef,
  CdkHeaderRow,
  CdkHeaderRowDef,
  CdkRow,
  CdkRowDef,
} from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, Directive, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: '[atlHeaderRowDef]',
  providers: [{ provide: CdkHeaderRowDef, useExisting: AtlHeaderRowDef }],
  inputs: ['columns: atlHeaderRowDef', 'sticky: atlaHeaderRowDefSticky'],
})
export class AtlHeaderRowDef extends CdkHeaderRowDef {}

@Directive({
  selector: '[atlFooterRowDef]',
  providers: [{ provide: CdkFooterRowDef, useExisting: AtlFooterRowDef }],
  inputs: ['columns: atlFooterRowDef', 'sticky: atlFooterRowDefSticky'],
})
export class AtlFooterRowDef extends CdkFooterRowDef {}

@Directive({
  selector: '[atlRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: AtlRowDef }],
  inputs: ['columns: atlRowDefColumns', 'when: atlRowDefWhen'],
})
export class AtlRowDef<T> extends CdkRowDef<T> {}

@Component({
  selector: 'atl-header-row, tr[atl-header-row]',
  template: CDK_ROW_TEMPLATE,
  host: {
    class: 'atl-header-row',
    role: 'row',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'atlHeaderRow',
  providers: [{ provide: CdkHeaderRow, useExisting: AtlHeaderRow }],
})
export class AtlHeaderRow extends CdkHeaderRow {}

@Component({
  selector: 'atl-footer-row, tr[atl-footer-row]',
  template: CDK_ROW_TEMPLATE,
  host: {
    class: 'atl-footer-row',
    role: 'row',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'atlFooterRow',
  providers: [{ provide: CdkFooterRow, useExisting: AtlFooterRow }],
})
export class AtlFooterRow extends CdkFooterRow {}

@Component({
  selector: 'atl-row, tr[atl-row]',
  template: CDK_ROW_TEMPLATE,
  host: {
    class: 'atl-row',
    role: 'row',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'atlRow',
  providers: [{ provide: CdkRow, useExisting: AtlRow }],
})
export class AtlRow extends CdkRow {}
