import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { TranslateService } from '@ngx-translate/core';
import * as mime from 'mime';
import { Observable } from 'rxjs';

import { ToasterService } from '../toaster/toaster.service';

@Injectable({
  providedIn: 'root',
})
export class FileLoadService {
  constructor(
    private readonly http: HttpClient,
    @Inject('ENVIRONMENT') private readonly environment: IEnvironment,
    private translateService: TranslateService,
    private toasterService: ToasterService
  ) {}

  fileLoad(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  async urlToFile(url: string, filename: string, id: any): Promise<File> {
    const _file =  new File([await (await fetch(url)).blob()], filename);
    //todo: implement in next MR
    //@ts-ignore
    _file.id = id;
    return _file;
  }

  proxyLoad(url: string): Observable<Blob> {
    return this.http.get(`${this.environment.apiBaseUrl}/api/v1/files/proxy`, {
      responseType: 'blob',
      params: {
        link: url,
      },
    });
  }

  public copyFileFromModel(props: { url: string; name: string }): Promise<void> {
    return fetch(props.url)
      .then((response) => response.blob())
      .then((blob) => {
        const imagename = props.name;
        const imagetype = mime.getType(props.name)!;

        if (blob.type === 'application/octet-stream') {
          blob = new Blob([blob], { type: imagetype });
        }
        if (imagetype === 'image/jpeg') {
          blob = new Blob([blob], { type: 'image/png' });
          const clipboardItem = new ClipboardItem({
            [blob.type]: blob,
          });
          navigator.clipboard.write([clipboardItem]).then(() => {
            this.toasterService.openSuccessToast(
              this.translateService.instant('Shared.Description.Image_copied_to_clipboard')
            );
          });
        } else if (imagetype === 'image/png') {
          const clipboardItem = new ClipboardItem({
            [blob.type]: blob,
          });
          navigator.clipboard.write([clipboardItem]).then(() => {
            this.toasterService.openSuccessToast(
              this.translateService.instant('Shared.Description.Image_copied_to_clipboard')
            );
          });
        } else {
          //* Fallback for unsupported ClipboardItem types
          const blobUrl = URL.createObjectURL(blob);
          const clipboardItem = new ClipboardItem({
            ['text/html']: new Blob([`<img src="${blobUrl}" _tmpname="${imagename}" _tmptype="${imagetype}">`], {
              type: 'text/html',
            }),
          });
          navigator.clipboard.write([clipboardItem]).then(() => {
            this.toasterService.openSuccessToast(
              this.translateService.instant('Shared.Description.Image_copied_to_clipboard')
            );
          });
        }
      });
  }
}
