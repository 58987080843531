import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdminModel,
  CombinedMeetingsFilters,
  EMeetingAttendeeRoles,
  EMeetingKind,
  EMeetingStatusValue,
  EReclamationReportType,
  getTimeProjects,
  IBatchCreate,
  IBatchMeetingsAttributes,
  IBodyMeetingModel,
  IRequestMeetingUpdate,
  ITablePagination,
  IUnionMeetingsLisResponse,
  IVisibleForList,
  MeetingAttendeeConfig,
  MeetingAttendeeModel,
  MeetingModel,
  MeetingsDownloadModel,
  MeetingsFilterCounter,
  MeetingsFilterParamType,
  MeetingsModel,
  MeetingTypeModel,
  ProxyMemberModel,
  UnionListModel,
  UnionModel,
} from '@atlas-workspace/shared/models';
import { AuthAdminService, DataTableHelperService } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import dayjs from 'dayjs';
import { BehaviorSubject, concat, mergeMap, Observable, of, reduce } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeetingsService {
  public updatesPagination$ = new BehaviorSubject<ITablePagination | null>(null);

  constructor(
    private http: HttpClient,
    private authAdminService: AuthAdminService,
    private tableService: DataTableHelperService,
  ) {}

  createMeeting(projectId: string, meeting: IBodyMeetingModel): Observable<MeetingModel> {
    const body = {
      meeting: meeting,
    };
    return this.http
      .post<{ data: MeetingModel }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings`, body)
      .pipe(
        map((res) => res?.data),
        map((data) => plainToClass(MeetingModel, data)),
      );
  }

  getMeeting(projectId: string, id: number): Observable<MeetingModel> {
    return this.http
      .get<{ data: MeetingModel }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings/${id}`)
      .pipe(
        map((res) => res?.data),
        map((data) => plainToClass(MeetingModel, data)),
      );
  }

  resendInviteMeeting(projectId: string, id: number): Observable<any> {
    return this.http
      .patch(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings/${id}/resend_invite`, {});
  }

  getMeetingsState(
    projectId: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
    filter?: CombinedMeetingsFilters,
  ): Observable<any> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (filter) {
      for (const key in filter) {
        if (filter[<EMeetingStatusValue>key]) {
          if (EMeetingStatusValue.Today === key) {
            const today = dayjs().format('DD.MM.YYYY');
            params = params.append('max_date', today);
            params = params.append('min_date', today);
          } else if (Object.values(EMeetingStatusValue).includes(key as EMeetingStatusValue)) {
            params = params.append('status', key);
          }
        }
      }

      if (filter.unitsIds?.length) {
        filter.unitsIds.forEach(item => {
          params = params.append('unit_ids[]', String(item));
        });
      }
      if (filter.unitGroupIds?.length) {
        filter.unitGroupIds.forEach(item => {
          params = params.append('layout_type_ids[]', String(item));
        });
      }
    }
    return this.http.get<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings`, {
      params,
      observe: 'response',
    });
  }

  createMeetingType(type: string): Observable<MeetingTypeModel> {
    const firmId = this.authAdminService.firm?.firmId;
    const body = {
      meeting_type: {
        name: type,
      },
    };

    return this.http
      .post<{ data: MeetingTypeModel }>(`${environment.apiBaseUrl}api/v1/firms/${firmId}/meeting_types`, body)
      .pipe(
        map((res) => res?.data),
        map((data) => plainToClass(MeetingTypeModel, data)),
      );
  }

  getMeetingTypes(): Observable<MeetingTypeModel[]> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        per_page: '100',
      },
    });
    const firmId = this.authAdminService.firm?.firmId;
    return this.http
      .get<{ data: { meeting_types: MeetingTypeModel[] } }>(
        `${environment.apiBaseUrl}api/v1/firms/${firmId}/meeting_types`,
        {
          params,
        },
      )
      .pipe(
        map((res) => res?.data?.meeting_types),
        map((data: MeetingTypeModel[]) => plainToClass(MeetingTypeModel, data)),
      );
  }

  removeMeeting(projectId: string, id: number): Observable<any> {
    return this.http.delete<{ data: MeetingModel }>(
      `${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings/${id}`,
    );
  }

  removeMeetings(projectId: string, ids: number[]): Observable<any> {
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => (params = params.append('ids[]', id.toString())));
    return this.http.delete<{ data: MeetingModel }>(
      `${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings/batch_destroy`,
      {
        params,
      },
    );
  }

  updateMeeting(
    projectId: string,
    meeting: IRequestMeetingUpdate,
    id: number,
    sendSMS: boolean,
  ): Observable<MeetingModel> {
    const body = {
      meeting: {
        ...meeting,
        send_via_sms: sendSMS,
      },
    };
    return this.http
      .patch<{ data: MeetingModel }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings/${id}`, body)
      .pipe(
        map((res) => res?.data),
        map((data) => plainToClass(MeetingModel, data)),
      );
  }

  batchCreate(projectId: number, formValue: any, sendSMS: boolean, isGroup: boolean): Observable<MeetingsModel[]> {
    const visibleUnits = formValue.visibleFor
      .filter((item: IVisibleForList) => !item.group)
      .map((g: IVisibleForList) => g.originalId);
    const visibleGroup = formValue.visibleFor
      .filter((item: IVisibleForList) => item.group)
      .map((g: IVisibleForList) => g.originalId);

    const meetingsAttributes = formValue.availability.map((item: any) => {
      const attributes: IBatchMeetingsAttributes = {
        start_at_in_timezone: getTimeProjects(item.date, item.time.from),
        end_at_in_timezone: getTimeProjects(item.date, item.time.to),
        kind: isGroup ? EMeetingKind.Group : EMeetingKind.Single,
      };
      if (item.limit) attributes.max_units = item.limit;
      if (item.for?.length) attributes.unit_ids = item.for;
      return attributes;
    });

    const [create, ...rest] = this.chunkArray<IBatchMeetingsAttributes>(meetingsAttributes);

    const body: IBatchCreate = {
      meetings_union: {
        title: formValue.title,
        message: formValue.message,
        assignee_id: formValue.responsible[0].id || null,
        protocols_template_id: formValue.protocol?.length ? formValue.protocol[0]?.id : null,
        invite_is_sent: formValue.invite,
        guest_ids: formValue.guests?.map((g: any) => g.adminId || g.id) || [],
        visible_to_unit_ids: visibleUnits,
        visible_to_unit_group_ids: visibleGroup,
        confirmation: formValue.customer,
        send_via_sms: sendSMS,
        meetings_attributes: create,
      },
    };

    return this.http
      .post<{ data: UnionModel }>(environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings_unions`, body)
      .pipe(
        map((res) => res.data),
        mergeMap((data) => {
          if (rest.length) {
            const restReq = rest.map((item) =>
              this.addMeetingsAttributesToUnion(projectId, data.id, item, formValue.customer),
            );
            return concat(...restReq);
          } else {
            return of(data);
          }
        }),
        reduce((all: UnionModel, current: UnionModel) => current),
        map((data) => plainToClass(MeetingsModel, data.meetings)),
      );
  }

  addMeetingsAttributesToUnion(
    projectId: number,
    unionId: number,
    attributes: any[],
    confirmation: boolean,
  ): Observable<UnionModel> {
    const body = {
      meetings_union: {
        id: unionId,
        confirmation: confirmation,
        meetings_attributes: attributes,
      },
    };

    return this.http
      .post<{
        data: UnionModel;
      }>(environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings_unions/add_meeets`, body)
      .pipe(map((res) => res.data));
  }

  private chunkArray<T>(array: T[], chunkSize = 20): T[][] {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }

    return result;
  }

  downloadMeetings(
    projectId: number,
    type = EReclamationReportType.PDF,
    sort = '',
    search = '',
    pagination?: ITablePagination,
    filter?: MeetingsFilterParamType,
  ): Observable<any> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, pagination);

    params = params.set('report_type', type);

    if (filter) {
      for (const status in filter) {
        if (filter[<EMeetingStatusValue>status]) {
          if (EMeetingStatusValue.Today === status) {
            const today = dayjs().format('DD.MM.YYYY');
            params = params.append('max_date', today);
            params = params.append('min_date', today);
          } else {
            params = params.append('status', status);
          }
        }
      }
    }

    return this.http
      .post(environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings/report`, null, {
        params,
      })
      .pipe(
        map((response: any) => {
          const document = response && response.document;
          return plainToClass(MeetingsDownloadModel, document);
        }),
      );
  }

  getUnionMeetings(
    projectId: string,
    assigneeIds: number[],
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
  ): Observable<IUnionMeetingsLisResponse> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);

    if (assigneeIds.length) {
      assigneeIds.forEach((assignee) => {
        params = params.append('assignee_ids[]', assignee.toString());
      });
    }

    return this.http
      .get<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings_unions`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((result) => {
          const pagination = this.tableService.getPagination(result);
          const meetings = plainToClass(UnionListModel, result.body?.data?.meetings_unions as UnionListModel[]);
          return { pagination, meetings };
        }),
      );
  }

  getUnionMeeting(projectId: string, id: number): Observable<UnionModel> {
    return this.http
      .get<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings_unions/${id}`)
      .pipe(map((res) => plainToClass(UnionModel, res?.data)));
  }

  setUnionMeeting(projectId: string, id: number, body: any): Observable<UnionModel> {
    return this.http
      .patch<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings_unions/${id}`, {
        meetings_union: body,
      })
      .pipe(map((res) => plainToClass(UnionModel, res?.data)));
  }

  unionResendInvite(projectId: string, id: number): Observable<any> {
    return this.http.patch<any>(
      `${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings_unions/${id}/resend_invite`,
      {},
    );
  }

  removeUnion(projectId: string, id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings_unions/${id}`);
  }

  getMeetingsCounter(projectId: string): Observable<MeetingsFilterCounter> {
    return this.http
      .get<{
        data: MeetingsFilterCounter;
      }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meetings_unions/index_counter`)
      .pipe(map((res) => plainToClass(MeetingsFilterCounter, res.data)));
  }

  /**
   * https://api.journeyapp.dev.scrij.com/api-docs#tag/Meeting-Protocol-Attendee-Configs/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meeting_protocol_attendee_configs/get **/
  getMeetingAttendeeConfig(projectId: string): Observable<MeetingAttendeeConfig> {
    return this.http
      .get<{
        data: MeetingAttendeeConfig;
      }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meeting_protocol_attendee_configs`)
      .pipe(
        map((res) => plainToClass(MeetingAttendeeConfig, res.data)
      ));
  }

  /**
   * https://api.journeyapp.dev.scrij.com/api-docs#tag/Meeting-Protocol-Attendee-Configs/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meeting_protocol_attendee_configs/post */
  setMeetingAttendeeConfig(projectId: string, attendee: ProxyMemberModel | AdminModel, role: EMeetingAttendeeRoles): Observable<MeetingAttendeeModel> {
    const objToSend = {
      admin_id: attendee.id,
      role: role,
      project_id: projectId
    };
    const body = {
      project_meeting_protocol_attendee_config: objToSend,
    };
    return this.http
      .post<{ data: MeetingAttendeeModel }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/meeting_protocol_attendee_configs`, body)
      .pipe(
        map((res) => res?.data),
        map((data) => plainToClass(MeetingAttendeeModel, data)),
      );
  }

  /**
   * https://api.journeyapp.dev.scrij.com/api-docs#tag/Meeting-Protocol-Attendee-Configs/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meeting_protocol_attendee_configs~1%7Bid%7D/delete
   */
  removeMeetingAttendee(projectId: string, id: number): Observable<any> {
    return this.http.delete<{ data: MeetingAttendeeModel }>(
      `${environment.apiBaseUrl}api/v1/projects/${projectId}/meeting_protocol_attendee_configs/${id}`,
    );
  }
}
