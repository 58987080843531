<div
  class="time-select"
  atlClickOutside
  (clickOutside)="close()"
  [class.active]="availabilitySettings ? showFromList || showToList : false"
>
  <div class="time-select__input">
    <input
      #from
      [(ngModel)]="fromValue"
      type="text"
      [mask]="mask"
      (focus)="focusFormList(true, fromListEl)"
      (input)="inputFrom(from.value)"
      (keydown.enter)="enterFrom()"
      (keydown.tab)="enterFrom(false)"
      [readOnly]="disabled"
      [placeholder]="availabilitySettings ? placeholder : ('Title.Select_time' | translate)"
    />
    <ul #fromListEl class="time-select__input-select from v-scrollbar small" [class.show]="showFromList">
      <li
        *ngFor="let time of fromList"
        (click)="selectFrom($event, time)"
        [class.select]="fromValue ? time.value === fromValue : time.value === startValue"
        [class.disabled]="dateIsSelected ? time.hour * 60 + time.min < minTime.hour * 60 + minTime.min : false"
      >
        {{ time.value }}
      </li>
    </ul>
  </div>
  <ng-container *ngIf="!singleTime">
    <div class="time-select__line" *ngIf="!showFromList && !showToList"></div>
    <img *ngIf="availabilitySettings" class="time-select__arrow" src="assets/arrow-right-gray.svg" alt="arrow" />
    <div class="time-select__input">
      <input
        #to
        [(ngModel)]="toValue"
        type="text"
        [mask]="mask"
        (focus)="focusToList(true, toListEl)"
        (input)="inputTo(to.value)"
        (keydown.enter)="enterTo()"
        (keydown.tab)="enterTo()"
        [readOnly]="disabled"
        [placeholder]="availabilitySettings ? placeholder : ('Title.Select_time' | translate)"
      />
      <ul #toListEl class="time-select__input-select to v-scrollbar small" [class.show]="showToList">
        <li *ngFor="let time of toList" (click)="selectTo($event, time)" [class.select]="time.value === toValue">
          {{ time.value }}
          <span class="d-block ellipsis">({{ time.des }})</span>
        </li>
      </ul>
    </div>
    <img *ngIf="availabilitySettings" class="time-select__icon" src="assets/clock-gray.svg" alt="icon" />
  </ng-container>
</div>
