<ng-container *ngIf="type.increaseProducts?.length || type.decreaseProducts?.length">
  <div class="blocked-product-description mb-16">
    <img src="/assets/lighting-icon-sm.svg" alt="icon" class="mr-8 mt-4" />
    <span class="fz-16 lh-24 blocked-text">

      <ng-container *ngIf="type.increaseProducts?.length">
        <ng-container *ngIf="type.increaseProducts?.length === 1; else severalIncreaseItems">
          {{'Shared.Title.Sizing_rule_single_increase' | translate : {
            triggerName: type.name,
            dependableType: type.increaseProducts[0].itemableName,
          } }}
        </ng-container>

        <ng-template #severalIncreaseItems>
          {{ 'Shared.Title.Sizing_rule_msg' | translate : {
            triggerName: type.name,
            actionName: ('Title.Increase' | translate),
            dependableType: (type.increaseProducts | joinValues: 'itemableName'),
          } }}
        </ng-template>
      </ng-container>

      <ng-container *ngIf="type.decreaseProducts?.length">
        <ng-container *ngIf="type.increaseProducts?.length; else onlyDecreasingProducts">
          {{ 'Shared.Title.Sizing_rule_decrease_addition' | translate : {
            dependableType: (type.decreaseProducts | joinValues: 'itemableName'),
            amount: (type.decreaseProducts | joinValues: 'quantityOrAreaSize': type.name)
          } }}
        </ng-container>
      </ng-container>
    </span>
  </div>


  <ng-template #onlyDecreasingProducts>
    <ng-container *ngIf="type.decreaseProducts?.length === 1; else severalDecreaseItems">
        {{'Shared.Title.Sizing_rule_single_decrease' | translate : {
          triggerName: type.name,
          dependableType: type.decreaseProducts[0].itemableName,
        } }}
    </ng-container>
  </ng-template>
  <ng-template #severalDecreaseItems>
    {{ 'Shared.Title.Sizing_rule_msg' | translate : {
      triggerName: type.name,
      actionName: ('Title.Decrease' | translate),
      dependableType: (type.decreaseProducts | joinValues: 'itemableName'),
      amount: (type.decreaseProducts | joinValues: 'quantityOrAreaSize': type.name)
    } }}
  </ng-template>
</ng-container>
