import {CommonModule} from "@angular/common";
import { Component, Input, OnInit } from '@angular/core';
import {InfiniteScrollDirective} from "@atlas-workspace/shared/directives";
import {EUnitDetailsScope, EUnitEventLogType, UnitEventLogModel} from '@atlas-workspace/shared/models';
import {PaginationUtil, UnitHelperService, UnitsService} from "@atlas-workspace/shared/service";
import {FirmIconComponent} from "@atlas-workspace/shared/ui";
import {NgbDropdownModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {TranslateModule} from "@ngx-translate/core";
import { take } from 'rxjs/operators';

import { UnitAnalyticsFilterComponent } from "../unit-analytics-filter/unit-analytics-filter.component";

@UntilDestroy()
@Component({
  selector: 'atl-unit-analytics',
  templateUrl: './unit-analytics.component.html',
  styleUrls: ['./unit-analytics.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbDropdownModule,
    NgbTooltipModule,
    UnitAnalyticsFilterComponent,
    InfiniteScrollDirective,
    FirmIconComponent,
  ],
  providers: [UnitHelperService],
})
export class UnitAnalyticsComponent implements OnInit {
  @Input() scope: EUnitDetailsScope = EUnitDetailsScope.Units;
  @Input() public unitId!: number;
  @Input() set filterByType(type: EUnitEventLogType) {
    this.filters = { type: [type] };
    this.filterOnlyBySpecificType = true;
    this.pagination.currentPage = 1;
    queueMicrotask(() => this.getUnitsEventLogs());
  };

  public analyticsData!: UnitEventLogModel[];
  private readonly minCountItems = 5;
  public readonly maxDocumentNameLength = 50;
  public readonly scopes: typeof EUnitDetailsScope = EUnitDetailsScope;
  private pagination = PaginationUtil.defaultPagination;
  public filtersCounter!: number;
  private filters!: { type: string[]; date?: { from: string; to: string | null } };
  public isLoading = true;
  public filterOnlyBySpecificType = false;

  constructor(
    private unitsService: UnitsService,
    private unitHelperService: UnitHelperService,
  ) {}

  ngOnInit(): void {
    this.unitHelperService
      .isNeedUpdateList()
      .pipe(take(1))
      .subscribe((val: boolean) => {
        if (val) {
          this.getUnitsEventLogs();
          this.unitHelperService.setUpdateList(false);
        }
      });
  }

  public getUnitsEventLogs(): void {
    this.unitsService
      .getUnitEventLogs(this.unitId, undefined, undefined, this.pagination, this.filters)
      .pipe(untilDestroyed(this))
      .subscribe((logs: UnitEventLogModel[]) => {
        this.analyticsData = logs;

        this.isLoading = false;
      });
  }

  public loadMore(i: number): void {
    if (i === this.analyticsData.length - this.minCountItems) {
      if (this.pagination?.currentPage) {
        this.pagination.currentPage++;
        if (this.pagination?.currentPage > this.pagination?.totalPages) {
          return;
        }
      }
      this.unitsService
        .getUnitEventLogs(this.unitId, undefined, undefined, this.pagination, this.filters)
        .pipe(untilDestroyed(this))
        .subscribe((logs: UnitEventLogModel[]) => {
          this.analyticsData.push(...logs);
        });
    }
  }

  public filterEvent(data: { type: string[]; date: { from: string; to: string | null } }): void {
    this.isLoading = true;
    this.filters = data;
    this.filtersCounter = data.type.length + (data.date ? 1 : 0);
    if (this.pagination) this.pagination.currentPage = 1;

    this.getUnitsEventLogs();
  }
}
