<atl-ngb-modal-header [title]="modalInfo?.title" (closeModalEvent)="modalRef.close()" [isClient]="true"></atl-ngb-modal-header>

<form
  [formGroup]="form"
  class="reclamation__form v-scrollbar"
  [class.decline]="status === statusKeys.Declined"
  [class.only-view]="onlyView"
>
  <div class="reclamation__control" *ngIf="status === statusKeys.Declined && onlyView">
    <label for="reclamation-decline-reason" class="reclamation__form-label">
      <span>{{ 'Shared.Reclamation.Decline_reason_label' | translate }}</span>
    </label>
    <atl-input formControlName="reason" [readOnly]="true"></atl-input>
  </div>

  <div class="reclamation__control">
    <label for="reclamation-decline-comment" class="reclamation__form-label">
      <span>{{ 'Shared.Reclamation.Decline_comment_label' | translate }}</span>
    </label>
    <atl-custom-text-area
      tabindex="0"
      formControlName="comment"
      class="reclamation__control-textarea"
      id="reclamation-decline-comment"
      [placeholder]="'Shared.Reclamation.Add_comment_placeholder'"
    ></atl-custom-text-area>
  </div>

  <div class="reclamation__control">
    <div class="reclamation__form-attachments" *ngIf="!onlyView; else viewAttachments">
      <atl-document-drag-and-drop
        class="create-task__input-file"
        [newestViewImage]="true"
        [label]="'Shared.Messages.Attachments'"
        [format]="acceptedExtensions"
        [asUploadButton]="true"
        formControlName="files"
        [maxUploadedFiles]="maxAttachmentsAmount"
        [multiple]="true"
      >
      </atl-document-drag-and-drop>
    </div>
  </div>
</form>

<atl-ngb-modal-footer *ngIf="!onlyView">
  <div class="w-100 d-flex justify-content-end">
    <button
      (click)="modalRef.close()"
      class="newest-button button-gray mr-16"
      [class.loading]="isLoading"
      [disabled]="isLoading"
      data-cy="close-create-reclamation-button"
    >
      {{ 'Shared.Button.Cancel' | translate }}
    </button>
    <button
      (click)="disputeReclamation()"
      class="newest-button-spinner button-red fz-16 lh-24 br-32"
      [ngClass]="{ 'loading pointer-events-none': isLoading }"
      [disabled]="form.invalid"
      data-cy="decline-reclamation-button"
    >
      {{ modalInfo.button | translate }}
    </button>
  </div>
</atl-ngb-modal-footer>

<ng-template #viewAttachments>
  <div class="reclamation__control">
    <label class="reclamation__form-label">
      <span>{{ 'Shared.Reclamation.Decline_attachments_label' | translate }}</span>
    </label>
    <ng-container *ngIf="form?.value?.files.length; else emptyAttachments">
      <div class="reclamation__form-attachments">
        <atl-newest-document-card
          *ngFor="let file of form?.value?.files"
          (click)="openFilePreview(file)"
          class="thread-attachments__item-full-width"
          [downloadLink]="file.fileName?.downloadUrl"
          [fileName]="file.name | truncate: maxNameLength"
          [fileSize]="file.fileSize"
          [fileType]="file.fileExtension"
          [hasRemove]="false"
        >
        </atl-newest-document-card>
      </div>
    </ng-container>
    <ng-template #emptyAttachments>
      <span class="reclamation__empty-field">{{ 'Shared.Reclamation.No_attachments' | translate }}</span>
    </ng-template>
  </div>
</ng-template>
