import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangeFormatDatePipe } from '@atl-libs-shared-pipes/change-formate-date/change-format-date.pipe';
import { SharedPipesModule } from '@atl-libs-shared-pipes/shared-pipes.module';
import { JoinStringArrayPipeModule } from '@atl-libs-shared-pipes/string-array-formatter/string-arrat-formatter.module';
import { UnderscoreReplacerModule } from '@atl-libs-shared-pipes/underscore-replacer/underscore-replacer.module';
import { DataTableModule } from '@atlas-workspace/data-table';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import {
  DrawingMarkComponent,
  FilterDropdownModule,
  InputRadioComponent, MeetingDataPickerComponent,
  NewestTextEditorModule,
  NgxIntlTelInputModule,
  SharedFormModule,
  TextEditorWideComponent, UnitAnalyticsComponent,
} from '@atlas-workspace/shared/form';
import {SharedModalsModule} from '@atlas-workspace/shared/modals';
import {NoProjectSegmentInChangeRequestIdentifierPipe} from "@atlas-workspace/shared/pipes";
import { CustomNotesService } from '@atlas-workspace/shared/service';
import {
  ActionMessageBannerComponent,
  ButtonLoaderComponent,
  CustomIconComponent,
  FirmIconComponent,
  HeaderIconComponent,
  NgbModalFooterComponent,
  NgbModalHeaderComponent,
  NotFoundModule,
  ProgressLoaderModule,
  RoundDropdownComponent,
  SharedTableComponent,
  SimpleTabsComponent,
  TableSortModule,
  UsersViewerComponent,
} from '@atlas-workspace/shared/ui';
import { NgbDropdownItem, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { ChangeRequestActivityComponent } from './change-request-activity/change-request-activity.component';
import { ChangeRequestCombineOfferComponent } from './change-request-combine-offer/change-request-combine-offer.component';
import { ChangeRequestCustomerNotesComponent } from './change-request-customer-notes/change-request-customer-notes.component';
import {
  ChangeRequestGroupDetailsComponent
} from "./change-request-group-details/change-request-group-details.component";
import { ChangeRequestInternalNotesComponent } from './change-request-internal-notes/change-request-internal-notes.component';
import { CombinePricingModalComponent } from './combine-pricing-modal/combine-pricing-modal.component';
import { CreateOfferComponent } from './create-offer/create-offer.component';
import { DetailChangeRequestComponent } from './detail-change-request/detail-change-request.component';
import { GroupChangeRequestModalComponent } from './group-change-request-modal/group-change-request-modal.component';
import { GroupCreatorModalComponent } from './group-creator-modal/group-creator-modal.component';
import { GroupMarkFloorPlanModalComponent } from './group-mark-floor-plan-modal/group-mark-floor-plan-modal.component';
import { OfferDetailComponent } from './offer-detail/offer-detail.component';
import { OffersListComponent } from './offers-list/offers-list.component';
import {SubOfferDetailComponent} from "./sub-offer-detail/sub-offer-detail.component";
import {SubOfferListComponent} from "./sub-offer-list/sub-offer-list.component";

const components = [
  DetailChangeRequestComponent,
  OffersListComponent,
  OfferDetailComponent,
  ChangeRequestCustomerNotesComponent,
  ChangeRequestInternalNotesComponent,
  ChangeRequestActivityComponent,
  CreateOfferComponent,
  GroupCreatorModalComponent,
  GroupChangeRequestModalComponent,
  GroupMarkFloorPlanModalComponent,
  ChangeRequestCombineOfferComponent,
  CombinePricingModalComponent,
  SubOfferListComponent,
  SubOfferDetailComponent,
  ChangeRequestGroupDetailsComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedFormModule,
    JoinStringArrayPipeModule,
    UnderscoreReplacerModule,
    SharedPipesModule,
    TableSortModule,
    ReactiveFormsModule,
    FormsModule,
    SharedDirectivesModule,
    DataTableModule,
    NgbTooltipModule,
    NotFoundModule,
    SharedModalsModule,
    FilterDropdownModule,
    ProgressLoaderModule,
    NewestTextEditorModule,
    TranslateModule,
    ChangeFormatDatePipe,
    TextEditorWideComponent,
    SimpleTabsComponent,
    ActionMessageBannerComponent,
    DrawingMarkComponent,
    ButtonLoaderComponent,
    HeaderIconComponent,
    NgbModalFooterComponent,
    FirmIconComponent,
    SharedTableComponent,
    InputRadioComponent,
    NgbModalHeaderComponent,
    NgbDropdownItem,
    RoundDropdownComponent,
    UsersViewerComponent,
    CustomIconComponent,
    NgxMaskDirective,
    NgxIntlTelInputModule,
    MeetingDataPickerComponent,
    UnitAnalyticsComponent,
    NoProjectSegmentInChangeRequestIdentifierPipe,
  ],
  declarations: [...components],
  providers: [CustomNotesService],
  exports: [...components],
})
export class SharedChangeRequestsAdminModule {}
