<div class="header d-flex align-items-center justify-content-between">
  <div>
    <h2 class="fz-16 lh-24 fw-500">{{ headerData?.title }}</h2>
    <p class="fz-12 lh-16 header__date">{{ 'Button.Update' | translate }} {{ headerData?.subtitle | date }}</p>
  </div>

  <div class="d-flex gap-4">
    <ng-container *ngIf="messageView">
      <atl-header-icon
        *ngIf="!slides[this.index]?.fileResources?.length"
        icon="/assets/message-icon-gray-sm.svg"
        [class.active]="showComment"
        (click)="toggleComment()"
        [ngbTooltip]="'Shared.Entity.Comment' | translate"
        triggers="hover touchstart"
        [openDelay]="tooltipDelay"
      ></atl-header-icon>
    </ng-container>
    <atl-header-icon
      *ngIf="!slides[index].fileResources?.length"
      icon="/assets/copy-link.svg"
      [ngbTooltip]="'Shared.Description.Copy_image' | translate"
      [openDelay]="tooltipDelay"
      placement="bottom"
      triggers="hover touchstart"
      [class.client]="isClient"
      (click)="onCopyImage()"
    ></atl-header-icon>
    <a
      tabindex="-1"
      class="header__action-link"
      [ngbTooltip]="'Shared.Entity.Download' | translate"
      [openDelay]="tooltipDelay"
      [href]="headerData.downloadLink"
      triggers="hover touchstart"
      download
    >
      <atl-header-icon icon="/assets/download-icon-new.svg"></atl-header-icon>
    </a>
    <atl-header-icon
      icon="/assets/trash-icon-sm.svg"
      (click)="deleteImage()"
      [ngbTooltip]="translates.buttonDelete"
      triggers="hover touchstart"
      [openDelay]="tooltipDelay"
    ></atl-header-icon>
    <img class="header__action" src="/assets/separator.svg" alt="icon" />
    <atl-header-icon
      icon="/assets/close-icon.svg"
      (click)="closeModal()"
      [ngbTooltip]="'Alt.Close' | translate"
      triggers="hover touchstart"
      [openDelay]="tooltipDelay"
    ></atl-header-icon>
  </div>
</div>
<div class="d-flex w-100">
  <div class="swiper-container-wrapper w-100 position-relative">
    <swiper-container
      atlSwiper
      [config]="swiperConfig"
      [slides]="slides"
      init="false"
      class="swiper-container"
      [ngClass]="{ invisible: !loaded }"
      (indexChanged)="onIndexChange($event); cancelComment()"
    >
      <swiper-slide *ngFor="let slide of slides; let i = index">
        <div #container *ngIf="i === index" class="images">
          <ng-container *ngIf="!slide.fileResources?.length; else emptyVideo">
            <ng-container *ngIf="!(slide.name | isRaw); else emptyRaw">
              <img (load)="onLoad()" [src]="slide.fileName.url" alt="image" class="slide" />
            </ng-container>
          </ng-container>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
  <div *ngIf="showComment" class="comment comment__block">
    <div class="comment__block-header">
      {{ 'Shared.Entity.Comment' | translate }}
    </div>
    <div class="comment__view d-flex flex-column justify-content-between">
      <ng-container *ngIf="!editMode && slides[index]?.comment as comment; else commentBlock">
        <div class="d-inline-flex align-items-start justify-content-between">
          <div class="comment__view-content">
            <atl-firm-icon
              class="monitoring mr-8"
              [isUser]="true"
              [model]="comment.author"
              nameKey="name"
              [isMonochrome]="true"
            ></atl-firm-icon>
            <div class="d-flex flex-column min-w-0">
              <span class="fz-14 comment__name">{{ comment.author.name }}</span>
              <span *ngIf="updated; else now" class="fz-12 lh-16 comment__date">{{
                comment.updatedAt | timePassed
              }}</span>
            </div>
          </div>

          <atl-default-dropdown>
            <div #dropdown="ngbDropdown" ngbDropdown container="body">
              <div class="cursor-pointer dropdown-trigger" id="defaultDropdown" ngbDropdownToggle>
                <atl-header-icon
                  icon="/assets/dots.svg"
                  [class.active]="dropdown._open"
                  [ngbTooltip]="'Shared.Button.More' | translate"
                  triggers="hover touchstart"
                  [openDelay]="tooltipDelay"
                ></atl-header-icon>
              </div>
              <div class="br-12" ngbDropdownMenu aria-labelledby="defaultDropdown">
                <button (click)="editComment()" ngbDropdownItem>{{ translates.buttonEdit }}</button>
                <span class="dropdown-divider"></span>
                <button (click)="deleteComment()" class="btn-delete" ngbDropdownItem>
                  {{ translates.buttonDelete }}
                </button>
              </div>
            </div>
          </atl-default-dropdown>
        </div>
        <p class="fz-14 lh-24 comment__text">{{ comment.body }}</p>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #commentBlock>
  <form [formGroup]="textareaForm" class="comment__add" (ngSubmit)="postComment()">
    <textarea
      atlReplaceInput
      class="v-scrollbar"
      atlAutoresize
      formControlName="comment"
      [placeholder]="'Shared.Input.Add_comment.Placeholder' | translate"
    ></textarea>
    <div class="d-flex">
      <button
        type="button"
        (click)="cancelComment()"
        class="mr-16 br-12 fz-12 global-button global-button--gray global-button--fill comment__cancel"
      >
        {{ translates.buttonCancel }}
      </button>
      <button [disabled]="textareaForm.invalid" type="submit" class="fz-12 br-12 global-button comment__post">
        {{ translates.buttonPost }}
      </button>
    </div>
  </form>

  <span
    class="global-caption global-caption--error text-align-center"
    *ngIf="textareaForm.controls.comment.errors?.maxlength"
    >{{ translates.addCommentError }}</span
  >
</ng-template>

<ng-template #now
  ><span class="ml-32 fz-12 comment__date">{{ translates.now }}</span></ng-template
>

<ng-template #emptyVideo>
  <div class="full-screen-modal__empty-video">
    <img (load)="onLoad()" src="assets/video-empty.svg" alt="video-empty" />
    <span>{{ 'Shared.Description.Not_supporting_video' | translate }}</span>
  </div>
</ng-template>

<ng-template #emptyRaw>
  <div class="full-screen-modal__empty-video">
    <img (load)="onLoad()" src="assets/no_images.png" alt="raw-empty" />
  </div>
</ng-template>
