<div class="move-to-modal__header">
  <div class="d-flex align-items-center min-w-0 mr-16">
    <span class="d-block ellipsis"> {{ 'Shared.Button.Move' | translate }} {{ title }} </span
    ><ng-container *ngIf="movingItems?.length === 1">"</ng-container>
  </div>

  <atl-header-icon
    (click)="closeModal()"
    icon="/assets/close-icon.svg"
    [ngbTooltip]="'Alt.Close' | translate"
    [openDelay]="tooltipDelay"
    [container]="'body'"
    triggers="hover touchstart"
    data-cy="cy-close-btn-reclamation-creation"
  ></atl-header-icon>
</div>

<div class="move-to-modal__body">
  <div class="move-to-modal__breadcrumbs">
    <span (click)="backToRoot()" class="fz-16 lh-24 fw-500 cursor-pointer"
      >{{ 'Entity.Unit' | translate }} {{ unitIdentifier }}</span
    >
    <atl-fdv-breadcrumbs
      *ngIf="currentFolder"
      [selectFolder]="currentFolder"
      (selectParent)="backToParentFolder($event)"
    ></atl-fdv-breadcrumbs>
  </div>

  <div class="move-to-modal__list v-scrollbar">
    <ng-container *ngIf="folderList.length; else empty"></ng-container>
    <ng-container *ngFor="let item of folderList">
      <ng-container *ngIf="item.documentType === documentType.Folder">
        <div
          (click)="selectFolderHandler(item)"
          (dblclick)="openFolder(item)"
          class="move-to-modal__item"
          [class.disabled]="item.disabled"
          [class.selected]="item.id === selectFolder?.id"
        >
          <div class="d-flex align-items-center radio-icon-hover min-w-0">
            <img alt="icon" class="mr-8 cursor-pointer flex-shrink-0" src="/assets/document-folder-grey.svg" />
            <span class="fz-16 lh-24 d-block ellipsis">{{ item.title }}</span>
          </div>
          <img src="/assets/arrow-right.svg" alt="icon" class="invisible arrow-icon" />
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<atl-ngb-modal-footer>
  <p class="invisible text-blue-955 cursor-pointer fz-16 lh-24 fw-500">+ {{ 'Button.Add_folder' | translate }}</p>

  <div class="d-inline-flex align-items_center">
    <button (click)="closeModal()" class="newest-button button-gray mr-16">
      {{ 'Shared.Button.Cancel' | translate }}
    </button>
    <button
      (click)="moveSelectedToFolder()"
      [disabled]="disabledMoveIn"
      class="newest-button"
      data-cy="move-modal-button"
    >
      {{ 'Shared.Button.Move' | translate }}
    </button>
  </div>
</atl-ngb-modal-footer>

<ng-template #empty>
  <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
    <img src="assets/folder-icon.svg" alt="icon" />
    <span class="fz-16 lh-24 text-black-810 mt-16">
      {{ 'Shared.Description.This_folder_is_empty' | translate }}
    </span>
  </div>
</ng-template>
