import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  ERuleConditionName,
  ERuleStatementType,
  ERuleType,
  ITablePagination,
  SpecialRuleModel,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthAdminService } from '../auth/auth-admin.service';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';

@Injectable()
export class RulesService {
  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private readonly http: HttpClient,
    private readonly authAdminService: AuthAdminService,
    private readonly tableService: DataTableHelperService
  ) {}

  getSpecialRules(
    projectId: string,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    statementType?: ERuleStatementType,
    statementId?: number
  ): Observable<SpecialRuleModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    params = params.set('rule_type', ERuleType.ReclamationResponsibility);
    if (statementType) params = params.set('statement_type', statementType);
    if (statementId) params = params.set('statement_id', statementId.toString());
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/special_rules`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data?.special_rules),
        map((data: SpecialRuleModel[]) => plainToClass(SpecialRuleModel, data))
      );
  }

  public createSpecialRule(
    projectId: string,
    statementType: ERuleStatementType,
    statementId: number,
    conditionName?: ERuleConditionName,
    conditionValueId?: number
  ): Observable<any> {
    const fd = new FormData();
    fd.append('rule_type', ERuleType.ReclamationResponsibility);
    fd.append('special_rule[statement_type]', statementType);
    fd.append('special_rule[statement_id]', statementId.toString());
    if (conditionName) fd.append('special_rule[condition_name]', conditionName);
    if (conditionValueId) fd.append('special_rule[condition_value_id]', conditionValueId.toString());

    return this.http.post<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/special_rules`, fd).pipe(
      map((res) => res.data),
      map((data: SpecialRuleModel) => plainToClass(SpecialRuleModel, data))
    );
  }

  removeRule(projectId: number, id: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('rule_type', ERuleType.ReclamationResponsibility);
    return this.http.delete<{ data: SpecialRuleModel }>(
      `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/special_rules/${id}`,
      { params: params }
    );
  }
}
