import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl,FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-thread-header-search',
  templateUrl: './thread-header-search.component.html',
  styleUrls: ['./thread-header-search.component.scss'],
})
export class ThreadHeaderSearchComponent implements OnInit {
  @Output() searchThread = new EventEmitter<string>();

  private readonly searchInterval = 500;
  private readonly keyCodeEnter = 13;

  public form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({ search: new FormControl('') });

    this.form
      .get('search')
      ?.valueChanges.pipe(untilDestroyed(this), debounceTime(this.searchInterval))
      .subscribe((value) => {
        this.getListOfThreads(value);
      });
  }

  getListOfThreads(val: string): void {
    this.searchThread.emit(val);
  }

  onSearchThreads(event: KeyboardEvent): void {
    const { keyCode } = event;
    if (keyCode === this.keyCodeEnter) {
      this.getListOfThreads(this.form.get('search')?.value);
    }
  }
}
