import { Injectable } from '@angular/core';
import { ImageModel } from '@atlas-workspace/shared/models';
import { Select, Store } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { IllustrationsService } from '../..//modules/projects/components/specific-project-wrapper/components/illustrations/services/illustrations.service';
import { AddIllustration, RemoveIllustration, SetIllustrations } from './illustrations.action';
import { IllustrationsState } from './illustrations.state';

@Injectable({
  providedIn: 'root',
})
export class IllustrationsFacadeService {
  @Select(IllustrationsState.selectStateData) private _illustrations$!: Observable<ImageModel[]>;

  constructor(private illustrationsService: IllustrationsService, private store: Store) {}

  get illustrations$(): Observable<ImageModel[]> {
    return this._illustrations$.pipe(share());
  }

  getIllustrations(projectId: string): Observable<ImageModel[]> {
    return this.illustrationsService.getIllustrationsState(projectId).pipe(
      map((res) => res.data.visuals),
      map((data) => plainToClass(ImageModel, data)),
      tap((illustrations) => {
        this.setIllustrations(illustrations);
      })
    );
  }

  setIllustrations(illustrations: ImageModel[]): void {
    this.store.dispatch(new SetIllustrations(cloneDeep(illustrations)));
  }

  addIllustrations(illustrations: ImageModel[]): void {
    this.store.dispatch(new AddIllustration(illustrations));
  }

  removeIllustrations(id: number): void {
    this.store.dispatch(new RemoveIllustration(id));
  }
}
