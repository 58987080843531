import { DataSource } from '@angular/cdk/collections';
import { ThreadDraftModel, ThreadModel } from '@atlas-workspace/shared/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

@UntilDestroy()
export class ListOfThreadsDataSource extends DataSource<ThreadModel | ThreadDraftModel> {
  private _data$: BehaviorSubject<ThreadModel[] | ThreadDraftModel[]> = new BehaviorSubject<
    ThreadModel[] | ThreadDraftModel[]
  >([]);

  get data$(): Observable<ThreadModel[] | ThreadDraftModel[]> {
    return this._data$.asObservable();
  }

  connect(): Observable<ThreadModel[] | ThreadDraftModel[]> {
    return this._data$;
  }

  // NOSONAR-BEGIN
  // Required as mandatory DataSource method
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}
  // NOSONAR-END

  loadTable(reclamations: ThreadModel[] | ThreadDraftModel[]): void {
    this._data$.next(reclamations);
  }
}
