import { CommonModule, DatePipe, DecimalPipe, I18nSelectPipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconByTypePipeModule } from '@atl-libs-shared-pipes/file-type-pipe/icon-by-type-pipe.module';

import { IterableAlreadyIncludesPrimitivePipe } from './array-pipes/iterable-includes-primitive.pipe';
import { ClientMeetingStatusPipe } from './client-meeting-status/client-meeting-status.pipe';
import { CustomDecimalPipe } from './custom-decimal-pipe/custom-decimal.pipe';
import { DefaultDatePipe } from './default-date/default-date.pipe';
import { FirmPrefixPipeModule } from './firm-prefix/firm-prefix.module';
import { IsRawPipe } from './is-raw/is-raw.pipe';
import { RemoveHtmlTagPipe } from './remove-html-tags/remove-html-tag.pipe';
import { RemoveSpacePipe } from './remove-html-tags/remove-spaces.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { UpperCaseFirstPipe } from './text-pipes/upper-case-first.pipe';
import { TimePassedPipe } from './time-passed/time-passed.pipe';
import { TruncatePipeModule } from './truncate/truncate.pipe.module';
import { ReplaceUnderscorePipe } from './underscore-replacer/underscore-replacer.pipe';
import { AfterSlashPipe } from './after-slash-pipe/after-slash.pipe';
import { PhaseLinkedPipe } from './phase-linked/phase-linked.pipe';
import {PricePipe} from "@atl-libs-shared-pipes/price-pipes/price.pipe";

const pipeComponents = [
  DefaultDatePipe,
  IterableAlreadyIncludesPrimitivePipe,
  TimePassedPipe,
  UpperCaseFirstPipe,
  RemoveHtmlTagPipe,
  SafeHtmlPipe,
  CustomDecimalPipe,
  RemoveSpacePipe,
  IsRawPipe,
  ClientMeetingStatusPipe,
  AfterSlashPipe
];

const pipeModules = [
  IconByTypePipeModule,
  TruncatePipeModule,
  FirmPrefixPipeModule,
  PhaseLinkedPipe,
  PricePipe,
];

@NgModule({
  declarations: [...pipeComponents],
  exports: [...pipeComponents, ...pipeModules],
  providers: [...pipeComponents, DatePipe, DecimalPipe, TitleCasePipe, ReplaceUnderscorePipe, I18nSelectPipe],
  imports: [CommonModule, ...pipeModules],
})
export class SharedPipesModule {}
