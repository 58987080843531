import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetectZoomModule, SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedFormModule } from '@atlas-workspace/shared/form';
import {
  CustomIconComponent,
  CustomTooltipModule, FirmIconComponent,
  HeaderIconComponent, NgbModalFooterComponent,
  SimpleTabsComponent
} from '@atlas-workspace/shared/ui';
import { NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ReclamationClientMessagesModule } from '../reclamation-client-messages/reclamation-client-messages.module';
import { ReclamationClientDetailComponent } from './reclamation-client-detail.component';

@NgModule({
  declarations: [ReclamationClientDetailComponent],
  exports: [ReclamationClientDetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedFormModule,
    SharedDirectivesModule,
    NgbTooltipModule,
    TranslateModule,
    DetectZoomModule,
    ReclamationClientMessagesModule,
    CustomTooltipModule,
    SimpleTabsComponent,
    HeaderIconComponent,
    CustomIconComponent,
    FirmIconComponent,
    NgbModalFooterComponent
  ],
  providers: [NgbActiveModal],
})
export class ReclamationClientDetailModule {}
