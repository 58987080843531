import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReclamationActivitiesModel, ReclamationsModel } from '@atlas-workspace/shared/models';

@Component({
  selector: 'atl-reclamation-admin-activities',
  templateUrl: './reclamation-admin-activities.component.html',
  styleUrls: ['./reclamation-admin-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReclamationAdminActivitiesComponent {
  @Input() private set reclamationData(value: ReclamationsModel) {
    this.activitiesData = [];
    const initActivities = value.activities.slice();
    this.activitiesData = initActivities.reverse();
  }

  public activitiesData!: ReclamationActivitiesModel[];
}
