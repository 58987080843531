import { Injectable } from '@angular/core';
import { ImageModel, ImmutableSelector } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import { AddIllustration, RemoveIllustration, SetIllustrations } from './illustrations.action';
import { IllustrationsStateModel } from './illustrations.model';

@State({
   name: 'illustrationsState',
   defaults: defaultState.illustrationsState,
 })
@Injectable()
export class IllustrationsState {

  @Selector()
  @ImmutableSelector()
  static selectStateData(state: IllustrationsStateModel): ImageModel[] | null {
    return state.illustrations;
  }

  @Action(SetIllustrations)
  setIllustrations(ctx: StateContext<IllustrationsStateModel>, {illustrations}: SetIllustrations): void {
    ctx.setState(
      patch<IllustrationsStateModel>({illustrations})
    );
  }

  @Action(AddIllustration)
  addIllustrations(ctx: StateContext<IllustrationsStateModel>, {illustrations}: AddIllustration): void {
    ctx.setState(
      patch<IllustrationsStateModel>({
        illustrations: append<ImageModel>(illustrations)
      })
    );
  }

  @Action(RemoveIllustration)
  @ImmutableSelector()
  removeIllustrations(ctx: StateContext<IllustrationsStateModel>, {id}: RemoveIllustration): void {
    const state = ctx.getState();
    const illustrations = state.illustrations?.filter(i => i.id !== id);
    ctx.setState(
      patch<IllustrationsStateModel>({illustrations})
    );
  }
}
