import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeFormatDate',
  standalone: true,
})
export class ChangeFormatDatePipe implements PipeTransform {
  transform(value: string, separator: string): string {
    return value.split(separator).reverse().join(separator);
  }
}
