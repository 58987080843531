import { Injectable } from '@angular/core';
import { ImmutableSelector, ITablePagination, UnitUserModel } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import { AddUnit, RemoveUnits, SetPagination, SetTotalFirstLoading, SetUnit, SetUnits } from './units.action';
import { UnitsStateModel } from './units.model';

@State({
  name: 'unitsState',
  defaults: defaultState.unitsState,
})
@Injectable()
export class UnitsState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: UnitsStateModel): UnitsStateModel {
    return state;
  }

  @Action(SetUnits)
  setUnits(ctx: StateContext<UnitsStateModel>, { units }: SetUnits): void {
    ctx.setState(patch<UnitsStateModel>({ units }));
  }

  @Action(SetUnit)
  setUnit(ctx: StateContext<UnitsStateModel>, { unit }: SetUnit): void {
    ctx.setState(
      patch<UnitsStateModel>({
        units: updateItem<UnitUserModel>((u) => u?.id === unit.id, unit),
      })
    );
  }

  @Action(AddUnit)
  addUnit(ctx: StateContext<UnitsStateModel>, { unit }: AddUnit): void {
    const state = ctx.getState();
    ctx.setState(
      patch<UnitsStateModel>({
        units: insertItem<UnitUserModel>(unit, 0),
        pagination: patch<ITablePagination>({
          totalCount: state.pagination.totalCount + 1,
        }),
        totalCount: state.totalCount + 1,
      })
    );
  }

  @Action(SetPagination)
  setPagination(ctx: StateContext<UnitsStateModel>, { pagination }: SetPagination): void {
    ctx.setState(patch<UnitsStateModel>({ pagination }));
  }

  @Action(SetTotalFirstLoading)
  setTotalFirstLoading(ctx: StateContext<UnitsStateModel>, { firstLoad, totalCount }: SetTotalFirstLoading): void {
    ctx.setState(patch<UnitsStateModel>({ firstLoad, totalCount }));
  }

  @Action(RemoveUnits)
  @ImmutableSelector()
  removeUnits(ctx: StateContext<UnitsStateModel>, { ids }: RemoveUnits): void {
    const state = ctx.getState();
    const units = state.units?.filter((u) => !ids.some((id) => u.id === id));
    ctx.setState(
      patch<UnitsStateModel>({
        units,
        totalCount: state.totalCount - ids.length,
      })
    );
  }
}
