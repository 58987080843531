<ng-container>
  <form class="list-of-projects-view__header-form" [formGroup]="form">
    <atl-newest-input
      class="list-of-projects-view__search-input"
      atlDisableInputDrop
      formControlName="search"
      [icon]="'input_search_icon.svg'"
      [placeholder]="'Shared.Entity.Search'"
      [clearable]="true"
      (keypress)="onSearchThreads($event)"
    ></atl-newest-input>
  </form>
</ng-container>
