<div class="global-label" atlClickOutside (clickOutside)="hidePicker()">
  <div *ngIf="label" class="global-label__header-undo">
    <span class="global-label__name"> {{ label }} </span>
    <atl-undo-changes
      (countdownFinished)="finishUndo()"
      (undoClick)="cancelUndo()"
      *ngIf="showUndo$ | async"
      [fieldName]="label"
      [undoTimer]="undoTimer$ | async"
    ></atl-undo-changes>
  </div>
  <div class="date-input-wrapper" tabindex="1">
    <input
      #input
      atlLockField
      autocomplete="off"
      (isLocked)="isLocked($event)"
      (click)="visiblePicker()"
      (blur)="onTouched()"
      (change)="changeValue(input.value.trim()); input.focus()"
      (input)="hidePicker(); changeValue(input.value.trim())"
      (keydown.enter)="input.blur()"
      [ngModel]="inputValue"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      class="global_input global_input--date-time newest-date-time-picker__input"
      [class.newest-date-time-picker__input-disabled]="disabled"
      [class.newest-date-time-picker__input-icon-hovered]="hoveredIcon"
      name="dp"
      type="text"
    />

    <ng-container *ngIf="disabled; else defaultState">
      <span class="date-input-icon" [class.disabled]="disabled">
        <img alt="calendar" [src]="calendarIcon" />
      </span>
    </ng-container>

    <ng-template #defaultState>
      <span
        (click)="visible = !visible; input.focus()"
        (mouseenter)="onHoverIcon()"
        (mouseleave)="onBlurIcon()"
        class="date-input-icon"
      >
        <img alt="calendar" [src]="calendarIcon" />
      </span>
    </ng-template>

    <ngb-datepicker
      #dp
      class="input-date-picker__calendar dropdown-menu"
      [ngModel]="inputValue"
      [ngClass]="visible ? 'date-input__datepicker-show' : 'date-input__datepicker-hide'"
      [firstDayOfWeek]="0"
      [navigation]="navigation"
      [outsideDays]="outsideDays"
      [weekdays]="0"
      (dateSelect)="selectedDate($event)"
    >
    </ngb-datepicker>
  </div>
</div>
