<div class="newest-textarea__wr" [class.newest-textarea__ellipsis]="inputValue?.length >= maxLengthString && !focused">
  <textarea
    #input
    class="newest-textarea__textarea"
    atlReplaceInput
    atlAutoHeightTextarea
    atlMaxLength
    [maxLength]="maxLengthTextarea"
    [currentValue]="input.value"
    [focusInput]="focused"
    [placeholder]="placeholder"
    [value]="inputValue"
    [disabled]="disabled"
    [readonly]="readonly"
    (input)="changeValue(input.value)"
    (focus)="onFocus(true)"
    (blur)="onBlur()"
    (focusout)="onFocus(false)"
  ></textarea>
</div>
