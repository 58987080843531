/* eslint-disable max-len */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ITextEditorInitConfig } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { EditorComponent } from '@tinymce/tinymce-angular';

const translateKey = {
  no: 'nb_NO',
  sv: 'sv_SE',
  en: 'en_US',
};

@Component({
  selector: 'atl-newest-text-editor',
  templateUrl: './newest-text-editor.component.html',
  styleUrls: ['./newest-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewestTextEditorComponent implements AfterViewInit {
  editorInitConfig!: ITextEditorInitConfig;
  focused = false;
  @Input() disabled = false;
  @Input() set config(config: ITextEditorInitConfig) {
    this.editorInitConfig = {
      ...config,
    };

    if (this.translateService.currentLang !== 'en') {
      // @ts-ignore
      this.editorInitConfig.language = translateKey[this.translateService.currentLang];
    }
    this.editorInitConfig.readonly = this.control.disabled;
    this.control.enable();
  }
  @Input() public apiKey!: string;
  @Input() public readonly control!: AbstractControl;
  @Input() public readonly asTextArea = false;
  @Input() public readonly textAreaPlaceholder = '';

  @ViewChild('editor') editor!: EditorComponent;
  isDropdownOpen = false;

  private readonly allowedImagesExtensions = 'image/jpeg,image/jpg,image/png,image/gif,image/bmp,image/webp';

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.listenEditoClickOutsideEvent();
  }

  hasFocus(): void {
    this.focused = true;
    if (this.editorInitConfig.readonly) {
      this.editor.disabled = true;
    }
  }

  closeMenu(): void {
    if (this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }

  hasBlur(): void {
    this.focused = false;
    if (this.editorInitConfig.readonly) {
      this.editor.disabled = false;
    }
  }

  setHeadingLevel(level: number): void {
    const mainEditor = this.editor.editor;
    this.removeLastSymbol();
    if (mainEditor) {
      mainEditor.execCommand('FormatBlock', false, `h${level}`);
      this.isDropdownOpen = false;
    }

    this.focused = true;
    this.cdr.markForCheck();
  }

  toggleBulletList(): void {
    const mainEditor = this.editor.editor;
    if (mainEditor) {
      this.removeLastSymbol();
      mainEditor.execCommand('InsertUnorderedList');
      this.isDropdownOpen = false;
    }
    this.focused = true;
    this.cdr.markForCheck();
  }

  toggleNumberedList(): void {
    const mainEditor = this.editor.editor;
    if (mainEditor) {
      this.removeLastSymbol();
      mainEditor.execCommand('InsertOrderedList');
      this.isDropdownOpen = false;
    }
    this.focused = true;
    this.cdr.markForCheck();
  }

  openImageUploader(): void {
    const mainEditor = this.editor.editor;
    if (mainEditor) {
      this.removeLastSymbol();
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', this.allowedImagesExtensions);
      input.onchange = (): void => {
        const file = input.files ? input.files[0] : null;
        if (!file) {
          return;
        }
        const reader = new FileReader();
        reader.onload = (): void => {
          const url = reader.result;
          mainEditor.insertContent(
            `
              <img style="display: block; width: 100%; clear: both; margin-top: 8px; margin-bottom: 8px; object-fit: contain; margin-left: auto; margin-right: auto; max-width: 600px; max-height: 600px" src="${url}" alt="${file.name}"/>
            `
          );
        };
        reader.readAsDataURL(file);
      };
      input.click();
      this.isDropdownOpen = false;
      this.cdr.markForCheck();
    }
  }

  get formControl(): FormControl {
    return this.control as FormControl;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === '/') {
      this.isDropdownOpen = true;
    } else {
      this.isDropdownOpen = false;
    }
    this.cdr.markForCheck();
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      const selectedText = this.editor.editor.selection.getContent();
      const totalText = this.editor.editor.getContent();

      if (selectedText.length === totalText.length) {
        this.control.setValue('');
      }
    }
    this.cdr.detectChanges();
  }

  private removeLastSymbol(): void {
    const mainEditor = this.editor.editor;
    mainEditor.execCommand('delete');
  }

  private listenEditoClickOutsideEvent(): void {
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains('tox-dialog-wrap__backdrop')) {
        this.editor.editor.windowManager.close();
      }
    });
  }
}
