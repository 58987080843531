import { Injectable } from '@angular/core';
import { ITablePagination, IUpdate, ShortUpdateModel, UpdateModel } from '@atlas-workspace/shared/models';
import { DataTableHelperService } from '@atlas-workspace/shared/service';
import { Select, Store } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { UpdatesService } from '../../modules/projects/components/specific-project-wrapper/services/updates/updates.service';
import { defaultState } from '../default-state';
import {
  AddUpdate,
  RemoveUpdate,
  SetUpdate,
  SetUpdates,
  UpdatesSetPagination,
  UpdatesSetTotalFirstLoading,
} from './updates.action';
import { UpdatesStateModel } from './updates.model';
import { UpdatesState } from './updates.state';

@Injectable({
  providedIn: 'root',
})
export class UpdatesFacadeService {
  @Select(UpdatesState.selectStateData) private _updates$!: Observable<UpdatesStateModel>;

  constructor(
    private updatesService: UpdatesService,
    private store: Store,
    private dataTableHelper: DataTableHelperService
  ) {}

  get updates$(): Observable<UpdatesStateModel> {
    return this._updates$.pipe(share());
  }

  public getUpdates(
    projectId: string,
    search = '',
    sort = 'default',
    paginate?: ITablePagination
  ): Observable<ShortUpdateModel[]> {
    return this.updatesService.getUpdatesState(projectId, search, sort, paginate).pipe(
      tap((returnData) => {
        const pagination = this.dataTableHelper.getPagination(returnData);

        if (this.store.snapshot().updatesState.firstLoad) {
          this.store.dispatch(new UpdatesSetTotalFirstLoading(false, pagination.totalCount));
        }

        this.setPagination(pagination);
      }),
      map((res) => res.body.data.updates),
      map((data: IUpdate[]) => plainToClass(ShortUpdateModel, data)),
      tap((returnData) => {
        if (this.store.snapshot().updatesState.pagination.currentPage === 1 && sort === 'default' && !search) {
          this.setUpdates(returnData);
        }
      })
    );
  }

  setUpdates(updates: ShortUpdateModel[]): void {
    this.store.dispatch(new SetUpdates(cloneDeep(updates)));
  }

  setChangeUpdates(update: ShortUpdateModel | UpdateModel): void {
    this.store.dispatch(new SetUpdate(update));
  }

  addCreateUpdates(updates: ShortUpdateModel | UpdateModel): void {
    this.store.dispatch(new AddUpdate(updates));
  }

  removeUpdatesLocal(ids: number[]): void {
    this.store.dispatch(new RemoveUpdate(ids));
  }

  setPagination(pagination: ITablePagination = defaultState.updatesState.pagination): void {
    this.store.dispatch(new UpdatesSetPagination(pagination));
  }
}
