import { Pipe, PipeTransform } from '@angular/core';
import { EMeetingKind, MeetingsModel } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'unitsFreeSlot',
  standalone: true,
})
export class UnitsFreeSlotPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(meeting: MeetingsModel): string {
    const booket = this.translateService.instant('Shared.Meeting.Status.Booked').toLowerCase();
    switch (meeting.kind) {
      case EMeetingKind.Single:
        return meeting.units?.length ? meeting.units[0].unit.identifier : `0/1 ${booket}`;
      case EMeetingKind.Group:
        return meeting.maxUnits
          ? `${meeting.units?.length || 0}/${meeting.maxUnits} ${booket}`
          : `${meeting.units?.length || 0} ${booket}`;
    }
  }
}
