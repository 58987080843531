import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from '@atl-libs-shared-pipes/shared-pipes.module';
import { SharedTranslateForChildModule } from '@atlas-workspace/shared/translate';

import { MoveToModalComponent } from './move-to-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedPipesModule, SharedTranslateForChildModule],
  declarations: [MoveToModalComponent],
  exports: [MoveToModalComponent],
})
export class MoveToModalModule {}
