import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SearchPipe } from '@atl-libs-shared-pipes/array-filter/search.pipe';
import { TimePassedAgoModule } from '@atl-libs-shared-pipes/time-passed-ago/time-passed-ago.module';
import { TruncatePipeModule } from '@atl-libs-shared-pipes/truncate/truncate.pipe.module';
import { DataTableModule } from '@atlas-workspace/data-table';
import { DetectZoomModule, RestoreScrollDirective, SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedFormModule, TitledCheckboxModule } from '@atlas-workspace/shared/form';
import { SharedModalsModule } from '@atlas-workspace/shared/modals';
import {
  CustomIconComponent,
  NewMultiFilterDropdownComponent,
  NotFoundModule,
  TableSortModule,
  TooltipComponent
} from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ListOfThreadsDraftItemModule } from '../list-of-threads-draft-item/list-of-threads-draft-item.module';
import { ListOfThreadsFilterComponent } from '../list-of-threads-filter/list-of-threads-filter.component';
import { ListOfThreadsItemModule } from '../list-of-threads-item/list-of-threads-item.module';
import { ListOfThreadsComponent } from './list-of-threads.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SharedDirectivesModule,
    TitledCheckboxModule,
    FormsModule,
    DataTableModule,
    ReactiveFormsModule,
    SharedFormModule,
    SharedModalsModule,
    NotFoundModule,
    TableSortModule,
    TruncatePipeModule,
    TooltipComponent,
    DetectZoomModule,
    NgbTooltipModule,
    SearchPipe,
    TimePassedAgoModule,
    ListOfThreadsItemModule,
    ListOfThreadsDraftItemModule,
    RestoreScrollDirective,
    NewMultiFilterDropdownComponent,
    CustomIconComponent
  ],
  declarations: [ListOfThreadsComponent, ListOfThreadsFilterComponent],
  exports: [ListOfThreadsComponent],
})
export class ListOfThreadsModule {}
