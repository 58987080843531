import { Injectable } from '@angular/core';
import { ImmutableSelector } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import { SetOptions } from './option-stores.action';
import { OptionStoresStateModel } from './option-stores.model';

@State({
  name: 'optionsState',
  defaults: defaultState.optionsState,
})
@Injectable()
export class OptionsState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: OptionStoresStateModel): OptionStoresStateModel {
    return state;
  }

  @Action(SetOptions)
  setProjects(ctx: StateContext<OptionStoresStateModel>, { options }: SetOptions): void {
    ctx.setState(patch<OptionStoresStateModel>({ options: options }));
  }
}
