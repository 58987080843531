import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import closeIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/close.svg';

@Component({
  selector: 'atl-reclamation-create-confirm',
  templateUrl: './reclamation-create-confirm.component.html',
  styleUrls: ['./reclamation-create-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReclamationCreateConfirmComponent implements OnChanges {
  @Input() public modalRef!: NgbModalRef;
  @Input() public title = 'Reclamation.Reclamation_conditions';
  @Input() public description = 'Reclamation.Reclamation_conditions_description';
  @Input() public checkboxLabel = 'Reclamation.Reclamation_conditions_checkbox';
  @Input() public showCheckbox = true;
  @Input() public readonly previewMode = false;
  @Output() public readonly confirmPass = new EventEmitter<boolean>();

  public isConfirm = false;
  public readonly closeIcon = closeIcon;
  public readonly tooltipDelay = 500;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) this.title = changes.title.currentValue as string;
    if (changes.description) this.description = changes.description.currentValue as string;
    if (changes.checkboxLabel) this.checkboxLabel = changes.checkboxLabel.currentValue as string;
  }

  public changeEvent(e: boolean): void {
    this.isConfirm = e;
  }

  public closeModal(): void {
    this.confirmPass.emit(false);
  }

  public confirm(): void {
    this.confirmPass.emit(true);
  }
}
