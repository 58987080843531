<div *ngIf="floorPlanItems?.length" class="floor-list">
  <ng-container *ngFor="let floor of floorPlanItems">
    <ng-container *ngFor="let item of floor.floor">
      <ng-container *ngIf="item.selected">
        <div class="floor-list__item">
          <img [src]="item.plan?.filename?.url || item.plan?.filenameRemoteUrl || item.plan?.plan?.fullImage?.url" alt="floor-plan">
          <span class="floor-list__remove" (click)="remove(item)">
          <atl-custom-icon size="8" [icon]="removeIcon"></atl-custom-icon>
        </span>
          <div class="floor-list__hover"></div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
