import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChartModel,
  IChart,
  IChartTaskProgressModel,
  IProjectUpdate,
  ITimeLineSortItem,
  ProjectModel,
  TypeChartModel,
} from '@atlas-workspace/shared/models';
import { AuthAdminService } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ITimelineBatchUpdate, ITimelineCreate, ITimelineUpdate } from '../interface/timeline.interface';

@Injectable()
export class TimelineService {
  constructor(private http: HttpClient, private authAdminService: AuthAdminService) {}

  public getTimeLineInfo(projectId: string, sort?: ITimeLineSortItem): Observable<ChartModel> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    let params: HttpParams = new HttpParams();
    if (firmId) params = params.set('firm_id', firmId.toString());
    if (sort) {
      params = params.set('sort_by', `${sort.sortName}_${sort.status}`);
    }
    return this.http
      .get<{ data: IChart }>(environment.apiBaseUrl + `api/v1/projects/${projectId}/phases`, { params })
      .pipe(map(({ data }) => plainToClass(ChartModel, data)));
  }

  public getTimeLineProgress(projectId: string): Observable<IChartTaskProgressModel> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http
      .get<{ data: IChart }>(environment.apiBaseUrl + `api/v1/projects/${projectId}/progress`, {
        params: {
          firm_id: firmId.toString(),
        },
      })
      .pipe(map(({ data }) => plainToClass(IChartTaskProgressModel, data)));
  }

  public timelineVisibility(projectID: string, updatedProject: IProjectUpdate | FormData): Observable<ProjectModel> {
    return this.http.put(environment.apiBaseUrl + `api/v1/projects/${projectID}`, updatedProject).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ProjectModel, data))
    );
  }

  public updateTimeLine(projectID: string, phaseID: number, body: ITimelineUpdate): Observable<ChartModel> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http
      .put<{ data: IChart }>(environment.apiBaseUrl + `api/v1/projects/${projectID}/phases/${phaseID}`, body, {
        params: {
          firm_id: firmId.toString(),
        },
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ChartModel, data))
      );
  }

  public batchUpdateTimeLine(projectID: string, body: ITimelineBatchUpdate): Observable<unknown> {
    return this.http.put<{ data: IChart }>(
      environment.apiBaseUrl + `api/v1/projects/${projectID}/phases/batch_update`,
      body
    );
  }

  public createTimeLine(projectID: string, body: ITimelineCreate): Observable<TypeChartModel> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http
      .post<{ data: IChart }>(environment.apiBaseUrl + `api/v1/projects/${projectID}/phases`, body, {
        params: {
          firm_id: firmId.toString(),
        },
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(TypeChartModel, data))
      );
  }

  public deleteTimeLine(projectID: string, ids: number[]): Observable<unknown> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    const httpOptions = {
      params: params,
      body: { ids: ids },
    };
    return this.http.delete(environment.apiBaseUrl + `api/v1/projects/${projectID}/phases/batch_destroy`, httpOptions);
  }
}
