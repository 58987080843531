import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GuestsService } from '@atl-admin-app/modules/settings/services/guests.service';
import { DataTableModule } from '@atlas-workspace/data-table';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { CustomDropdownComponent, PhoneInputModule, SharedFormModule } from '@atlas-workspace/shared/form';
import { SharedModalsModule } from '@atlas-workspace/shared/modals';
import { SharedPipesModule, UnderscoreReplacerModule } from '@atlas-workspace/shared/pipes';
import { AdminMembersService, UsersService } from '@atlas-workspace/shared/service';
import {
  ActionMessageBannerComponent,
  ButtonLoaderComponent,
  CustomMenuModule, FirmIconComponent, HeaderIconComponent,
  NgbModalFooterComponent,
  NgbModalHeaderComponent, SharedTableComponent,
  TableSortModule
} from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ProjectUsersService } from '../projects/components/specific-project-wrapper/components/users/services/project-users.service';
import { CustomAccessComponent } from './../shared/components/custom-access/custom-access.component';
import { DeleteUserComponent } from './components/members/delete-user/delete-user.component';
import { TransferOwnerComponent } from './components/members/transfer-ownership/transfer.component';

@NgModule({
  declarations: [
    TransferOwnerComponent,
    DeleteUserComponent,
    CustomAccessComponent,
  ],
  imports: [
    CommonModule,
    SharedFormModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModalsModule,
    CustomMenuModule,
    DataTableModule,
    UnderscoreReplacerModule,
    TableSortModule,
    SharedDirectivesModule,
    SharedPipesModule,
    NgbTooltipModule,
    PhoneInputModule,
    CustomDropdownComponent,
    NgbModalFooterComponent,
    NgbModalHeaderComponent,
    ButtonLoaderComponent,
    SharedTableComponent,
    HeaderIconComponent,
    FirmIconComponent,
    ActionMessageBannerComponent
  ],
  exports: [CustomAccessComponent],
  providers: [AdminMembersService, GuestsService, ProjectUsersService, UsersService],
})
export class SettingsModule {}
