<div class="task-stat card">
  <div class="task-stat__main">
    <span class="task-stat__name"> {{ statName }} </span>
    <div class="task-stat__progress">
      <ng-template [ngIf]="showProgress">
        <ng-content> </ng-content>
      </ng-template>
    </div>
  </div>
</div>
