<div class="activity v-scrollbar">
  <ng-container *ngFor="let item of activityListRender">
    <div class="activity__item">
      <div class="d-flex align-items-center justify-content-center mr-8">
        <atl-firm-icon [model]="item" [isUser]="true" [nameKey]="'user'" [isMonochrome]="true"></atl-firm-icon>
      </div>
      <div>
        <div class="fz-12 lh-16 text-gray-890">{{ item.date | date: 'd MMMM, YYYY · HH:mm' }}</div>
        <div class="activity__item-message">
          <span [innerHTML]="item.message"></span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
