import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {PracticalInfoTemplateModal, UpdateModel} from '@atlas-workspace/shared/models';
import {NgbActiveModal, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalHeaderComponent, ShadowDomComponent} from "@atlas-workspace/shared/ui";
import {TranslateModule} from "@ngx-translate/core";
import {SharedPipesModule} from "@atlas-workspace/shared/pipes";

@Component({
  selector: 'atl-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
  standalone: true,
  imports: [NgbModalHeaderComponent, ShadowDomComponent, TranslateModule, NgbTooltipModule, SharedPipesModule]
})
export class TemplatePreviewComponent implements OnInit {
  @Input() template!: UpdateModel | PracticalInfoTemplateModal;
  @Input() headerTitle = 'Title.Newsletter_preview';
  @Input() inputTitle = 'Newsletter.Modal.Name.Title';

  public readonly tooltipOpenDelay = 400;

  constructor(private modalService: NgbActiveModal, private dsr: DomSanitizer) {}

  closeModal(): void {
    this.modalService.close();
  }

  useTemplate(): void {
    this.modalService.close(this.template.id);
  }

  ngOnInit(): void {
    this.template.description = this.dsr.sanitize(
      SecurityContext.HTML,
      this.dsr.bypassSecurityTrustHtml(<string>this.template.description)
    );
  }
}
