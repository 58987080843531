import { Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { EThreadScope, ReclamationsModel } from '@atlas-workspace/shared/models';

@Component({
  selector: 'atl-reclamation-client-messages',
  templateUrl: './reclamation-client-messages.component.html',
  styleUrls: ['./reclamation-client-messages.component.scss'],
})
export class ReclamationClientMessagesComponent implements OnInit {
  @Input() public threadViewComponent!: Type<any>;
  @Input() public reclamation!: ReclamationsModel;
  @Input() public disabled = false;

  @ViewChild('threadView', { static: true, read: ViewContainerRef })
  private vcRef!: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.initThreadViewComponent();
  }

  private initThreadViewComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.threadViewComponent);
    const componentRef = this.vcRef.createComponent(componentFactory);
    componentRef.instance.scope = EThreadScope.Reclamation;
    componentRef.instance._threadId = this.reclamation.messageThreadId;
    componentRef.instance.disabled = this.disabled;
    componentRef.instance.isModal = true;
  }
}
