import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import {CustomIconComponent, SimpleTabsComponent} from '@atlas-workspace/shared/ui';

import { ReclamationAdminInternalNotesComponent } from './reclamation-admin-internal-notes.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ReclamationAdminInternalNotesComponent],
  exports: [ReclamationAdminInternalNotesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    SimpleTabsComponent,
    CustomIconComponent,
    TranslateModule
  ],
})
export class ReclamationAdminInternalNotesModule {}
