import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

// @ts-ignore
import closeIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/close.svg';

@Component({
  selector: 'atl-change-request-create-confirm',
  templateUrl: './change-request-create-confirm.component.html',
  styleUrls: ['./change-request-create-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRequestCreateConfirmComponent implements OnChanges {
  @Input() public modalRef!: NgbModalRef;
  @Input() public title = 'Reclamation.Reclamation_conditions';
  @Input() public description = 'Reclamation.Reclamation_conditions_description';
  @Input() public checkboxLabel = 'Reclamation.Reclamation_conditions_checkbox';
  @Input() public showCheckbox = true;
  @Input() public readonly previewMode = false;
  @Input() public showControls = true;

  @Output() public readonly confirmPass = new EventEmitter<boolean>();

  public isConfirm = false;
  public readonly closeIcon = closeIcon;
  public readonly tooltipDelay = 500;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["title"]) this.title = changes['title'].currentValue as string;
    if (changes['description']) this.description = changes['description'].currentValue as string;
    if (changes['checkboxLabel']) this.checkboxLabel = changes['checkboxLabel'].currentValue as string;
  }

  public changeEvent(e: boolean): void {
    this.isConfirm = e;
  }

  public closeModal(): void {
    this.confirmPass.emit(false);
  }

  public confirm(): void {
    this.confirmPass.emit(true);
  }
}
