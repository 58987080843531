import { ITablePagination, UnitUserModel } from '@atlas-workspace/shared/models';

export class SetUnits {
  static readonly type = '[Units] save change units local';
  constructor(public units: UnitUserModel[]) {}
}

export class SetUnit {
  static readonly type = '[Units] save change unit local';
  constructor(public unit: UnitUserModel) {}
}

export class AddUnit {
  static readonly type = '[Units] add unit local';
  constructor(public unit: UnitUserModel) {}
}

export class RemoveUnits {
  static readonly type = '[Units] remove units local';
  constructor(public ids: number[]) {}
}

export class SetPagination {
  static readonly type = '[Units] set pagination';
  constructor(public pagination: ITablePagination) {}
}

export class SetTotalFirstLoading {
  static readonly type = '[Units] set total first loading';
  constructor(public firstLoad: boolean, public totalCount: number) {}
}
