<div class="toggle-control__wrapper">
  <div
    *ngFor="let item of itemsMenu"
    class="toggle-control__item-menu"
    [class.active-toggle-menu]="item.id === activeTab"
    (click)="selectItemMenu(item)"
  >
    {{ item.stringKey | translate }}
  </div>
</div>
