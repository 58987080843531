import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageModalComponent implements OnInit {
  @Input() public title = '';
  @Input() public fieldTitle = 'Shared.Entity.Comment';
  @Input() public placeholder = 'Shared.Comment_here';
  @Input() public button = '';
  @Input() public blueButton = false;
  @Input() public onlyView = false;
  @Input() modalRef!: NgbModalRef;
  @Input() private comment = '';
  @Input() public isClient = false;
  @Input() public showLabel = true;
  @Input() public edited = false;

  public form!: FormGroup;
  private readonly minCommentLength = 1;
  public readonly maxCommentLength = 300;

  ngOnInit(): void {
    this.initForm();
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  private initForm(): void {
    this.form = new FormGroup({
      comment: new FormControl(
        {
          value: this.edited ? this.comment : this.onlyView ? this.comment : '',
          disabled: this.onlyView,
        },
        [
          ...(!this.edited ? [Validators.required] : []),
          Validators.minLength(this.minCommentLength),
          Validators.maxLength(this.maxCommentLength),
        ],
      ),
    });
  }

  get changedInput(): boolean {
    return this.edited
      ? this.comment?.replace(/\r?\n|\r/g, '|') === this.form.get('comment')?.value?.replace(/\r?\n|\r/g, '|')
      : this.form.invalid;
  }

  public changeStatus(): void {
    const comment = this.form.get('comment')?.value;
    this.modalRef.close(comment);
  }
}
