<div class="unit-analytics">
  <div class="unit-analytics__header">
    <h2 class="fz-20 lh-24 fw-500 mr-12">{{'Shared.Entity.Activities' | translate}}</h2>
    <div
      *ngIf="!filterOnlyBySpecificType"
      class="unit-analytics__filter"
      [class.d-none]="scope === scopes.MeetingDetails"
    >
      <div
          #dropdownMenu="ngbDropdown"
          ngbDropdown
          container="body"
          [placement]="['bottom-right']"
          dropdownClass="dropdown-filter"
          class="dropdown-filter"
        >
          <button
            ngbDropdownToggle
            id="unitActivityFilter"
            class="newest-button newest-button--gray filter-button fz-14"
            [class.filter-button__active]="filtersCounter > 0"
          >
            <img class="mr-8" src="/assets/filter-icon-new.svg" alt="icon" />
            {{ 'Shared.Filters' | translate }}
            <p
              *ngIf="filtersCounter > 0"
              class="ml-8 counter fz-14 d-flex align-items-center justify-content-center"
              [class.counter__extended]="filtersCounter > 9"
            >
              +{{ filtersCounter }}
            </p>
          </button>
          <div ngbDropdownMenu aria-labelledby="unitActivityFilter" class="filter-dropdown-wrapper">
            <atl-unit-analytics-filter
              (filterDataEvent)="filterEvent($event)"
            ></atl-unit-analytics-filter>
          </div>
        </div>
    </div>
  </div>

  <div class="unit-analytics__content v-scrollbar" [class.show-line]="analyticsData && analyticsData.length && !isLoading">
    <ng-container *ngIf="!isLoading; else loading">
      <ng-container *ngIf="analyticsData && analyticsData.length; else noAnalytics">
        <ng-container *ngFor="let event of analyticsData; let i = index">
          <div class="unit-analytics__event" (atlInfiniteScroll)="loadMore(i)">
            <atl-firm-icon
              class="unit-analytics__event-user user"
              [isUser]="true"
              [model]="event.whoDoing"
              nameKey="name"
              [isMonochrome]="true"
            ></atl-firm-icon>
            <div class="unit-analytics__event-description">
              <div class="unit-analytics__event-description-date">{{ event.createdAt | date: 'd MMMM, YYYY · HH:mm' }}</div>
              <div class="unit-analytics__event-description-text">
                <span
                  [innerHTML]="event.text"
                  [ngbTooltip]="event.changedData?.name"
                  [disableTooltip]="event.changedData?.name?.length < maxDocumentNameLength"
                  triggers="hover touchstart"
                ></span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noAnalytics>
  <ng-container *ngIf="filtersCounter > 0; else emptyState">
      <div class="unit-analytics__no-result flex-column-centered f-family-sfpro">
        <img src="assets/glass-no-result.svg" alt="icon" />
        <h2 class="fw-500 lh-24 fz-20 text-black-960 mt-16">{{ 'ProjectList.Empty_State.No_results' | translate }}</h2>
        <p class="fw-400 lh-24 fz-16 text-black-810 mt-4">
          {{ 'Description.No_results_for_selected_filters' | translate }}
        </p>
      </div>
  </ng-container>
</ng-template>

<ng-template #emptyState>
  <div class="unit-analytics__empty">
    <img class="unit-analytics__empty-icon" src="/assets/empty-analytics-icon.svg" alt="No analytics" />
    <div class="unit-analytics__empty-title">{{ 'Units.Analytics.No_analytics_yet' | translate }}</div>
    <div class="unit-analytics__empty-description">
      {{ 'Units.Analytics.All_activity_Unit_will_displayed_here' | translate }}
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="spinner-wrapper project-wrapper">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>
