<div (clickOutside)="close()" atlClickOutside class="button-expanded-dropdown__wrapper">
  <button [disabled]="disabled" (click)="toggle()" class="button-expanded-dropdown__button">
    <span class="button-expanded-dropdown__button-text" (click)="toggleIcon()">
      <ng-content></ng-content>
    </span>
  </button>
  <div *ngIf="expanded" class="button-expanded-dropdown__dropdown-content">
    <ng-content select="dropdownContent"></ng-content>
  </div>
</div>
