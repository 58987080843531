<!--
  ******************************************************
  @Cypress
  - interaction with editor
  - interaction with mention
  ******************************************************
-->
<div #hostChildWrapperElement class="mention-editor__wrapper">
  <editor
    [ngClass]="translateService?.currentLang"
    [formControl]="control"
    [apiKey]="apiKey"
    [init]="config"
    (onLoadContent)="editorOnInit($event)"
    (onKeyUp)="getContentHandler.emit()"
    (onKeyDown)="onInputKeyPress($event.event)"
    (onClick)="editorOnClick()"
    data-cy="cy-editor"
    [disabled]="disabled"
  ></editor>

  <ng-template [ngIf]="tagUserContainerRect">
    <div
      class="mention-tagging v-scrollbar"
      data-cy="mention-tagging"
      atlClickOutside
      (clickOutside)="hideTaggingUserList()"
      [style.left.px]="calculateHorizontalPositionTaggingBox"
      [style.top.px]="calculateVerticalPositionTaggingBox"
      [style.maxHeight.px]="taggingParams.maxBoxHeight"
    >
      <ng-container *ngIf="isMentionSearchable && !searchableUsers.length; else mentionItems">
        <div class="mention-tagging-empty d-flex align-items-center lh-32">
          {{ 'Shared.Entity.No_matches' | translate }}
        </div>
      </ng-container>
      <ng-template #mentionItems>
        <div
          *ngFor="let user of searchableUsers"
          class="mention-tagging-item d-flex align-items-center"
          [attr.data-cy-mention-id]="user?.id"
          (click)="tagUser(user?.id)"
        >
          <atl-firm-icon
            class="mention-tagging-item-icon"
            [model]="user"
            nameKey="name"
            [isUser]="true"
            [isMonochrome]="true"
          ></atl-firm-icon>
          <div class="overflow-hidden">
            <p class="mention-tagging-item-name ellipsis fz-14 lh-24 fw-400">{{ user.name }}</p>
            <p class="mention-tagging-item-email ellipsis fz-12 lh-16 fw-400">{{ user.email }}</p>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
