import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ELang, ELocalStorageKeys } from '@atlas-workspace/shared/models';
import { LocalStorageService, OnboardingService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingModalComponent implements OnInit {
  @Input() public step = 0;

  public isSwedishLang = false;
  public showCurrentStep = 0;
  public allSteps = 4;
  private readonly currentLang!: string | null;

  constructor(
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly localStorageService: LocalStorageService,
    protected readonly onboardingService: OnboardingService,
  ) {
    this.currentLang = this.localStorageService.get(ELocalStorageKeys.Language);
  }

  ngOnInit(): void {
    this.isSwedishLang = this.currentLang === ELang.Swedish;
    if (this.currentLang !== ELang.Norwegian) {
      --this.allSteps;
    }
  }

  setStep(step: number): void {
    this.step = step;
    this.showCurrentStep = step;
    if (this.step >= 2 && this.currentLang !== ELang.Norwegian) {
      --this.showCurrentStep;
    }
  }

  public nextStep(): void {
    this.step++;
    this.saveStepToStorage();
    this.onboardingService.setCurrentStep(this.step);
  }

  public prevStep(): void {
    this.step--;
    if (this.step >= 2 && this.currentLang !== ELang.Norwegian) {
      this.step--;
    }
    this.saveStepToStorage();
    this.onboardingService.setCurrentStep(this.step);
  }

  public skipAllSteps(): void {
    this.ngbActiveModal.close();
    this.onboardingService.setCompleteOnboarding();
    this.localStorageService.remove(ELocalStorageKeys.GlobalOnboardingStep);
  }

  private saveStepToStorage(): void {
    this.localStorageService.set(ELocalStorageKeys.GlobalOnboardingStep, this.step);
  }
}
