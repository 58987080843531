import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedTranslateForChildModule } from '@atlas-workspace/shared/translate';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SimpleModalWrapperComponent } from './simple-modal-wrapper.component';
import {ButtonLoaderComponent} from "@atlas-workspace/shared/ui";

@NgModule({
  declarations: [SimpleModalWrapperComponent],
  exports: [SimpleModalWrapperComponent],
  imports: [CommonModule, SharedTranslateForChildModule, NgbTooltipModule, ButtonLoaderComponent],
})
export class SimpleModalWrapperModule {}
