import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'joinValues',
  standalone: true
})
export class JoinValuesPipe implements PipeTransform {
  private readonly amountId = 'quantityOrAreaSize';
  constructor(private readonly translateService: TranslateService) {}

  transform(items: any[], property: string, typeName = '', separator = ', '): string {
    if (!items || !property) return '';

    let transformedItems: string[];

    if (property === this.amountId) {
      transformedItems = items.map(item => 
        item[property] || (`${typeName} ${this.translateService.instant('Shared.Title.Amount_sqm')}`)
      );

      const fallbackValue = `${typeName} ${this.translateService.instant('Shared.Title.Amount_sqm')}`;
      const uniqueItems = transformedItems.filter((value, index, self) => 
        value !== fallbackValue || self.indexOf(value) === index
      );

      return uniqueItems.join(separator);
    }

    transformedItems = items.map(item => item[property]);
    return transformedItems.join(separator);
  }
}
