<atl-ngb-modal-header [title]="title" (closeModalEvent)="closeModal()"></atl-ngb-modal-header>
<form [formGroup]="form" class="combine-pricing" tabindex="0">
  <p *ngIf="description" class="fz-14 lh-24 word-break mb-24">{{ description | translate }}</p>
  <div *ngIf="!priceOnly" class="combine-pricing__input mb-24">
    <atl-input label="Entity.Name" placeholder="Input.Name.Placeholder.Type" formControlName="name"></atl-input>
  </div>
  <div class="combine-pricing__input">
    <atl-input
      [label]="priceLabel"
      placeholder="Title.Type_price"
      formControlName="price"
      [mask]="mask"
      [separatorLimit]="separatorLimit"
      thousandSeparator=" "
    ></atl-input>
  </div>
</form>
<atl-ngb-modal-footer>
  <div class="d-flex align-items-center justify-content-end w-100">
    <button (click)="closeModal()" class="newest-button button-gray fz-16 mr-16 br-32">
      {{ 'Button.Cancel' | translate }}
    </button>
    <button (click)="setPrice()" [disabled]="form.invalid || !changedForm.length" class="newest-button">
      {{ setButton | translate }}
    </button>
  </div>
</atl-ngb-modal-footer>
