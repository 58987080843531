import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ProjectModel } from '@atlas-workspace/shared/models';

type ThreadPickProjectModel = Pick<ProjectModel, 'projectId' | 'name'> & { selected: boolean; visible: boolean };

@Component({
  selector: 'atl-create-thread-projects-modal',
  templateUrl: './create-thread-projects-modal.component.html',
  styleUrls: ['./create-thread-projects-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateThreadProjectsModalComponent implements OnInit {
  @Input() project?: ThreadPickProjectModel;
  @Input() public set onSearch(_term: string) {
    this.search = _term;
    this.searchValueChangesHandler();
  }
  @Input() public set initProjects(_list: ProjectModel[]) {
    this.initializeProjects(_list);
  }
  @Output() public readonly selectHandler = new EventEmitter<ThreadPickProjectModel>();

  public search = '';
  public isLoading = true;
  public projects?: ThreadPickProjectModel[];
  public readonly dataOutsideClickArea = '[data-project-outside-click-area]';

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.searchValueChangesHandler();
  }

  private initializeProjects(_projects: ProjectModel[]): void {
    this.projects = this.formatProjectsProperties(_projects);
    this.isLoading = false;
    this.cd.detectChanges();
  }

  private formatProjectsProperties(list: ProjectModel[]): ThreadPickProjectModel[] {
    return list.map(
      (el) =>
        ({
          projectId: el.projectId,
          name: el.name,
          selected: this.project?.projectId === el.projectId,
          visible: true,
        } as ThreadPickProjectModel)
    );
  }

  private searchValueChangesHandler(): void {
    if (this.projects && (this.search === '' || this.search.trim())) {
      this.searchProjects(this.search.trim());
    }
  }

  public onClickOutside(e: Event): void {
    const target = <HTMLElement>e.target;
    const isNodeScopeToIgnoreEvent =
      target?.hasAttribute(this.dataOutsideClickArea) || target?.closest(this.dataOutsideClickArea);
    if (!isNodeScopeToIgnoreEvent) {
      this.onSelect();
    }
  }

  public onSelect(): void {
    this.selectHandler.emit(this.projects?.find((p) => p.selected));
  }

  public get hasSearchableProjects(): boolean {
    return !!this.projects?.find((p) => p.visible);
  }

  private searchProjects(term: string): void {
    this.projects?.forEach((p) => {
      p.visible = term ? p.name.toLowerCase().includes(term.toLowerCase()) : true;
    });
    this.cd.detectChanges();
  }

  public check(item: ThreadPickProjectModel): void {
    item.selected = !item.selected;
    this.projects?.forEach((p) => p.projectId === item.projectId || (p.selected = false));
    if (item.selected) this.onSelect();
  }
}
