import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  ChangeRequestOfferModel,
  ChangeRequestSettingModel,
  ClientChangeRequestModel,
  ClientChangeRequestResponseModel,
  EChangeRequestStatus,
  IFloorDrawToSave,
  ITablePagination,
} from '@atlas-workspace/shared/models';
import { DataTableHelperService } from '@atlas-workspace/shared/service';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientChangeRequestService {
  constructor(
    private http: HttpClient,
    private tableService: DataTableHelperService,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {}

  getChangeRequests(
    unitId: number,
    sort: string,
    search: string,
    paginate: ITablePagination,
    status?: string
  ): Observable<ClientChangeRequestResponseModel> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);

    if (status) {
      params = params.append('status', status);
    }

    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/change_requests/change_requests`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const pagination = this.tableService.getPagination(response);
          return { ...response.body, pagination };
        }),
        map(({ data, pagination }: any) => ({ ...plainToClass(ClientChangeRequestResponseModel, data), pagination }))
      );
  }

  createChangeRequest(
    unitId: number,
    value: any,
    drawingData: IFloorDrawToSave[]
  ): Observable<ClientChangeRequestModel> {
    const body = new FormData();
    body.append('change_request[description]', value.description);
    if (drawingData) {
      body.append('change_request[floor_draw_versions_attributes]', JSON.stringify(drawingData));
    }
    if (value.attachments?.length) {
      value.attachments.map((file: File) => {
        body.append('change_request[file_resources_attributes][][filename]', file);
      });
    }

    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/change_requests/change_requests`, body)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ClientChangeRequestModel, data))
      );
  }

  getDetailChangeRequest(unitId: number, id: number): Observable<ClientChangeRequestModel> {
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/change_requests/change_requests/${id}`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ClientChangeRequestModel, data))
      );
  }

  setOfferStatus(
    unitId: number,
    reqId: number,
    id: number,
    status: EChangeRequestStatus,
    comment?: string
  ): Observable<ChangeRequestOfferModel> {
    const body = new FormData();
    body.append('change_request_offer[status]', status);
    if (comment) {
      body.append('change_request_offer[comment]', comment);
    }

    return this.http
      .patch(
        `${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/change_requests/change_requests/${reqId}/offers/${id}/change_status`,
        body
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ChangeRequestOfferModel, data))
      );
  }

  getChangeRequestOfferList(
    unitId: number,
    reqId: number,
    sort: string,
    page: number,
    perPage: number
  ): Observable<ChangeRequestOfferModel[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('sort_by', sort);
    params = params.append('page', page.toString());
    params = params.append('per_page', perPage.toString());

    return this.http
      .get<{ data: { offers: ChangeRequestOfferModel[] } }>(
        `${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/change_requests/change_requests/${reqId}/offers`,
        {
          params,
        }
      )
      .pipe(
        map((res) => res.data.offers),
        map((data) => plainToClass(ChangeRequestOfferModel, data))
      );
  }

  /**
   * @todo Not in use anymore!
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Client-Project-Change-Requests-Settings/paths/~1api~1v1~1client~1projects~1%7Bproject_id%7D~1project_change_requests_settings/get
   */
  getSettings(projectId: number): Observable<ChangeRequestSettingModel> {
    return this.http
      .get<{ data: ChangeRequestSettingModel }>(
        `${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/project_change_requests_settings`
      )
      .pipe(
        map((res) => res.data),
        map((data) => plainToClass(ChangeRequestSettingModel, data))
      );
  }

  /**
   * @see Not provided in docs.
   * @extends https://api.journeyapp.uat.scrij.com/api-docs#tag/Client-Project-Change-Requests-Settings/paths/~1api~1v1~1client~1projects~1%7Bproject_id%7D~1project_change_requests_settings/get
   */
  getUnitSettings(projectId: number, unitId: number): Observable<ChangeRequestSettingModel> {
    return this.http
      .get<{ data: ChangeRequestSettingModel }>(
        `${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/units/${unitId}/project_change_requests_settings`
      )
      .pipe(
        map((res) => res.data),
        map((data) => plainToClass(ChangeRequestSettingModel, data))
      );
  }
}
