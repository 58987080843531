import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { ImageModel, UnitFloorModel } from '@atlas-workspace/shared/models';
import { FileLoadService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SwiperOptions } from 'swiper/types';

import { IModalHeaderInterface } from '../../interfaces/modal-header.interface';

@Component({
  selector: 'atl-image-modal-v2',
  templateUrl: './image-modal-v2.component.html',
  styleUrls: ['./image-modal-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageModalV2Component implements OnInit {
  public config!: SwiperOptions;
  readonly tooltipDelay = 500;
  @Input() slides: ImageModel[] = [];
  @Input() index!: number;
  @Input() floors: UnitFloorModel[] = [];

  @Input() set swiperConfig(c: SwiperOptions) {
    this.config = c;
    this.config.resizeObserver = true;
    if (typeof this.index === 'number') {
      this.config.initialSlide = this.index;
    }
    this.config.navigation = this.slides.length > 1;
  }

  @Input() modalRef!: NgbModalRef;
  @Input() getUrlFromProperty = 'fileName';
  @Input() getSubtitleProperty: keyof ImageModel = 'createdAt';
  @Input() isClient = false;
  @Input() isFloorPlan = false;

  public activeFloor!: UnitFloorModel;
  floorSlides = [];
  isGeneralState = true;

  private floorTitle = this.translateService.instant('Shared.Entity.Floor_plan');

  constructor(private readonly translateService: TranslateService, private readonly fileLoadService: FileLoadService,
    private readonly cdr: ChangeDetectorRef
  ) {}


  get headerData(): IModalHeaderInterface {
    let link: string;
    if (!this.floors.length) {
      link = this.slides[this.index].fileName.downloadUrl;
    } else if (this.isGeneralState) {
      link = this.isFloorPlan
        ? <string>this.activeFloor.plan?.fileName?.downloadUrl
        : <string>this.activeFloor.plan?.fullImage?.downloadUrl;
    } else {
      link = this.activeFloor.electroPlan?.fileName?.downloadUrl as string;
    }

    return {
      title: this.isFloorPlan ? this.floorTitle : this.slides[this.index].name,
      subtitle: this.slides[this.index][this.getSubtitleProperty] as string,
      downloadLink: link,
    };
  }

  changeState(value: boolean): void {
    this.isGeneralState = value;
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
     if (this.floors?.length) {
      [this.activeFloor] = this.floors;
      if (this.activeFloor.plan) {
        this.isGeneralState = true;
      } else {
        this.isGeneralState = false;
      }
     }
  }

  public onIndexChange(i: number): void {
    this.index = i;
  }

  public onCopyImage(): void {
    let name: string;
    if (!this.floors.length) {
      name = this.slides[this.index].name;
    } else if (this.isGeneralState) {
      name = this.activeFloor.plan?.name as string
    } else {
      name = this.activeFloor.electroPlan?.name as string
    }
    this.fileLoadService.copyFileFromModel({
      url: this.headerData.downloadLink!,
      name: name,
    });
  }

  setActiveFloor(floor: UnitFloorModel): void {
     this.activeFloor = floor;
     if (this.activeFloor.plan) {
      this.isGeneralState = true;
    } else {
      this.isGeneralState = false;
    }
     this.cdr.markForCheck();
  }
}
