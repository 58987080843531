import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {IndexedDbService} from '@atlas-workspace/shared/service';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PwaInterceptor implements HttpInterceptor {
  constructor(
    private indexedDbService: IndexedDbService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method !== 'GET' && !navigator.onLine) {
      this.indexedDbService.initSaveData(request).then();
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        return throwError(response);
      })
    );
  }
}
