<div class="request__wrapper d-flex flex-column min-h-0" (atlCloseModalEsc)="closeModal()" atlWindowInnerHeight>
  <header class="request__header d-flex flex-column flex-shrink-0" [class.tabs]="offerList?.length">
    <div *ngIf="modalRef" class="d-flex align-items-center justify-content-between">
      <div class="request__header-title fz-16 lh-24 fw-500">
        {{ 'Title.Change_request' | translate }}
        - {{ 'Entity.Unit' | translate }} {{ changeRequestUnit?.identifier }}
      </div>
      <div class="request__header-action">
        <div class="d-flex gap-4">
          <atl-header-icon
            class="activity"
            (click)="openActivity()"
            [class.active]="activeTab === changeRequestTabKey.Activities"
            icon="/assets/analytics-icon.svg"
            [ngbTooltip]="'Shared.Entity.Activities' | translate"
            triggers="hover touchstart"
          ></atl-header-icon>

          <div class="drop-down" (clickOutside)="isMoreDropdownExpanded = false" atlClickOutside>
            <atl-header-icon
              [class.active]="isMoreDropdownExpanded"
              (click)="isMoreDropdownExpanded = !isMoreDropdownExpanded"
              icon="/assets/dropdown-dot-2.svg"
              [ngbTooltip]="'Button.More' | translate"
              triggers="hover touchstart"
            ></atl-header-icon>
            <div *ngIf="isMoreDropdownExpanded" class="drop-down__expand">
              <div (click)="copyToClipboard()" class="drop-down__expand-button justify-unset">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <img src="/assets/unit_link.svg" alt="link option" />
                    <span class="drop-down__expand-button-text">{{
                        'Entity.Link_for_admin_portal' | translate: { title: ('Entity.Unit' | translate) }
                      }}</span>
                  </div>
                  <div *ngIf="isMac">
                    <img src="/assets/mac-copy-link.svg" class="mac-copy-cmd" alt="copy link mac" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <atl-header-icon
            (click)="closeModal()"
            icon="/assets/unit_close.svg"
            [ngbTooltip]="'Alt.Close' | translate"
            triggers="hover touchstart"
          ></atl-header-icon>
        </div>
      </div>
    </div>

    <atl-simple-tabs
      *ngIf="offerList?.length"
      class="newest-tabs mt-12"
      [activeTab]="activeTab"
      [tabs]="navigationMenu"
      [withId]="true"
      [routeNavigation]="false"
      [useTooltip]="true"
      (selectingTab)="selectAnotherTab($event)"
      [scrollControl]="true"
    ></atl-simple-tabs>
  </header>

  <ng-container [ngSwitch]="activeTab">
    <ng-container *ngSwitchCase="changeRequestTabKey.General">
      <ng-container *ngTemplateOutlet="changeRequestUnitDetailsTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="changeRequestTabKey.Offer">
      <ng-container *ngTemplateOutlet="detailOffersList"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="changeRequestTabKey.Activities">
      <ng-container *ngTemplateOutlet="activityPageTemplate"></ng-container>
    </ng-container>
  </ng-container>

</div>

<ng-template #changeRequestUnitDetailsTemplate>
  <ng-container *ngIf="!openedChangeRequest; else detailChangeRequest">
    <section class="d-flex flex-column min-h-0 h-100 v-scrollbar" tabindex="0">
      <div *ngIf="changeRequestUnit.totalCombineOffersPrice" class="request__banner">
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <div class="fz-16 lh-24 fw-500 mb-4">{{'Entity.Offer_sent_to_buyer' | translate}}</div>
            <div class="fz-16 lh-24">{{'Shared.Title.Total_sum' | translate}}: {{changeRequestUnit.totalCombineOffersPrice | price }}</div>
          </div>
          <span (click)="openOfferTab()" class="fz-16 lh-24 fw-500 text-blue-980 cursor-pointer">
            {{'Entity.See_offer' | translate}}
          </span>
        </div>
      </div>
      <div class="request__controls d-flex flex-column gap-8">
        <!-- Unit -->
        <div class="request__control">
          <p class="request__control-title">
            <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Entity.Unit' | translate }}
          </p>
          <div class="request__control-input d-flex align-items-center gap-8 identifier pl-8 min-w-0">
            <span class="d-block ellipsis">{{ changeRequestUnit.identifier }}</span>
            <ng-container *ngIf="viewerUsers?.length">
              <span class="text-gray-890">·</span>
              <atl-users-viewer [users]="viewerUsers" [icon]="false"></atl-users-viewer>
            </ng-container>
          </div>
        </div>
        <!-- Responsible-->
        <div class="request__control">
          <p class="request__control-title">
            <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
            {{ 'Shared.Entity.Responsible' | translate }}
          </p>
          <div class="request__control-input d-flex align-items-center">
            <atl-drop-down-v2
              #assigneeSelect
              class="multiple-select-component w-100"
              [formControl]="responsibleControl"
              [listOfItems]="users"
              [bindLabel]="'name'"
              [searchParams]="['name', 'email']"
              [placeholder]="
                assigneeSelect.isOpen
                  ? ('Entity.Search_for' | translate: { value: ('Shared.Entity.Responsible' | translate) })
                  : ('Input.Select.Responsible' | translate)
              "
              [notFoundText]="'Shared.Entity.Not_found' | translate"
              [useCustomItemView]="true"
              [useCustomLabelView]="true"
            >
              <ng-template atlInputLabelTemplate let-item="item">
                <div class="multiple-select-user-card-wrapper">
                  <div>
                    <div
                      class="multiple-select-user-card-icon"
                      [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                    >
                      <span>{{ item?.name | firmPrefix }}</span>
                    </div>
                    <span>{{ item?.name | truncate: nameTruncate }}</span>
                    <img
                      class="multiple-select-user-card-remove"
                      src="/assets/multiple_remove_icon.svg"
                      alt="Remove icon"
                      (click)="onRemoveSelectedItem()"
                    />
                  </div>
                  <img
                    class="multiple-select-user-card-checkbox"
                    src="/assets/multiple_select_checkbox.svg"
                    alt="Check state"
                  />
                </div>
              </ng-template>
              <ng-template atlInputTemplate let-item="item" let-selected="selected">
                <div class="multiple-select-user-card-wrapper">
                  <div>
                    <div
                      class="multiple-select-user-card-icon"
                      [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                    >
                      <span>{{ item?.name | firmPrefix }}</span>
                    </div>
                    <div>
                      <p class="request-create-dropdown-user-name fz-14 lh-24 fw-400" [class.selected]="selected">
                        {{ item?.name | truncate: nameTruncate }}
                      </p>
                      <p class="request-create-dropdown-user-email fz-12 lh-16 fw-400">{{ item?.email }}</p>
                    </div>
                    <img
                      class="multiple-select-user-card-remove"
                      src="/assets/multiple_remove_icon.svg"
                      alt="Remove icon"
                      (click)="onRemoveSelectedItem()"
                    />
                  </div>
                  <img
                    class="multiple-select-user-card-checkbox"
                    src="/assets/multiple_select_checkbox.svg"
                    alt="Check state"
                  />
                </div>
              </ng-template>
            </atl-drop-down-v2>
          </div>
        </div>
        <!-- Progress -->
        <div class="request__control">
          <p class="request__control-title">
            <img [src]="'assets/hash.svg'" alt="icon" />{{ 'Entity.Progress' | translate }}
          </p>
          <div class="request__control-input pl-8">
            {{
              'Meeting.Progress'
                | translate
                  : {
                      processed: changeRequestUnit.changeRequestsStats.processedRequests,
                      total: changeRequestUnit.changeRequestsStats.total
                    }
            }}
          </div>
        </div>
        <!-- Open date -->
        <div class="request__control">
          <p class="request__control-title">
            <img [src]="'assets/calendar-gray.svg'" alt="icon" />{{
              'Change_request.Settings.Opening_date.Input_label' | translate
            }}
          </p>
          <div class="request__control-input pl-8">
            {{ changeRequestUnit.openDate | date: 'dd.MM.yyyy · HH:mm' }}
          </div>
        </div>
        <!-- Deadline -->
        <div class="request__control">
          <p class="request__control-title">
            <img [src]="'assets/calendar-gray.svg'" alt="icon" />{{ 'Entity.Deadline' | translate }}
          </p>
          <div class="request__control-input z-index-2">
            <atl-meeting-data-picker
              class="single-time"
              [formControl]="closedDateControl"
              [singleTime]="true"
              [initMinDate]="false"
              [min]="openDateForPicker"
              [timeIsCurrentDate]="false"
              clearButtonLabel="Button.Reset"
              placeholder="Shared.Title.Add_date"
              [tooltipMsg]="closedDateErrorMsg"
              (onresetPicker)="onresetClosedDate()"
              (onsetDate)="onsetClosedDate()"
            ></atl-meeting-data-picker>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between p-24">
        <div class="d-flex align-items-center">
          <h3 class="fz-20 lh-24 fw-500 mr-8">{{ 'Title.Change_requests' | translate }}</h3>
          <span *ngIf="totalChangeRequests" class="counter text-gray-890 fz-14 lh-16 fw-500 br-8">
            {{ totalChangeRequests }}
          </span>
        </div>
        <div class="d-flex align-items-center">
          <ng-container *ngIf="totalChangeRequests">
            <ng-container *ngIf="viewMode === viewModeKey.List">
              <button
                *ngIf="changeRequestGroups.length"
                class="request__download-btn mr-12"
                (click)="download()"
              >
                <span class="fz-14 fw-500 lh-24 mr-8">{{ 'Shared.Entity.Download' | translate }}</span>
                <img src="assets/download-icon-new.svg" alt="download" />
              </button>
            </ng-container>

            <atl-newest-select-dropdown
              class="header-view-mode mr-12"
              [bindTitle]="'title'"
              [bindValue]="'key'"
              [customTitleValue]="false"
              [customTitleTemplate]="false"
              [listOfValues]="groupViewList"
              [(ngModel)]="viewMode"
              [showArrow]="true"
              [sweechLockOff]="true"
            ></atl-newest-select-dropdown>
          </ng-container>

          <ng-container *ngIf="viewMode === viewModeKey.List">
            <button
              (click)="openCreationModal()"
              class="newest-button newest-button-plus h-px-32 fz-14"
            >
              {{ 'Shared.Button.Add_new_item' | translate }}
            </button>
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="totalChangeRequests; else empty">
        <ng-container [ngSwitch]="viewMode">
          <ng-container *ngSwitchCase="viewModeKey.List">
            <ng-container *ngFor="let group of changeRequestGroups; index as groupIndex">
              <span *ngIf="changeRequestGroups.length > 1" class="pl-24 pr-24 mb-16 fz-16 lh-24 fw-500">
                {{ 'Title.Change_request' | translate }} {{ group.identifier || (groupIndex + 1) }}
              </span>
              <atl-shared-table class="gl-shared-table--lv requests__table table-action" [class.safari]="isSafari">
                <ng-container ngProjectAs="shared-table-header">
                  <th atlColumnDef="unit_identifier" class="shared-table__header-cell w87">
                    <div>ID</div>
                  </th>

                  <th atlColumnDef="status" class="shared-table__header-cell w12">
                    <span>{{ 'Entity.Status' | translate }}</span>
                  </th>
                </ng-container>

                <ng-container ngProjectAs="shared-table-body">
                  <tr
                    *ngFor="let changeRequest of group.changeRequests; index as i; let last = last"
                    (click)="openChangeRequestDetails(changeRequest)"
                  >
                    <td class="shared-table__body-cell w75">
                      <div class="gl-ellipsis-table-wr">
                        <div class="d-flex align-items-center gap-12 ellipsis">
                          <span class="fz-14 lh-24 fw-400">
                            {{ changeRequest.identifier | noProjectSegmentInChangeRequestIdentifier }}
                          </span>
                          <span
                            #identifierAndDescription
                            class="description fz-14 lh-24 fw-400 ellipsis"
                            [ngbTooltip]="changeRequest.description"
                            [openDelay]="400"
                            [disableTooltip]="
                              identifierAndDescription.offsetWidth >= identifierAndDescription.scrollWidth
                            "
                            triggers="hover touchstart"
                          >
                            {{ changeRequest.description }}
                          </span>
                          <div
                            class="d-inline-flex align-items-center position-relative message-counter-wrapper w-auto unread"
                            *ngIf="changeRequest?.messageThreadHasMessages && !changeRequest?.messageThreadState.read"
                          >
                            <atl-custom-icon [icon]="messagesIcon"></atl-custom-icon>
                            <div *ngIf="!changeRequest?.messageThreadState?.read && changeRequest.messageThreadUnreadCount > 0" class="counter">
                              {{ changeRequest.messageThreadUnreadCount }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="shared-table__body-cell w12 min-width-sm">
                      <div class="gl-ellipsis-table-wr">
                        <span
                          class="d-block ellipsis fz-14 lh-24 fw-400 status"
                          [ngClass]="'status__' + statusList[changeRequest.status]?.style"
                        >
                          {{ statusList[changeRequest.status].title | translate }}
                        </span>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </atl-shared-table>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="viewModeKey.Floor">
            <div class="request__floor-view">
              <atl-simple-tabs
                *ngIf="navList.length > 1"
                [withId]="true"
                [activeId]="floorTabActiveId"
                [tabs]="navList"
                [routeNavigation]="false"
                (selectingTab)="selectTab($event)"
                [scrollControl]="true"
                class="newest-tabs"
              ></atl-simple-tabs>
              <atl-image-mark
                *ngIf="previewPlan; else noFloors"
                [mark]="mark"
                [imageUrl]="previewPlan"
                [multiplePins]="true"
                [floorId]="floorTabActiveId"
                (click)="selectFloorPlanModal()"
                class="v-scrollbar h-scrollbar"
                [class.mt-24]="navList.length > 1"
              ></atl-image-mark>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </section>
    <atl-ngb-modal-footer *ngIf="getAllowOffers?.length">
      <div class="d-flex align-items-center justify-content-end w-100">
        <button (click)="initCombineOfferModal()" class="newest-button">
          {{ 'Button.Create_offer' | translate }}
        </button>
      </div>
    </atl-ngb-modal-footer>

    <ng-container *ngIf="loadingDocument">
      <atl-progress-loader
        (onclose)="closeDocumentLoading()"
        [title]="'Shared.Title.Generating_document' | translate"
        class="z-index-2"
      ></atl-progress-loader>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #activityPageTemplate>
  <section class="h-100">
    <div class="backtoall pt-24 pl-24 pr-24">
      <button (click)="openActivity(false)" class="d-flex align-items-center gap-4 p-0">
        <img src="assets/arrow-left-newest.svg" alt="Back" />
        <span>{{ 'Change_request.Units.All_change_requests' | translate }}</span>
      </button>
    </div>
    <atl-unit-analytics
      class="d-block"
      [unitId]="changeRequestUnit.id"
      [filterByType]="analyticsFilterType"
    ></atl-unit-analytics>
  </section>
</ng-template>

<ng-template #detailChangeRequest>
  <atl-detail-change-request
    [isChangeRequestUnitDetailScope]="true"
    [modalRef]="openedChangeRequestModalRef"
    [changeRequest]="openedChangeRequest"
    [projectId]="projectId"
    [unitId]="changeRequestUnit.id"
    [modalView]="false"
    [advanceMode]="true"
    [threadViewComponent]="threadViewComponent"
    (updateTable)="openedChangeRequestUnitChanged($event)"
    (closeHandler)="openedChangeRequestWasClosed()"
    (changeThreadStateEvent)="changeRequestThreadStateChanged($event)"
  ></atl-detail-change-request>
</ng-template>

<ng-template #detailOffersList>
  <atl-sub-offer-list
    [projectId]="projectId"
    [offers]="offerList"
    [unitId]="unit.id"
    (updateOffersList)="updateOffersList($event)"
    [unit]="unit"
  ></atl-sub-offer-list>
</ng-template>

<ng-template #loadingBlock>
  <div class="spinner-wrapper">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>

<ng-template #noFloors>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <h3 class="fz-20 lh-24 fw-500 mb-8">{{ 'Shared.Title.No_unit_selected' | translate }}</h3>
    <p class="fz-16 lh-24 text-gray-890 text-align-center max-w-555">
      {{ 'Shared.Mark.Message.No_floor_plan' | translate }}
      <span (click)="unitRedirect()" class="cursor-pointer text-blue-980">{{
        'Shared.Mark.Message.Unit_details_page' | translate
      }}</span>
    </p>
  </div>
</ng-template>

<ng-template #empty>
  <div class="d-flex justify-content-center align-items-center w-100 h-100">
    <div class="text-align-center max-w-500">
      <img class="empty-requests__image" src="assets/empty-change-request.svg" alt="image" />
      <h2 class="fz-20 fw-500 lh-24 mt-16 text-align-center">
        {{ 'Change_Request.Empty.Available_start.Title' | translate }}
      </h2>
      <p class="fz-16 lh-24 mt-8 text-black-810 text-align-center">{{ 'Change_Request.Empty_state' | translate }}</p>
    </div>
  </div>
</ng-template>
