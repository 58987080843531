import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'atl-free-text-modal',
  templateUrl: './free-text-modal.component.html',
  styleUrls: ['./free-text-modal.component.scss']
})
export class FreeTextModalComponent  extends ConfirmModalComponent {

  readonly title = 'Title.Free_text_modal';
  public readonly tooltipDelay = 500;

  constructor(ngbActiveModal: NgbActiveModal, public translateService: TranslateService){
    super(ngbActiveModal, translateService);
  }

}
