<div
  class="sort-header-container"
  (click)="handleSort($event)"
  [class.sort-header-selected]="sortable && activeId && activeId === sortParam"
  [class.cursor-auto]="!sortable"
>
  <button class="sort-header-button fw-600">
    <ng-content></ng-content>
    <ng-container *ngIf="sortable">
      <atl-custom-icon
        *ngIf="activeId; else inactive"
        class="sort"
        [class]="arrowDirection"
        [ngClass]="{ active: activeId }"
        [icon]="sortDescIcon"
        [size]="8"
      ></atl-custom-icon>
    </ng-container>
  </button>
</div>

<ng-template #inactive>
  <atl-custom-icon class="sort" [icon]="sortAscIcon" [size]="8"></atl-custom-icon>
</ng-template>
