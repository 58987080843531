<div class="unit-analytics-filter">
  <!-- Date -->
  <div class="unit-analytics-filter__category-wrapper">
    <div class="d-flex justify-content-between align-items-center unit-analytics-filter__category-header">
      <div class="cursor-pointer d-inline-flex align-items-center lh-24" (click)="expandFilterCategory('date')">
        <span class="unit-analytics-filter__category-title fz-14 text-gray-890">
          {{ filterLabelTranslate.date | translate }}
        </span>
      </div>
    </div>

    <div
      *ngIf="filterExpandState.date"
      class="unit-analytics-filter__category-items-wrapper"
    >
      <atl-input-date-range
        #dateRangePicker
        class="units-activity-date-range-filter"
        (selectDate)="dateSelect($event)"
        (resetEvent)="resetDateEvent()"
        [updateDate]="filterData.date[0]"
        [showEndDate]="true"
        [placeholder]="'Shared.Date_picker.Input.Placeholder' | translate"
      ></atl-input-date-range>
    </div>
  </div>
  <!-- Type -->
  <div class="unit-analytics-filter__category-wrapper">
    <div class="d-flex justify-content-between align-items-center unit-analytics-filter__category-header">
      <div class="cursor-pointer d-inline-flex align-items-center lh-24" (click)="expandFilterCategory('type')">
        <span class="unit-analytics-filter__category-title fz-14 text-gray-890">
          {{ filterLabelTranslate.type | translate }}
        </span>
      </div>
    </div>

    <div
      *ngIf="filterExpandState.type"
      class="unit-analytics-filter__category-items-wrapper v-scrollbar"
    >
      <ng-container *ngFor="let item of filterData.type; let index = index">
        <atl-checkbox
          (changed)="updateSelectedFilterList($event, index)"
          [size]="16"
          [value]="item.selected"
          class="threads-list cursor-pointer"
        >
          <div class="custom-label">
            <p
              class="title fz-14 lh-24 ml-8 ellipsis"
              #filterItem
              [ngbTooltip]="filterItemTemplate"
              [container]="'body'"
              [openDelay]="tooltipOpenDelay"
              [disableTooltip]="filterItem.offsetWidth >= filterItem.scrollWidth"
              placement="right"
              triggers="hover touchstart"
            >
              {{ item.name | translate }}
            </p>
          </div>
        </atl-checkbox>
      </ng-container>
    </div>
  </div>
  <!-- Footer -->
  <div class="unit-analytics-filter__footer">
    <div (click)="resetAllFilters()" class="fz-14 lh-24 unit-analytics-filter__reset">{{ 'Shared.Button.Reset_all' | translate }}</div>
  </div>
</div>
