import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {EditorMultiFileUploadComponent, FileValidators, InputComponent} from '@atlas-workspace/shared/form';
import { NewestConfirmModalComponent } from '@atlas-workspace/shared/modals';
import {
  acceptedGlobalExtensions,
  acceptedVideoExtensions,
  BatchCreateThreadsBodyModel,
  correctMimeTypes,
  CreateDraftRequestBodyModel,
  CUSTOM_FILE_LIST_CHANGE_EDITOR_EVENT_NAME,
  EAccessTag,
  EFileType,
  EThreadScope,
  EThreadUnitSelectionType,
  EUserTypeAdmin,
  FileModel,
  IDraftRemoveData,
  ImageModel,
  initEditorCustomButtons,
  ITextEditorInitConfig,
  IThreadEditorInitCustomButtonsProps,
  IUnitShort,
  ProjectModel,
  RegExpHelper,
  ThreadDraftInfoModel,
  ThreadDraftModel,
  ThreadUnitsWithGroupsModel,
} from '@atlas-workspace/shared/models';
import {
  CableService,
  FileHelper,
  MessageBannerService,
  ModalFacadeService,
  PageHelperService,
  ProjectService,
  RestThreadsService,
  SharedUiStorageService,
  TextEditorService,
  ThreadsHelperService,
} from '@atlas-workspace/shared/service';
import { TextEditorData } from '@atlas-workspace/shared/ui';
import {NgbDropdown, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import * as mime from 'mime';
import { defer, iif, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  first,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import copyIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/dublicate.svg';
import uploadIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/paperclip_grey.svg';
import threadSendIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/send_new.svg';
import styleSettingsSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/text-formatting.svg';
import deleteIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/threads/delete.svg';

import { CreateThreadProjectsModalComponent } from '../create-thread-projects-modal/create-thread-projects-modal.component';
import { CreateThreadUnitsModalComponent } from '../create-thread-units-modal/create-thread-units-modal.component';
import { MentionsTextEditorComponent } from '../mentions-text-editor/mentions-text-editor.component';

@UntilDestroy()
@Component({
  selector: 'atl-create-thread',
  templateUrl: './create-thread.component.html',
  styleUrls: ['./create-thread.component.scss'],
  providers: [TextEditorService],
})
export class CreateThreadComponent implements OnInit {
  @Input() scope!: EThreadScope;
  @Input() modalRef?: NgbModalRef;
  @Input() firmId?: number;
  @Input() projectId?: number;
  @Input() unitId?: number;
  @Input() isDraft?: boolean;

  @Input()
  public set draftData(val: ThreadDraftModel) {
    if (val) {
      this.draftId = val.id;
      this.getDraftInfo();
    }
  }

  @ViewChild('projectPickerWrapperRef', { read: ElementRef }) projectPickerWrapperRef?: ElementRef;
  @ViewChild('unitsPickerWrapperRef', { read: ElementRef }) unitsPickerWrapperRef!: ElementRef;
  @ViewChild('unitsInput', { read: InputComponent }) unitsInput!: InputComponent;
  @ViewChild('editorElementRef', { read: ElementRef }) editorElementRef!: ElementRef;
  @ViewChild('editorComponentRef') editorComponent!: MentionsTextEditorComponent;
  @ViewChild('fileRef', { read: ElementRef }) fileRef!: ElementRef;
  @ViewChild('fileComponent') fileComponent!: EditorMultiFileUploadComponent;

  @Output() public readonly closeModal = new EventEmitter();
  @Output() public readonly createDraftEvent = new EventEmitter<ThreadDraftModel>();
  @Output() public readonly updateDraftEvent = new EventEmitter<ThreadDraftModel>();
  @Output() public readonly removeDraft = new EventEmitter<IDraftRemoveData>();

  private unitDropdown?: NgbDropdown;
  private draftId: number | null = null;
  readonly tooltipDelay = 500;
  public loading = false;
  public apiKey!: string;
  public form!: FormGroup;
  public projectSearchControl: FormControl = new FormControl('');
  public unitsSearchControl: FormControl = new FormControl('');
  public isProjectControlSearchable = false;
  public isUnitsControlSearchable = false;
  public editorInitConfig!: ITextEditorInitConfig;
  public droppedFiles?: File[];
  public isDragDropSectionHovered = false;
  public selectedUnits: ThreadUnitsWithGroupsModel[] = [];
  private pickerProjects?: ProjectModel[];
  private _draftData: ThreadDraftInfoModel | undefined;
  public isButtonDisabled = false;
  public alsoSendSMS = false;
  public showUnitsCount = 10;
  public unitSearch = '';

  readonly acceptedExtensions: string;
  readonly maxUploadedFiles = 10;
  readonly maxUploadedFilesSize = 3e7;
  readonly threadSendIcon = threadSendIcon;
  public readonly autoSave = true;
  public readonly eThreadScopes = EThreadScope;
  public readonly mentionUsersPerView = 5;
  private readonly formChangeDelay = 100;
  private readonly searchControlDebounceTime = 300;

  private customUnitModel?: IUnitShort;
  private readonly mobileSize = 768;
  public isMac = false;

  constructor(
    @Inject('ENVIRONMENT') env: IEnvironment,
    private fb: FormBuilder,
    private textEditorService: TextEditorService,
    private translateService: TranslateService,
    private modalFacadeService: ModalFacadeService,
    private pageHelperService: PageHelperService,
    private restThreadsService: RestThreadsService,
    private threadsHelperService: ThreadsHelperService,
    private sharedUiStorageService: SharedUiStorageService,
    public cdRef: ChangeDetectorRef,
    private cableService: CableService,
    private projectService: ProjectService,
    private readonly messageBannerService: MessageBannerService,
  ) {
    this.apiKey = env.tinyMceApiKey;
    this.acceptedExtensions = [acceptedGlobalExtensions, acceptedVideoExtensions].join(', ');
    this.correctAcceptedMimeTypes(correctMimeTypes);
    this.isMac = window?.navigator.userAgent.includes('Mac');
  }

  ngOnInit(): void {
    this.initEditorToolbarCallbacks();
    if (!this.form) {
      this.initForm();
    }
    this.setPickerProjects(undefined, true);
    this.initUnitSearch();
  }

  setSendSMS(val: boolean): void {
    this.alsoSendSMS = val;
  }

  public setFileToDocuments(file: File): void {
    this.fileComponent.loadValidFiles([file]);
  }

  private getDraftInfo(): void {
    this.restThreadsService
      .getDraftInfo(this.draftId as number)
      .pipe(
        untilDestroyed(this),
        tap((data: ThreadDraftInfoModel) => {
          data.attachments = data.attachments.map((file: ImageModel | FileModel) => {
            if (FileValidators.isImageExtension(file)) {
              file.type = EFileType.Image;
              file = plainToClass(ImageModel, file);
            } else {
              file.type = EFileType.File;
              file = plainToClass(FileModel, file);
            }
            return file;
          });
        }),
        switchMap((data) => {
          return iif(
            () => Boolean(data.params.messageThread.projectId && this.scope === EThreadScope.Global),
            defer(() =>
              this.projectService.getProjectsChunks(this).pipe(
                first(),
                map((projects) => {
                  const draftProjectName =
                    projects.find((p) => p.projectId === data.params.messageThread.projectId)?.name || '';
                  data.params.messageThread.projectName = draftProjectName;
                  return data;
                }),
              ),
            ),
            defer(() => of(data)),
          );
        }),
      )
      .subscribe((res) => {
        this._draftData = res;
        this.changeFormData(res);
      });
  }

  private changeFormData(newData: ThreadDraftInfoModel): void {
    newData.attachments = newData.attachments.map((file) => {
      const tempType = FileHelper.handleFileType(file.fileExtension as string);
      file.type = FileValidators.isImageFieldValue(tempType) ? EFileType.Image : EFileType.File;
      return file;
    });
    const draftMessageThreadModel = newData.params.messageThread;
    this.form?.patchValue({
      project: draftMessageThreadModel?.projectId
        ? {
            id: draftMessageThreadModel?.projectId,
            name: draftMessageThreadModel?.projectName,
          }
        : null,
      units: newData.units,
      subject: draftMessageThreadModel.subject,
      body: newData.params.message.text,
      document: newData.attachments,
    });
  }

  public checkIsButtonDisabled(disabled: boolean): void {
    if (this.form.controls.document.value?.length) {
      this.isButtonDisabled = false;
      return;
    }
    this.isButtonDisabled = disabled;
  }

  private initForm(): void {
    if (this.scope === EThreadScope.Unit && this.unitId) {
      this.customUnitModel = {
        id: this.unitId,
        identifier: '',
      };
    }
    this.form = this.fb.group({
      units: new FormControl(
        this.scope !== EThreadScope.Unit ? [] : [this.customUnitModel],
        this.scope !== EThreadScope.Unit ? Validators.required : [],
      ),
      subject: new FormControl('', Validators.required),
      body: new FormControl(''),
      document: new FormControl([]),
    });

    if (this.scope === EThreadScope.Global) {
      this.form.addControl('project', new FormControl(null, Validators.required));
      this.form
        .get('project')
        ?.valueChanges.pipe(untilDestroyed(this))
        .subscribe(() => {
          this.form.get('units')?.setValue([]);
          this.selectedUnits = [];
        });
    }

    this.form
      .get('body')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateDoc();
      });

    this.form
      .get('document')
      ?.valueChanges.pipe(untilDestroyed(this), delay(this.formChangeDelay))
      .subscribe(() => {
        this.updateDoc();
        this.checkIsButtonDisabled(!this.form.controls.document.value.length);
      });
  }

  private updateDoc(): void {
    of(true)
      .pipe(delay(this.formChangeDelay))
      .subscribe(() => {
        if (this.editorElementRef && this.fileRef) {
          this.textEditorService.updateIframeFiles(this.editorElementRef, this.fileRef);
        }
        setTimeout(() => {
          this.editorComponent.editor?.fire(CUSTOM_FILE_LIST_CHANGE_EDITOR_EVENT_NAME);
        });
      });
  }

  private initEditorToolbarCallbacks(): void {
    const props: IThreadEditorInitCustomButtonsProps = {
      customAttachmentsButton: {
        onAction: this.triggerFileControl.bind(this),
        setEnabled: this.checkIfSetEnabledForCustomAttachmentsButton.bind(this),
        toolbar: 'toolbar2',
        icon: uploadIcon,
        tooltip: this.translateService.instant('Shared.Messages.Attachments'),
      },
      customCopyButton: {
        onAction: this.copyToClipboard.bind(this),
        icon: copyIcon,
        toolbar: 'toolbar2',
        tooltip: this.translateService.instant('Shared.Editor.Copy_message'),
      },
      customDissmissButton: {
        onAction: this.removeDraftModal.bind(this),
        icon: deleteIcon,
        toolbar: 'toolbar2',
        tooltip: this.translateService.instant('Shared.Button.Delete'),
      },
    };
    const config = cloneDeep(TextEditorData);
    if (window.innerWidth <= this.mobileSize) {
      delete props.customDissmissButton;
      const mobileStyle = config.fileStyleCardRemoveMobile;
      config.editorInitConfigNewThreadsAdmin.content_style += mobileStyle;
    }

    this.editorInitConfig = initEditorCustomButtons({ ...config.editorInitConfigNewThreadsAdmin }, props);
    this.editorInitConfig.placeholder = this.translateService.instant('Shared.Entity.Write_message_no_dots');
  }

  private triggerFileControl(): void {
    this.fileRef.nativeElement.querySelector('[data-cy="cy-input-file-browse"]').click();
  }

  private checkIfSetEnabledForCustomAttachmentsButton(): boolean {
    return (this.form.controls.document.value || []).filter(Boolean).length < this.maxUploadedFiles;
  }

  private copyToClipboard(): void {
    const filteredText = this.form
      .get('body')
      ?.value.replace(RegExpHelper.tags, '')
      .replace(RegExpHelper.whiteSpaces, '')
      .replaceAll('\n', ' ');
    this.pageHelperService.copyToClipboard(filteredText, true);

    this.messageBannerService.triggerShowBanner({
      text: 'Shared.Entity.Link_copied',
      icon: false
    });
  }

  close(): void {
    this.modalRef?.close();
  }

  public removeDraftModal(): void {
    if (this.isDraft && this.draftId) {
      const removeData: IDraftRemoveData = {
        id: Number(this.draftId),
        confirm: true,
      };
      this.removeDraft.emit(removeData);
      return;
    }
    const deleteModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    const instance = <NewestConfirmModalComponent>deleteModalRef.componentInstance;
    instance.title = this.translateService.instant(`Shared.Threads.Delete_message`);
    instance.description = this.translateService.instant(`Shared.Threads.Delete_message_description`);
    instance.button.text = this.translateService.instant('Shared.Button.Yes_delete');
    instance.passConfirm.pipe(untilDestroyed(this)).subscribe(() => {
      deleteModalRef.close();
      if (this.draftId) {
        const removeData: IDraftRemoveData = {
          id: Number(this.draftId),
          confirm: true,
        };
        this.removeDraft.emit(removeData);
      } else {
        this.close();
      }
    });
  }

  createDraft(): void {
    const formValues = this.form.getRawValue();
    this.loading = true;
    if (
      this.form.dirty &&
      (formValues.units?.length ||
        formValues.subject ||
        formValues.project?.projectId ||
        !this.hasMessageOnlyWhiteSpace ||
        formValues.document?.length)
    ) {
      const documents = this.form.get('document')?.value || [];
      const filesToSave = [];
      const idsToSave: number[] = [];
      for (const document of documents) {
        if (document instanceof File) {
          filesToSave.push(document);
        } else {
          if (document) idsToSave.push(document.id);
        }
      }

      this.restThreadsService
        .saveThreadAttachments(filesToSave)
        .pipe(
          switchMap((savedFiles) => {
            const newFilesIds = savedFiles.map((f) => f.id);
            const _projectId = this.scope === EThreadScope.Global ? formValues.project?.projectId : this.projectId;
            const bodyModel: CreateDraftRequestBodyModel = new CreateDraftRequestBodyModel();
            bodyModel.firmId = this.firmId;
            bodyModel.projectId = _projectId;
            if (this.scope === EThreadScope.Unit) {
              bodyModel.unitId = this.unitId;
            } else if (formValues.units.length === 1) {
              bodyModel.unitId = formValues.units[0].id;
            }
            bodyModel.message = {
              text: formValues.body,
              attachmentIds: [...idsToSave, ...newFilesIds],
              mentions: [],
            };
            bodyModel.messageThread = {
              subject: formValues.subject,
              unitIds: this.scope !== EThreadScope.Unit ? formValues.units.map((u: any) => u.id) : [this.unitId],
              projectId: _projectId,
            };
            if (this.draftId) return this.restThreadsService.updateDraft(bodyModel, this.draftId);
            return this.restThreadsService.createDraft(bodyModel);
          }),
          finalize(() => {
            this.loading = false;
            this.close();
          }),
          take(1),
        )
        .subscribe((draft: ThreadDraftModel) => {
          if (this.draftId) {
            this.updateDraftEvent.emit(draft);
          } else {
            this.createDraftEvent.emit(draft);
            this.cableService.drafts$.next();
          }
        });
    }
    this.close();
  }

  correctAcceptedMimeTypes(correctMime: { mimeType: string; extension: string[] }[]): void {
    correctMime.forEach((mimeItem) => {
      mime.define({ [mimeItem.mimeType]: mimeItem.extension }, true);
    });
  }

  private get hasMessageOnlyWhiteSpace(): boolean {
    return !this.form.controls.body.value?.replace(RegExpHelper.whiteSpaces, '')?.replace(RegExpHelper.tags, '')?.trim()
      ?.length;
  }

  adjustSelectedUnits(models: ThreadUnitsWithGroupsModel[]): void {
    const _units: IUnitShort[] = [];
    const isSelectedUnitGroup = models.some(item => item.type === EThreadUnitSelectionType.UnitGroup);
    if (isSelectedUnitGroup) {
      const units = models.reduce((acc: IUnitShort[], model: ThreadUnitsWithGroupsModel) => {
        model.units.forEach(unit => {
          if (!acc.some(u => u.id === unit.id)) {
            acc.push(unit);
          }
        });
        return acc;
      }, []);
      _units.push(...units);
    } else {
      _units.push(...models);
    }

    this.form.get('units')?.setValue([..._units]);
    this.cdRef.detectChanges();
  }

  submitForm(): void {
    if ((this.scope === EThreadScope.Project && !this.projectId) || !this.isSubmitEnabled) {
      return;
    }

    const documents = this.form.get('document')?.value || [];

    const filesToSave = [];
    const idsToSave: number[] = [];
    for (const document of documents) {
      if (document instanceof File) {
        filesToSave.push(document);
      } else {
        if (document) idsToSave.push(document.id);
      }
    }

    this.loading = true;
    this.restThreadsService
      .saveThreadAttachments(filesToSave)
      .pipe(
        switchMap((savedFiles) => {
          const newFilesIds = savedFiles.map((f) => f.id);
          const formValues = this.form.getRawValue();
          const mentions = this.threadsHelperService.formatMentions(
            this.form.value.body,
            this.editorComponent.users,
            this.editorComponent.taggingParams,
          );
          const body: BatchCreateThreadsBodyModel = {
            project_id: this.scope === EThreadScope.Global ? formValues.project?.projectId : this.projectId,
            subject: formValues.subject,
            text: this.threadsHelperService.removeWhitespaces(formValues.body),
            unit_ids: this.scope !== EThreadScope.Unit ? formValues.units.map((u: any) => u.id) : [this.unitId],
            attachment_ids: [...idsToSave, ...newFilesIds],
            mentions: mentions,
            send_via_sms: this.alsoSendSMS,
          };
          return this.restThreadsService.batchCreateThreads(body);
        }),
        finalize(() => {
          this.loading = false;
          this.modalRef?.close();
        }),
        take(1),
      )
      .subscribe((threads) => {
        if (this.draftId) {
          const removeData: IDraftRemoveData = {
            id: Number(this.draftId),
            confirm: false,
          };
          this.removeDraft.emit(removeData);
        }
        this.cableService.threads$.next(threads[0]);
        this.cableService.unreadCounters$.next(null);
      });
  }

  public get popupProjectId(): number {
    return this.scope === EThreadScope.Global ? this.form.controls.project.value?.projectId : this.projectId;
  }

  private initUnitSearch(): void {
    this.unitsSearchControl.valueChanges
      .pipe(debounceTime(this.searchControlDebounceTime), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((term) => (this.unitSearch = term));
  }

  public openChangeHandler(open: boolean, unitDropdown: NgbDropdown): void {
    this.unitDropdown = unitDropdown;
    if (open) {
      if (this._draftData?.units?.length) {
        this._draftData.units = this._draftData.units.map((i) => {
          i.selected = true;
          return i;
        });
        this.selectedUnits = this.threadsHelperService.threadDraftUnitModelToThreadUnitsWithGroupsModel(
          this._draftData.units,
        );
        this._draftData.units = [];
      }
      this.isUnitsControlSearchable = true;
      of(null).pipe(delay(0)).subscribe(() => this.unitsInput?.inputElement?.nativeElement?.focus());
    } else {
      this.isUnitsControlSearchable = false;
      this.unitSearch = '';
      of(null).pipe(delay(0)).subscribe(() => {
        if (this.unitsInput?.inputElement?.nativeElement?.value) {
          this.unitsInput.inputElement.nativeElement.value = '';
        }
        this.unitsInput?.inputElement?.nativeElement?.blur();
      });
    }
    this.cdRef.detectChanges();
  }

  public get disabledUnitSelect(): boolean {
    return (this.scope === EThreadScope.Global && !this.form.controls.project?.value) ||
      (this.scope === EThreadScope.Project && !this.projectId)
  }

  public unitSelectHandler(units: ThreadUnitsWithGroupsModel[]): void {
    this.form.markAsDirty();
    this.selectedUnits = units;
    this.adjustSelectedUnits(this.selectedUnits);
  }

  public inputHandler(event: Event, unitDropdown: NgbDropdown): void {
    if (unitDropdown.isOpen()) {
      event.stopPropagation();
    }
  }

  public removeSelectItem(e: Event, item: ThreadUnitsWithGroupsModel): void {
    e.stopPropagation();
    this.selectedUnits = [...this.selectedUnits.filter(i => i.id !== item.id)];
    this.adjustSelectedUnits(this.selectedUnits);
  }

  public get typeSelected(): boolean {
    return this.selectedUnits.some(item => item.type ===  EThreadUnitSelectionType.Unit);
  }

  public onClickEditor(): void {
    this.unitDropdown?.close();
  }

  public openProjectsSelection(): void {
    if (!this.projectPickerWrapperRef || this.isProjectControlSearchable) {
      return;
    }
    const modalRef = this.modalFacadeService.openModal(CreateThreadProjectsModalComponent, {
      ...this.sharedUiStorageService.modalCreateThreadProject,
      container: this.projectPickerWrapperRef.nativeElement,
    });
    this.isProjectControlSearchable = true;
    const _modalComponent = <CreateThreadProjectsModalComponent>modalRef.componentInstance;
    _modalComponent.project = this.form.get('project')?.value;
    queueMicrotask(() => {
      (<HTMLDivElement>this.projectPickerWrapperRef?.nativeElement)?.querySelector('input')?.focus();
    });
    const projectSearchControlSubscription = this.projectSearchControl.valueChanges
      .pipe(debounceTime(this.searchControlDebounceTime), distinctUntilChanged(), takeUntil(modalRef.closed))
      .subscribe((term) => (_modalComponent.onSearch = term));

    _modalComponent.selectHandler.subscribe((project) => {
      this.form.get('project')?.setValue(project);
      this.form.markAsDirty();
      this.isProjectControlSearchable = false;
      this.projectSearchControl.setValue('', { emitEvent: false });
      projectSearchControlSubscription.unsubscribe();
      modalRef.close();
    });
    this.setPickerProjects(_modalComponent);
  }

  public get isSubmitEnabled(): boolean {
    return (
      !this.isButtonDisabled &&
      this.form.valid &&
      !this.loading &&
      (!this.hasMessageOnlyWhiteSpace || this.form.controls.document.value?.length)
    );
  }

  public get isMobileView(): boolean {
    return window.innerWidth <= this.mobileSize;
  }

  private setPickerProjects(component?: CreateThreadProjectsModalComponent, skipComponent = false): void {
    if (!skipComponent && this.pickerProjects && component) {
      component.initProjects = this.pickerProjects;
      return;
    }
    this.projectService.projectsList$
      .pipe(
        take(1),
        switchMap((projectsList) => {
          if (this.projectService.isLoadedAllProjects) return of(projectsList);
          return this.projectService.getProjectsChunks(this).pipe(untilDestroyed(this));
        }),
        map((list) => this.filterPickerProjects(list)),
        tap((projects) => this.assignListToPickerProjects(projects, component)),
      )
      .subscribe();
  }

  private filterPickerProjects(list: ProjectModel[]): ProjectModel[] {
    return list.filter(
      (p) =>
        p.currentRole !== EUserTypeAdmin.Custom || !!p.projectAccess.find((acc) => acc.accTag === EAccessTag.Threads),
    );
  }

  private assignListToPickerProjects(list: ProjectModel[], component?: CreateThreadProjectsModalComponent): void {
    this.pickerProjects = list;
    if (component) component.initProjects = this.pickerProjects;
  }
}
