import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LayoutProductType } from '@atlas-workspace/shared/models';
import { JoinValuesPipe } from '@atlas-workspace/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-amount-rule-description',
  templateUrl: './amount-rule-description.component.html',
  styleUrls: ['./amount-rule-description.component.scss'],
  standalone: true,
  imports: [JoinValuesPipe, CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmountRuleDescriptionComponent {
  @Input() public readonly type!: LayoutProductType; 
}
