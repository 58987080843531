import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  ClientDetailChangeRequestModalComponent,
  DetailChangeRequestComponent,
} from '@atlas-workspace/change-requests';
import { FloorPlanVersionSelectComponent } from '@atlas-workspace/shared/form';
import { NewestConfirmModalComponent } from '@atlas-workspace/shared/modals';
import {
  ChangeRequestModel,
  ClientChangeRequestModel,
  EAccessTag,
  EAttachableType,
  ECableChannelEvents,
  ELocalStorageKeys,
  EPlatform,
  EProjectRoles,
  EThreadFolders,
  EThreadScope,
  ICableChannelEmit,
  IFloorVersionList,
  ILocalStorageUserInfo,
  IMessageModelAttachmentsReq,
  ITablePagination,
  IThreadState,
  IThreadUnit,
  OnSubmitEditorPropsType,
  ThreadMessageModel,
  ThreadViewModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import { ReclamationAdminDetailComponent, ReclamationClientDetailComponent } from '@atlas-workspace/shared/reclamation';
import {
  CableService,
  ChangeReqHelperService,
  ChangeRequestService,
  ClientChangeRequestService,
  LocalStorageService,
  ModalFacadeService,
  ModuleAccessService,
  PaginationUtil,
  ProfileService,
  ProjectService,
  ReclamationAdminService,
  ReclamationHelperService,
  RestThreadsService,
  RouterUtil,
  SharedUiStorageService,
  ThreadsHelperService,
} from '@atlas-workspace/shared/service';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import dayjs from 'dayjs';
import { BehaviorSubject, from, of, Subject } from 'rxjs';
import { delay, first, switchMap, take, takeUntil } from 'rxjs/operators';

import backIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow_right-gray.svg';
import documentsSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/message-folder-grey.svg';
import messagesUnread from '!!raw-loader?!@atlas-workspace/shared/assets/lib/messages-unread.svg';
import deleteSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/threads/delete.svg';
import doneSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/threads/done_v3.svg';
import reclamationInfoSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/threads/reclamation-info.svg';

import {
  getThreadsDeleteDescriptionTranslateKey,
  getThreadsDeleteTitleTranslateKey,
} from '../../helpers/threads-transtate-keys.helper';
import { ThreadAttachmentsComponent } from '../thread-attachments/thread-attachments.component';
import { ThreadEditorWrapperComponent } from '../thread-editor-wrapper/thread-editor-wrapper.component';
import { ThreadViewRecipientsComponent } from '../thread-view-recipients/thread-view-recipients.component';
import { ACTIVE_THREAD_ID_DATA_ATTRIBUTE, THREAD_MESSAGES_PAGINATE_CHUNK } from "../../helpers/threads";

@UntilDestroy()
@Component({
  selector: 'atl-thread-view',
  templateUrl: './thread-view.component.html',
  styleUrls: ['./thread-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChangeRequestService, ClientChangeRequestService],
})
export class ThreadViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(ThreadEditorWrapperComponent) editorWrapperComponent!: ThreadEditorWrapperComponent;
  @ViewChild('listContainer') listContainer!: ElementRef;
  @Input() public set _threadId(val: number) {
    this.setThreadId(val);
    this.messages = [];
    if (this.editorWrapperComponent) {
      this.editorWrapperComponent.form.reset({ body: '', document: null }, { emitEvent: false, onlySelf: true });
      this.editorWrapperComponent.resetUsers();
    }
    this.forkInit(this.threadId);
  }
  @Input() public scope!: EThreadScope;
  @Input() public isModal = false;
  @Input() accessTag: EAccessTag = EAccessTag.Reclamations;
  @Input() public disabled = false;

  @Input() public changeRequest!: ChangeRequestModel | ClientChangeRequestModel;
  @Input() public unit!: UnitUserModel;

  public threadId!: number;

  private deleteModalRef: NgbModalRef | undefined;

  public isLoading = false;
  public scopeTypes = EThreadScope;
  public messages: ThreadMessageModel[] = [];
  public thread!: ThreadViewModel;

  private currentUserId!: number;
  public modalRef?: NgbModalRef;
  private subscriptions: Subject<void> = new Subject<void>();
  public platform!: EPlatform;
  public platformTypes = EPlatform;

  public readonly reclamationInfoSvg = reclamationInfoSvg;
  public readonly deleteSvg = deleteSvg;
  public readonly documentsSvg = documentsSvg;
  public readonly doneSvg = doneSvg;
  public readonly backIcon = backIcon;
  public readonly messagesUnread = messagesUnread;
  public readonly unixToUtcHook = 1000;
  readonly tooltipOpenDelay = 500;
  private readonly mobileSize = 744;
  private readonly tabletSize = 1024;

  public readonly dotDivider = ' · ';
  public readonly dashDivider = ' - ';

  private pagination!: ITablePagination;
  private defaultPagination: ITablePagination = {
    ...PaginationUtil.defaultPagination,
    pageItems: THREAD_MESSAGES_PAGINATE_CHUNK
  };

  public droppedFiles?: File[];
  public isDragDropSectionHovered = false;

  public threadViewComponent!: any;
  public floorPlanItems!: IFloorVersionList[];
  public floorPlanItems$ = new BehaviorSubject<IFloorVersionList[] | null>(null);
  public isProjectContractor = false;
  public isClient!: boolean;
  public alsoSendSMS = false;
  public isClientGuest = false;

  private modalReqRef: NgbModalRef | null = null;

  public readonly clientClass = 'client-radius';

  constructor(
    @Inject(WEB_PLATFORM_TYPE) private platformType: EPlatform,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private restThreadsService: RestThreadsService,
    private localStorageService: LocalStorageService,
    public cd: ChangeDetectorRef,
    private modalFacadeService: ModalFacadeService,
    private sharedUiStorageService: SharedUiStorageService,
    private cableService: CableService,
    private threadsHelperService: ThreadsHelperService,
    private projectService: ProjectService,
    private readonly profileService: ProfileService,
    public readonly moduleAccessService: ModuleAccessService,
    private readonly changeRequestService: ChangeRequestService,
    private clientChangeRequestService: ClientChangeRequestService,
    private readonly reclamationService: ReclamationAdminService,
    private readonly elementRef: ElementRef,
    private readonly routerUtil: RouterUtil,
    private readonly injector: Injector,
    private readonly reclamationHelperService: ReclamationHelperService,
    private readonly changeReqHelperService: ChangeReqHelperService,
  ) {}

  ngOnInit(): void {
    this.platform = this.platformType;
    this.isClient = this.platform === EPlatform.WEB_CLIENT;
    if (this.scope !== this.scopeTypes.ReclamationThreadView && this.scope !== this.scopeTypes.ChangeRequestMessagesView && !this.threadId) {
      this.setThreadId(+this.route.snapshot.params['id']);
    }
    if (this.isClient) this.getUserRole();
    this.initExtraData();
    this.initUserInfo();
    this.initOnCableInitializedHandler();
    this.forkInit(this.threadId, true);
    this.subscribeToCableThreadsSnapshots();
    this.subscribeToMessagesStatusUpdates();

    this.threadViewComponent = ThreadViewComponent;
  }

  ngAfterViewInit(): void {
    this.initRouterEventsListener();
    this.setContractorRole();
  }

  private getDetailChangeRequest(): void {
    this.changeRequestService
      .getChangeRequestDetails(this.thread.projectId.toString(), this.thread.changeRequestId!.toString())
      .pipe(take(1))
      .subscribe((data) => {
        this.threadsHelperService.setExtraData({
          changeRequest: data,
        });
      });
  }

  protected getUnitDetails(): void {
    this.reclamationService
      .getUnitDetails(this.thread.projectId.toString(), this.thread.units[0].id)
      .pipe(untilDestroyed(this))
      .subscribe((unit) => {
        this.threadsHelperService.setExtraData({
          unit: unit,
        });
      });
  }

  private getUserRole(): void {
    this.profileService.getIsGuest.pipe(untilDestroyed(this)).subscribe((isGuest) => {
      this.isClientGuest = isGuest;
      this.disabled = this.isClientGuest;
    });
  }

  setSendSMS(val: boolean): void {
    this.alsoSendSMS = val;
  }

  private initExtraData(): void {
    this.threadsHelperService.getExtraData.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data?.unit) {
        this.unit = data.unit;
      }

      if (data?.changeRequest) {
        this.changeRequest = data.changeRequest;
      }
      this.cd.markForCheck();
    });
  }

  private setContractorRole(): void {
    this.projectService.adminProject$.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.isProjectContractor = res.currentRole === EProjectRoles.Contractor;
      }
    });
  }

  private subscribeToMessagesStatusUpdates(): void {
    this.cableService.updateMessagesStatus$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (this.threadId === thread.messageThreadId) {
        thread.messages.forEach((newMessageState) => {
          const index = this.messages.findIndex((message) => message.id === newMessageState.id);
          if (index !== -1) {
            this.messages[index] = {
              ...this.messages[index],
              read: newMessageState.read,
              delivered: newMessageState.delivered,
            };
          }
        });
        this.cd.detectChanges();
      }
    });
  }

  private subscribeToCableThreadsSnapshots(): void {
    this.cableService.threads$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (thread.removed && thread.id === this.threadId) {
        this.leaveThisThread();
      }
    });
  }

  private initRouterEventsListener(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeConnection();
        if (this.route.snapshot.params['id']) {
          this.setThreadId(+this.route.snapshot.params['id']);
          this.messages = [];
          this.editorWrapperComponent.threadId = this.threadId;
          this.editorWrapperComponent.form.reset({ body: '', document: null }, { emitEvent: false, onlySelf: true });
          this.editorWrapperComponent.resetUsers();
          this.forkInit(this.threadId);
        }
      }
    });

    if (
      this.scope !== EThreadScope.InternalNotes &&
      this.scope !== EThreadScope.InternalNotesAdmins &&
      this.scope !== EThreadScope.Reclamation &&
      this.scope !== EThreadScope.ReclamationThreadView &&
      this.scope !== EThreadScope.ChangeRequestMessagesView &&
      this.scope !== EThreadScope.ChangeRequest &&
      this.scope !== EThreadScope.ChangeRequestGroups
    ) {
      this.route.data.pipe(untilDestroyed(this)).subscribe((v) => {
        this.scope = v['type'];
      });
    }
  }

  private forkInit(threadID: number, firstExecution = false): void {
    this.pagination = { ...this.defaultPagination };
    if (!firstExecution) {
      this.initConnection(threadID);
    }
    this.getThread(threadID);
    this.getMessagesChunk(threadID, this.pagination, true);
  }

  private initConnection(threadId: number): void {
    this.cableService.initMessagesConnection(
      threadId,
      ({ data: message, event }: ICableChannelEmit<ThreadMessageModel>): void => {
        if (threadId !== this.threadId) return;
        if (event === ECableChannelEvents.RemoveMessage) {
          const i = this.messages.findIndex((m) => m.id === message.id);
          i === -1 || this.messages.splice(i, 1);
          this.cd.markForCheck();
          this.cableService.threads$.next(this.threadsHelperService.threadCustomModelToThreadShortModel(this.thread));
        }
        if (event === ECableChannelEvents.NewMessage && this.messages.findIndex((m) => m.id == message.id) === -1) {
          this.pushChunkMessages([plainToClass(ThreadMessageModel, message)], true);
          this.cableService.markMessagesAsRead([message.id]);
        }
      },
    );
  }

  private closeConnection(skipNewSubjectDefinition = false): void {
    this.subscriptions.next();
    this.subscriptions.complete();
    if (!skipNewSubjectDefinition) {
      this.subscriptions = new Subject();
    }
    this.cableService.closeMessagesConnection();
  }

  private initOnCableInitializedHandler(): void {
    this.cableService.isCableInitialized$.pipe(untilDestroyed(this)).subscribe((isInitialized) => {
      if (isInitialized && this.threadId) {
        this.initConnection(this.threadId);
      }
    });
  }

  private getThread(threadId: number): void {
    this.restThreadsService
      .getThreadById(threadId)
      .pipe(takeUntil(this.subscriptions))
      .subscribe((record) => {
        this.thread = record;
        this.threadsHelperService.setThreadStateReadId(this.thread.id, this.thread);
        if (
          (this.scope === EThreadScope.Global || this.scope === EThreadScope.Project || this.isClient) &&
          this.route?.snapshot?.queryParams?.reclamationId
        ) {
          this.openReclamationModalId(this.route?.snapshot?.queryParams?.reclamationId, this.isClient);
        }
        if (this.thread.changeRequestId && this.platform === this.platformTypes.WEB_ADMIN) {
          this.getDetailChangeRequest();
          this.getUnitDetails();
        }
        this.cableService.unreadCounters$.next(null);
      });
  }

  private initUserInfo(): void {
    const userInfo = this.localStorageService.get<ILocalStorageUserInfo>(ELocalStorageKeys.UserInfo);
    if (userInfo) this.currentUserId = userInfo.id;
  }

  public submit({ message, documents, mentions }: OnSubmitEditorPropsType): void {
    const floorMessagesModel: IMessageModelAttachmentsReq[] = this.floorPlanItems?.length
      ? this.floorPlanItems.reduce((acc: IMessageModelAttachmentsReq[], floor) => {
          const val = floor.floor
            .filter((f) => f.selected)
            .map((f) => ({
              attachable_id: f.plan?.id,
              // @ts-ignore
              attachable_type: f.plan?.changeRequestId ? EAttachableType.FloorVersion : EAttachableType.UnitFloor,
              version_number: floor.version,
            }));
          // @ts-ignore
          acc = [...acc, ...val];
          return acc;
        }, [])
      : [];

    this.isLoading = true;
    this.editorWrapperComponent.isLoading = true;
    if (window.innerWidth < this.mobileSize) {
      this.editorWrapperComponent.editorComponent.editor?.getBody().setAttribute('contenteditable', 'false');
    }
    this.restThreadsService
      .saveThreadAttachments(documents)
      .pipe(
        switchMap((savedFiles) =>
          this.restThreadsService.createThreadMesssage(
            this.threadId,
            message,
            savedFiles.map((f) => f.id),
            mentions,
            floorMessagesModel,
            this.isClient ? false : this.alsoSendSMS,
          ),
        ),
        take(1),
      )
      .subscribe(
        (mess: ThreadMessageModel) => {
          this.floorPlanItems = [];
          this.floorPlanItems$.next(this.floorPlanItems);
          this.scrollToBottom();
          if (this.messages.findIndex((m) => m.id == mess.id) === -1) {
            this.pushChunkMessages([mess], true);
          }
          this.editorWrapperComponent.form.reset({ body: '', document: null }, { emitEvent: false, onlySelf: true });
          this.editorWrapperComponent.isLoading = false;
          if (window.innerWidth < this.mobileSize) {
            this.editorWrapperComponent.editorComponent.setFocus(false);
            this.enableEditorAfterSubmit();
          }
          this.cd.detectChanges();
          this.editorWrapperComponent.cd.detectChanges();
          this.isLoading = false;
          this.cd.detectChanges();
          this.thread.state.answered = true;
          this.thread.state.read = true;
          this.cableService.threads$.next(this.threadsHelperService.threadCustomModelToThreadShortModel(this.thread));
        },
        () => {
          this.isLoading = false;
          this.editorWrapperComponent.isLoading = false;
        },
      );
  }

  private enableEditorAfterSubmit(): void {
    of(true)
      .pipe(delay(100))
      .subscribe(() => {
        this.editorWrapperComponent.editorComponent.editor?.getBody().setAttribute('contenteditable', 'true');
      });
  }

  public changeRequestModal(): void {
    const modalRef = this.modalFacadeService.openModal(FloorPlanVersionSelectComponent, {
      centered: true,
      windowClass: 'full-screen-modal floor-plan-modal',
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.floorDrawVersions = this.changeRequest.floorDrawVersions;
    modalRef.componentInstance.floors = this.unit.hasOwnFloorPlans ? this.unit.unitFloors : this.unit.floors;
    modalRef.componentInstance.projectId = this.thread.projectId;
    modalRef.componentInstance.unitId = this.unit.id;

    modalRef.dismissed.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.floorPlanItems = res;
        this.floorPlanItems$.next(this.floorPlanItems);
      }
    });
  }

  public updateFloorPlan(value: IFloorVersionList[]): void {
    this.floorPlanItems = value;
    this.floorPlanItems$.next(this.floorPlanItems);
  }

  private scrollToBottom(): void {
    this.listContainer.nativeElement.scrollTo({
      top: this.listContainer.nativeElement.scrollHeight,
      behavior: 'smooth',
    });
  }

  public changeThreadState(state: Partial<IThreadState>): void {
    this.restThreadsService
      .batchUpdateThreadsStates([this.threadId], state)
      .pipe(take(1))
      .subscribe(() => {
        if (state.done !== undefined) this.thread.state.done = state.done;
        if (state.read !== undefined) this.thread.state.read = state.read;
        if (
          this.scope !== EThreadScope.Reclamation &&
          this.scope !== EThreadScope.ChangeRequest &&
          this.scope !== EThreadScope.ChangeRequestGroups &&
          this.scope !== EThreadScope.ChangeRequestMessagesView &&
          this.scope !== EThreadScope.InternalNotes &&
          this.scope !== EThreadScope.InternalNotesAdmins &&
          this.scope !== EThreadScope.ReclamationThreadView
        ) {
          this.leaveThisThread();
        } else {
          this.threadsHelperService.triggerUnreadThreadEvent(true);
        }
        this.cableService.threads$.next(this.threadsHelperService.threadCustomModelToThreadShortModel(this.thread));
        this.cd.detectChanges();
      });
  }

  openAttachmentsModal(): void {
    const modalClass = this.isClient
      ? this.sharedUiStorageService.modalThreadAttachments.modalDialogClass + ` ${this.clientClass}`
      : this.sharedUiStorageService.modalThreadAttachments.modalDialogClass;
    const modalRefAttachments = this.modalFacadeService.openModal(ThreadAttachmentsComponent, {
      ...this.sharedUiStorageService.modalThreadAttachments,
      modalDialogClass: modalClass,
    });

    modalRefAttachments.componentInstance.modalRef = modalRefAttachments;
    modalRefAttachments.componentInstance.threadId = this.threadId;
    modalRefAttachments.componentInstance.isClient = this.isClient;
  }

  public openReclamationModal(template: TemplateRef<unknown>): void {
    if (this.scope === this.scopeTypes.Reclamation && !this.isClient) {
      this.reclamationHelperService.triggerOpenReclamationDetailsTab();
      return;
    }
    const modalClass = this.isClient
      ? this.sharedUiStorageService.modalReclamation.modalDialogClass + ` ${this.clientClass}`
      : this.sharedUiStorageService.modalReclamation.modalDialogClass;
    this.modalRef = this.modalFacadeService.openModal(template, {
      ...this.sharedUiStorageService.modalReclamation,
      modalDialogClass: modalClass,
    });
  }

  public openReclamationModalId(reclamationId: number, client: boolean): void {
    const modalClass = this.sharedUiStorageService.modalReclamation.modalDialogClass + (client ? ' client-radius' : '');
    const modalRefDetail = this.modalFacadeService.openModal(
      client ? ReclamationClientDetailComponent : ReclamationAdminDetailComponent,
      {
        ...this.sharedUiStorageService.modalReclamation,
        modalDialogClass: modalClass,
        injector: this.injector,
      },
    );

    modalRefDetail.componentInstance.reclamationId = reclamationId;
    if (!client) {
      modalRefDetail.componentInstance.setProjectId = this.thread?.projectId;
      modalRefDetail.componentInstance.pinMode = false;
      modalRefDetail.componentInstance?.removeReclamationEmit?.pipe(untilDestroyed(this)).subscribe(() => {
        this.clearReclamationId();
      });
    }

    modalRefDetail.componentInstance.threadViewComponent = this.threadViewComponent;
    modalRefDetail.componentInstance.disableSwitchingReclamation = true;
    modalRefDetail.componentInstance.disableOpenReclamation = true;

    this.routerUtil.removeQueryParamsWithoutEmittingEvents();
  }

  public getChangeRequestDetails(id: number): void {
    if (this.platformType === this.platformTypes.WEB_CLIENT) {
      this.clientChangeRequestService
        .getDetailChangeRequest(this.thread.units[0].id, id)
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.openDetailRequestModal(data, this.thread.units[0].id);
        });
    } else {
      if (this.scope === this.scopeTypes.ChangeRequest || this.scope === this.scopeTypes.ChangeRequestGroups) {
        this.changeReqHelperService.triggerOpenChangeReqDetailsTab();
        return;
      }
      this.changeRequestService
        .getChangeRequestDetails(this.thread.projectId.toString(), id.toString())
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.openDetailRequestModal(data, data.unit.id as number);
        });
    }
  }

  public openDetailRequestModal(req: ChangeRequestModel | ClientChangeRequestModel, unitId: number): void {
    if (this.modalReqRef) return;
    const modalClass = this.isClient
      ? this.sharedUiStorageService.modalOptionStoreModal.modalDialogClass + ` ${this.clientClass}`
      : this.sharedUiStorageService.modalOptionStoreModal.modalDialogClass;
    this.modalReqRef = this.modalFacadeService.openModal(
      this.isClient ? ClientDetailChangeRequestModalComponent : DetailChangeRequestComponent,
      {
        ...this.sharedUiStorageService.modalOptionStoreModal,
        modalDialogClass: modalClass,
        beforeDismiss: () => {
          this.modalReqRef = null;
          return true;
        },
      },
    );
    this.modalReqRef.componentInstance.modalRef = this.modalReqRef;
    this.modalReqRef.componentInstance.projectId = this.thread.projectId;
    this.modalReqRef.componentInstance.unitId = unitId;
    this.modalReqRef.componentInstance.changeRequest = req;
    this.modalReqRef.componentInstance.hideCustomerCommunication = true;
    this.modalReqRef.componentInstance.threadViewComponent = ThreadViewComponent;
  }

  public clearReclamationId(): void {
    this.thread.reclamationId = null;
    this.cableService.threads$.next(this.threadsHelperService.threadCustomModelToThreadShortModel(this.thread));
  }

  public openRecipientsModal(): void {
    const modalRefAttachments = this.modalFacadeService.openModal(ThreadViewRecipientsComponent, {
      ...this.sharedUiStorageService.modalThreadAttachments,
    });
    const modalComponent = <ThreadViewRecipientsComponent>modalRefAttachments.componentInstance;
    modalComponent.modalRef = modalRefAttachments;
    modalComponent.projectId = this.thread.projectId;
    modalComponent.unitList = this.thread.units;
    modalComponent.saveChangesEmitter.pipe(first()).subscribe((units) => {
      const unitIds = units.map((u) => u.id);
      if (this.thread.units.length === units.length && this.thread.units.every((u) => unitIds.includes(u.id))) {
        modalRefAttachments.close();
      } else {
        this.updateMultiThreadUnitList(modalRefAttachments, units);
      }
    });
  }

  private updateMultiThreadUnitList(ref: NgbModalRef, _units: IThreadUnit[]): void {
    this.restThreadsService
      .updateMultiThreadUnitList(
        this.threadId,
        _units.map((u) => u.id),
      )
      .pipe(first())
      .subscribe((res) => {
        ref.close();
        this.thread.units = _units;
        this.thread.unreadCount = res.unitsCount;
        this.cd.markForCheck();
        this.cableService.threads$.next(this.threadsHelperService.threadCustomModelToThreadShortModel(this.thread));
      });
  }

  public leaveThisThread(onlyMobile?: boolean): void {
    if (onlyMobile && window.innerWidth > this.tabletSize) return;
    if (this.scope === EThreadScope.ReclamationThreadView || this.scope === EThreadScope.ChangeRequestMessagesView) {
      return this.threadsHelperService.setCustomThreadId(EThreadFolders.All);
    }
    void this.router.navigate(['../'], { relativeTo: this.route });
  }

  private getMessagesChunk(threadId: number, p: ITablePagination, isFirstChunk = false): void {
    this.restThreadsService
      .getThreadMesssages(threadId, p)
      .pipe(takeUntil(this.subscriptions))
      .subscribe((res) => {
        this.pagination = res[1];
        this.pushChunkMessages(res[0]);
        if (!isFirstChunk) {
          this.cableService.threads$.next(this.threadsHelperService.threadCustomModelToThreadShortModel(this.thread));
          this.cableService.unreadCounters$.next(null);
          this.threadsHelperService.setThreadStateReadId(this.thread.id, this.thread);
        }
      });
  }

  public onNextChunk(last: boolean): void {
    if (!last || this.isLoading) {
      return;
    }
    if (this.messages.length < this.pagination.pageItems) {
      return;
    }
    if (this.pagination.currentPage + 1 > this.pagination.totalPages) {
      return;
    }
    this.pagination.currentPage++;
    this.getMessagesChunk(this.threadId, this.pagination);
  }

  private pushChunkMessages(_list: ThreadMessageModel[], isNewInstance = false): void {
    if (isNewInstance) {
      this.messages.unshift(this.expandMessageProperties(_list[0]));
      this.incrementPaginationProps();
    } else {
      this.messages.push(
        ..._list
          .filter((el) => !this.messages.find((m) => m.id === el.id))
          .reverse()
          .map((m) => this.expandMessageProperties(m)),
      );
    }

    this.cd.detectChanges();
  }

  private expandMessageProperties(model: ThreadMessageModel): ThreadMessageModel {
    model.dayIdentifier = dayjs(model.createdAt * this.unixToUtcHook).format('YYYY-MM-DD');
    model.isSelf = model.sender.id === this.currentUserId;
    return model;
  }

  private incrementPaginationProps(): void {
    this.pagination.totalCount++;
    if (this.pagination.totalCount > this.pagination.pageItems * this.pagination.totalPages) {
      this.pagination.totalPages++;
    }
  }

  public openRemoveThreadModal(): void {
    from(this.getConfirmationComponentEmitter('thread'))
      .pipe(
        switchMap(() => {
          this.deleteModalRef?.close();
          return this.restThreadsService.batchDestroyThreads([this.threadId]);
        }),
        take(1),
      )
      .subscribe(() => {
        this.cableService.threads$.next(this.threadsHelperService.threadCustomModelToThreadShortModel(this.thread));
        this.leaveThisThread();
      });
  }

  public openRemoveMessageModal(id: number): void {
    from(this.getConfirmationComponentEmitter('message'))
      .pipe(
        switchMap(() => {
          this.deleteModalRef?.close();
          this.pagination.totalCount--;
          return this.restThreadsService.deleteThreadMessage(this.threadId, id);
        }),
        take(1),
      )
      .subscribe();
  }

  private getConfirmationComponentEmitter(entity: 'thread' | 'message'): EventEmitter<boolean> {
    this.deleteModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    const instance = <NewestConfirmModalComponent>this.deleteModalRef.componentInstance;
    instance.title = this.translateService.instant(getThreadsDeleteTitleTranslateKey(entity));
    instance.description = this.translateService.instant(getThreadsDeleteDescriptionTranslateKey(entity));
    instance.button.text = this.translateService.instant('Shared.Button.Yes_delete');
    return instance.passConfirm;
  }

  public isSameMessageSender(i: number): boolean {
    return i + 1 < this.messages.length && this.messages[i].sender.id == this.messages[i + 1].sender.id;
  }

  public isNecessaryToShowDelimiter(i: number, last: boolean): boolean {
    return (
      last || (i + 1 < this.messages.length && this.messages[i].dayIdentifier != this.messages[i + 1].dayIdentifier)
    );
  }

  public goBack(): void {
    if (this.scope === this.scopeTypes.Unit) {
      const listType = this.route.snapshot.params.list_type;
      const unitId = this.route.parent?.snapshot.params.unit_id;

      const bracketsIndex = this.router.url.indexOf('(');
      let url = this.router.url;
      if (bracketsIndex) {
        url = url.substring(0, bracketsIndex - 1);
      }
      const newUrl = url.substring(1).split('/');
      void this.router.navigate([
        ...newUrl,
        {
          outlets: {
            threads: [unitId, 'list', listType],
          },
        },
      ]);
    }
  }

  private hasBaseAccessToShowWidget(i: number): boolean {
    return i === this.messages.length - 1 && this.pagination.totalCount === this.messages.length;
  }

  public isAccessToShowReclamationWidget(i: number): boolean {
    const hasBaseAccess = Boolean(this.hasBaseAccessToShowWidget(i) && this.thread?.reclamationId);
    return Boolean(
      this.platform === this.platformTypes.WEB_ADMIN
        ? hasBaseAccess && this.scope !== EThreadScope.InternalNotes
        : hasBaseAccess && this.scope === this.scopeTypes.Client && this.moduleAccessService.accesses?.pageReclamations,
    );
  }

  public isAccessToShowChangeRequestWidget(i: number): boolean {
    const hasBaseAccess = Boolean(this.hasBaseAccessToShowWidget(i) && this.thread?.changeRequestId);
    return Boolean(
      this.platform === this.platformTypes.WEB_ADMIN
        ? hasBaseAccess
        : hasBaseAccess &&
            this.scope === this.scopeTypes.Client &&
            this.moduleAccessService.accesses?.pageChangeRequests,
    );
  }

  ngOnDestroy(): void {
    this.closeConnection(true);
  }

  get isOnlyBuyersMessages(): boolean {
    return this.messages.every((m) => m.sender.id === this.currentUserId);
  }

  private setThreadId(id: number): void {
    this.threadId = id;
    (<HTMLElement>this.elementRef.nativeElement).setAttribute(ACTIVE_THREAD_ID_DATA_ATTRIBUTE, id.toString());
  }
}
