import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';

import { StatisticCardComponent } from '../statisctic-card/statistic-card.component';

@Component({
  selector: 'atl-statistic-card-dropdown',
  templateUrl: './statistic-card-dropdown.component.html',
  styleUrls: ['./statistic-card-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
})
export class StatisticCardDropdownComponent<T> extends StatisticCardComponent {
  @Input() hasMenu = false;
  @Input() listOfInfo: T[] = [];

  @Output() selectedItemHandler = new EventEmitter<T>();

  public isDisplayMenu = false;

  public toggleMenu(): void {
    if (!this.hasMenu) {
      return;
    }
    this.isDisplayMenu = !this.isDisplayMenu;
  }

  public closeMenu(): void {
    this.isDisplayMenu = false;
  }

  selectItem(item: T): void {
    this.selectedItemHandler.next(item);
  }
}
