import {Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {ChangeRequestModel, EThreadScope, UnitUserModel} from '@atlas-workspace/shared/models';

@Component({
  selector: 'atl-change-request-customer-notes',
  templateUrl: './change-request-customer-notes.component.html',
  styleUrls: ['./change-request-customer-notes.component.scss']
})
export class ChangeRequestCustomerNotesComponent implements OnInit {
  @Input() threadViewComponent!: Type<any>;
  @Input() changeRequest!: ChangeRequestModel;
  @Input() unit!: UnitUserModel;
  @Input() advanceMode = false;

  @ViewChild('threadView', { static: true, read: ViewContainerRef })
  private vcRef!: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.initThreadViewComponent();
  }

  private initThreadViewComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.threadViewComponent);
    const componentRef = this.vcRef.createComponent(componentFactory);
    componentRef.instance.scope = this.advanceMode ? EThreadScope.ChangeRequestGroups : EThreadScope.ChangeRequest;
    componentRef.instance.changeRequest = this.changeRequest;
    componentRef.instance.unit = this.unit;
    componentRef.instance._threadId = this.changeRequest.messageThreadId;
    componentRef.instance.isModal = true;
  }
}
