import {CommonModule} from "@angular/common";
import {Component, HostBinding, HostListener, Input} from "@angular/core";

@Component({
  selector: "atl-filter-chip",
  templateUrl: "./filter-chip.component.html",
  styleUrls: ["./filter-chip.component.scss"],
  standalone: true,
  imports: [CommonModule]
,})
export class FilterChipComponent {
  @Input() set selected(value: any) {
    this.isSelected = Boolean(value);
  };
  @Input() title!: string;
  @Input() dot: '·' | '•' = '·';
  @Input() useStaticIcon = false;
  @Input() staticIcon = '/assets/filter-icon-new.svg';
  @Input() useActiveIcon = false;
  @Input() activeIcon = '/assets/newest-checkmark.svg';
  @Input() useRoundedCount = false;
  @Input() useRoundedCountWithPlusSign = true;
  @Input() showZeroCount = false;
  @Input() count?: number;
  @Input() useDropdownIcon = false;
  @Input() dropdownIcon = '/assets/caret-down.svg';
  @Input() isDropdownOpen = false;
  @Input() disabled = false;
  @Input() useAdaptivity = false;

  public isSelected!: boolean;

  @HostListener('click', ['$event'])
  @HostListener('touchstart', ['$event'])
  preventInteraction(e: Event): void {
    if (this.disabled) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
  }
}
