import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { CustomIconComponent } from "@atlas-workspace/shared/ui";

import { ReclamationMessagesInfoModule } from '../reclamation-messages-info/reclamation-messages-info.module';
import { ReclamationAdminMessagesComponent } from './reclamation-admin-messages.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ReclamationAdminMessagesComponent],
  exports: [ReclamationAdminMessagesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    ReclamationMessagesInfoModule,
    CustomIconComponent,
    TranslateModule
  ],
})
export class ReclamationAdminMessagesModule {}
