import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MessageModalComponent } from '@atlas-workspace/shared/form';
import { ModalFacadeService, ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  changeReqStatusList,
  ChangeRequestGroupOfferModel,
  ChangeRequestOfferModel,
  ChangeRequestSettingModel,
  ClientChangeRequestModel,
  ClientCombinedOfferModel,
  EChangeRequestStatus,
  EChangeRequestTab,
  EChangeRequestTabId,
  EThreadScope,
  FloorModel,
  ISettingsMenu,
  UnitFloorModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import {
  CableService,
  ClientChangeRequestService,
  ClientCounterService,
  ReclamationClientService,
  SharedUiStorageService,
  ThreadsHelperService,
  UnreadOffersCounterHelper,
} from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import {Observable} from "rxjs";
import { take } from 'rxjs/operators';

import { navigationMenuClient } from '../../../helpers/change-request-client.helper';

@UntilDestroy()
@Component({
  selector: 'atl-detail-change-request-modal',
  templateUrl: './detail-change-request-modal.component.html',
  styleUrls: ['./detail-change-request-modal.component.scss', '../../../styles/status.component.scss'],
  providers: [ClientChangeRequestService],
})
export class ClientDetailChangeRequestModalComponent implements OnInit {
  @Input() modalRef!: NgbModalRef;
  @Input() changeRequest!: ClientChangeRequestModel;
  @Input() unitId!: number;
  @Input() navigationMenuID?: string;
  @Input() threadViewComponent!: any;
  @Input() isDualView!: boolean;
  @Input() isAlLowBankId!: boolean;
  @Input() bankIdToggle!: boolean;
  @Input() public projectId!: number;
  @Input() unit!: UnitUserModel;
  @Input() isGuest = false;
  @Input() hideCustomerCommunication = false;
  @Input() isGroupScope = false;
  @Input() isGroupReadonlyScope = false;
  @Input() settings?: ChangeRequestSettingModel;
  @Input() groupRequestIndex?: number;
  @Input() activeGroupOffer?: ChangeRequestGroupOfferModel;
  @Input() getActiveGroupOfferCb?: () => Observable<ClientCombinedOfferModel>;

  @Output() readonly openMessagesTabEmit = new EventEmitter<number | null>();
  @Output() readonly updateTable = new EventEmitter<ClientChangeRequestModel>();
  @Output() readonly onopenGroupOffer = new EventEmitter<ChangeRequestGroupOfferModel>();

  public previewPlan?: string;
  public onlyWithFloorPlanIndex: number | null = null;
  public activeGroupOfferFullInfo?: ClientCombinedOfferModel;
  public offers!: ChangeRequestOfferModel[];
  public loadOffers = false;
  public activeTab = EChangeRequestTabId.General;
  public navigationMenu: ISettingsMenu[] = cloneDeep(navigationMenuClient);
  public readonly requestTab = EChangeRequestTabId;
  public readonly offerSentStatus = EChangeRequestStatus.Offered;
  public readonly offerExpiredStatus = EChangeRequestStatus.OfferExpired;
  public readonly eThreadScopes = EThreadScope;
  public isLoading = false;
  public statusKeys = EChangeRequestStatus;
  public statusList = changeReqStatusList;
  public floorPlanData!: (UnitFloorModel | FloorModel)[] | any;
  public openActiveOffer = false;
  public showBanner = false;
  public bannerText = this.translateService.instant('Shared.Entity.Link_copied');

  constructor(
    private changeRequestService: ClientChangeRequestService,
    private modalHelpersService: ModalHelpersService,
    private translateService: TranslateService,
    private modalFacadeService: ModalFacadeService,
    private sharedUiStorageService: SharedUiStorageService,
    private threadsHelperService: ThreadsHelperService,
    private cableService: CableService,
    private readonly clientCounterService: ClientCounterService,
    private readonly reclamationService: ReclamationClientService,
    private readonly unreadCRsCounterHelper: UnreadOffersCounterHelper
  ) {}

  ngOnInit(): void {
    this.getOfferList();
    if (this.isGroupScope) {
      this.navigationMenu = this.navigationMenu.filter((item) => item.id !== EChangeRequestTabId.Offer);
    }
    if (this.isDualView || this.hideCustomerCommunication) {
      this.navigationMenu = this.navigationMenu.filter((item) => item.id !== EChangeRequestTabId.ClientCustomer);
    } else {
      this.updateThreadCount();
      this.subscribeToThreadReadStateChange();
      this.subscribeToCableThreadsSnapshots();
    }
    if (this.unit) {
      this.setFloorPlanData();
    } else {
      this.getUnitDetail();
    }

    if (this.navigationMenuID) {
      const tab = this.navigationMenu.find((item) => item.id === this.navigationMenuID)?.id as EChangeRequestTabId;
      if (tab) {
        this.activeTab = tab;
      }
    }

    if (this.changeRequest.status === this.statusKeys.Offered) {
      this.navigationMenu.forEach((menu) => {
        if (menu.name === this.requestTab.Offer) {
          menu.counter = 1;
        }
      });
    }
    this.handleFloorPlanIndexOfGroupOffer();
  }

  private handleFloorPlanIndexOfGroupOffer(): void {
    if (this.getActiveGroupOfferCb) {
      this.getActiveGroupOfferCb().pipe(untilDestroyed(this)).subscribe((offer) => {
        this.activeGroupOfferFullInfo = offer;
        const version = offer.subOffers.find(s => s.changeRequest.id === this.changeRequest.id)?.versionNumber || 1;
        const drawVersionIndex = this.changeRequest.floorDrawVersions.findIndex(v => v.versionNumber === version);
        const floorId = this.changeRequest.floorDrawVersions[drawVersionIndex]?.floor?.id;
        if (typeof floorId === 'number') {
          this.onlyWithFloorPlanIndex = this.floorPlanData.findIndex((v:UnitFloorModel) => v.id === floorId);
        } else {
          this.onlyWithFloorPlanIndex =
            this.floorPlanData.findIndex((v:UnitFloorModel) => v.id === this.changeRequest.floorId);
        }
      });
    } else if (this.isGroupReadonlyScope) {
      this.onlyWithFloorPlanIndex =
        this.floorPlanData.findIndex((v:UnitFloorModel) => v.id === this.changeRequest.floorId);
    }
  }

  private getUnitDetail(): void {
    this.reclamationService
      .getUnitDetails(this.projectId, this.unitId)
      .pipe(take(1))
      .subscribe((unit) => {
        this.unit = unit;
        this.setFloorPlanData();
      });
  }

  private offerCount(): void {
    const isCount = this.offers.some((offer) => offer.status === EChangeRequestStatus.Offered);
    this.navigationMenu.forEach((menu) => {
      if (menu.name === this.requestTab.Offer) {
        menu.counter = isCount ? 1 : 0;
      }
    });
    this.navigationMenu = [...this.navigationMenu];
  }

  private setFloorPlanData(): void {
    this.floorPlanData = this.unit.mixinFloors;
  }

  closeModal(): void {
    this.modalRef.dismiss();
  }

  private getOfferList(openActive = false): void {
    this.changeRequestService
      .getChangeRequestOfferList(this.unitId, this.changeRequest.id, '', 1, 50)
      .pipe(take(1))
      .subscribe((offers) => {
        this.offers = offers;
        this.loadOffers = true;
        this.offerCount();
        this.viewedCount();

        if (openActive) {
          this.openActiveOffer = true;
          this.activeTab = this.requestTab.Offer;
        }
        this.changeRequest.viewed = true;
        this.updateTable.emit(this.changeRequest);
      });
  }

  viewedCount(): void {
    this.unreadCRsCounterHelper.getUnreadChangeRequestsCounter(this.projectId, this.unitId).pipe(take(1)).subscribe();
  }

  private updateThreadCount(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.navigationMenu.find((item) => item.id === EChangeRequestTabId.ClientCustomer)!.counter =
      this.changeRequest.messageThreadUnreadCount;
    this.navigationMenu = [...this.navigationMenu];
  }

  private subscribeToThreadReadStateChange(): void {
    this.threadsHelperService
      .getThreadStateReadId()
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        if (id === this.changeRequest?.messageThreadId) {
          this.changeRequest.messageThreadUnreadCount = 0;
          this.changeRequest.messageThreadState.read = true;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.id === EChangeRequestTabId.ClientCustomer)!.counter = 0;
          this.navigationMenu = [...this.navigationMenu];
        }
      });
  }

  private subscribeToCableThreadsSnapshots(): void {
    this.cableService.threads$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (Number(this.projectId) !== thread.project?.id) {
        return;
      }
      if (
        thread.id === this.changeRequest?.messageThreadId &&
        this.activeTab !== EChangeRequestTabId.ClientCustomer &&
        this.changeRequest
      ) {
        this.changeRequest.messageThreadHasMessages = true;
        this.changeRequest.messageThreadUnreadCount = Number(thread.unreadCount);
        this.changeRequest.messageThreadState.answered = false;
        this.changeRequest.messageThreadState.read = false;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === EChangeRequestTabId.ClientCustomer)!.counter = thread.unreadCount;
        this.navigationMenu = [...this.navigationMenu];
      }
    });
  }

  public selectTab(e: { itemId: EChangeRequestTabId, tabName: EChangeRequestTab }): void {
    this.activeTab = e.itemId;
    this.openActiveOffer = false;

    if (this.activeTab === this.requestTab.Offer && !this.loadOffers) {
      this.getOfferList();
    }

    if (this.activeTab === this.requestTab.ClientCustomer) {
      this.openMessagesTabEmit.emit(this.changeRequest.id);
    } else {
      this.openMessagesTabEmit.emit(null);
    }
  }

  public openPreview(index: number): void {
    if (this.changeRequest.fileResources) {
      this.modalHelpersService.previewImages(this.changeRequest.fileResources, index);
    }
  }

  public changeStatus(status: EChangeRequestStatus): void {
    this.changeRequest.status = status;
    this.changeRequest.localizedStatus = this.translateService.instant(this.statusList[status].title);
    this.updateTable.emit(this.changeRequest);
  }

  public updateStateOffer(offer: ChangeRequestOfferModel[]): void {
    this.offers = offer;
    this.offerCount();
  }

  public openActiveOfferTab(): void {
    if (!this.offers) {
      this.getOfferList(true);
    } else {
      this.openActiveOffer = true;
      this.activeTab = this.requestTab.Offer;
    }
  }

  public showComment(): void {
    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalClientCreateOffer
    );

    const comment = this.changeRequest.eventLogs[this.changeRequest.eventLogs.length - 1].comment;

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Shared.Title.Change_request_was_declined';
    modalRef.componentInstance.button = 'Shared.Yes_decline';
    modalRef.componentInstance.placeholder = 'Change_request.Decline.Modal.Placeholder';
    modalRef.componentInstance.comment = comment;
    modalRef.componentInstance.onlyView = true;
    modalRef.componentInstance.isClient = true;
  }

  public openGroupOffer(): void {
    this.onopenGroupOffer.emit(this.activeGroupOffer);
  }

  public showActionBanner(show: boolean): void {
    this.showBanner = show;
  }
}
