import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {ChangeRequestCreateConfirmComponent} from "@atlas-workspace/change-requests";
import {ChangeRequestSettingModel} from "@atlas-workspace/shared/models";
import {ModalFacadeService, PageHelperService} from "@atlas-workspace/shared/service";
import {take} from "rxjs/operators";

@Component({
  selector: "atl-detail-change-request-header-dropdown",
  templateUrl: "./detail-change-request-header-dropdown.component.html",
  styleUrls: ["./detail-change-request-header-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRequestHeaderDropdownComponent {
  @Input() changeRequestId!: number;
  @Input() createdAt?: string;
  @Input() settings!: ChangeRequestSettingModel;

  public isExpanded = false;
  public readonly tooltipDelay = 4e2;

  @Output() oncopy = new EventEmitter<void>();

  constructor(
    private readonly modalFacadeService: ModalFacadeService,
    private readonly pageHelperService: PageHelperService,
  ) {}

  public toggle(value?: boolean): void {
    this.isExpanded = value !== undefined ? value : !this.isExpanded;
  }

  public copyLink(): void {
    const params = '?changeRequestId=' + this.changeRequestId;
    this.pageHelperService.copyToClipboard(params, false, () => this.oncopy.emit());
    this.toggle(false);
  }

  public openTerms(): void {
    const modalRef = this.modalFacadeService.openModal(ChangeRequestCreateConfirmComponent, {
      centered: true,
      windowClass: 'confirm-condition-modal client-radius',
    });

    const componentInstance = modalRef.componentInstance as ChangeRequestCreateConfirmComponent;
    componentInstance.modalRef = modalRef;
    componentInstance.showControls = false;
    componentInstance.showCheckbox = false;
    componentInstance.title = this.settings.termsConditionsData.termsTitle;
    componentInstance.description = this.settings.termsConditionsData.termsDescription;
    componentInstance.confirmPass.pipe(take(1)).subscribe(() => modalRef.dismiss());
  }
}
