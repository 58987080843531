<div
  class="filter__chip d-flex gap-4 align-items-center br-16 f-family-sfpro"
  [ngClass]="{
    'selected': isSelected || useDropdownIcon && isDropdownOpen,
    'use-rounded-count': useRoundedCount,
    'disabled': disabled,
    'adaptive': useAdaptivity
  }"
>
  <img *ngIf="useStaticIcon" [src]="staticIcon" class="static-icon" alt="icon">
  <img *ngIf="!useStaticIcon && useActiveIcon" [src]="activeIcon" class="active-icon" alt="Icon">

  <span class="fz-14 lh-24 fw-400 text-black-960">{{ title }}</span>

  <ng-template [ngIf]="showZeroCount && count !== undefined || !showZeroCount && count">
    <span *ngIf="!useRoundedCount" class="fz-14 lh-24 fw-400 text-black-960">{{ dot }}</span>
    <span class="fz-14 lh-24 fw-400 text-black-960" [class.rounded-count]="useRoundedCount">
      <ng-template [ngIf]="useRoundedCount && useRoundedCountWithPlusSign">+</ng-template>{{ count }}
    </span>
  </ng-template>

  <img *ngIf="useDropdownIcon" [src]="dropdownIcon" class="dropdown-icon" [class.opened]="isDropdownOpen" alt="icon">
</div>
