import {Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {ChangeRequestModel, EAccessTag, EThreadScope} from '@atlas-workspace/shared/models';

@Component({
  selector: 'atl-change-request-internal-notes',
  templateUrl: './change-request-internal-notes.component.html',
  styleUrls: ['./change-request-internal-notes.component.scss']
})
export class ChangeRequestInternalNotesComponent implements OnInit {
  @Input() public threadViewComponent!: Type<any>;
  @Input() public changeRequest!: ChangeRequestModel;

  @ViewChild('threadView', { static: true, read: ViewContainerRef })
  private vcRef!: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.initThreadViewComponent();
  }

  private initThreadViewComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.threadViewComponent);
    const componentRef = this.vcRef.createComponent(componentFactory);
    componentRef.instance.scope = EThreadScope.InternalNotes;
    componentRef.instance.accessTag = EAccessTag.ChangeRequests;
    componentRef.instance._threadId = this.changeRequest.notesMessageThreadId;
    componentRef.instance.isModal = true;
  }
}
