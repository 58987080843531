import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaxLengthDirective, NoEmojiDirective } from '@atlas-workspace/shared/directives';
import { AutoHeightTextareaDirective } from './directive/auto-height-textarea.directive';

@Component({
  selector: 'atl-newest-textarea',
  templateUrl: './newest-textarea.component.html',
  styleUrls: ['./newest-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewestTextareaComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NoEmojiDirective, AutoHeightTextareaDirective, MaxLengthDirective],
})
export class NewestTextareaComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() maxLengthString = 100;
  @Input() maxLengthTextarea = 500;
  @Input() readonly = false;

  @ViewChild('input') input!: ElementRef;

  public inputValue!: string;
  private initValue!: string;
  public focused = false;

  private onChange!: (value: string) => void;
  public onTouched!: () => void;

  constructor(protected cdr: ChangeDetectorRef) {}

  public changeValue(value: string): void {
    this.onChange(value);
    this.inputValue = value;
    return;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.initValue = value;
    this.inputValue = value;
    this.cdr.markForCheck();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onFocus(event: boolean): void {
    this.focused = event;
  }

  public onBlur(): void {
    this.input.nativeElement.scrollTop = 0;
  }
}
