export enum EToggleView {
  Table = 'table',
  Card = 'card',
}

export interface IToggle {
  title: string;
  icon: string;
  value: EToggleView;
  active: boolean;
}

export const toggle: IToggle[] = [
  {
    title: 'Shared.View_toggle.Table_view',
    icon: '/assets/table-icon-new.svg',
    value: EToggleView.Table,
    active: true,
  },
  { title: 'Shared.View_toggle.Cards_view', icon: '/assets/card-view.svg', value: EToggleView.Card, active: false },
];
