<!--
  ******************************************************
  @Cypress
  - interaction with message item element
  - interaction with message text
  - interaction with delivered indicator
  - interaction with message attachments
  - interaction with message options (mark unread, delete)
  ******************************************************
-->
<div
  class="message__item-position"
  [ngClass]="[
    (!message.isSelf && scope === scopeTypes.InternalNotes) ||
    (message.sender.type === userTypes.User && platform === platformTypes.WEB_ADMIN) ||
    (message.sender.type === userTypes.MainAdmin && platform === platformTypes.WEB_CLIENT)
      ? 'align-items-start'
      : 'align-items-end',
    isSameSender && !afterDelimiter ? 'mt-4' : 'mt-16',
    scope ? scope : ''
  ]"
>
  <ng-container
    [ngTemplateOutlet]="message.isDeleted ? deletedMessageTemplate : defaultMessageTemplate"
    [ngTemplateOutletContext]="{ $implicit: message }"
  >
  </ng-container>

  <ng-container *ngIf="message.messagesModelAttachments.length">
    <ng-container *ngFor="let messagesAttachments of message.messagesModelAttachments">
      <ng-container *ngIf="unit && changeRequest">
        <ng-container
          [ngTemplateOutlet]="floorPlan"
          [ngTemplateOutletContext]="{ $implicit: messagesAttachments }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #floorPlan let-messagesAttachments>
  <div
    *ngIf="messagesAttachments | threadMessageChangeRequest: changeRequest.floorDrawVersions : unit as floor"
    class="message__floor"
    [attr.data-cy-id]="message.id"
  >
    <span
      class="message__floor-header"
      [ngClass]="[
        (!message.isSelf && scope === scopeTypes.InternalNotes) ||
        (message.sender.type === userTypes.User && platform === platformTypes.WEB_ADMIN) ||
        (message.sender.type === userTypes.MainAdmin && platform === platformTypes.WEB_CLIENT)
          ? 'text-align-left'
          : 'text-align-right'
      ]"
    >
      {{ floor?.name || floor?.floorName }}
      <span *ngIf="messagesAttachments?.versionNumber">
        · {{ 'Shared.Entity.Version' | translate }} {{ messagesAttachments.versionNumber }}</span
      >
    </span>
    <div #parent class="message__floor-floor" (click)="showVersionFloorPlan(messagesAttachments?.versionNumber)">
      <img
        [atlImageResize]="parent"
        [src]="floor?.filename?.url || floor?.filenameRemoteUrl || floor?.plan?.fullImage?.url"
        alt="floor_plan"
      />
    </div>
  </div>
  <div
    *ngIf="
      message.sender.type === userTypes.MainAdmin &&
      isFirst &&
      platform !== platformTypes.WEB_CLIENT &&
      scope !== scopeTypes.InternalNotes &&
      message.messagesModelAttachments.length
    "
    class="mt-4 fz-12 lh-16 fw-500 text-gray-890 text-align-right"
    data-cy="message-delivered-indicator"
  >
    {{
      (!message.read && !message.delivered
        ? 'Shared.Entity.Sent'
        : message.read
          ? 'Shared.Entity.Read'
          : 'Shared.Entity.Delivered'
      ) | translate
    }}
  </div>
</ng-template>

<ng-template #defaultMessageTemplate let-message>
  <div
    class="message__item"
    data-cy="message"
    [attr.data-cy-id]="message.id"
    [ngClass]="{
      self: message.isSelf,
      'client-message': platform === platformTypes.WEB_CLIENT,
      'keep-options-hidded': hasOpenedAttachmentOptions
    }"
  >
    <header role="banner" class="message__item-header">
      <atl-firm-icon
        class="message__item-icon"
        [model]="message.sender"
        nameKey="name"
        [isUser]="true"
        [isMonochrome]="true"
      ></atl-firm-icon>
      <span class="fz-14 lh-24 fw-500" data-cy="message-sender">{{
        message.isSelf ? ('Shared.Entity.Me' | translate) : message.sender.name
      }}</span>
      <span class="message__item-date fz-12 lh-16 fw-400">{{ message.createdAt | messageIntervalDate | async }}</span>
    </header>
    <div class="message__item-content">
      <div
        *ngIf="message.text"
        class="message__item-content-text fz-14 lh-24 fw-400"
        data-cy="message-text"
        [innerHTML]="message.text | safeHtml"
      ></div>
      <div
        *ngIf="message.attachments?.length"
        class="message__item-content-attachments mt-8"
        data-cy="message-attachments"
      >
        <ng-container *ngFor="let file of message.attachments">
          <atl-newest-document-card
            (click)="openFilePreview(file)"
            [image]="file"
            [fileName]="file.name"
            [fileType]="file.extension"
            [fileSize]="file.size"
            [downloadLink]="file.fileName.url"
            [isImage]="file.isImage"
            [hasRemove]="false"
            [useDropdownOptions]="platform === platformTypes.WEB_ADMIN"
            [closestDropdownOptionsSelector]="'.messages__container'"
            (optionsOpened)="setOpenedOption($event)"
          >
          </atl-newest-document-card>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="platform !== platformTypes.WEB_CLIENT"
      class="message__item-options br-8"
      data-cy="message-item-options"
    >
      <button
        *ngIf="isFirst && scope !== scopeTypes.InternalNotes"
        class="message__item-options-item flex-column-centered"
        data-cy="message-item-mark-unread-option"
        (click)="unread.emit()"
        [ngbTooltip]="'Threads.Mark_unread' | translate"
        [openDelay]="tooltipOpenDelay"
        placement="top"
        triggers="hover touchstart"
        [tooltipClass]="message.isSelf || message.sender.type === userTypes.MainAdmin ? 'tooltip-to-left' : ''"
      >
        <atl-custom-icon [icon]="messagesUnread"></atl-custom-icon>
      </button>
      <button
        *ngIf="!threadHasOnlyOneMessage && message.isSelf"
        class="message__item-options-item flex-column-centered"
        data-cy="message-item-delete-option"
        (click)="remove.emit(message.id)"
        [ngbTooltip]="'Button.Delete' | translate"
        [openDelay]="tooltipOpenDelay"
        placement="top"
        triggers="hover touchstart"
        [tooltipClass]="message.isSelf || message.sender.type === userTypes.MainAdmin ? 'tooltip-to-left' : ''"
      >
        <atl-custom-icon [icon]="deleteSvg"></atl-custom-icon>
      </button>
    </div>
  </div>

  <div
    *ngIf="
      message.sender.type === userTypes.MainAdmin &&
      isFirst &&
      platform !== platformTypes.WEB_CLIENT &&
      scope !== scopeTypes.InternalNotes &&
      !message.messagesModelAttachments.length
    "
    class="mt-4 fz-12 lh-16 fw-500 text-gray-890 text-align-right"
    data-cy="message-delivered-indicator"
  >
    {{
      (!message.read && !message.delivered
        ? 'Shared.Entity.Sent'
        : message.read
          ? 'Shared.Entity.Read'
          : 'Shared.Entity.Delivered'
      ) | translate
    }}
  </div>
</ng-template>

<ng-template #deletedMessageTemplate let-message>
  <div class="message__item-deleted">
    <atl-firm-icon
      class="message__item-icon"
      [model]="message.sender"
      nameKey="name"
      [isUser]="true"
      [isMonochrome]="true"
    ></atl-firm-icon>
    <span class="fz-14 lh-24 fw-400"
      ><b class="fw-500">{{ message.sender.name }}</b> {{ 'Shared.Entity.deleted_message' | translate }}</span
    >
    <span class="fz-12 lh-16 fw-400 capitalize">{{ message.createdAt | messageIntervalDate | async }}</span>
  </div>
</ng-template>
