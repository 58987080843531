import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

import { InputDateTimePickerComponent } from '../input-date-time-picker/input-date-time-picker.component';

@Component({
  selector: 'atl-newest-date-time-picker',
  templateUrl: './newest-date-time-picker.component.html',
  styleUrls: ['./newest-date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewestDateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NewestDateTimePickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewestDateTimePickerComponent
  extends InputDateTimePickerComponent
  implements ControlValueAccessor, OnChanges, Validator
{
  @Input() public calendarIcon = 'assets/newest_calendar.svg';
  @Input() navigation: 'select' | 'arrows' | 'none' = 'arrows';
  @Input() outsideDays: 'visible' | 'hidden' | 'collapsed' = 'visible';
  public hoveredIcon = false;

  onHoverIcon(): void {
    this.hoveredIcon = true;
  }

  onBlurIcon(): void {
    this.hoveredIcon = false;
  }
}
