import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'atl-double-button',
  templateUrl: './double-button.component.html',
  styleUrls: ['./double-button.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, TranslateModule, NgbTooltipModule],
})
export class DoubleButtonComponent {
  @Input() title = '';
  @Input() icon = 'assets/clock-gray.svg';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() tooltipTitle = 'Title.Schedule_for_later';

  @Output() private readonly firstOnClick = new EventEmitter;
  @Output() private readonly secondOnClick = new EventEmitter;

  public readonly tooltipDelay = 400;

  firstHandler(): void {
    if (this.loading) return;
    this.firstOnClick.emit();
  }

  secondHandler(): void {
    if (this.loading) return;
    this.secondOnClick.emit();
  }
}
