import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';

import { TextEditorComponent } from './text-editor.component';

@NgModule({
  imports: [CommonModule, EditorModule, ReactiveFormsModule],
  declarations: [TextEditorComponent],
  exports: [TextEditorComponent],
})
export class TextEditorModule {}
