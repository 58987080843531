import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { DropDownWritingComponent } from '../drop-down-writing/drop-down-writing.component';

@Component({
  selector: 'atl-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDropdownComponent extends DropDownWritingComponent implements ControlValueAccessor {
  @Input() removable = true;
  @Input() inputSubTitle: string | undefined;
  @Output() remove = new EventEmitter();

  removeItem(): void {
    this.remove.emit(true);
  }
}
