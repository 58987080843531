import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';
import { TranslateModule } from '@ngx-translate/core';

import { EToggleView, IToggle, toggle } from './view-toggle.model';

@Component({
  selector: 'atl-view-toggle',
  templateUrl: './view-toggle.component.html',
  styleUrls: ['./view-toggle.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, ClickOutsideDirective],
})
export class ViewToggleComponent implements OnInit {
  @Input() toggle!: EToggleView;
  @Input() showIcon = false;

  public toggleList: IToggle[] = toggle;
  public activeToggle!: IToggle;
  public isOpen = false;
  public readonly checkIcon = '/assets/multiple_select_checkbox.svg';
  public readonly arrowIcon = '/assets/arrow_down_option_store.svg';

  @Output() private readonly selectView = new EventEmitter<EToggleView>();

  ngOnInit(): void {
    const activeToggle = this.toggleList.find((l) => l.value === this.toggle);
    if (activeToggle) {
      this.activeToggle = activeToggle;
    }
  }

  select(item: IToggle): void {
    if (this.activeToggle.value !== item.value) {
      this.activeToggle = item;
      this.selectView.emit(item.value);
    }
    this.isOpen = false;
  }

  openToggle(): void {
    this.isOpen = !this.isOpen;
  }

  close(): void {
    this.isOpen = false;
  }
}
