import { ChangeDetectorRef, Component, Input, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapterService } from '@atlas-workspace/shared/form';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  AdminProfileService,
  AuthAdminService,
  CompaniesService,
  ContractorService, NetworkService,
  ProjectMembersService,
  RandomColorService,
  ReclamationAdminService,
  ReclamationHelperService
} from '@atlas-workspace/shared/service';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { ReclamationAdminCreateComponent } from '../reclamation-admin-create/reclamation-admin-create.component';

@Component({
  selector: 'atl-protocol-reclamation-create',
  templateUrl: './protocol-reclamation-create.component.html',
  styleUrls: [
    './protocol-reclamation-create.component.scss',
    '../reclamation-admin-create/reclamation-admin-create.component.scss',
  ],
})
export class ProtocolReclamationCreateComponent extends ReclamationAdminCreateComponent {

  @Input() set activeUnit(value: number) {
    if (value) {
      this.setUnitControlDelay(value);
    }
  }

  public readonly longNameTruncateProtocol = 15;
  public readonly longEmailTruncateProtocol = 20;

  constructor(
    protected reclamationAdminService: ReclamationAdminService,
    protected fb: FormBuilder,
    protected authService: AuthAdminService,
    protected randomColorService: RandomColorService,
    protected route: ActivatedRoute,
    protected dateAdapter: DateAdapterService,
    protected router: Router,
    protected cdr: ChangeDetectorRef,
    protected renderer: Renderer2,
    protected reclamationHelperService: ReclamationHelperService,
    protected contractorService: ContractorService,
    protected companiesService: CompaniesService,
    protected profileService: AdminProfileService,
    protected modalHelpersService: ModalHelpersService,
    protected network: NetworkService,
    protected readonly projectMembersService: ProjectMembersService
  ) {
    super(
      reclamationAdminService,
      fb,
      authService,
      randomColorService,
      route,
      dateAdapter,
      router,
      cdr,
      renderer,
      reclamationHelperService,
      contractorService,
      companiesService,
      profileService,
      modalHelpersService,
      network,
      projectMembersService
    );
  }


  private setUnitControlDelay(id: number): void {
    of(true)
      .pipe(take(1), delay(100))
      .subscribe(() => {
        this.form.controls.unit.setValue([{ id: id }], { emitEvent: this.isOnline });
        this.form.controls.room?.enable();
        this.form.controls.product?.enable();
        if (!this.isOnline) return;
        super.getUnitDetails(id);
      });
  }
}
