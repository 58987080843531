<div class="custom-dropdown-wrapper">
  <div class="d-inline-flex">
    <span *ngIf="inputTitle" class="fz-16 lh-24 dropdown-title"> {{ inputTitle }} </span>
    <span class="dropdown-subtitle" *ngIf="inputSubTitle">{{ inputSubTitle }}</span>
  </div>
  <div class="dropdown-block">
    <ng-select
      class="custom-select"
      [items]="listOfItems"
      [bindLabel]="bindLabel"
      [bindValue]="bindValue"
      [disabled]="disabled"
      dropdownPosition="bottom"
      [ngModel]="inputValue"
      [clearable]="clearable"
      [searchable]="searchable"
      (search)="onListenInput($event)"
      (change)="changeValue($event)"
      [placeholder]="placeholder || label"
    >
      <ng-container *ngIf="customItemView">
        <ng-template ng-label-tmp let-item="item">
          <ng-container>
            <ng-template [ngTemplateOutlet]="labelTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
          </ng-container>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          <ng-container>
            <ng-template [ngTemplateOutlet]="labelTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-select>
    <img *ngIf="removable" (click)="removeItem()" src="/assets/close-md.svg" alt="close" />
  </div>
</div>
