import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'timePassedFromMessage',
})
export class TimePassedFromMessagePipe implements PipeTransform {
  transform(value: string): string {
    const todaysDate = new Date().setHours(0, 0, 0, 0);
    const tempDate = new Date(value).setHours(0, 0, 0, 0);

    if (tempDate == todaysDate) {
      return `${dayjs(value).format('HH:mm A')}`;
    } else {
      return `${dayjs(value).format('D MMM')}`;
    }
  }
}
