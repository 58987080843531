import { ITablePagination } from '@atlas-workspace/shared/models';

export const defaultState = {
  unitsState: {
    units: null,
    pagination: {
      currentPage: 1,
      pageItems: 50,
      totalCount: 50,
      totalPages: 1,
    },
    firstLoad: true,
    totalCount: 0,
  },
  practicalsState: {
    practicals: null,
    pagination: {
      currentPage: 1,
      pageItems: 50,
      totalCount: 50,
      totalPages: 1,
    },
    firstLoad: true,
    totalCount: 0,
  },
  productState: {
    products: null,
    pagination: {
      currentPage: 1,
      pageItems: 50,
      totalCount: 50,
      totalPages: 1,
    },
    firstLoad: true,
    totalCount: 0,
  },
  projectOverviewState: {
    projectOverview: null,
  },
  projectsState: {
    projects: null,
    myProjects: null,
    myTasks: null,
    myMeetings: null
  },
  updatesState: {
    updates: null,
    pagination: {
      currentPage: 1,
      pageItems: 50,
      totalCount: 50,
      totalPages: 1,
    },
    firstLoad: true,
    totalCount: 0,
  },
  meetingsState: {
    meetings: null,
    pagination: {
      currentPage: 1,
      pageItems: 50,
      totalCount: 50,
      totalPages: 1,
    },
    firstLoad: true,
    totalCount: 0,
  },
  illustrationsState: {
    illustrations: null,
  },
  documentsState: {
    documents: null,
    pagination: {
      currentPage: 1,
      pageItems: 50,
      totalCount: 50,
      totalPages: 1,
    },
    firstLoad: true,
    totalCount: 0,
  },
  optionsState: {
    options: null,
  },
  offersState: {
    offers: null,
  },
  optionUnitsState: {
    optionUnits: null,
  },
  analyticsState: {
    totalCount: 0,
    openCount: 0,
    averagePerUnit: 0,
    averageResolution: 0,
    statusStats: [],
    typesStats: [],
    categoriesStats: [],
    responsibleCountStats: [],
    averageAdminResolution: [],
  },
  reclamationsProject: {
    reclamations: null,
    pagination: {
      currentPage: 1,
      pageItems: 50,
      totalCount: 50,
      totalPages: 1,
    },
    firstLoad: true,
    totalCount: 0,
  },
};

export class DefaultStateModel {
  pagination!: ITablePagination;
  firstLoad!: boolean;
  totalCount!: number;
}
