import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InlineDateTimePickerComponent, MessageModalComponent } from '@atlas-workspace/shared/form';
import { ModalFacadeService, ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  ChangeRequestOfferModel,
  ClientChangeRequestModel,
  EChangeRequestStatus,
  FloorModel,
  IFloorType,
  offerStatusList,
  UnitFloorModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import { ClientChangeRequestService, ProfileService, SharedUiStorageService, SignicatService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss', '../../../styles/status.component.scss'],
  providers: [SignicatService],
})
export class OfferDetailComponent implements OnInit {
  @Input() set editOffer(offer: ChangeRequestOfferModel) {
    this.offer = offer;
    if (!this.form) {
      this.initForm();
    }
    this.setForm();
  }

  @Input() public readonly changeRequest!: ClientChangeRequestModel;
  @Input() public readonly unitId!: number;
  @Input() isDualView!: boolean;
  @Input() isAlLowBankId!: boolean;
  @Input() bankIdToggle!: boolean;
  @Input() floorPlanData!: UnitFloorModel[] | FloorModel[] | any;
  @Input() unit!: UnitUserModel;

  @Output() private readonly back = new EventEmitter<ChangeRequestOfferModel>();
  @Output() private readonly updateOffer = new EventEmitter<ChangeRequestOfferModel>();

  @ViewChild('datePicker') datePicker!: InlineDateTimePickerComponent;

  public readonly floorType = IFloorType;
  public offer!: ChangeRequestOfferModel;
  public form!: FormGroup;
  public isLoading = false;
  public isShowMessageBanner = false;
  public loadingDocument = false;
  public readonly titleMaxLength = 50;
  public readonly descriptionMaxLength = 800;
  public readonly statusClasses = offerStatusList;
  public readonly messageBannerText = 'Change_request.Offers.Date_changed';
  public readonly statusKeys = EChangeRequestStatus;

  public isGuest!: boolean;

  private subscriptionSignIn?: Subscription;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modalHelpersService: ModalHelpersService,
    private modalFacadeService: ModalFacadeService,
    private sharedUiStorageService: SharedUiStorageService,
    private changeRequestService: ClientChangeRequestService,
    private signicatService: SignicatService,
    private readonly profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.getUserRole();
  }

  public backOffersList(): void {
    this.back.emit(this.offer);
  }

  private initForm(): void {
    this.form = this.fb.group({
      title: ['', [Validators.maxLength(this.titleMaxLength), Validators.required]],
      description: [{ value: '', disabled: true }, Validators.maxLength(this.descriptionMaxLength)],
      price: ['', Validators.required],
      expirationDate: [''],
      fileResources: [{value: [], disabled: true}],
    });
  }

  private getUserRole(): void {
    this.profileService.getIsGuest.pipe(untilDestroyed(this)).subscribe((isGuest) => {
      this.isGuest = isGuest;
    });
  }

  private setForm(): void {
    this.form.patchValue({
      title: this.offer.title,
      description: this.offer.description,
      price: this.offer.price,
      expirationDate: this.offer.expirationDate,
      fileResources: this.offer.fileResources,
    });

    if (this.offer.status !== EChangeRequestStatus.OfferExpired) {
      this.form.get('expirationDate')?.disable();
    } else {
      this.form.get('expirationDate')?.enable();
    }
    this.cdr.markForCheck();
  }

  public closeDocumentLoading(): void {
    this.loadingDocument = false;
    this.subscriptionSignIn?.unsubscribe();
  }

  public openPreview(index: number): void {
    const files = this.form.get('fileResources')?.value ?? [];
    this.modalHelpersService.previewImages(files, index);
  }

  showComment(): void {
    const event = this.changeRequest.eventLogs.filter(
      (e) => e.changeRequestOfferId === this.offer.id && e.status === this.offer.status
    );

    if (!event.length) return;

    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalClientCreateOffer
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Shared.Title.Offer_was_declined';
    modalRef.componentInstance.onlyView = true;
    modalRef.componentInstance.isClient = true;
    modalRef.componentInstance.comment = event[0].comment;
  }

  public declineOffer(): void {
    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalClientCreateOffer
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Entity.Decline_offer';
    modalRef.componentInstance.button = 'Shared.Yes_decline';
    modalRef.componentInstance.placeholder = 'Change_request.Decline.Modal.Placeholder';
    modalRef.componentInstance.isClient = true;

    modalRef.result.then((res) => {
      if (res !== undefined) {
        this.changeStatus(EChangeRequestStatus.OfferDeclined, res);
      }
    });
  }

  public get isFlowBankId(): boolean {
    return this.isAlLowBankId && !this.isDualView && this.bankIdToggle;
  }

  private signInDocument(): void {
    this.loadingDocument = true;
    this.subscriptionSignIn = this.signicatService
      .changeRequestOfferSignIn(this.unitId, this.changeRequest.id, this.offer.id)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((url) => {
        window.open(url, '_self');
      });
  }

  public approveOffer(): void {
    if (this.isFlowBankId) {
      this.signInDocument();
      return;
    }
    this.changeStatus(EChangeRequestStatus.OfferApproved);
  }

  private changeStatus(status: EChangeRequestStatus, comment = ''): void {
    this.changeRequestService
      .setOfferStatus(this.unitId, this.changeRequest.id, this.offer.id, status, comment)
      .pipe(take(1))
      .subscribe((offer) => {
        this.offer = offer;
        this.updateOffer.emit(offer);

        if (offer.status === EChangeRequestStatus.OfferDeclined) {
          const eventLogs = this.changeRequest.eventLogs[this.changeRequest.eventLogs.length - 1];
          eventLogs.comment = comment;
          eventLogs.status = EChangeRequestStatus.OfferDeclined;
        }

        this.setForm();
        this.datePicker.changeValue(this.offer.expirationDate);
      });
  }
}
