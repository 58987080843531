/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { EThreadFolders } from '@atlas-workspace/shared/models';

export const getEmptyFolderTranslateKey = (foldertype: EThreadFolders) => {
  switch (foldertype) {
    case EThreadFolders.Done:
      return 'Messages.Empty_done_messages';
    case EThreadFolders.Drafts:
      return 'Messages.Empty_drafts_messages';
    case EThreadFolders.Inbox:
      return 'Messages.Empty_inbox_messages';
    case EThreadFolders.Sent:
      return 'Messages.Empty_sent_messages';
    default:
      return 'Messages.Empty_all_messages';
  }
};

export const getFolderTitleTranslateKey = (foldertype: EThreadFolders) => {
  switch (foldertype) {
    case EThreadFolders.Done:
      return 'Messages.Done';
    case EThreadFolders.Drafts:
      return 'Messages.Drafts';
    case EThreadFolders.Inbox:
      return 'Messages.Inbox';
    case EThreadFolders.Sent:
      return 'Messages.Sent';
    default:
      return 'Messages.All';
  }
};

export const getThreadsDeleteTitleTranslateKey = (entity: 'thread' | 'message') => {
  return entity === 'thread' ? `Shared.Threads.Delete_thread` : 'Shared.Threads.Delete_message';
};

export const getThreadsDeleteDescriptionTranslateKey = (entity: 'thread' | 'message') => {
  return entity === 'thread' ? `Shared.Threads.Delete_thread_description` : 'Shared.Threads.Delete_message_description';
};
