import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'atl-statistic-card',
  templateUrl: './statistic-card.component.html',
  styleUrls: ['./statistic-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class StatisticCardComponent {
  @Input() statValue!: number | string;
  @Input() totalValue!: number;
  @Input() showPercents = false;
  @Input() isWithCheckbox = false;
  @Input() statName!: string;
}
