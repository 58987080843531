import {Injectable} from "@angular/core";
import {EChangeRequestType} from "@atlas-workspace/shared/models";
import {ClientChangeRequestService, ClientCounterService} from "@atlas-workspace/shared/service";
import {Observable, shareReplay} from "rxjs";
import {map, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class UnreadOffersCounterHelper {
  private isAdvancedChangeRequests$?: Observable<boolean>;
  private lastUnitId?: number;

  constructor(
    private readonly clientCounterService: ClientCounterService,
    private readonly clientChangeRequestService: ClientChangeRequestService,
  ) {}

  public getUnreadChangeRequestsCounter(projectId: number, unitId: number): Observable<number> {
    return this.setIsAdvancedChangeRequests(projectId, unitId).pipe(
      switchMap(isAdvanced => {
        if (isAdvanced) {
          return this.clientCounterService.unreadChangeRequestCombinedOffersCounter(unitId);
        } else {
          return this.clientCounterService.unviewedChangeRequestCounter(unitId);
        }
      })
    );
  }

  private setIsAdvancedChangeRequests(projectId: number, unitId: number): Observable<boolean> {
    if (this.lastUnitId !== unitId) {
      this.isAdvancedChangeRequests$ = undefined;
      this.lastUnitId = unitId;
    }

    if (!this.isAdvancedChangeRequests$) {
      this.isAdvancedChangeRequests$ = this.clientChangeRequestService
        .getUnitSettings(projectId, unitId)
        .pipe(
          map(settings => settings.requestType === EChangeRequestType.Advanced),
          shareReplay(1)
        );
    }
    return this.isAdvancedChangeRequests$;
  }
}
