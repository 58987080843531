<div class="reclamation-activities__vertical-line"></div>
<div class="reclamation-activities v-scrollbar">
  <ng-container *ngIf="activitiesData && activitiesData.length; else noActivities"> </ng-container>
  <ng-container *ngFor="let event of activitiesData; let i = index">
    <div class="reclamation-activities__event">
      <atl-firm-icon
        *ngIf="event?.updatedBy"
        class="reclamation-activities__event-user user"
        [isUser]="true"
        [model]="event?.updatedBy"
        nameKey="name"
        [isMonochrome]="true"
      ></atl-firm-icon>
      <div class="reclamation-activities__event-description">
        <div class="reclamation-activities__event-description-date">{{ event.updatedDate | date: 'd MMMM, YYYY · HH:mm' }}</div>
        <div class="reclamation-activities__event-description-text">
          <span [innerHTML]="event.text"></span>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noActivities>
  <div class="reclamation-activities__empty">
    <img class="reclamation-activities__empty-icon" src="/assets/analytics-icon.svg" alt="No activities" />
    <div class="reclamation-activities__empty-title">{{ 'Reclamation.No_activities_yet' | translate }}</div>
  </div>
</ng-template>
