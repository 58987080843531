<div class="toggle" atlClickOutside (clickOutside)="close()">
  <div class="toggle__view" (click)="openToggle()" [class.active]="isOpen">
    <ng-container
      *ngTemplateOutlet="toggleItem; context: { item: activeToggle, list: false, selected: false }"
    ></ng-container>
    <img class="arrow" [class.active]="isOpen" [src]="arrowIcon" alt="arrow" />
  </div>
  <div class="toggle__list" [class.show]="isOpen">
    <ng-container *ngFor="let item of toggleList">
      <div class="toggle__list-item" (click)="select(item)">
        <ng-container
          *ngTemplateOutlet="
            toggleItem;
            context: { item: item, list: true, selected: item.value === activeToggle.value }
          "
        ></ng-container>
        <img *ngIf="item.value === activeToggle.value" [src]="checkIcon" alt="icon" />
      </div>
    </ng-container>
  </div>
</div>

<ng-template #toggleItem let-item="item" let-list="list" let-selected="selected">
  <div class="toggle__item" [class.selected]="selected">
    <img *ngIf="showIcon" [src]="item.icon" alt="icon" />
    <span>{{ item.title | translate }}</span>
  </div>
</ng-template>
