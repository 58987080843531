import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedChangeRequestsAdminModule } from './components/change-request-admin/shared-change-requests-admin.module';
import { SharedChangeRequestsClientnModule } from './components/change-request-client/shared-change-request-client.module';

@NgModule({
  imports: [
    CommonModule,
    SharedChangeRequestsAdminModule,
    SharedChangeRequestsClientnModule
  ],
  declarations: [],
  providers: [],
  exports: [
    SharedChangeRequestsAdminModule,
    SharedChangeRequestsClientnModule
  ],
})
export class SharedChangeRequestsModule {}
