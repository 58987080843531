import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  EAttendeesRole, EOneYearFilterType, EProtocolTemplateKind,
  ESignerType,
  FileModel,
  GlobalHeaderModel, ITablePagination,
  MeetingProtocolModel,
  ProtocolTemplateList, ProtocolTemplateModel,
  TAttendeesPerson,
  TProxyUser,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {DataTableHelperService} from "../data-table-helper/data-table-helper.service";

@Injectable()
export class ProtocolsService {
  private readonly headers = new HttpHeaders().set(GlobalHeaderModel.SkipGlobalInterceptor, 'true');
  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private tableService: DataTableHelperService,
  ) {}

  /**
   * Get Meeting Protocol
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Meetings/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meetings~1%7Bid%7D~1protocol/get
   */
  public getProtocolData(projectId: number, meetingId: number): Observable<MeetingProtocolModel> {
    return this.http
      .get(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings/${meetingId}/protocol`)
      .pipe(
        map((res: any) => res.data),
        map((x) => plainToClass(MeetingProtocolModel, x)),
      );
  }

  /**
   * List Meetings Protocols
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Meetings-Protocols/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meetings_protocols/get
   */
  public getProjectProtocols(projectId: number, unitId: number): Observable<MeetingProtocolModel[]> {
    const params: HttpParams = new HttpParams().set('unit_id', String(unitId));
    return this.http
      .get(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings_protocols`, { params })
      .pipe(
        map((res: any) => res.data?.protocols),
        map((x) => plainToClass(MeetingProtocolModel, x) as unknown as MeetingProtocolModel[]),
      );
  }

  /**
   * Update Meetings Protocols
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Meetings-Protocols/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meetings_protocols~1%7Bid%7D/patch
   */
  public updateConnectedMeetingProtocol(
    projectId: number,
    connectedProtocols: number[],
    protocolId: number,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    if (!connectedProtocols.length) {
      fd.append('protocol[connected_to_protocol_ids][]', '');
    }
    connectedProtocols.forEach((el) => {
      fd.append('protocol[connected_to_protocol_ids][]', el.toString());
    });
    return this.http
      .patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings_protocols/${protocolId}`, fd, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => res.data),
        map((x) => plainToClass(MeetingProtocolModel, x)),
      );
  }

  /**
   * Update Meetings Protocols
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Meetings-Protocols/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meetings_protocols~1%7Bid%7D/patch
   */
  public updateOneYearReclamationsFilterProtocol(
    projectId: number,
    type: EOneYearFilterType | null,
    protocolId: number,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[reclamation_filter_type]', type || '');
    return this.http
      .patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings_protocols/${protocolId}`, fd, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => res.data),
        map((x) => plainToClass(MeetingProtocolModel, x)),
      );
  }

  public addingProtocolUser(
    projectId: number,
    protocolId: number,
    personId: number,
    personType: ESignerType,
    role: EAttendeesRole,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[attendees_attributes][][person_id]', String(personId));
    fd.append('protocol[attendees_attributes][][person_type]', personType);
    fd.append('protocol[attendees_attributes][][role]', role);
    return this.patchProtocol(projectId, protocolId, fd);
  }

  public editingOtherProtocolUser(
    projectId: number,
    meetingId: number,
    otherAttendees: string,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[other_attendees]', otherAttendees);
    return this.patchProtocol(projectId, meetingId, fd);
  }

  public editingOtherRecipients(
    projectId: number,
    meetingId: number,
    value: string[],
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    if (!value.length) {
      fd.append('protocol[other_recipients][]', '');
    }
    value.forEach((item) => {
      fd.append('protocol[other_recipients][]', item);
    });

    return this.patchProtocol(projectId, meetingId, fd);
  }

  public editingProtocolDeveloper(
    projectId: number,
    protocolId: number,
    devName: string,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[developer_name]', devName);
    return this.patchProtocol(projectId, protocolId, fd);
  }

  public removeProtocolUser(projectId: number, protocolId: number, personId: number): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fd.append('protocol[attendees_attributes][][id]', String(personId));
    fd.append('protocol[attendees_attributes][][_destroy]', 'true');
    return this.patchProtocol(projectId, protocolId, fd);
  }

  public addingProtocolProxyUser(
    projectId: number,
    protocolId: number,
    infos: TProxyUser,
    role: EAttendeesRole,
  ): Observable<MeetingProtocolModel> {
    const fd = this.generateProxyFormData(infos);
    fd.append('protocol[attendees_attributes][][role]', role);

    if (infos.files?.length) {
      infos.files.forEach((file) => {
        fd.append(
          'protocol[attendees_attributes][][proxy_person_attributes][file_resources_attributes][][filename]',
          file,
          file.name,
        );
      });
    }

    return this.patchProtocol(projectId, protocolId, fd);
  }

  public toggleSignerUser(
    projectId: number,
    protocolId: number,
    attendeesId: number,
    state: boolean,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[attendees_attributes][][id]', attendeesId.toString());
    fd.append('protocol[attendees_attributes][][signature_required]', `${state}`);
    return this.patchProtocol(projectId, protocolId, fd);
  }

  public deleteSigner(
    projectId: number,
    protocolId: number,
    attendeesId: number,
    imageId: string,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[attendees_attributes][][id]', attendeesId.toString());
    fd.append('protocol[attendees_attributes][][signature_attributes][id]', imageId.toString());
    fd.append('protocol[attendees_attributes][][signature_attributes][_destroy]', 'true');

    return this.patchProtocol(projectId, protocolId, fd);
  }

  public updatingProtocolProxyUser(
    projectId: number,
    protocolId: number,
    infos: TProxyUser,
    personId: number,
    proxyInfos: TAttendeesPerson,
  ): Observable<MeetingProtocolModel> {
    const fd = this.generateProxyFormData(infos);
    fd.append('protocol[attendees_attributes][][id]', personId.toString());

    if (infos.files?.length) {
      infos.files.forEach((file) => {
        if (file instanceof File) {
          fd.append(
            'protocol[attendees_attributes][][proxy_person_attributes][file_resources_attributes][][filename]',
            file,
            file.name,
          );
        }
      });
    }

    if (proxyInfos.fileResources.length) {
      const existingFiles: FileModel[] = (infos.files as any).filter((x: FileModel) => x.id);
      proxyInfos.fileResources.forEach((file: FileModel) => {
        if (existingFiles.some((x) => x.id === file.id)) {
          return;
        }
        fd.append(
          'protocol[attendees_attributes][][proxy_person_attributes][file_resources_attributes][][id]',
          file.id,
        );
        fd.append(
          'protocol[attendees_attributes][][proxy_person_attributes][file_resources_attributes][][_destroy]',
          'true',
        );
      });
    }

    return this.patchProtocol(projectId, protocolId, fd);
  }

  public createProtocolField(
    projectId: number,
    protocolId: number,
    fieldId: number,
    newValue: any,
    isFile = false,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append(`protocol[answers_attributes][][field_id]`, String(fieldId));
    if (!isFile) {
      fd.append('protocol[answers_attributes][][value]', newValue);
    } else {
      fd.append(
        'protocol[answers_attributes][][uploaded_images_attributes][][filename]',
        newValue[0],
        newValue[0].name,
      );
    }

    return this.patchProtocol(projectId, protocolId, fd);
  }

  public saveSignature(
    projectId: number,
    protocolId: number,
    signerId: number,
    file: File,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[attendees_attributes][][id]', String(signerId));
    fd.append('protocol[attendees_attributes][][signature_attributes][filename]', file, file.name);
    return this.patchProtocol(projectId, protocolId, fd);
  }

  public updateProtocolField(
    projectId: number,
    protocolId: number,
    id: number,
    value: any,
    isFile = false,
  ): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[answers_attributes][][id]', String(id));
    if (!isFile) {
      fd.append('protocol[answers_attributes][][value]', value);
    } else {
      if (value.length) {
        fd.append('protocol[answers_attributes][][uploaded_images_attributes][][filename]', value[0], value[0].name);
      } else {
        fd.append('protocol[answers_attributes][][_destroy]', String(true));
      }
    }
    return this.patchProtocol(projectId, protocolId, fd);
  }

  public clearProtocolField(projectId: number, protocolId: number, id: number): Observable<MeetingProtocolModel> {
    const fd = new FormData();
    fd.append('protocol[answers_attributes][][id]', String(id));
    fd.append('protocol[answers_attributes][][_destroy]', 'true');
    return this.patchProtocol(projectId, protocolId, fd);
  }

  /**
   * Complete Meetings Protocols
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Meetings-Protocols/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meetings_protocols~1%7Bid%7D~1complete/patch
   */
  public completeProtocol(projectId: number, protocolId: number): Observable<MeetingProtocolModel> {
    return this.http
      .patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings_protocols/${protocolId}/complete`, {})
      .pipe(
        map((res: any) => res.data),
        map((x) => plainToClass(MeetingProtocolModel, x)),
      );
  }

  /**
   * Update Meetings Protocols
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Meetings-Protocols/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1meetings_protocols~1%7Bid%7D/patch
   */
  private patchProtocol(projectId: number, protocolId: number, fd: FormData): Observable<MeetingProtocolModel> {
    return this.http
      .patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/meetings_protocols/${protocolId}`, fd, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => res.data),
        map((x) => plainToClass(MeetingProtocolModel, x)),
      );
  }

  private generateProxyFormData(infos: TProxyUser): FormData {
    const formData = new FormData();
    formData.append('protocol[attendees_attributes][][proxy_person_attributes][name]', infos.name);
    formData.append('protocol[attendees_attributes][][proxy_person_attributes][email]', infos.email);
    formData.append('protocol[attendees_attributes][][proxy_person_attributes][note]', infos.note);
    return formData;
  }

  /**
   * List Protocols Templates
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Protocols-Templates/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1protocols~1templates/get
   */
  getProtocolsTemplates(
    firmId: number,
    search = '',
    sort = '',
    paginationData?: ITablePagination,
    defaultFilter?: EProtocolTemplateKind,
    withInactive = false
  ): Observable<{value: ProtocolTemplateList[], pagination: ITablePagination}> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginationData);
    if (defaultFilter) {
      params = params.append('kind', defaultFilter);
    }
    if (withInactive) {
      params = params.append('with_inactive', String(withInactive));
    }
    return this.http
      .get<{
        data: { templates: ProtocolTemplateList[] };
      }>(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/protocols/templates`, {params, observe: 'response'})
      .pipe(
        map((res) => this.tableService.transformResponse<ProtocolTemplateList>(res, ProtocolTemplateList, 'templates'))
      );
  }

  /**
   * Show Protocols Template
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Protocols-Templates/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1protocols~1templates~1%7Bid%7D/get
   */
  showProtocolTemplate(
    firmId: number,
    id: number
  ): Observable<ProtocolTemplateModel> {
    return this.http
      .get<{data: ProtocolTemplateModel}>(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/protocols/templates/${id}`)
      .pipe(
        map((res: any) => plainToClass(ProtocolTemplateModel, res.data)),
      );
  }

  /**
   * Copy Protocols Template
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Protocols-Templates/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1protocols~1templates~1%7Bid%7D~1copy/post
   */
  copyProtocolTemplate(
    firmId: number,
    id: number,
    data: any
  ): Observable<ProtocolTemplateModel> {
    const body = {template: data};
    return this.http
      .post<{data: ProtocolTemplateModel}>(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/protocols/templates/${id}/copy`, body)
      .pipe(
        map((res: any) => plainToClass(ProtocolTemplateModel, res.data)),
      );
  }

  /**
   * Update Protocols Template
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Protocols-Templates/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1protocols~1templates~1%7Bid%7D~1copy/post
   */
  updateProtocolTemplate(
    firmId: number,
    id: number,
    data: any
  ): Observable<ProtocolTemplateModel> {
    const body = {template: data};
    return this.http
      .patch<{data: ProtocolTemplateModel}>(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/protocols/templates/${id}`, body)
      .pipe(
        map((res: any) => plainToClass(ProtocolTemplateModel, res.data)),
      );
  }
}
