import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TimePassedFromMessagePipe } from './time-passed-from-message.pipe';

@NgModule({
  declarations: [TimePassedFromMessagePipe],
  exports: [TimePassedFromMessagePipe],
  providers: [TimePassedFromMessagePipe],
  imports: [CommonModule],
})
export class TimePassedFromMessageModule {}
