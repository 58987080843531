import {IStoreModalGeneral} from "../interface/store-modal.interface";
import {IConfigureOptionForm} from "../models/layout-option.model";

export const menuComponents = {
  rules: 'Entity.Rules',
  general: 'Shared.Button.Details',
  addRooms: 'Entity.Add_rooms',
  connectUnits: 'Title.Connect_units',
  adjustSettings: 'Entity.Adjust_settings',
  packages: 'Shared.Entity.Packages'
};

export const navigationMenu = [
  { name: menuComponents.general, disabled: false, route: '', id: 'cy-store-modal-menu-item-general' },
  { name: menuComponents.connectUnits, disabled: false, route: '', tooltip: '', isShowTooltip: false, id: 'cy-store-modal-menu-item-connect-units' },
  { name: menuComponents.rules, disabled: false, route: '', id: 'cy-store-modal-menu-item-rules' },
  { name: menuComponents.packages, disabled: false, route: '', id: 'cy-store-modal-menu-item-packages' },
];

export const tabList = ['Title.Connect_units', 'Entity.Settings'];

export interface ICreationEventHandler {
  status: ECreationEventHandler;
  value: INewestRoom[] | IStoreModalGeneral | IConfigureOptionForm;
  numberOfFloors?: number;
}

export enum ECreationEventHandler {
  General = 'changing_general',
  Room = 'changing_room',
  AdjustSettings = 'changing_adjust_settings',
}

export function generationRoomId(): number {
  return Math.floor(Math.random() * 10000);
}

export interface INewestRoom {
  [key: string]: string | number | null | undefined | boolean;

  categoryId: number;
  id: number;
  type: number | null;
  typeTitle?: string;
  name: string;
  size: number | null;
  newestRoom: boolean;
  isPublished: boolean;
  includeInOption?: boolean;
  floorNumber?: number | null;
  position: number;
  isRoom: boolean;
}

export enum EOptionStoreHeader {
  OptionStoreDetail = 'Title.Option_store_details',
  NewOptionStore = 'Title.New_option_store',
}

export const unitRoomTypeTranslate: Map<string, string> = new Map([
  ['Bathroom', 'Entity.Bathroom'],
  ['Bedroom', 'Entity.Bedroom'],
  ['Cold room', 'Entity.Cold_room'],
  ['Entrance', 'Entity.Entrance'],
  ['Garage', 'Entity.Garage'],
  ['Hallway', 'Entity.Hallway'],
  ['Kitchen', 'Entity.Kitchen'],
  ['Laundry', 'Entity.Laundry'],
  ['Living', 'Entity.Living'],
  ['Storage', 'Entity.Storage'],
  ['Technical', 'Entity.Technical'],
  ['WC', 'Entity.Wc'],
]);
