import { Component, EventEmitter, Injector, Input, OnInit, Output, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileValidators } from '@atlas-workspace/shared/form';
import { ModalFacadeService, ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  clientReclamationStatusList,
  EFileType,
  EPositionControl,
  EReclamationStatusKey,
  EReclamationTab,
  FloorModel,
  ImageModel,
  IMark,
  ISettingsMenu,
  IThreadState,
  ReclamationsModel,
  ThreadModel,
  UnitFloorModel,
} from '@atlas-workspace/shared/models';
import {
  CableService,
  PageHelperService,
  ProfileService,
  ReclamationClientService,
  SharedUiStorageService,
  ThreadsHelperService,
} from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs/operators';

import messagesIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/admin-header/messages.svg';
import arrowRightIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow_right.svg';

import { inputHelper, navigationClientMenu } from '../../../helpers/reclamations.helper';
import { ReclamationClientCommentModalComponent } from '../reclamation-client-comment-modal/reclamation-client-comment-modal.component';

@UntilDestroy()
@Component({
  selector: 'atl-reclamation-client-detail',
  templateUrl: './reclamation-client-detail.component.html',
  styleUrls: ['./reclamation-client-detail.component.scss'],
  providers: [ReclamationClientService],
})
export class ReclamationClientDetailComponent implements OnInit {
  @Input() public modalRef!: NgbModalRef;

  @Input()
  public set reclamationId(value: number) {
    if (value) {
      this.reclamationLoading = true;
      this._reclamationId = +value;
      this.getReclamationDetails();
    }
  }

  @Input() private openTab: EReclamationTab | null = null;
  @Input() public disableSwitchingReclamation = false;
  @Input() public disableOpenReclamation = false;
  @Input() public disabledForGuest = false;
  @Input() public threadViewComponent!: Type<any>;
  @Output() private readonly openMessagesTabEmit = new EventEmitter<number | null>();
  @Output() private readonly changeThreadStateEvent = new EventEmitter<IThreadState>();

  public readonly tooltipDelay = 500;
  public messagesIcon = messagesIcon;

  private _reclamationId!: number;
  public isLoading!: boolean;
  public reclamationLoading!: boolean;

  public readonly routeNavigation = false;
  public activeTab = EReclamationTab.General;
  public navigationMenu: ISettingsMenu[] = navigationClientMenu;
  public reclamationTabs = EReclamationTab;

  public floorPlanData!: (UnitFloorModel | FloorModel)[] | undefined;
  public mark?: IMark[];

  public reclamation!: ReclamationsModel | null;

  public unitId!: number;
  public projectId!: number;

  public expanded = false;

  @Output() private readonly changeReclamationHandler = new EventEmitter<EPositionControl>();
  public readonly positionControl = EPositionControl;
  public readonly showSwitchArrows = true;

  public statusKeys = EReclamationStatusKey;
  public fieldsHelper = inputHelper;
  public statusList = clientReclamationStatusList;
  public status!: EReclamationStatusKey;
  public arrowRight = arrowRightIcon;
  public isDualView = false;

  constructor(
    private modalHelpersService: ModalHelpersService,
    private reclamationService: ReclamationClientService,
    private route: ActivatedRoute,
    private modalFacadeService: ModalFacadeService,
    private sharedUiStorageService: SharedUiStorageService,
    private injector: Injector,
    private pageHelperService: PageHelperService,
    private profileService: ProfileService,
    private cableService: CableService,
    private threadsHelperService: ThreadsHelperService
  ) {
    let parentRoute = this.route.parent;
    while (parentRoute) {
      const params = parentRoute.snapshot.params;
      if (params.projectId && params.unitId) {
        this.projectId = params.projectId;
        this.unitId = params.unitId;
        break;
      }
      parentRoute = parentRoute.parent;
    }
  }

  ngOnInit(): void {
    this.profileService.isDualView.pipe(untilDestroyed(this)).subscribe((dualVies) => {
      this.isDualView = dualVies;
      if (this.isDualView || this.disableOpenReclamation) {
        this.navigationMenu = this.navigationMenu.filter((x) => x.id !== 'rec-tab-customer-dialogue');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === 'rec-tab-customer-dialogue')!.counter = 0;
        this.subscribeToCableThreadsSnapshots();
        this.subscribeToThreadReadStateChange();
      }
    });
  }

  private cloneNavMenu(): void {
    this.navigationMenu = cloneDeep(this.navigationMenu);
  }

  private subscribeToCableThreadsSnapshots(): void {
    this.cableService.threads$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (Number(this.projectId) !== thread.project?.id) {
        return;
      }
      if (thread.id === this.reclamation?.messageThreadId && this.activeTab !== EReclamationTab.Messages) {
        if (this.reclamation) {
          this.reclamation.messageThreadHasMessages = true;
          this.reclamation.messageThreadUnreadCount = Number(thread.unreadCount);
          this.reclamation.messageThreadState.answered = false;
          this.reclamation.messageThreadState.read = false;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.id === 'rec-tab-customer-dialogue')!.counter = thread.unreadCount;
          this.cloneNavMenu();
        }
      }
      if (thread.id && this.activeTab === EReclamationTab.Messages) {
        if (this.reclamation && thread.id === this.reclamation?.messageThreadId) {
          if (thread instanceof ThreadModel) {
            this.reclamation.messageThreadHasMessages = true;
            this.reclamation.messageThreadState.read = true;
            this.reclamation.messageThreadState.answered = false;
          } else {
            this.reclamation.messageThreadState = thread.state as IThreadState;
            this.reclamation.messageThreadHasMessages = true;
          }
          this.changeThreadStateEvent.emit(this.reclamation.messageThreadState);
        }
      }
    });
  }

  private subscribeToThreadReadStateChange(): void {
    this.threadsHelperService
      .getThreadStateReadId()
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        if (id === this.reclamation?.messageThreadId) {
          this.reclamation.messageThreadUnreadCount = 0;
          this.reclamation.messageThreadState.read = true;
          const state = this.reclamation.messageThreadState;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.id === 'rec-tab-customer-dialogue')!.counter = 0;
          this.cloneNavMenu();
          this.changeThreadStateEvent.emit(state);
        }
      });
  }

  getReclamationDetails(): void {
    this.reclamationService
      .getReclamationDetails(this.unitId, this._reclamationId)
      .pipe(
        untilDestroyed(this),
        tap((rec) => {
          rec.fileResources = rec.fileResources?.map((file) => {
            if (FileValidators.isImageExtension(file)) file.type = EFileType.Image;
            file = plainToClass(ImageModel, file);
            return file;
          });
        })
      )
      .subscribe((reclamation) => {
        this.reclamation = reclamation;
        this.status = this.reclamation.status;
        this.getFloorPlan();
        this.reclamationLoading = false;
        if (!this.disableOpenReclamation) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.id === 'rec-tab-customer-dialogue')!.counter =
            this.reclamation.messageThreadUnreadCount;
          this.cloneNavMenu();
        }
        if (this.openTab) {
          this.selectAnotherTab({ tabName: this.openTab });
          this.openTab = null;
        }
      });
  }

  private getFloorPlan(): void {
    this.floorPlanData = [];
    if (this.reclamation?.floor) {
      this.floorPlanData.push(this.reclamation?.floor);

      if (this.reclamation.floor.pointX === null || this.reclamation.floor.pointY === null) {
        this.mark = [];
        return;
      }
      const markObj = {
        floorId: this.reclamation.floor?.id,
        floorNumber: this.reclamation.floor.floorNumber,
        floorType: this.reclamation.floor.type,
        mark: [
          {
            x: this.reclamation.floor?.pointX,
            y: this.reclamation.floor?.pointY,
          },
        ],
      };

      this.mark = [markObj as unknown as IMark];
    }
  }

  public selectAnotherTab(e: { tabName: EReclamationTab }): void {
    if (e.tabName === this.activeTab || !this.reclamation) {
      return;
    }
    this.activeTab = e.tabName;
    if (this.activeTab === EReclamationTab.Messages) {
      this.openMessagesTabEmit.emit(this._reclamationId);
    } else {
      this.openMessagesTabEmit.emit(null);
    }
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  public onChangeReclamation(state: EPositionControl): void {
    if (this.disableSwitchingReclamation) {
      return;
    }
    if (this.activeTab === EReclamationTab.Messages) {
      this.selectAnotherTab({ tabName: this.reclamationTabs.General });
    }
    this.reclamation = null;
    this.changeReclamationHandler.next(state);
  }

  public openPreview(index: number): void {
    if (this.reclamation?.fileResources) {
      this.modalHelpersService.previewImages(this.reclamation.fileResources, index);
    }
  }

  changeReclamationStatusRequest(status: EReclamationStatusKey): void {
    this.isLoading = true;
    this.reclamationService
      .changeReclamationStatus(this.unitId, this._reclamationId, status)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          if (this.reclamation) {
            this.reclamation.status = response.status;
            this.reclamation.localizedStatus = response.localizedStatus;
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  openCommentModal(onlyView = false, status: EReclamationStatusKey): void {
    const modalRef = this.modalFacadeService.openModal(ReclamationClientCommentModalComponent, {
      ...this.sharedUiStorageService.modalDeclineReclamation,
      injector: this.injector,
    });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.reclamationId = this._reclamationId;
    modalRef.componentInstance.onlyView = onlyView;
    modalRef.componentInstance.status = status;
    if (onlyView) modalRef.componentInstance.reclamationEventLog = this.reclamation?.lastEventLog;
    modalRef.componentInstance.declineEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.getReclamationDetails();
    });
  }

  public copyUrl(): void {
    const params = '?reclamationId=' + this._reclamationId;
    this.pageHelperService.copyToClipboard(params);
    this.close();
  }

  public close(): void {
    this.expanded = false;
  }

  public toggle(): void {
    this.expanded = !this.expanded;
  }
}
