import { ImageModel } from '@atlas-workspace/shared/models';

export class SetIllustrations {
  static readonly type = '[Illustrations] save change illustrations local';
  constructor(public illustrations: ImageModel[]) {}
}

export class AddIllustration {
  static readonly type = '[Illustrations] save change illustration local';
  constructor(public illustrations: ImageModel[]) {}
}

export class RemoveIllustration {
  static readonly type = '[Illustrations] remove illustration local';
  constructor(public id: number) {}
}
