import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  ChangeRequestGroupListModel,
  ChangeRequestGroupModel,
  ChangeRequestModel,
  ChangeRequestOfferModel,
  ChangeRequestResponseModel,
  ChangeRequestSettingModel,
  ChangeRequestsUnitModel,
  ChangeRequestsUnitResponseModel,
  CombinedOffersListModel,
  CreateCombinedOffer,
  CreatedCombinedOfferModel,
  DocumentPdfModel,
  EChangeRequestStatus,
  FileModel,
  GlobalChangeRequestResponseModel,
  GroupChangeRequestData,
  IChangeRequestFilter,
  IChangeRequestUnitsViewFilter,
  IFloorDrawToSave,
  IGroupCreationChangeRequestsList,
  ImageModel, IPrefillOfferForm,
  IReqChangeRequestSetting,
  ITablePagination, PrefillOfferInfo,
  RemoveOfferModel,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthAdminService } from '../auth/auth-admin.service';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';

@Injectable()
export class ChangeRequestService {
  public changeRequestPagination$ = new Subject<ITablePagination | null>();

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private tableService: DataTableHelperService,
    private readonly authAdminService: AuthAdminService,
  ) {}

  /**
   * @Cypress
   */
  public getChangeRequests(
    projectId: number,
    search = '',
    sort = '',
    hasMessages: boolean,
    filter: Partial<IChangeRequestFilter>,
    paginate?: ITablePagination,
  ): Observable<ChangeRequestResponseModel> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);

    if (hasMessages) {
      params = params.append('message_thread_has_messages', String(hasMessages));
    }

    if (filter?.status?.length) {
      filter.status.forEach((status) => {
        params = params.append('statuses[]', status);
      });
    }

    if (filter?.units?.length) {
      filter.units.forEach((unit) => {
        params = params.append('unit_ids[]', unit.toString());
      });
    }

    if (filter?.responsible?.length) {
      filter.responsible.forEach((user) => {
        params = params.append('responsible_ids[]', user.toString());
      });
    }

    if (filter.date) {
      params = params.append('creation_start_date', filter.date.from);

      if (filter.date.to) {
        params = params.append('creation_end_date', filter.date.to);
      }
    }

    return this.http
      .get<{ data: ChangeRequestResponseModel }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests`,
        {
          params,
          observe: 'response',
        },
      )
      .pipe(
        tap((result) => {
          if (result.headers) {
            const pagination = this.tableService.getPagination(result);
            this.changeRequestPagination$.next(pagination);
          }
        }),
        map((res: any) => res.body?.data),
        map((data) => plainToClass(ChangeRequestResponseModel, data)),
      );
  }

  /**
   * Get Units Change Requests Stats
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units-Stats/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1units~1stats~1change_requests/get
   */
  public getChangeRequestUnits(
    projectId: number,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    filter?: IChangeRequestUnitsViewFilter,
  ): Observable<ChangeRequestsUnitResponseModel> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    params = params.append('only', String(filter?.only));

    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/stats/change_requests`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          if (result.headers && result.body) {
            const pagination = this.tableService.getPagination(result);
            this.changeRequestPagination$.next(pagination);
          }
        }),
        map((res) => res.body.data),
        map((data) => plainToClass(ChangeRequestsUnitResponseModel, data)),
      );
  }

  /**
   * Get Units Change Requests Stats Details
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units-Stats/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1units~1%7Bid%7D~1stats~1change_requests_details/get
   */
  public getChangeRequestUnitById(projectId: number, id: number): Observable<ChangeRequestsUnitModel> {
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${id}/stats/change_requests_details`)
      .pipe(
        map((res) => res.data),
        map((data) => plainToClass(ChangeRequestsUnitModel, data)),
      );
  }

  /**
   * Get pdf document for change requests from unit
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/ChangeRequests/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1change_requests~1unit_pdf_document/get
   */
  public getDownloadUrlForChangeRequestUnit(projectId: number, unitId: number): Observable<string> {
    const params = new HttpParams({
      fromObject: { unit_id: unitId },
    });
    return this.http
      .get<DocumentPdfModel>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/unit_pdf_document`,
        { params },
      )
      .pipe(
        map((data) => plainToClass(DocumentPdfModel, data)),
        map(({ document }) => document.downloadUrl),
      );
  }

  public getGlobalChangeRequests(
    search = '',
    sort = '',
    hasMessages: boolean,
    filter: Partial<IChangeRequestFilter>,
    paginate?: ITablePagination,
  ): Observable<GlobalChangeRequestResponseModel> {
    const firmId = this.authAdminService.firm?.firmId;
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);

    if (hasMessages) {
      params = params.append('message_thread_has_messages', String(hasMessages));
    }

    if (filter?.projects?.length) {
      filter.projects.forEach((project) => {
        params = params.append('project_ids[]', project);
      });
    }

    if (filter?.status?.length) {
      filter.status.forEach((status) => {
        params = params.append('statuses[]', status);
      });
    }

    if (filter?.units?.length) {
      filter.units.forEach((unit) => {
        params = params.append('unit_ids[]', unit.toString());
      });
    }

    if (filter?.responsible?.length) {
      filter.responsible.forEach((user) => {
        params = params.append('responsible_ids[]', user.toString());
      });
    }

    if (filter.date) {
      params = params.append('creation_start_date', filter.date.from);

      if (filter.date.to) {
        params = params.append('creation_end_date', filter.date.to);
      }
    }

    return this.http
      .get<{ data: GlobalChangeRequestResponseModel }>(
        `${this.environment.apiBaseUrl}api/v1/firms/${firmId}/change_requests/`,
        {
          params,
          observe: 'response',
        },
      )
      .pipe(
        tap((result) => {
          if (result.headers) {
            const pagination = this.tableService.getPagination(result);
            this.changeRequestPagination$.next(pagination);
          }
        }),
        map((res: any) => res.body?.data),
        map((data) => plainToClass(GlobalChangeRequestResponseModel, data)),
      );
  }

  /**
   * @Cypress
   */
  public createChangeRequest(
    projectId: string,
    value: any,
    drawingData: IFloorDrawToSave[],
  ): Observable<ChangeRequestModel> {
    const body = this.createBodyChangeRequest(value);
    if (drawingData) {
      body.append('change_request[floor_draw_versions_attributes]', JSON.stringify(drawingData));
    }

    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests`, body)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ChangeRequestModel, data)),
      );
  }

  public getChangeRequestDetails(projectId: string, id: string): Observable<ChangeRequestModel> {
    return this.http
      .get<{ body: { data: ChangeRequestModel } }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${id}`,
        {
          observe: 'response',
        },
      )
      .pipe(
        map((res: any) => res.body?.data),
        map((data) => plainToClass(ChangeRequestModel, data)),
      );
  }

  public setStatusChangeRequest(
    projectId: string,
    reqId: number,
    status: string,
    comment: string,
  ): Observable<ChangeRequestModel> {
    const body = new FormData();
    body.append('change_request[status]', status);
    body.append('change_request[comment]', comment);

    return this.http
      .patch(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}/change_status`,
        body,
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ChangeRequestModel, data)),
      );
  }

  public updateChangeRequest(
    projectId: string,
    reqId: number,
    description: string,
    responsible: string,
    files: File[] | (FileModel | ImageModel)[],
    destroy = false,
    drawingData: IFloorDrawToSave[],
  ): Observable<ChangeRequestModel> {
    const body = new FormData();
    body.append('change_request[description]', description);
    body.append('change_request[responsible_id]', responsible);
    if (drawingData) {
      body.append('change_request[floor_draw_versions_attributes]', JSON.stringify(drawingData));
    }

    if (files.length) {
      if (destroy) {
        (files as (FileModel | ImageModel)[]).forEach((file: FileModel | ImageModel) => {
          body.append('change_request[file_resources_attributes][][id]', file.id.toString());
        });
        body.append('change_request[file_resources_attributes][][_destroy]', String(true));
      } else {
        (files as File[]).forEach((file: File) => {
          body.append('change_request[file_resources_attributes][][filename]', file);
        });
      }
    }

    return this.http
      .patch(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}`,
        body,
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ChangeRequestModel, data)),
      );
  }

  public updatePrefillChangeRequest(
    projectId: string,
    reqId: number,
    value: IPrefillOfferForm,
    files: File[] | (FileModel | ImageModel)[],
    destroy = false,
  ): Observable<ChangeRequestModel> {
    const body = new FormData();
    if (value.id) {
      body.append('change_request[prefill_offer_info_attributes][id]', String(value.id));
    }

    const price = typeof value.price === 'string' ? +value.price.replace(/\s/g, '') : value.price;
    body.append('change_request[prefill_offer_info_attributes][price]', String(price || ''));
    body.append('change_request[prefill_offer_info_attributes][description]', value.description || '');
    body.append('change_request[prefill_offer_info_attributes][version_number]', String(value.versionNumber?.[0]?.version || ''));

    if (files.length) {
      if (destroy) {
        (files as (FileModel | ImageModel)[]).forEach((file: FileModel | ImageModel) => {
          body.append('change_request[prefill_offer_info_attributes][file_resources_attributes][][id]', file.id.toString());
        });
        body.append('change_request[prefill_offer_info_attributes][file_resources_attributes][][_destroy]', String(true));
      } else {
        (files as File[]).forEach((file: File) => {
          body.append('change_request[prefill_offer_info_attributes][file_resources_attributes][][filename]', file);
        });
      }
    }

    return this.http
      .patch(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}`,
        body,
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ChangeRequestModel, data)),
      );
  }

  public setNotesChangeRequest(projectId: string, reqId: number, value: string): Observable<ChangeRequestModel> {
    const body = { change_request: { note: value } };

    return this.http
      .patch(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}/update_note`,
        body,
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ChangeRequestModel, data)),
      );
  }

  public createChangeRequestOffer(
    projectId: string,
    reqId: number,
    body: FormData,
  ): Observable<ChangeRequestOfferModel> {
    return this.http
      .post(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}/offers`,
        body,
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ChangeRequestOfferModel, data)),
      );
  }

  getChangeRequestOffers(
    projectId: string,
    reqId: number,
    sort: string,
    page: number,
    perPage: number,
  ): Observable<ChangeRequestOfferModel[]> {
    const params = new HttpParams({
      fromObject: {
        sort_by: sort,
        page: page.toString(),
        per_page: perPage.toString(),
      },
    });

    return this.http
      .get<{ data: { offers: ChangeRequestOfferModel[] } }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}/offers`,
        {
          params,
        },
      )
      .pipe(
        map((res) => res.data.offers),
        map((data: ChangeRequestOfferModel[]) => plainToClass(ChangeRequestOfferModel, data)),
      );
  }

  batchDestroyOffers(projectId: string, reqId: number, ids: number[]): Observable<RemoveOfferModel> {
    let params = new HttpParams();
    if (ids.length) {
      ids.forEach((id) => {
        params = params.append('ids[]', id.toString());
      });
    }

    return this.http
      .delete<RemoveOfferModel>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}/offers/batch_destroy`,
        {
          params,
        },
      )
      .pipe(map((data: RemoveOfferModel) => plainToClass(RemoveOfferModel, data)));
  }

  updateOffer(projectId: string, reqId: number, id: number, date: string): Observable<ChangeRequestOfferModel> {
    const body = new FormData();
    body.append('change_request_offer[expiration_date]', date);

    return this.http
      .patch<{
        data: ChangeRequestOfferModel;
      }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}/offers/${id}`,
        body,
      )
      .pipe(
        map((res: any) => res.data),
        map((data: ChangeRequestOfferModel) => plainToClass(ChangeRequestOfferModel, data)),
      );
  }

  setStatusOffer(
    projectId: string,
    reqId: number,
    id: number,
    status: EChangeRequestStatus,
  ): Observable<ChangeRequestOfferModel> {
    const body = new FormData();
    body.append('change_request_offer[status]', status);

    return this.http
      .patch<{
        data: ChangeRequestOfferModel;
      }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${reqId}/offers/${id}/change_status`,
        body,
      )
      .pipe(
        map((res: any) => res.data),
        map((data: ChangeRequestOfferModel) => plainToClass(ChangeRequestOfferModel, data)),
      );
  }

  downloadPdf(projectId: string, id: number): Observable<DocumentPdfModel> {
    return this.http
      .get<DocumentPdfModel>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/change_requests/${id}/pdf_document`,
      )
      .pipe(map((data: DocumentPdfModel) => plainToClass(DocumentPdfModel, data)));
  }

  private createBodyChangeRequest(value: any): FormData {
    const body = new FormData();
    body.append('change_request[unit_id]', value.unit[0].id);
    body.append('change_request[status]', EChangeRequestStatus.New);
    body.append('change_request[description]', value.description);
    body.append('change_request[responsible_id]', value.responsible?.[0]?.id || '');
    body.append('change_request[floor_id]', value.floorId);
    body.append('change_request[floor_type]', value.floorType);
    body.append('change_request[point_x]', value.pointX);
    body.append('change_request[point_y]', value.pointY);

    if (value.attachments.length) {
      value.attachments.map((file: File) => {
        body.append('change_request[file_resources_attributes][][filename]', file);
      });
    }

    return body;
  }

  /**
   * Get Project Change Requests settings
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Project-Change-Requests-Settings/paths/~1api~1v1~1projects~1{project_id}~1project_change_requests_settings/get
   */
  getProjectSettings(projectId: number): Observable<ChangeRequestSettingModel> {
    return this.http
      .get<{
        data: ChangeRequestSettingModel;
      }>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/project_change_requests_settings`)
      .pipe(
        map((res: any) => res.data),
        map((data: ChangeRequestSettingModel) => plainToClass(ChangeRequestSettingModel, data)),
      );
  }

  /**
   * Update Project Change Requests settings
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Project-Change-Requests-Settings/paths/~1api~1v1~1projects~1{project_id}~1project_change_requests_settings/patch
   */
  setProjectSettings(
    projectId: number,
    data: Partial<IReqChangeRequestSetting>,
  ): Observable<ChangeRequestSettingModel> {
    const body = { project_change_requests_settings: data };
    return this.http
      .patch<{
        data: ChangeRequestSettingModel;
      }>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/project_change_requests_settings`, body)
      .pipe(
        map((res: any) => res.data),
        map((v: ChangeRequestSettingModel) => plainToClass(ChangeRequestSettingModel, v)),
      );
  }

  /**
   * Create Requests Group
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Groups/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1requests_groups/post
   */
  groupCreation(projectId: string, body: FormData, unitId: number): Observable<ChangeRequestGroupModel> {
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/requests_groups`, body, {
        params: {
          unit_id: unitId,
        },
      })
      .pipe(
        map((res: any) => res.data),
        map((v: ChangeRequestGroupModel) => plainToClass(ChangeRequestGroupModel, v)),
      );
  }

  /**
   * Get Requests Group
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Groups/paths/~1api~1v1~1projects~1{project_id}~1change_requests~1requests_groups/get
   */
  getGroupChangeRequests(projectId: string, unitId: number): Observable<ChangeRequestGroupModel[]> {
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/requests_groups`, {
        params: {
          unit_id: unitId,
        },
      })
      .pipe(
        map((res: any) => res.data.requests_groups),
        map((v: ChangeRequestGroupModel[]) => plainToClass(ChangeRequestGroupModel, v)),
      );
  }

  /**
   * Get Group Change Request
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Groups/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1requests_groups/get
   */
  getGroupChangeRequestsData(projectId: string, unitId: number, id: number): Observable<GroupChangeRequestData[]> {
    return this.http
      .get(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/requests_groups/${id}/requests_for_offer`,
        {
          params: {
            unit_id: unitId,
          },
        },
      )
      .pipe(
        map((res: any) => res.data),
        map((v: GroupChangeRequestData[]) => plainToClass(GroupChangeRequestData, v)),
      );
  }

  /**
   * Create Change Requests Combined Offer
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Combined-Offers/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1combined_offers/post
   */
  createGroupOffer(projectId: string, body: FormData): Observable<CreatedCombinedOfferModel> {
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/combined_offers`, body)
      .pipe(
        map((res: any) => res.data),
        map((v: CreatedCombinedOfferModel) => plainToClass(CreatedCombinedOfferModel, v)),
      );
  }

  /**
   * Change Requests Combined Offers List
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Combined-Offers/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1combined_offers/get
   */
  changeRequestsCombinedOffersList(projectId: string, unitId: number): Observable<CombinedOffersListModel[]> {
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/combined_offers`, {
        params: {
          unit_id: unitId,
        },
      })
      .pipe(
        map((res: any) => res.data.combined_offers),
        map((v: CombinedOffersListModel[]) => plainToClass(CombinedOffersListModel, v)),
      );
  }

  /**
   * Get Change Requests Combined Offer
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Combined-Offers/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1combined_offers~1%7Bid%7D/get
   */
  changeRequestsCombinedOffer(projectId: string, id: number): Observable<CreatedCombinedOfferModel> {
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/combined_offers/${id}`)
      .pipe(
        map((res: any) => res.data),
        map((v: CreatedCombinedOfferModel) => plainToClass(CreatedCombinedOfferModel, v)),
      );
  }

  /**
   * Update Change Requests Combined Offer
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Combined-Offers/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1combined_offers~1%7Bid%7D/put
   */
  updateChangeRequestsCombinedOffer(projectId: string, id: number, date: string): Observable<CreatedCombinedOfferModel> {
    return this.http
      .put(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/combined_offers/${id}`, {
        combined_offer: {
          expiration_date: date
        }
      })
      .pipe(
        map((res: any) => res.data),
        map((v: CreatedCombinedOfferModel) => plainToClass(CreatedCombinedOfferModel, v)),
      );
  }

  /**
   * Change status Change Requests Combined Offer
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Combined-Offers/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1combined_offers~1%7Bid%7D~1change_status/patch
   */
  changeStatusChangeRequestsCombinedOffer(projectId: string, id: number, status: EChangeRequestStatus): Observable<CreatedCombinedOfferModel> {
    return this.http
      .patch(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/combined_offers/${id}/change_status`, {
        combined_offer: {
          status
        }
      })
      .pipe(
        map((res: any) => res.data),
        map((v: CreatedCombinedOfferModel) => plainToClass(CreatedCombinedOfferModel, v)),
      );
  }

  /**
   * Destroy Change Requests Combined Offer
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Change-Requests-Combined-Offers/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1change_requests~1combined_offers~1batch_destroy/delete
   */
  removeChangeRequestsCombinedOffer(projectId: string, ids: number[]): Observable<any> {
    let params = new HttpParams();
    if (ids.length) {
      ids.forEach((id) => {
        params = params.append('ids[]', id.toString());
      });
    }
    return this.http
      .delete(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/change_requests/combined_offers/batch_destroy`, {params});
  }
}
