/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  EAccessTag,
  EPlatform,
  EProjectRoles,
  EThreadScope,
  IdBookmark,
  ITablePagination,
  ITextEditorInitConfig,
  IThreadUnit,
  IUnitShort,
  MentionUsersModel,
  ThreadUserModel,
} from '@atlas-workspace/shared/models';
import {
  FileLoadService,
  PaginationUtil,
  ProjectService,
  RestThreadsService,
  TextEditorService,
  ThreadsHelperService,
} from '@atlas-workspace/shared/service';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { blobToFile, extractFileFromContent, getFileDataFromBlobURL } from '@atlas-workspace/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { unescape } from 'lodash';
import { defer, EMPTY, iif, Observable, of } from 'rxjs';
import { delay, expand, map, take, toArray } from 'rxjs/operators';
import { Editor } from 'tinymce';

import { threadEditorTaggingParams } from '../../helpers/mentions-params';

@Component({
  selector: 'atl-mentions-text-editor',
  templateUrl: './mentions-text-editor.component.html',
  styleUrls: ['./mentions-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [TextEditorService],
})
export class MentionsTextEditorComponent implements OnChanges, OnInit {
  @Input() public config!: ITextEditorInitConfig;
  @Input() public readonly apiKey!: string;
  @Input() public control!: FormControl;
  @Input() public projectId?: number;
  @Input() public units?: (IThreadUnit | IUnitShort)[];
  @Input() public readonly isCreationModal = false;
  @Input() public readonly isClientCreationModal = false;
  @Input() public set mentionUsersPerView(n: number) {
    this.taggingParams.setUsersPerView(n);
  }
  @Input() public scope?: EThreadScope;
  @Input() accessTag: EAccessTag = EAccessTag.Reclamations;
  @Input() private readonly threadId!: number;

  @Input() public disabled!: boolean;
  @Input() private readonly pasteFileAttachment = true;

  @Output() public readonly getContentHandler = new EventEmitter<string>();
  @Output() public readonly submitHandler = new EventEmitter<void>();
  @Output() public readonly mentionHandler = new EventEmitter<string>();
  @Output() private readonly isContentEmpty = new EventEmitter<boolean>();
  @Output() private readonly setFile = new EventEmitter<File>();
  @Output() private readonly onClickEditor = new EventEmitter();

  @ViewChild('hostChildWrapperElement', { read: ElementRef }) hostChildWrapperElement!: ElementRef;
  @ViewChild(EditorComponent) tinymceEditorComponent!: EditorComponent;

  public editor: Editor | undefined;
  public isMentionSearchable = false;
  public tagUserContainerRect: DOMRect | null = null;
  private bookmark: IdBookmark | null = null;
  public users?: ThreadUserModel[];
  public searchableUsers: ThreadUserModel[] = [];
  public isVisibleStyleSettingsToolbar = true;

  public readonly taggingParams = threadEditorTaggingParams;

  public isProjectContractor = false;
  public showEditor = true;

  constructor(
    @Inject('ENVIRONMENT') env: IEnvironment,
    @Inject(WEB_PLATFORM_TYPE) private readonly portal: EPlatform,
    public readonly cd: ChangeDetectorRef,
    public readonly translateService: TranslateService,
    private readonly restThreadsService: RestThreadsService,
    private readonly threadsHelperService: ThreadsHelperService,
    private readonly projectService: ProjectService,
    private readonly fileLoadService: FileLoadService,
  ) {
    this.apiKey = env.tinyMceApiKey;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.units) {
      return;
    }
    if (!changes.units.firstChange) {
      this.users = undefined;
      this.searchableUsers = [];
    }

    const previousUnits = changes.units.previousValue ? <(IThreadUnit | IUnitShort)[]>changes.units.previousValue : [];
    const currentUnits = changes.units.currentValue ? <(IThreadUnit | IUnitShort)[]>changes.units.currentValue : [];
    if (
      currentUnits.length === previousUnits.length &&
      currentUnits.every((currUnit) => !!previousUnits.find((prevUnit) => prevUnit.id === currUnit.id))
    ) {
      return;
    }
    const html = this.editor?.getContent({ format: 'raw' }) || '';
    if (this.threadsHelperService.detectTaggedUsers(html).length) {
      this.editor?.setContent('');
    }

    if (this.tinymceEditorComponent?.editor && this.disabled) {
      this.tinymceEditorComponent.editor.mode.set('readonly');
    }
  }

  ngOnInit(): void {
    this.getProjectContractor();
    this.initPreprocessPaste();
  }

  public editorOnClick(): void {
    this.onClickEditor.emit();
  }

  public setFocus(focus: boolean): void {
    of(true)
      .pipe(take(1), delay(0))
      .subscribe(() => {
        if (focus) {
          this.editor?.getDoc().body.focus();
        } else {
          this.editor?.getDoc().body.blur();
        }
        this.cd.detectChanges();
      });
  }

  private initPreprocessPaste(): void {
    if (!this.pasteFileAttachment) return;
    this.config.paste_preprocess = async (editor, args): Promise<void> => {
      const unescaped = unescape(args.content);
      const domparser = new DOMParser();
      const parseddom = domparser.parseFromString(unescaped, 'text/html');
      const tmpBlobName = parseddom.querySelector('img')?.getAttribute('_tmpname');
      const tmpMimeType = parseddom.querySelector('img')?.getAttribute('_tmptype');
      args.content = unescaped;

      const link = extractFileFromContent(args.content);
      if (link) {
        args.content = '';
        if (/^blob:/.test(link)) {
          let file = await getFileDataFromBlobURL(link);
          if (!file) return;
          if (tmpBlobName && tmpMimeType) {
            file = new File([file], tmpBlobName, { type: tmpMimeType });
          }
          this.setFile.emit(file);
        } else {
          this.loadFile(link);
        }
      }
    };
  }

  private loadFile(url: string): void {
    this.fileLoadService
      .proxyLoad(url)
      .pipe(take(1))
      .subscribe((blob) => {
        const file = blobToFile(blob);
        this.setFile.emit(file);
      });
  }

  private getProjectContractor(): void {
    this.projectService.adminProject$.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.isProjectContractor = res.currentRole === EProjectRoles.Contractor;
      }
    });
  }

  public editorOnInit(ed: EventObj<Document>): void {
    this.editor = ed.editor as Editor;

    this.threadsHelperService.preventDefaultButtonsTitlesReflection(this.hostChildWrapperElement);

    if (this.portal === EPlatform.WEB_CLIENT) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.editor.on('keyup', () => {
        this.updateSubmitButtonAvailability();
      });

      this.editor.on('paste redo undo', () => {
        this.updateSubmitButtonAvailability();
      });
      this.editor.on('input', () => {
        this.updateSubmitButtonAvailability();
      });

      return;
    }

    this.editor.on('keyup', (e: KeyboardEvent | InputEvent) => {
      if ((<KeyboardEvent>e).key === this.taggingParams.KEY && (<KeyboardEvent>e).shiftKey) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const parentOfFocusNode = this.editor!.selection.getSel()?.focusNode?.parentNode as HTMLElement;
        if (!parentOfFocusNode?.classList?.contains('mce-offscreen-selection')) {
          this.showChatUsers();
        }
      }
      if ((<KeyboardEvent>e).key === 'Escape' && this.tagUserContainerRect) {
        this.hideTaggingUserList();
      }
      if (this.tagUserContainerRect && (<KeyboardEvent>e).key === 'Backspace' && !this.editor?.getContent()) {
        this.hideTaggingUserList();
      }
      if ((<KeyboardEvent>e).key === 'Backspace') {
        const range = this.editor?.selection.getRng();
        const startOffset = range?.startOffset;

        this.editor?.selection.setCursorLocation(range!.startContainer, startOffset as number);
        this.updateSubmitButtonAvailability();
      }
    });
    this.editor.on('paste redo undo', () => {
      this.updateSubmitButtonAvailability();
      if (this.tagUserContainerRect) {
        this.hideTaggingUserList();
      }
    });
    this.editor.on('input', (e: KeyboardEvent | InputEvent) => {
      this.updateSubmitButtonAvailability();
      if (this.tagUserContainerRect) {
        if ((e as InputEvent).inputType === 'insertText' && !!(e as InputEvent).data?.trim()) {
          this.checkTaggingSegment();
        } else if ((e as InputEvent).inputType === 'deleteContentBackward') {
          this.checkTaggingSegment(true);
        } else {
          this.hideTaggingUserList();
        }
      }
    });
  }

  private updateSubmitButtonAvailability(): void {
    const content = this.editor?.getContent();
    const isSubmitButtonDisabled = content?.trim() === '';
    this.isContentEmpty.emit(isSubmitButtonDisabled);
  }

  public tagUser(id: number): void {
    if (this.editor && this.bookmark?.id) {
      const focusNode = this.editor.selection.getSel()!.focusNode;
      let focusNodeContent = '';
      if (focusNode && focusNode.nodeType === Node.TEXT_NODE) {
        focusNodeContent = (focusNode as Text).data;
      }
      const tagSegment = focusNodeContent.substring(focusNodeContent.lastIndexOf(this.taggingParams.KEY) || 0);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedUser = this.users!.find((u) => u.id == id)!;

      const html = this.threadsHelperService.replaceMentionSegmentToElement(
        this.editor.getContent({ format: 'raw' }),
        tagSegment,
        this.bookmark.id,
        selectedUser.id,
        selectedUser.name,
      );
      this.editor.setContent(html, { format: 'html' });
      this.mentionHandler.emit(html);

      this.editor?.focus(false);
      const targetBookmark = this.editor.dom.select<HTMLElement>('span#' + this.bookmark?.id);
      this.editor.selection.select(targetBookmark[0]);
      this.editor.dom.remove(targetBookmark[0]);

      this.hideTaggingUserList();
    }
  }

  public triggerMentionKeyup(): void {
    this.editor?.execCommand('mceInsertContent', false, this.taggingParams.KEY);
    // @ts-ignore
    this.editor?.fire('keyup', { key: this.taggingParams.KEY, shiftKey: true });

    this.updateSubmitButtonAvailability();
  }

  private checkTaggingSegment(isBackward = false): void {
    if (this.editor && this.users) {
      const focusNode = this.editor.selection.getSel()!.focusNode;
      let focusNodeContent = '';
      if (focusNode && focusNode.nodeType === Node.TEXT_NODE) {
        focusNodeContent = (focusNode as Text).data;
      }
      if (!focusNodeContent) {
        return this.hideTaggingUserList();
      }
      const tagSegment = focusNodeContent.substring(focusNodeContent.lastIndexOf(this.taggingParams.KEY) || 0);
      if (tagSegment && tagSegment.length > 1 && tagSegment[0] === this.taggingParams.KEY) {
        const taggingTerm = tagSegment.substring(1);
        this.searchableUsers = this.users.filter((u) => {
          return (
            u['name'].toLowerCase().includes(taggingTerm.toLowerCase()) ||
            u['email'].toLowerCase().includes(taggingTerm.toLowerCase())
          );
        });
        this.isMentionSearchable = true;
      } else if (tagSegment && tagSegment.length === 1 && tagSegment[0] === this.taggingParams.KEY) {
        this.searchableUsers = this.users;
        if (isBackward) {
          this.tagUserContainerRect = this.editor.selection.getBoundingClientRect() as DOMRect;
        }
      } else {
        this.hideTaggingUserList();
      }
      this.cd.markForCheck();
    }
  }

  getUsersForMentions(): Observable<MentionUsersModel[]> {
    const usersPagination = { ...PaginationUtil.defaultPagination };
    return this.getUsersForMentionsRequest(usersPagination).pipe(
      take(1),
      expand((admins) => {
        if (admins.length === usersPagination.pageItems) {
          usersPagination.currentPage++;
          return this.getUsersForMentionsRequest(usersPagination);
        }
        return EMPTY;
      }),
      toArray(),
      map((arrays) => arrays.reduce((acc, val) => acc.concat(val), [])),
    );
  }

  getUsersForMentionsRequest(pagination: ITablePagination): Observable<MentionUsersModel[]> {
    return this.restThreadsService.getUsersForMentions(this.threadId, pagination, true);
  }

  private showChatUsers(): void {
    if (this.isProjectContractor || this.isCreationModal) {
      return;
    }

    if (!this.projectId || !this.units?.length) {
      return;
    }
    iif(
      () => !!this.users,
      defer(() => of(this.users)),
      defer(() => this.getUsersForMentions()),
    )
      .pipe(
        take(1),
        map((members) => {
          return (members as MentionUsersModel[]).map(
            (user: MentionUsersModel) =>
              ({
                id: user.id,
                name: user.name,
                type: user.type,
                email: user.email,
              }) as unknown as ThreadUserModel,
          );
        }),
      )
      .subscribe((members) => {
        this.users = members;
        if (this.editor && this.users) {
          this.searchableUsers = this.users;
          if (this.bookmark) {
            this.removeInsertedBookmarks();
          }
          this.bookmark = this.editor.selection.getBookmark() as IdBookmark;
          this.tagUserContainerRect = this.editor.selection.getBoundingClientRect() as DOMRect;
          this.cd.markForCheck();
          this.threadsHelperService.fixAutofocusOnZeroWidthNoBreakSpaceSymbol(this.editor);
        }
      });
  }

  public onInputKeyPress(event: KeyboardEvent): void {
    if (!this.editor || ((event.metaKey || event.ctrlKey) && event.key === 'c') || event.code.startsWith('Arrow')) {
      return;
    }
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault();
      return this.submitHandler.next();
    }
  }

  public hideTaggingUserList(): void {
    this.removeInsertedBookmarks();
    this.tagUserContainerRect = null;
    this.searchableUsers = [];
    this.isMentionSearchable = false;
    this.cd.detectChanges();
  }

  private removeInsertedBookmarks(): void {
    const targetBookmarksStart = this.editor?.dom.select<HTMLElement>(`#${this.bookmark?.id}_start`);
    targetBookmarksStart?.forEach((bookmarkStart) => this.editor?.dom.remove(bookmarkStart));
  }

  public get calculateVerticalPositionTaggingBox(): number {
    const visibleItemsCount =
      this.isMentionSearchable && !this.searchableUsers.length
        ? 1
        : this.searchableUsers.length > this.taggingParams.usersPerView
          ? this.taggingParams.usersPerView
          : this.searchableUsers.length;
    const mainDivHeight =
      this.taggingParams.boxItemHeight * visibleItemsCount +
      this.taggingParams.innerBoxPaddingY +
      this.taggingParams.gap * (visibleItemsCount - 1);
    return (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.tagUserContainerRect!.top -
      mainDivHeight -
      this.taggingParams.verticalEditorOffset +
      (this.isClientCreationModal ? this.taggingParams.clientCreationToolbarHeight : 0) +
      (this.isVisibleStyleSettingsToolbar ? this.taggingParams.toolbarHeight : 0)
    );
  }

  public get calculateHorizontalPositionTaggingBox(): number {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const leftPosition = this.tagUserContainerRect!.left - this.taggingParams.keySymbolWidth;
    let atlEditorOffsetWidth = this.hostChildWrapperElement.nativeElement.offsetWidth;
    if ('safari' in window) {
      atlEditorOffsetWidth = this.hostChildWrapperElement.nativeElement.firstChild.offsetWidth;
    }
    if (atlEditorOffsetWidth < leftPosition + this.taggingParams.boxWidth) {
      return atlEditorOffsetWidth - this.taggingParams.boxWidth;
    }
    return leftPosition;
  }
}
