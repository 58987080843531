import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  EGlobalFirmRole,
  ETablePagination,
  EUserKind,
  FirmBuyerProjects,
  FIrmContractorCompanies,
  GlobalFirmUsersList,
  ITablePagination,
  IUnitItem,
} from '@atlas-workspace/shared/models';
import { DataTableHelperService, getEntityListByChunks, PaginationUtil } from '@atlas-workspace/shared/service';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class FirmUsersService {
  public pagination$ = new Subject<ITablePagination>();

  private readonly _firmUnits = new BehaviorSubject<IUnitItem[] | null>(null);
  public firmUnits$ = this._firmUnits.asObservable();

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private tableService: DataTableHelperService
  ) {}

  resetFirmUnits(): void {
    this._firmUnits.next(null);
  }

  getUsers(
    firmId: number,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    kinds?: EUserKind[],
    roles?: string[],
    projectIds?: number[],
    contractorCompanyIds?: number[],
    companyIds?: number[],
    firmIds?: number[]
  ): Observable<GlobalFirmUsersList> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (kinds?.length) {
      kinds.forEach((kind) => {
        params = params.append('kinds[]', kind);
      });
    }
    if (roles?.length) {
      // @see https://agilie.atlassian.net/browse/AT-10478
      if (roles.includes(EGlobalFirmRole.UnitOwner)) {
        params = params.append('roles[]', EGlobalFirmRole.UnitCoOwner);
      }
      roles.forEach((role) => {
        params = params.append('roles[]', role);
      });
    }
    if (projectIds?.length) {
      projectIds.forEach((id) => {
        params = params.append('project_ids[]', id);
      });
    }
    if (companyIds?.length) {
      companyIds.forEach((id) => {
        params = params.append('company_ids[]', id);
      });
    }
    if (contractorCompanyIds?.length) {
      contractorCompanyIds.forEach((id) => {
        params = params.append('contractor_company_ids[]', id);
      });
    }
    if (firmIds?.length) {
      firmIds.forEach((id) => {
        params = params.append('firm_ids[]', id);
      });
    }

    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/firms/${firmId}/characters`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.pagination$.next(pagination);
        }),
        map((res) => res.body.data),
        map((value: GlobalFirmUsersList) => plainToClass(GlobalFirmUsersList, value))
      );
  }

  firmBuyerProjects(id: number): Observable<FirmBuyerProjects[]> {
    return this.http.get<any>(`${this.environment.apiBaseUrl}api/v1/firm_buyers/${id}`).pipe(
      map((res) => res.data.projects),
      map((value: FirmBuyerProjects[]) => plainToClass(FirmBuyerProjects, value))
    );
  }

  firmContractorCompanies(id: number): Observable<FIrmContractorCompanies[]> {
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/contractor_companies/contractor_view`, {
        params: { main_admin_id: id.toString() },
      })
      .pipe(
        map((res) => res.data.contractor_companies),
        map((value: FIrmContractorCompanies[]) => plainToClass(FIrmContractorCompanies, value))
      );
  }

  /**
   * @note Get all firm units
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units/paths/~1api~1v1~1units~1short_index/get
   */
  public getAllFirmUnits(componentInstance: unknown): void {
    const currentObservable$ = { observable: undefined };
    getEntityListByChunks<IUnitItem>({
      http: this.http,
      url: `${this.environment.apiBaseUrl}api/v1/units/short_index`,
      componentInstance: componentInstance,
      currentObservable$: currentObservable$,
      plainToClassCb: (body) => <IUnitItem[]>body.data.units,
      onLoadedCb: (units) => this._firmUnits.next(units),
    }).observable.subscribe();
  }
}
