import { Injectable } from '@angular/core';
import { ImmutableSelector } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import { SetOptionUnits } from './option-units.action';
import { OptionUnitsStateModel } from './option-units.model';

@State({
  name: 'optionUnitsState',
  defaults: defaultState.optionUnitsState,
})
@Injectable()
export class OptionUnitsState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: OptionUnitsStateModel): OptionUnitsStateModel {
    return state;
  }

  @Action(SetOptionUnits)
  setOffers(ctx: StateContext<OptionUnitsStateModel>, { optionUnits, phases }: SetOptionUnits): void {
    ctx.setState(patch<OptionUnitsStateModel>({ optionUnits: optionUnits, phases: phases }));
  }
}
