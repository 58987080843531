// All keys used to store data in the local storage

export enum ELocalStorageKeys {
  AuthHeaders = 'authHeaders',
  Firm = 'firm',
  Project = 'project',
  Unit = 'unit',
  UserInfo = 'userInfo',
  CachedMessages = 'cachedMessages',
  Language = 'lang',
  ExpandedSidebar = 'expandedSidebar',
  GlobalOnboardingStep = 'globalOnboardingStep',
  ProjectOnboardingStep = 'projectOnboardingStep',
  GlobalReclamationFilter = 'globalReclamationFilter',
  ProjectReclamationFilter = 'projectReclamationFilter',
  GlobalChangeRequestsFilter = 'globalChangeRequestsFilter',
  ProjectChangeRequestsFilter = 'projectChangeRequestsFilter',
  GlobalPriceRequestsFilter = 'globalPriceRequestsFilter',
  ProjectHasMessageFilter = 'projectHasMessageFilter',
  OptionStoreNavigationHistory = 'optionStoreNavigationHistory',
  ClientOptionsOnboardingStep = 'clientOptionsOnboardingStep',
  ClientShowroomOnboardingStep = 'clientShowroomOnboardingStep',
  AdminNewsletterActiveToggle = 'adminNewsletterActiveToggle',
  AdminReclamationActiveToggle = 'adminReclamationActiveToggle',
  AdminGlobalReclamationActiveToggle = 'adminGlobalReclamationActiveToggle',
  AdminChangeRequestsActiveToggle = 'adminChangeRequestsActiveToggle',
  AdminMeetingsActiveToggle = 'adminMeetingsActiveToggle',
  AdminPhasesActiveToggle = 'adminPhasesActiveToggle',
}
