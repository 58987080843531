import { BaseReportType, IDropdownReportsItem } from "@atlas-workspace/shared/models";

export const projectUsersDropdownReports: IDropdownReportsItem[] = [
  {
    type: BaseReportType.PDF,
    icon: "assets/attach-pdf-new.svg",
    labelTranslateKey: "Button.Download_buyers_PDF",
    commingSoon: true
  },
  {
    type: BaseReportType.XLS,
    icon: "assets/attach-xls-new.svg",
    labelTranslateKey: "Button.Download_buyers_XLS",
  }
];

export const globalUsersDropdownReports = [...projectUsersDropdownReports];
