<div class="gl-user-displaying__wrapper">
  <div
    class="gl-user-displaying__icon"
    [ngStyle]="{ 'background-color': iconColor, border: '1px solid ' + iconBorderColor }"
  >
    {{ name | firmPrefix }}
  </div>

  <div class="gl-user-displaying__description">
    <div class="gl-ellipsis-table-wr">
      <div
        #nameEl
        class="gl-user-displaying__name ellipsis"
        [ngbTooltip]="name"
        [openDelay]="tooltipDelay"
        container="body"
        triggers="hover touchstart"
        [disableTooltip]="nameEl.offsetWidth >= nameEl.scrollWidth"
      >
        {{ name | truncate: memberLengthName }}
      </div>
    </div>
    <div class="gl-ellipsis-table-wr">
      <div
        #emailEl
        class="gl-user-displaying__email ellipsis"
        [ngbTooltip]="email"
        [openDelay]="tooltipDelay"
        container="body"
        triggers="hover touchstart"
        [disableTooltip]="emailEl.offsetWidth >= emailEl.scrollWidth"
      >
        {{ email }}
      </div>
    </div>
  </div>
</div>
