import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { MyTaskModel } from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { map, Observable } from 'rxjs';

import { AuthAdminService } from '../auth/auth-admin.service';

@Injectable()
export class TasksService {
  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private readonly http: HttpClient,
    private readonly authAdminService: AuthAdminService
  ) {}

  getDashboardData(sampleType: string, allFirmStatistic: boolean): Observable<MyTaskModel> {
    const firm_id = this.authAdminService.firm?.firmId.toString();
    let params = new HttpParams();
    if (firm_id) params = params.set('firm_id', firm_id);
    if (sampleType) params = params.set('sample_type', sampleType);
    if (allFirmStatistic) params = params.set('all_firm_statistic', allFirmStatistic);
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/home/tasks`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data),
        map((data: MyTaskModel) => plainToClass(MyTaskModel, data))
      );
  }
}
