import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

import {EUnitFloorPlansTypeKey, EUnitStatus, IUnitMainBuyer, UnitTypeModel} from "../models/unit.model";
import {UnitMemberModel} from "../models/unit-member.model";
import {VendorModel} from "../models/vendor.model";

export enum UnitSellStatus {
  Sell = 'sell',
  Sold = 'sold',
  Reserved = 'reserved',
  Reserve = 'reserve',
  Unsold = 'unsold',
}

export enum UnitSellStatusTranslate {
  Sell = 'Entity.Sell',
  Sold = 'Entity.Sold',
  Reserved = 'Entity.Reserved',
  Reserve = 'Entity.Reserve',
  Unsold = 'Entity.Unsold',
}

export enum UnitModalState {
  Remove = 'remove',
}

export interface IUpdateUnitTable {
  id: number | null;
  buyerName: string | null;
  unitStatus: string;
  actualPrice: string;
  budgetPrice: string;
  identifier: string;
  unitType: string;
  percent: string;
  soldPrice: string | null;
  layoutTypeName: string | null;
  layoutTypes: any[];
  primaryOwners: IUnitMainBuyer[];
  mainPrimaryOwner: IUnitMainBuyer;
  newOwner: boolean;
  bra: string;
  roomsCount: number;
  newMember: UnitMemberModel;
  memberRemoved: boolean;
  fromOwnerToGuest: boolean;
}

export interface IUnitFloorMenu {
  index: number;
  created: boolean;
  floorNumber: number;
  isDefault: boolean;
  name: string;
  floorKind?: EUnitFloorPlansTypeKey
}


export enum EUnitDetailsScope {
  Units = 'Units',
  MeetingDetails = 'MeetingDetails',
}

export interface TUnitCreationAttributes {
  name: string;
  type: string;
  value: string | NgbDateStruct | null;
  forAll: boolean;
}

export interface TUnitCreationBody {
  actualPrice: string;
  budgetPrice: string;
  identifier: string;
  propertyType: string;
  status: EUnitStatus;
  unitType: UnitTypeModel[];
  companyName: VendorModel[];
  documents: {name: string, file: File[]}[];
  documents_electro: {name: string, file: File[]}[];
  ownerInfos?: {
    email: string;
    phone: string;
    name: string;
    sendInvite: boolean;
  };
  unitAttributes: TUnitCreationAttributes[];
  bra?: number;
  roomsCount?: number;
  parkingPlaceNumber?: string;
  storageUnitNumber?: string;
  storm?: string;
}
