import { Pipe, PipeTransform } from '@angular/core';
import {PhasesCategoriesModel} from "@atlas-workspace/shared/models";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'phaseLinked',
  standalone: true,
})
export class PhaseLinkedPipe implements PipeTransform {

  constructor(private readonly translateService: TranslateService) {}

  transform(value: PhasesCategoriesModel): string {
    return value.layoutOptionPhase
      ? this.translateService.instant('Title.Connected_to')  + ' ' + value.layoutOptionPhase.name
      : this.translateService.instant('Title.Not_connected_to_phase');
  }
}
