<atl-ngb-modal-header
  [title]="title"
  (closeModalEvent)="closeModal()"
>
</atl-ngb-modal-header>

<div class="group-creator">
  <atl-simple-tabs
    *ngIf="navList.length > 1"
    [withId]="true"
    [activeId]="floorTabActiveId"
    [tabs]="navList"
    [routeNavigation]="false"
    [scrollControl]="true"
    (selectingTab)="selectTab($event)"
    class="newest-tabs"
  ></atl-simple-tabs>
  <atl-image-mark
    *ngIf="previewPlan; else noFloors"
    [mark]="mark"
    [imageUrl]="previewPlan"
    [multiplePins]="true"
    [floorId]="floorTabActiveId"
    (existingMarkClick)="detailMark($event)"
    class="v-scrollbar h-scrollbar"
  ></atl-image-mark>
</div>


<ng-template #noFloors>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <h3 class="fz-20 lh-24 fw-500 mb-8">{{'Shared.Title.No_unit_selected' | translate}}</h3>
    <p class="fz-16 lh-24 text-gray-890 text-align-center max-w-555">
      {{ 'Shared.Mark.Message.No_floor_plan' | translate }}
      <span (click)="unitRedirect()" class="cursor-pointer text-blue-980">{{ 'Shared.Mark.Message.Unit_details_page' | translate }}</span>
    </p>
  </div>
</ng-template>
