import {ITablePagination, ReclamationsModel} from "@atlas-workspace/shared/models";

export class SetReclamationsProject {
  static readonly type = '[Reclamation Project] save change reclamations project local';
  constructor(public reclamations: ReclamationsModel[]) {}
}

export class SetReclamationProject {
  static readonly type = '[Reclamation Project] save change reclamation project local';
  constructor(public reclamation: ReclamationsModel) {}
}

export class AddReclamationProject {
  static readonly type = '[Reclamation Project] add reclamation project local';
  constructor(public reclamation: ReclamationsModel) {}
}

export class RemoveReclamationProject {
  static readonly type = '[Reclamation Project] remove reclamation project local';
  constructor(public ids: number[]) {}
}

export class ReclamationProjectSetPagination {
  static readonly type = '[Reclamation Project] set pagination';
  constructor(public pagination: ITablePagination) {}
}

export class ReclamationProjectSetTotalFirstLoading {
  static readonly type = '[Reclamation Project] set total first loading';
  constructor(public firstLoad: boolean, public totalCount: number) {}
}
