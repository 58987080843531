import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CreateOfferComponent } from '@atlas-workspace/change-requests';
import {NewestConfirmModalComponent} from '@atlas-workspace/shared/modals';
import {
  ChangeRequestModel,
  ChangeRequestOfferModel,
  EChangeRequestStatus, FloorModel,
  offerStatusList, UnitFloorModel, UnitUserModel
} from '@atlas-workspace/shared/models';
import {ChangeRequestService, ModalFacadeService, SharedUiStorageService} from '@atlas-workspace/shared/service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss', '../../../styles/status.component.scss'],
})
export class OffersListComponent implements OnInit {
  @Input() projectId!: string;
  @Input() public changeRequest!: ChangeRequestModel;
  @Input() offers!: ChangeRequestOfferModel[];
  @Input() makeOfferModal!: BehaviorSubject<any>;
  @Input() isDeclined!: boolean;
  @Input() floorPlanData!: UnitFloorModel[] | FloorModel[] | any;
  @Input() unit!: UnitUserModel;
  @Input() unitId!: number;

  @Output() private readonly changeStatus = new EventEmitter<EChangeRequestStatus>();
  @Output() private readonly updateOfferList = new EventEmitter<ChangeRequestOfferModel[]>();

  public isEditMode = false;
  public isFirstLoad = false;
  public editOffer!: ChangeRequestOfferModel;
  public searchParam = '';
  public sortParam = '';
  public selectedIds: number[] = [];
  public currentPage = 1;
  public isShowMessageBanner = false;
  public readonly statusClasses = offerStatusList;
  public readonly statusKeys = EChangeRequestStatus;
  public readonly pageItems = 50;
  public messageBanner = '';
  public readonly messageBannerSend = 'Title.Offer_was_sent';
  public readonly messageBannerRemove = 'Title.Offer_was_deleted';

  constructor(
    private modalFacadeService: ModalFacadeService,
    private sharedUiStorageService: SharedUiStorageService,
    private changeRequestService: ChangeRequestService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.makeOfferModal.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.createOffer();
      }
     });
  }

  get allOffersSelected(): boolean {
    return this.offers.every((offer) => offer.selected);
  }

  get disabledNewOffer(): boolean {
    return this.offers.some(offer =>  {
      return offer.status === EChangeRequestStatus.Offered
        || offer.status === EChangeRequestStatus.OfferExpired
        || offer.status === EChangeRequestStatus.OfferApproved;
    });
  }

  public updateOffer(offer: ChangeRequestOfferModel, isEditMode = false): void {
    this.isEditMode = isEditMode;
    const idx = this.offers.findIndex(o => o.id === offer.id);
    if (idx !== -1) {
      this.offers[idx] = offer;
      this.updateOfferList.emit(this.offers);
      if (offer.status === EChangeRequestStatus.OfferCanceled) {
        this.changeStatus.emit(EChangeRequestStatus.OfferCanceled);
      }
      this.checkAll(false);
    }
  }

  public checkAll(value: boolean): void {
    this.offers.map((offer: ChangeRequestOfferModel) => {
      if (offer.status !== this.statusKeys.OfferApproved) {
        offer.selected = value;
      }
    });
    this.selectedIds = this.offers.filter((x) => x.selected).map((x) => x.id);
  }

  public checkValue(unit: ChangeRequestOfferModel): void {
    const index: number = this.offers.findIndex((x) => x.id === unit.id);
    this.offers[index].selected = !this.offers[index].selected;
    this.selectedIds = this.offers.filter((x) => x.selected).map((x) => x.id);
  }

  public createOffer(oldOffer?: ChangeRequestOfferModel): void {
    const modalRef = this.modalFacadeService.openModal(CreateOfferComponent, {
      ...this.sharedUiStorageService.modalCreateOffer,
    });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.changeRequest = this.changeRequest;
    modalRef.componentInstance.offer = oldOffer;
    modalRef.componentInstance.isFloors = !!(this.unit.hasOwnFloorPlans ? this.unit.unitFloors?.length : this.unit.floors?.length);
    this.makeOfferModal.next(null);
    modalRef.componentInstance.createOffer.pipe(untilDestroyed(this)).subscribe((offer: ChangeRequestOfferModel) => {
      this.displayMessageBanner(this.messageBannerSend);
      this.offers.unshift(offer);
      this.changeStatus.emit(EChangeRequestStatus.Offered);
    });
  }

  public sort(param: string): void {
    this.sortParam = param;
    this.checkAll(false);
    this.currentPage = 1;
    this.getOffers();
  }

  public getNextPage(last: boolean): void {
    if (last && this.offers?.length === (this.currentPage * this.pageItems)) {
      this.currentPage++;
      this.getOffers();
    }
  }

  private getOffers(): void {
    this.changeRequestService.getChangeRequestOffers(
      this.projectId,
      this.changeRequest.id,
      this.sortParam,
      this.currentPage,
      this.pageItems
    ).pipe(take(1))
      .subscribe((offers) => {
        this.offers = this.currentPage === 1 ? offers : [...this.offers, ...offers];
      });
  }

  public openDetail(offer: ChangeRequestOfferModel): void {
    this.isEditMode = true;
    this.editOffer = offer;
  }

  public initConfirmModal(): void {
    const confirmModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-condition-modal',
    });

    confirmModalRef.componentInstance.title = `${this.translateService.instant('Button.Delete_offer')}?`;
    confirmModalRef.componentInstance.description = this.translateService.instant(
      'Description.Offer_delete_description'
    );
    confirmModalRef.componentInstance.button.text = this.translateService.instant('Shared.Button.Yes_delete');
    confirmModalRef.componentInstance.icon = 'basket.svg';
    this.handleOffersRemoval(confirmModalRef);
  }

  private handleOffersRemoval(modalRef: NgbModalRef): void {
    modalRef.componentInstance.passConfirm.pipe(untilDestroyed(this)).subscribe((v: boolean) => {
      if (v) {
        modalRef.close();
        this.removeOffers();
      }
    });
  }

  private removeOffers(): void {
    this.changeRequestService.batchDestroyOffers(this.projectId, this.changeRequest.id, this.selectedIds)
      .pipe(take(1))
      .subscribe((value) => {
        this.offers = this.offers.filter(offer => !this.selectedIds.some(id => id === offer.id));
        this.changeStatus.emit(value.newStatus);
        this.updateOfferList.emit(this.offers);
        this.checkAll(false);
        this.displayMessageBanner(this.messageBannerRemove);
        this.isEditMode = false;
      });
  }

  private displayMessageBanner(message: string): void {
    this.messageBanner = message;
    this.isShowMessageBanner = true;
  }

  public hideMessageBanner(): void {
    this.isShowMessageBanner = false;
  }

  removeOfferHandler(id: number): void {
    this.selectedIds = [id];
    this.initConfirmModal();
  }

  duplicateHandler(offer: ChangeRequestOfferModel): void {
    this.isEditMode = false;
    this.createOffer(offer);
  }
}
