import { Pipe, PipeTransform } from '@angular/core';
import { RuleDependableProduct } from '@atlas-workspace/shared/models';

@Pipe({
  name: 'filterAddedToWishlist',
  standalone: true
})
export class FilterAddedToWishlistPipe implements PipeTransform {
  transform(items: RuleDependableProduct[]): RuleDependableProduct[] {
    if (!items) return [];
    return items.filter(item => item.addedToWishlist || item.ruleItemableAddedToWishlist);
  }
}
