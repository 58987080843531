import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OptionReport } from '@atlas-workspace/shared/models';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OverviewService {
  constructor(private http: HttpClient) {}

  public getOptionReports(projectId: number): Observable<OptionReport> {
    return this.http.get(`${environment.apiBaseUrl}/api/v1/projects/${projectId}/reports`).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(OptionReport, data))
    );
  }
}
