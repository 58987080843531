/* eslint-disable sonarjs/no-duplicate-string */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdminTask } from '@atlas-admin/shared/interfaces/tasks.interface';
import {
  AdminTaskDetailModel,
  AdminTaskModel,
  AdminUnitModel,
  ETablePagination,
  IAdminTaskDetail,
  ITablePagination,
} from '@atlas-workspace/shared/models';
import { AuthAdminService, DataTableHelperService, PaginationUtil } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class TaskService {
  private tasksPagination$ = new BehaviorSubject<ITablePagination | null>(null);

  constructor(
    private http: HttpClient,
    private authAdminService: AuthAdminService,
    private tableService: DataTableHelperService
  ) {}

  public getTasks(
    projectId: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination
  ): Observable<AdminTaskModel[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http
      .get<any>(environment.apiBaseUrl + `/api/v1/projects/${projectId}/tasks`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
          this.tasksPagination$.next(pagination);
        }),
        map((res) => res.body.data?.tasks),
        map((data: IAdminTask[]) => plainToClass(AdminTaskModel, data))
      );
  }

  public getSpecificTask(projectId: string, taskId: number): Observable<AdminTaskDetailModel> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http
      .get<{ data: IAdminTaskDetail }>(environment.apiBaseUrl + `/api/v1/projects/${projectId}/tasks/${taskId}`, {
        params: {
          firm_id: firmId.toString(),
        },
      })
      .pipe(
        map((res) => res.data),
        map((data: IAdminTaskDetail) => plainToClass(AdminTaskDetailModel, data))
      );
  }

  public deleteTasks(projectId: string, ids: number[]): Observable<string> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    const httpOptions = {
      params: params,
      body: { ids: ids },
    };
    return this.http
      .delete(`${environment.apiBaseUrl}api/v1/projects/${projectId}/tasks/batch_destroy`, httpOptions)
      .pipe(
        map((res: any) => res?.message),
        map((data) => <string>data)
      );
  }

  public deleteSpecificTask(projectId: string, taskId: number): Observable<unknown> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http
      .delete(`${environment.apiBaseUrl}api/v1/projects/${projectId}/tasks/${taskId}`, {
        params: {
          firm_id: firmId.toString(),
        },
      })
      .pipe(map((res: any) => res?.message));
  }

  public createTask(projectId: string, body: FormData): Observable<unknown> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http.post(environment.apiBaseUrl + `api/v1/projects/${projectId}/tasks`, body, {
      params: {
        firm_id: firmId.toString(),
      },
    });
  }

  public updateTask(projectId: string, taskId: number, body: FormData): Observable<AdminTaskDetailModel> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http
      .put<{ data: IAdminTaskDetail }>(environment.apiBaseUrl + `api/v1/projects/${projectId}/tasks/${taskId}`, body, {
        params: {
          firm_id: firmId.toString(),
        },
      })
      .pipe(
        map((res: any) => res.data),
        map((data: IAdminTaskDetail) => plainToClass(AdminTaskDetailModel, data))
      );
  }

  public getUnitsByTask(
    projectId: string,
    taskId: number,
    sort = 'default',
    search = ''
  ): Observable<AdminUnitModel[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort);
    return this.http
      .get<{ data: { units: AdminUnitModel[] } }>(
        environment.apiBaseUrl + `api/v1/projects/${projectId}/tasks/${taskId}/units`,
        {
          params,
        }
      )
      .pipe(
        map((res: any) => res.data.units),
        map((units) => plainToClass(AdminUnitModel, <AdminUnitModel[]>units))
      );
  }

  updateUnitStatus(projectId: string, unitId: string, taskId: number, isDone: boolean): Observable<unknown> {
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http.put(
      environment.apiBaseUrl + `api/v1/projects/${projectId}/units/${unitId}/tasks/${taskId}/task_status`,
      {
        task_status: {
          is_done: isDone,
        },
      },
      {
        params: {
          firm_id: firmId.toString(),
        },
      }
    );
  }

  public getTasksTemplate(): Observable<IAdminTask[]> {
    const mockTasks = [
      {
        id: 1,
        name: 'Task #1',
        description: 'Lorem',
        dueDate: new Date(),
        total: 100,
        done: 10,
        selected: false,
      },
      {
        id: 2,
        name: 'Task #2',
        description: 'Lorem',
        dueDate: new Date(),
        total: 100,
        done: 10,
        selected: false,
      },
      {
        id: 3,
        name: 'Task #3',
        description: 'Lorem',
        dueDate: new Date(),
        total: 100,
        done: 10,
        selected: false,
      },
      {
        id: 4,
        name: 'Task #4',
        description: 'Lorem',
        dueDate: new Date(),
        total: 100,
        done: 10,
        selected: false,
      },
      {
        id: 5,
        name: 'Task #5',
        description: 'Lorem',
        dueDate: new Date(),
        total: 100,
        done: 10,
        selected: false,
      },
    ];
    return of(mockTasks);
  }

  public getSpecificTasksTemplate(): Observable<IAdminTask> {
    const mockTasks = {
      id: 1,
      name: 'Task #1',
      description:
        'This update has this text and it is great. This update has this text and it is great.' +
        'This update has this text and it is great. This update has this text and it is great.' +
        'This update has this text and it is great. This update has this text and it is great. ' +
        'Donec id elit non mi porta gravida at eget metus.' +
        'Cool stuff in bold Nullam id dolor id nibh ultricies vehicula ut id elit donec sed odio dui.',
      dueDate: new Date(),
      total: 100,
      done: 10,
      selected: false,
    };
    return of(mockTasks);
  }

  set pagination(p: ITablePagination) {
    this.tasksPagination$.next(p);
  }

  get pagination$(): Observable<ITablePagination | null> {
    return this.tasksPagination$.asObservable();
  }
}
