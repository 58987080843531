import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedFormModule } from '@atlas-workspace/shared/form';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ReclamationCreateConfirmComponent } from './reclamation-create-confirm.component';
import { HeaderIconComponent } from '@atlas-workspace/shared/ui';

@NgModule({
  declarations: [ReclamationCreateConfirmComponent],
  imports: [
    CommonModule,
    NgbTooltipModule,
    TranslateModule,
    SharedDirectivesModule,
    SharedFormModule,
    FormsModule,
    HeaderIconComponent
  ],
  exports: [ReclamationCreateConfirmComponent]
})
export class ReclamationCreateConfirmModule {}
