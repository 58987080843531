import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImageCropModalComponent } from '@atlas-workspace/shared/form';
import { NewestConfirmModalComponent } from '@atlas-workspace/shared/modals';
import {
  FileModel,
  FilePreview,
  ICroppingData,
  ImageModel,
  pdfMime,
  PositionedFile,
} from '@atlas-workspace/shared/models';
import { extractFirstPdfPageAsImage, ModalFacadeService, ToasterService } from '@atlas-workspace/shared/service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import * as mime from 'mime';
import { take } from 'rxjs/operators';

// @ts-ignore
import newestDownloadIconAttachment from '!!raw-loader?!@atlas-workspace/shared/assets/lib/download_simple.svg';
// @ts-ignore
import newestTrashIconAttachment from '!!raw-loader?!@atlas-workspace/shared/assets/lib/trash-icon-sm.svg';

import { FileValidators } from '../../validators/file-validators';

enum EFileType {
  Image = 'image',
  File = 'file',
}

interface IConfirmRemove {
  title: string;
  description: string;
}

@UntilDestroy()
@Component({
  selector: 'atl-input-file-drag-drop',
  templateUrl: './input-file-drag-drop.component.html',
  styleUrls: ['./input-file-drag-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileDragDropComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileDragDropComponent implements ControlValueAccessor, OnChanges {
  inputAcceptString = '';

  @Input() set format(value: string | Array<string>) {
    if (typeof value === 'string') {
      this.inputAcceptString = value;
    } else {
      this.inputAcceptString = (value || []).filter((mType) => mType).join(', ');
    }
  }

  @Input() fileAsImagePreview = false;
  @Input() newestViewImage = false;
  @Input() asGallery = false;
  @Input() autoSave = false;
  @Input() disableDelete = false;
  @Input() disabled = false;
  @Input() fileCanOnlyDownloaded = false;
  @Input() icon = 'basket.svg';
  @Input() isOptional = false;
  @Input() label: string | undefined;
  @Input() maxUploadedFiles = Infinity;
  @Input() minUploadedFiles = Infinity;
  @Input() multiple = false;
  @Input() optionalText = 'Entity.Optional';
  @Input() removeIconSvg = 'assets/image_trash.svg';
  @Input() removeTitle = 'document';
  @Input() removeDescription!: string;
  @Input() scrollable = false;
  @Input() uploadIcon = '';
  @Input() confirmRemoveText: IConfirmRemove | null = null;
  @Input() monitoring = false;
  @Input() newVideo = false;
  @Input() uploadFileType = 'Shared.Entity.Upload_file';
  @Input() isRawIcon = false;
  @Input() showPreview = true;
  @Input() useImageCropping = false;

  @Input() set maxUploadedFilesSize(value: number) {
    if (value) {
      this._maxUploadedFilesSize = value;
    }
  }

  get maxUploadedFilesSize(): number {
    return this._maxUploadedFilesSize;
  }

  @Input() onlyNew = false;
  @Input() imagePreview = true;
  @Output() readonly deleteExistingFile = new EventEmitter<FileModel | ImageModel>();
  @Output() readonly addNewFilesToExisting = new EventEmitter<File[]>();
  @Output() readonly updateListOfPreview = new EventEmitter();
  @Output() readonly openPreview = new EventEmitter();
  @Output() readonly previewImageHandler = new EventEmitter();
  @Output() readonly fileDeletion = new EventEmitter<number>();
  @Output() readonly fileAddition = new EventEmitter<FilePreview>();
  @Output() readonly dragleaveHandle = new EventEmitter();

  @Output() updateFilePositionsEmitter = new EventEmitter<(FileModel | ImageModel | undefined)[]>();
  @ViewChild('uploadInput') uploadInput!: ElementRef<HTMLElement>;
  editFiles: (FileModel | ImageModel)[] = [];
  files: PositionedFile[] = [];
  // global max size in the project should not exceed 30 Mb
  _maxUploadedFilesSize = 30000000;

  isDraggedOver = false;

  public readonly truncateFileName = 8;
  public listOfPreview: FilePreview[] = [];
  // TODO will be removed after new API's will had been added

  draggableGallerySelectedIndex = 0;
  borderBlue = '1px solid var(--color-blue-1000)';
  borderGray = '1px solid var(--color-gray-750)';
  public loadingGallery = false;
  protected onChange!: (value: (PositionedFile | FileModel | ImageModel)[] | null | undefined) => void;

  private onTouched!: () => void;

  public readonly downloadIcon = newestDownloadIconAttachment;
  public readonly trashIcon = newestTrashIconAttachment;

  constructor(
    protected cdr: ChangeDetectorRef,
    private modalFacadeService: ModalFacadeService,
    protected toasterService: ToasterService,
    protected translateService: TranslateService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackByFn: (i: number, item: any) => number = (i: number, item: any): number => item.id;

  handleUpload(): void {
    if (this.disabled) {
      return;
    }
    this.uploadInput.nativeElement.click();
  }

  onLoad(): void {
    this.loadingGallery = false;
    this.cdr.detectChanges();
  }

  selectedFile(event: Event): void {
    if (event.target) {
      const target = event.target as HTMLInputElement;
      const files: File[] = Array.from(target.files as FileList);
      this.loadValidFiles(files);
      target.value = '';
      target.files = null;
    }
  }

  selectFilePreview(file: FileModel): void {
    this.openPreview.emit(file);
  }

  loadValidFiles(files: File[]): void {
    if (this.checkFileSize(files)) {
      this.fileLoadingPreprocessing(files);
    } else {
      const validFiles: File[] = files.filter((x) => x.size <= this.maxUploadedFilesSize);
      if (validFiles.length) {
        this.fileLoadingPreprocessing(validFiles);
      }
      this.toasterService.openErrorToast(this.translateService.instant('Shared.Entity.File_too_big'));
      this.dragleaveHandle.emit();
    }
  }

  private checkFileSize(files: File[]): boolean {
    return files.every((file) => file.size <= this.maxUploadedFilesSize);
  }

  handleDrop(event: DragEvent): void {
    this.stopDefault(event);
    this.isDraggedOver = false;
    if (event.dataTransfer?.files?.length) {
      const files: File[] = Array.from(event.dataTransfer.files);
      this.loadValidFiles(files);
    }
  }

  filterByFormatAndMaxLength(files: File[], format: string): File[] {
    const countFiles = files.length;
    const fileName: string[] = [];
    if (format) {
      files = files.filter((file) => {
        if (file.type && (format.includes(String(mime.getExtension(file.type))) || format.includes(file.type))) {
          return true;
        } else {
          const ext = file.name.split('.');
          if (format.includes(ext[ext.length - 1].toLowerCase())) {
            return true;
          } else {
            fileName.push(file.name);
            return false;
          }
        }
      });

      if (files.length !== countFiles) {
        this.toasterService.openErrorToast(
          `${fileName.join(', ')} ${this.translateService.instant('Shared.Entity.Format_not_supported')}`,
        );
        this.dragleaveHandle.emit();
      }
    }

    if (files.length > this.maxUploadedFiles) {
      this.toasterService.openErrorToast(
        this.translateService.instant('Shared.Attachment.Error.Max_upload_V2', {
          maxUpload: this.maxUploadedFiles,
        }),
      );
    }

    const maxUploadedFiles = this.maxUploadedFiles - this.editFiles?.length - this.files?.length;
    if (maxUploadedFiles <= 0) {
      return [];
    }
    return files.slice(0, maxUploadedFiles);
  }

  fileLoadingPreprocessing(files: File[]): void {
    const acceptableFiles: File[] = this.filterByFormatAndMaxLength(files, this.inputAcceptString);
    if (!acceptableFiles.length) {
      // TODO resolve if it need
      // circular dependency
      // this.toasterService.openErrorToast('Your file types is not acceptable');
    } else {
      this.saveFile(acceptableFiles);
    }
    if (files.length !== acceptableFiles.length) {
      // TODO resolve if it need
      // circular dependency
      // this.toasterService.openErrorToast('Some from your file types is not acceptable');
    }
  }

  // ToDO: this logic should be refactored
  saveFile(files: PositionedFile[]): void {
    this.files ||= [];

    if (this.maximumNumberOfFilesHasBeenReached()) {
      return;
    }
    const countOfMaxAcceptableFiles: number = Math.min(
      this.maxUploadedFiles - (this.editFiles?.length as number) - (this.files?.length as number),
      files.length,
    );
    if (countOfMaxAcceptableFiles <= 0) {
      return;
    }
    files = files.slice(0, countOfMaxAcceptableFiles);

    if (this.useImageCropping && files.length) {
      const file = files[0];
      if (file.type === pdfMime) {
        void extractFirstPdfPageAsImage(file).then((img) => {
          this.openCropModalWrapper(img);
        });
      } else {
        this.openCropModalWrapper(file);
      }
      return;
    }

    this.saveFileHandler(files);
  }

  private openCropModalWrapper(file: PositionedFile): void {
    this.openCropModal(file).then(
      () => null,
      (e) => this.cropModalResultHandler(e, file),
    );
  }

  private cropModalResultHandler(e: ICroppingData | unknown, file: PositionedFile): void {
    if (e === 'close' || e === 0 || e === 1) {
      return;
    }
    const croppingData = <ICroppingData>e;
    const croppedFile = new File([croppingData.image!], file.name, { type: file.type });
    this.saveFileHandler([<PositionedFile>croppedFile]);
  }

  private saveFileHandler(files: PositionedFile[]): void {
    files.forEach((file, index) => {
      const fakePosI = this.listOfPreview.length + index;
      const positionedFile = file;
      positionedFile.position = fakePosI;
      this.files?.push(positionedFile);
      if (!this.showPreview) return;
      this.onPreparationPreview(file).then(() => {
        this.fileAddition.emit(this.listOfPreview[this.listOfPreview.length - 1]);
      });
    });
    if (this.autoSave) {
      this.addNewFilesToExisting.emit(files);
    }
    if (!this.onlyNew) {
      this.onChange([...this.editFiles, ...this.files]);
    } else {
      this.onChange(this.files);
    }
  }

  private async openCropModal(file: PositionedFile | File): Promise<File> {
    const modalRef = this.modalFacadeService.openModal(ImageCropModalComponent, {
      modalDialogClass: 'wider-modal',
      windowClass: 'custom-modal-main centered-modal-crop-image',
    });
    const componentInstance = <ImageCropModalComponent>modalRef.componentInstance;
    componentInstance.originalImage = file;
    componentInstance.maintainAspectRatio = false;
    componentInstance.event = new Event('open');
    componentInstance.cropperPosition = undefined;
    componentInstance.newConfirmBtnPosition = true;
    componentInstance.confirmBtnText = 'Title.Save_changes';

    return modalRef.result;
  }

  handleDragOver(event: Event): void {
    this.stopDefault(event);
    this.isDraggedOver = true;
  }

  handleDragEnter(): void {
    this.isDraggedOver = true;
  }

  handleDragLeave(): void {
    this.isDraggedOver = false;
  }

  stopDefault(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  async setImagePreview(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(reader.result);
      };
    });
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: (FileModel | ImageModel)[] = []): void {
    if (!value?.length) {
      this.listOfPreview = [];
      this.files = [];
      this.editFiles = [];
      this.cdr.detectChanges();
      return;
    }
    this.listOfPreview = [];
    this.files = [];
    this.editFiles = value;
    this.editFiles.forEach((file: FileModel | ImageModel) => {
      if (file.type !== 'image' && file.type !== 'file') {
        this.onPreparationPreview(file as unknown as File).then(() => null);
      } else if (file.type === 'image') {
        this.listOfPreview.push({
          type: EFileType.Image,
          previewImage: file.fileName.url,
          previewImageW260: file.fileName.w260?.url || file.fileName.url,
          editFile: file,
        });
      } else {
        this.listOfPreview.push({
          type: EFileType.File,
          editFile: file,
        });
      }
    });
    this.cdr.detectChanges();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  checkImageType(file: File): boolean {
    return FileValidators.isImage(file);
  }

  removeFile(index: number, event: Event, editFile?: FileModel | ImageModel): void {
    this.stopDefault(event);
    if (this.confirmRemoveText) {
      this.confirmFileRemoval(index, editFile);
      return;
    }
    this.removingFile(index, editFile);
  }

  private confirmFileRemoval(index: number, editFile?: FileModel | ImageModel): void {
    const modalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    modalRef.componentInstance.title = this.translateService.instant(this.confirmRemoveText?.title as string);
    modalRef.componentInstance.description = this.translateService.instant(
      this.confirmRemoveText?.description as string,
    );
    modalRef.componentInstance.button.text = this.translateService.instant('Shared.Button.Yes_delete');
    return modalRef.componentInstance.passConfirm.pipe(take(1)).subscribe(() => {
      modalRef.close();
      this.removingFile(index, editFile);
    });
  }

  removingFile(index: number, editFile?: FileModel | ImageModel): void {
    if (editFile) {
      this.editFiles?.splice(index, 1);
      this.listOfPreview?.splice(index, 1);
      if (this.autoSave) {
        this.deleteExistingFile.emit(editFile);
      }
    } else {
      const foundIndex = this.files.findIndex((file) => file.position === index);
      this.editFiles?.splice(index, 1);
      this.files?.splice(foundIndex, 1);
      this.listOfPreview?.splice(index, 1);
    }
    this.onChange([...this.editFiles, ...this.files]);
    if (index === this.draggableGallerySelectedIndex) {
      this.draggableGallerySelectedIndex = Math.max(index - 1, 0);
    }
    this.fileDeletion.emit(index);
    this.cdr.detectChanges();
  }

  maximumNumberOfFilesHasBeenReached(): boolean {
    return this.listOfPreview?.length === this.maxUploadedFiles;
  }

  async onPreparationPreview(file: File): Promise<void> {
    if (this.checkImageType(file) && this.imagePreview) {
      const url = await this.setImagePreview(file);
      this.listOfPreview.push({
        type: EFileType.Image,
        previewImage: url,
        previewImageW260: url,
        file: file,
        isTemp: true,
        isAutoSave: this.autoSave,
      });
    } else {
      this.listOfPreview.push({
        type: EFileType.File,
        file: file,
        fileType: String(mime.getExtension((file as File).type)),
        isTemp: true,
        isAutoSave: this.autoSave,
      });
    }
    this.cdr.detectChanges();
    this.updateListOfPreview.emit();
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.listOfPreview, event.previousIndex, event.currentIndex);
    if (this.autoSave) {
      this.updateFilePositionsEmitter.emit(
        this.listOfPreview.map((f, i) => {
          if (f.editFile) {
            f.editFile.position = i + 1;
            return f.editFile;
          } else {
            if (f.file) {
              f.file.position = i + 1;
              return f.file as any;
            }
          }
        }),
      );
    }
  }

  ngOnChanges({ editFiles }: SimpleChanges): void {
    if (editFiles && editFiles.currentValue) {
      this.editFiles = JSON.parse(JSON.stringify(editFiles.currentValue));
    }
  }

  saveGallerySelectedIndex(i: number): void {
    if (this.draggableGallerySelectedIndex === i) {
      return;
    }
    this.loadingGallery = true;
    this.draggableGallerySelectedIndex = i;
  }

  public onPreviewImage(): void {
    this.previewImageHandler.emit();
  }
}
