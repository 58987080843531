import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  acceptedRawExtensions,
  DocumentModel,
  DocumentPreviewHelper,
  EFileType,
  FdvModel,
} from '@atlas-workspace/shared/models';
import {NgxDocViewerModule, viewerType} from 'ngx-doc-viewer';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CommonModule } from "@angular/common";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { FdvTreeMenuComponent, HeaderIconComponent } from "@atlas-workspace/shared/ui";
import { FileSizePipeModule } from "@atlas-workspace/shared/pipes";
import { SharedDirectivesModule } from "@atlas-workspace/shared/directives";

@Component({
  selector: 'atl-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedDirectivesModule,
    NgbTooltipModule,
    NgbDropdownModule,
    TranslateModule,
    NgxDocViewerModule,
    PdfViewerModule,
    HeaderIconComponent,
    FdvTreeMenuComponent,
    FileSizePipeModule
  ]
})
export class PreviewDocumentComponent {
  @Input() set selectedFile(item: FdvModel | DocumentModel) {
    this.document = item;
    this.initPreview();
  }
  @Input() isClosed = false;
  @Input() isMove = false;

  @Output() private readonly download = new EventEmitter<FdvModel | DocumentModel>();
  @Output() private readonly delete = new EventEmitter<FdvModel | DocumentModel>();
  @Output() private readonly copyLink = new EventEmitter<FdvModel | DocumentModel>();
  @Output() private readonly rename = new EventEmitter<FdvModel | DocumentModel>();
  @Output() private readonly move = new EventEmitter<FdvModel | DocumentModel>();
  @Output() private readonly closed = new EventEmitter();

  @HostBinding('class.fullscreenmode') get fullscreenmode(): boolean {
    return this.fullScreen;
  }

  public document!: FdvModel | DocumentModel;
  public fileType!: EFileType;
  public previewType: viewerType = 'google';
  public showDetail = false;
  public fullScreen = false;
  public preventImageTransition = true;
  public readonly fileTypes = EFileType;
  public readonly openTooltipDelay = 500;
  public readonly preventImageTransitionDelay = 100;
  public scaleImage = 100;
  public previewLoading = false;
  public readonly zoomItems = [10, 25, 50, 75, 100, 125, 150, 200];
  private readonly minZoom = 10;
  private readonly maxZoom = 200;
  public readonly pdfViewerType = 'pdf';

  constructor(private readonly modalHelpersService: ModalHelpersService) {}

  private initPreview(): void {
    const isExceptionFile = acceptedRawExtensions.includes(this.document.fileExtension as string);
    this.fileType = isExceptionFile
      ? EFileType.Unknown
      : this.modalHelpersService.checkFileType(this.document as unknown as DocumentModel);
    if (this.fileType === EFileType.File || this.fileType === EFileType.Image) {
      this.previewLoading = true;
      this.preventImageTransition = true;
    }
    if (this.fileType === EFileType.File && this.document?.fileExtension) {
      this.previewType = DocumentPreviewHelper.getDocumentViewerType(this.document.fileExtension);
    }
    this.scaleImage = 100;
  }

  detailHandler(): void {
    this.showDetail = !this.showDetail;
  }

  contentLoaded(): void {
    this.previewLoading = false;
    of(null)
      .pipe(delay(this.preventImageTransitionDelay))
      .subscribe(() => {
        this.preventImageTransition = false;
      });
  }

  zoomHandler(num: number): void {
    if ((this.scaleImage === this.minZoom && num < 0) || (this.scaleImage === this.maxZoom && num > 0)) return;
    this.scaleImage += num;
  }

  setZoom(num: number): void {
    this.scaleImage = num;
  }

  openFullScreenModal(): void {
    this.fullScreen = true;
  }

  closeModal(): void {
    this.fullScreen = false;
  }

  deleteHandler(document: FdvModel | DocumentModel): void {
    this.delete.emit(document);
  }

  downloadHandler(document: FdvModel | DocumentModel): void {
    this.download.emit(document);
  }

  copyHandler(document: FdvModel | DocumentModel): void {
    this.copyLink.emit(document);
  }

  renameHandler(document: FdvModel | DocumentModel): void {
    this.rename.emit(document);
  }

  moveHandler(document: FdvModel | DocumentModel): void {
    this.move.emit(document);
  }

  closeHandler(): void {
    this.closed.emit();
  }
}
