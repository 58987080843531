<header role="banner">
  <span>{{ 'Threads.Messages_recipients' | translate }}</span>
  <atl-header-icon
    icon="/assets/unit_close.svg"
    (click)="modalRef.close()"
    [ngbTooltip]="'Alt.Close' | translate"
    [openDelay]="tooltipOpenDelay"
    triggers="hover touchstart"
  ></atl-header-icon>
</header>

<section class="thread-recipients">
  <div class="d-flex justify-content-between align-items-center">
    <span class="fz-20 lh-32 fw-500">{{ 'Title.Recipients' | translate }}</span>
    <atl-newest-input
      *ngIf="units?.length || (!units?.length && searchParam)"
      class="thread-recipients__search icon-search-black"
      [formControl]="search"
      [placeholder]="'Input.Search.Placeholder_without_dots'"
      [clearable]="true"
    ></atl-newest-input>
  </div>

  <ng-container *ngIf="units?.length || (units?.length && searchParam); else noSearchResult">
    <atl-shared-table
      class="gl-shared-table--lv thread-recipients__table"
      [bodyOverflow]="true"
      [scrollToTop$]="$scrollToTop"
      [scrollToTopOnTbody]="false"
    >
      <ng-container ngProjectAs="shared-table-header">
        <th
          [atlColumnDef]="'identifier'"
          atl-sort-header
          (sortEvent)="sort($event)"
          [sortParam]="sortParam"
          class="shared-table__header-cell w50"
        >
          <atl-titled-checkbox
            class="newest-checkbox ml-12 mr-12"
            [checkedPartially]="units?.length !== selectedUnits.length"
            (changeEvent)="checkAll($event)"
            [ngModel]="!!selectedUnits.length"
          ></atl-titled-checkbox>
          <span>{{ 'Input.Create_Unit.Unit.Title' | translate }}</span>
        </th>
        <th
          [atlColumnDef]="'buyer'"
          atl-sort-header
          (sortEvent)="sort($event)"
          [sortParam]="sortParam"
          class="shared-table__header-cell w50"
        >
          <span>{{ 'Entity.Owner' | translate }}</span>
        </th>
      </ng-container>
      <ng-container ngProjectAs="shared-table-body">
        <ng-container *ngFor="let row of units; let last = last">
          <tr
            class="shared-table__body-row cursor-pointer"
            (click)="checkRow(row)"
            (atlInfiniteScroll)="getNextChunk(last)"
            [threshold]="0.9"
            [class.selected]="row.selected"
          >
            <td class="shared-table__body-cell ellipsis">
              <div class="d-inline-flex align-items-center w-100">
                <atl-titled-checkbox
                  class="mr-12"
                  [value]="row.selected"
                  (changeEvent)="checkRow(row)"
                ></atl-titled-checkbox>
                <span
                  #identifier
                  class="ellipsis"
                  placement="top"
                  [ngbTooltip]="row.identifier"
                  [disableTooltip]="identifier.offsetWidth >= identifier.scrollWidth"
                  [openDelay]="tooltipOpenDelay"
                  triggers="hover touchstart"
                  >{{ row.identifier }}</span
                >
              </div>
            </td>
            <td class="shared-table__body-cell ellipsis">
              <div *ngIf="row.mainBuyer" class="d-inline-flex align-items-center fz-14 lh-24 pl-8 text-black-960 w-100">
                <atl-firm-icon
                  [isUser]="true"
                  [model]="row.mainBuyer"
                  nameKey="name"
                  [isMonochrome]="true"
                ></atl-firm-icon>
                <div
                  class="ellipsis ml-8"
                  #mainBuyer
                  placement="top"
                  [ngbTooltip]="row.mainBuyer?.name"
                  [disableTooltip]="mainBuyer.offsetWidth >= mainBuyer.scrollWidth"
                  [openDelay]="tooltipOpenDelay"
                  triggers="hover touchstart"
                >
                  <span>{{ row.mainBuyer?.name }}</span>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </atl-shared-table>
  </ng-container>
</section>

<footer>
  <button class="newest-button button-gray" (click)="modalRef.close()">{{ 'Shared.Button.Cancel' | translate }}</button>
  <button class="newest-button ml-16" (click)="saveChanges()" [disabled]="selectedUnits.length < 2">
    {{ 'Shared.Title.Save_changes' | translate }}
  </button>
</footer>

<ng-template #noSearchResult>
  <atl-not-found *ngIf="searchParam">
    {{ 'Entity.No_matches_for' | translate }} "{{ searchParam | truncate: truncate }}"
  </atl-not-found>
</ng-template>
