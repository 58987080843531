import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {EMixpanelEvent} from '@atlas-workspace/shared/models';
import { Observable } from 'rxjs';
import {AuthAdminService, MixpanelService} from '@atlas-workspace/shared/service';

@Injectable()
export class AuthAdminGuard  {
  constructor(private authService: AuthAdminService, private router: Router, private mixpanelService: MixpanelService) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.validateAuthHeaders()) {
      void this.router.navigate(['/auth/sign-in']);
      this.mixpanelService.track(EMixpanelEvent.SingOut, {}, false);
      return false;
    } else if (!this.authService.validateAuthFirm()) {
      void this.router.navigate(['/auth/select-firm']);
      return false;
    }

    return true;
  }
}

/*
 *
 * */
