import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReclamationCategoryModel, ReclamationCategoryTypeModel, ReclamationsModel, TReclamationsModelFormGroup } from '@atlas-workspace/shared/models';
import { ReclamationAdminService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { debounceTime, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'atl-reclamation-messages-info',
  templateUrl: './reclamation-messages-info.component.html',
  styleUrls: ['./reclamation-messages-info.component.scss']
})
export class ReclamationMessagesInfoComponent implements OnInit {
  @Input() private readonly projectId!: number;
  @Input() public reclamation!: ReclamationsModel;
  @Input() public isContractor = false;
  public expanded = false;
  public form!: FormGroup;
  public categories: ReclamationCategoryModel[] = [];
  public types: ReclamationCategoryTypeModel[] = [];
  public firstCategoryLoad = true;

  private readonly debounceTime = 500;

  constructor (
    private readonly fb: FormBuilder,
    private readonly reclamationService: ReclamationAdminService,
  ) {}

  ngOnInit(): void {
    this.getCategories();
    if (this.reclamation.type) this.getTypes(this.reclamation.type.category.id)
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group(<TReclamationsModelFormGroup>{
      description: [
        {
          value: this.reclamation.description || '',
          disabled: this.isContractor || this.reclamation.archived,
        },
        Validators.maxLength(500),
      ],
      category: [
        {
          value: this.reclamation.type?.category ? [this.reclamation.type.category] : [],
          disabled: this.reclamation?.archived || this.isContractor,
        },
        [Validators.required],
      ],
      type: [
        {
          value: this.reclamation.type ? [this.reclamation.type] : [],
          disabled: this.isContractor || this.reclamation.archived,
        },
        [Validators.required],
      ],
    });

    this.initFormListeners();
  }

  private initFormListeners(): void {
    this.form
      .get('category')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((v: any[]) => {
        const reclamationCopy = cloneDeep(this.reclamation);
        if (reclamationCopy && reclamationCopy.type?.category && v.length) {
          reclamationCopy.type.category.name = v[0].name;
        }

        if (v.length) {
          this.getTypes(v[0].id);
          if (this.isContractor) {
            this.form.get('type')?.disable({
              onlySelf: true,
              emitEvent: false,
            });
          } else {
            this.form.get('type')?.enable({
              onlySelf: true,
              emitEvent: false,
            });
          }

          this.form.get('type')?.setValue([], { emitEvent: false, onlySelf: true });
        } else {
          if (this.firstCategoryLoad) {
            this.firstCategoryLoad = false;
          }
          this.form.get('type')?.disable({
            onlySelf: true,
            emitEvent: false,
          });
          this.types = [];
          this.form.get('type')?.setValue([], { onlySelf: true, emitEvent: false });

          if (this.form.get('description')?.value.length) {
            this.updateReclamation();
          }
        }
      });

    this.form
      .get('type')
      ?.valueChanges.pipe(untilDestroyed(this), debounceTime(this.debounceTime))
      .subscribe((value: string) => {
        if (value) {
          this.updateReclamation();
        }
      });
    this.form
      .get('description')
      ?.valueChanges.pipe(untilDestroyed(this), debounceTime(this.debounceTime))
      .subscribe((value: string) => {
        if (value) {
          this.form.get('category')?.setValidators([]);
          this.form.get('type')?.setValidators([]);
        } else {
          this.form.get('category')?.setValidators([Validators.required]);
          this.form.get('type')?.setValidators([Validators.required]);
        }
      });
  }

  public toggle(): void {
    this.expanded = !this.expanded;
  }

  private getCategories(): void {
    this.reclamationService
      .getCategories()
      .pipe(untilDestroyed(this))
      .subscribe((categories: ReclamationCategoryModel[]) => {
        this.categories = categories;
      });
  }

  private getTypes(categoryId: number): void {
    this.reclamationService
      .getTypes(categoryId)
      .pipe(untilDestroyed(this))
      .subscribe((types: ReclamationCategoryTypeModel[]) => {
        this.types = types;
        this.firstCategoryLoad = false;
      });
  }

  public onRemoveSelectedItem(control: string, item: any, compareProp = 'id'): void {
    const items: any[] = this.form.get(control)?.value;
    const foundIndex = items.findIndex((a) => a[compareProp] === item[compareProp]);
    if (foundIndex !== -1) {
      items.splice(foundIndex, 1);
      this.form.get(control)?.setValue([...items]);
    }
  }

  public updateReclamation(): void {
    if (this.reclamation?.archived) return;
    this.reclamationService
      .updateProjectReclamation(this.projectId.toString(), this.reclamation.id, this.createFormData())
      .pipe(take(1))
      .subscribe((reclamation: ReclamationsModel) => {
        this.reclamationService.setUpdateReclamation(reclamation);
        this.reclamation = reclamation;
      });
  }

  private createFormData(): FormData {
    const formValue: Partial<TReclamationsModelFormGroup> = this.form.getRawValue();

    const body = new FormData();

    body.append('reclamation[description]', formValue.description);
    body.append('reclamation[type_id]', formValue.type[0]?.id || '');

    return body;
  }

}
