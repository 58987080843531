import {CommonModule, DatePipe, DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  Attribute,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  HostListener,
  Inject, Input, Output,
  ViewChild
} from '@angular/core';
import {FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClickOutsideDirective} from '@atlas-workspace/shared/directives';
import {IDataTime, ISelectTime} from '@atlas-workspace/shared/models';
import {
  NgbDate, NgbDatepicker,
  NgbDatepickerModule,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {PlacementArray} from "@ng-bootstrap/ng-bootstrap/util/positioning";
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import {of} from 'rxjs';
import {delay, take} from 'rxjs/operators';

import {DateAdapterService} from '../../services/date-adapter.service';
import { InputComponent } from '../input/input.component';
import {InputDateTimePickerComponent} from '../input-date-time-picker/input-date-time-picker.component';
import { TimeSelectComponent } from '../time-select/time-select.component';
import {getTimeString} from './helpers/data-picker.helper';

@Component({
  selector: 'atl-meeting-data-picker',
  templateUrl: './meeting-data-picker.component.html',
  styleUrls: ['./meeting-data-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MeetingDataPickerComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgxMaskDirective,
    ClickOutsideDirective,
    NgbDatepickerModule,
    InputComponent,
    TimeSelectComponent,
    NgbTooltipModule,
  ]
})
export class MeetingDataPickerComponent extends InputDateTimePickerComponent implements AfterViewInit {
  private currentDate!: string;
  private changeValidation = false;

  public selectTime?: ISelectTime  = {
    from: {hour: 8, min: 0},
    to: {hour: 9, min: 0}
  };
  public dateInputError = false;
  public readonly inputPlaceholder = 'Shared.Date_picker.Input.Placeholder';
  public selectDate?: NgbDateStruct;

  @Input() readonly index: string | number = '';
  @Input() readonly singleTime = false;
  @Input() readonly tooltipMsg = 'Shared.Date_picker.Tooltip';
  @Input() initMinDate = true;
  @Input() clearButtonLabel = 'Shared.Entity.Clear_all';
  @Input() timeIsCurrentDate = true;
  @Input() useAmPm: boolean = false;

  @Output() readonly onresetPicker = new EventEmitter<void>();
  @Output() readonly onsetDate = new EventEmitter<void>();
  @Input() inputButton = false;
  @Input() inputButtonTitle = 'Shared.Title.Advanced_settings';
  @Input() placement: PlacementArray = ['bottom-start', 'top-start', 'left'];
  @Input() container: 'body' | null = 'body';
  @Input() customClass = '';
  @Input() hasLocked = false;

  @Output() inputButtonHandler = new EventEmitter();

  @ViewChild('pickerWrapper', {read: ElementRef}) pickerWrapper!: ElementRef;
  @ViewChild('datepicker', {static: true}) inputDatePicker!: NgbInputDatepicker;
  @ViewChild('dp', { static: false }) datepicker!: NgbDatepicker;
  @ViewChild('atlInput', { static: true }) atlInput!: InputComponent;

  @HostListener('document:keyup.tab', ['$event'])
  @HostListener('document:keyup.shift.tab', ['$event'])
  handleOnTab(e: KeyboardEvent): void {
    if (!this.pickerWrapper.nativeElement.contains(e.target)) {
      this.hidePicker();
    }
  }

  constructor(
    @Attribute('tabindex') readonly tabindex: string,
    @Inject(DOCUMENT) readonly document: Document,
    public cdr: ChangeDetectorRef,
    public dateAdapter: DateAdapterService,
    private datePipe: DatePipe,
    public el: ElementRef,
  ) {
    super(cdr, dateAdapter);
  }

  onInputButtonHandler(e: Event): void {
    e.stopPropagation();
    this.inputButtonHandler.emit();
  }

  onTabHandler(e: KeyboardEvent, dir: 0 | 1): void {
    if (!this.tabindex) {
      return;
    }
    const formElement = this.document.querySelector<HTMLFormElement>(
      `[tabindex="${Number(this.tabindex) + (dir ? 1 : -1)}"] input`
    );
    if (formElement) {
      e.preventDefault();
      !formElement || formElement?.focus();
    }
  }

  ngAfterViewInit(): void {
    this.currentDate = this.dateAdapter.currentDate();
    if (!this.initMinDate) return;
    of([])
      .pipe(take(1), delay(0))
      .subscribe(() => this.min = this.dateAdapter.fromModel(this.currentDate) as NgbDateStruct);
    this.cdr.markForCheck();
  }

  get inputTitle(): string {
    if (this.inputValue?.length !== 10) return '';
    const from = this.selectTime?.from ? `${getTimeString(this.selectTime.from)}` : '';
    const to = this.selectTime?.to ? ` - ${getTimeString(this.selectTime.to)}` : '';
    const date = this.dateAdapter.convertToFormat(this.inputValue, 'DD.MM.YYYY', 'YYYY-MM-DD');
    if (this.singleTime) {
      if (this.useAmPm && this.selectTime?.from) {
        const hour= this.selectTime.from.hour!;
        const period = hour >= 12 ? 'PM' : 'AM';
        const timeBasedOnPeriod = getTimeString({ hour: hour % 12 || 12, min: this.selectTime?.from.min })
        return this.inputValue ? `${this.inputValue} · ${timeBasedOnPeriod} ${period}` : '';
      }
      return this.inputValue ? `${this.inputValue} · ${from}` : '';
    }
    return this.inputValue ? `${this.datePipe.transform(date, 'd MMM')} ${from}${to}` : '';
  }

  public showPicker(): void {
    this.inputDatePicker.toggle();
    this.cdr.detectChanges();
  }

  public setDate(): void {
    this.showPicker();
    this.onsetDate.emit()
  }

  pickerHandler(): void {
    if (!this.lockedTrigger.focused) return;
    this.onTouched();
  }

  public changeTine(time: ISelectTime): void {
    this.selectTime = time;
    if (this.inputValue) {
      this.updateFormValue(this.inputValue);
    }
  }

  changeValue(value: string): void {
    this.inputValue = value;
    this.updateFormValue(value);
  }

  clearValue(): void {
    if (this.disabled) return;
    this.inputValue = '';
    this.selectTime = undefined;
    this.updateFormValue(this.inputValue);
    this.hidePicker();
    this.onresetPicker.emit();
  }

  // @ts-ignore
  writeValue(value: IDataTime | null): void {
    if (!value) return;
    this.inputValue = this.dateAdapter.toModel(value.from);
    this.setTime(value);
    this.showSpecificCalendar();

    if (this.inputValue) {
      this.selectDate = this.dateAdapter.fromModel(this.inputValue || null) as NgbDate;
      this.datepicker?.focusDate(this.selectDate);
    }
    this.cdr.detectChanges();
  }

  public setTime(value: IDataTime): void {
    this.selectTime = {
      from: {hour: value.from.hour, min: value.from.min},
      to: {hour: value?.to?.hour, min: value?.to?.min}
    };
    this.cdr.detectChanges();
  }

  public inputDate(value: string): void {
    if (!value) {
      this.dateInputError = false;
    }
    const initDate = this.dateAdapter.fromModel(value)
    if (initDate && value !== this.dateAdapter.toModel(initDate)) return;
    if (initDate?.year && initDate?.month && initDate?.day) {
      this.selectedDate(initDate);
    }
  }

  public selectedDate(initDate: NgbDateStruct): void {
    this.selectDate = initDate;
    if (!initDate) return;
    const simpleDate = this.dateAdapter.toModel(initDate);

    if (this.dateAdapter.isBeforeDate(this.currentDate, simpleDate)) {
      this.changeValue(simpleDate);
      this.onTouched();
      this.dateInputError = false;
    } else {
      this.inputValue = simpleDate;
      this.dateInputError = true;
      this.changeValue(this.inputValue);
    }
  }

  validateShowPicker(): void {
    if (this.changeValidation && !this.dateInputError) {
      this.hidePicker();
    }
  }

  protected updateFormValue(date: string): void {
    if (date) {
      const modelValue = this.dateAdapter.fromModel(date);
      if (!modelValue?.day || !modelValue?.month || !modelValue?.year) {
        this.onChange(null);
      } else {
        this.datepicker.navigateTo(modelValue);
        this.datepicker.focusDate(modelValue);
        if (this.selectTime && modelValue) {
          this.changeValidation = true;
          this.onChange({
          // @ts-ignore
            from: {...modelValue, ...this.selectTime.from},
          // @ts-ignore
            to: {...modelValue, ...(this.singleTime ? this.selectTime.from : this.selectTime.to)}
          });
        } else {
          this.changeValidation = false;
          this.onChange(null);
        }
      }
    } else {
      this.changeValidation = false;
      this.onChange(null);
    }
  }

  onBlur(): void {
    this.onTouched();
    of(null).pipe(take(1), delay(0)).subscribe(() => {
      this.atlInput.inputElement?.nativeElement.blur();
    });
  }
}
