import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IImgFile, ImageModel } from '@atlas-workspace/shared/models';
import { AuthAdminService } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IllustrationsService {
  constructor(private http: HttpClient, private authAdminService: AuthAdminService) {}

  public getIllustrations(projectId: string): Observable<ImageModel[]> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    return this.http
      .get<{ data: { visuals: IImgFile[] } }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/visuals`, {
        params: params,
      })
      .pipe(
        map((res) => res.data.visuals),
        map((data) => plainToClass(ImageModel, data))
      );
  }

  public getIllustrationsState(projectId: string): Observable<{ data: { visuals: IImgFile[] } }> {
    return this.http.get<{ data: { visuals: IImgFile[] } }>(
      `${environment.apiBaseUrl}api/v1/projects/${projectId}/visuals`
    );
  }

  public uploadVisual(projectId: string, visual: FormData): Observable<ImageModel> {
    return this.http.post(`${environment.apiBaseUrl}api/v1/projects/${projectId}/visuals`, visual).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ImageModel, data))
    );
  }

  public deleteVisual(projectId: string, visualId: number): Observable<unknown> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    return this.http.delete(`${environment.apiBaseUrl}api/v1/projects/${projectId}/visuals/${visualId}`, {
      params: params,
    });
  }

  public updateVisualPosition(
    projectId: string,
    visualId: number,
    value: { visual: { position: number } }
  ): Observable<ImageModel> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    return this.http
      .put(`${environment.apiBaseUrl}api/v1/projects/${projectId}/visuals/${visualId}`, value, {
        params: params,
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ImageModel, data))
      );
  }
}
