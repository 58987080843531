import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AutoResizeModule } from '@atlas-workspace/shared/ui';

import { CommentTextAreaComponent } from './comment-textarea.component';

@NgModule({
  declarations: [CommentTextAreaComponent],
  exports: [CommentTextAreaComponent],
  imports: [CommonModule, AutoResizeModule, SharedDirectivesModule],
})
export class CommentTextAreaModule {}
