import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ELocalStorageKeys } from '@atlas-workspace/shared/models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LocalStorageService, OnboardingService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-project-modal',
  templateUrl: './project-onboarding-modal.component.html',
  styleUrls: ['./project-onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectOnboardingModalComponent implements OnInit {
  @Input() public step = 1;
  @Input() public contractorOnboarding = false;
  @Input() hideProjectHealthStep = false;

  public totalSteps = 4;

  constructor(
    private readonly ngbActiveModal: NgbActiveModal,
    private readonly localStorageService: LocalStorageService,
    private readonly onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    if (this.contractorOnboarding) {
      this.totalSteps = 2;
    }

    if (this.hideProjectHealthStep) {
      --this.totalSteps;
    }
  }

  public nextStep(): void {
    this.step++;
    this.saveStepToStorage();
    this.onboardingService.setCurrentProjectStep(this.step);
  }

  public prevStep(): void {
    this.step--;
    this.saveStepToStorage();
    this.onboardingService.setCurrentProjectStep(this.step);
  }

  public skipAllSteps(): void {
    this.ngbActiveModal.close();
    this.onboardingService.setCompleteProjectOnboarding();
    this.localStorageService.remove(ELocalStorageKeys.ProjectOnboardingStep);
  }

  private saveStepToStorage(): void {
    this.localStorageService.set(ELocalStorageKeys.ProjectOnboardingStep, this.step);
  }
}
