import { Injectable } from '@angular/core';
import { AdminPracticalInfoModal, ImmutableSelector, ITablePagination } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import {
  AddPractical,
  PracticalSetPagination,
  PracticalSetTotalFirstLoading,
  RemovePracticals,
  SetPractical,
  SetPracticals,
} from './practicals.action';
import { PracticalStateModel } from './practicals.model';

@State({
  name: 'practicalsState',
  defaults: defaultState.practicalsState,
})
@Injectable()
export class PracticalsState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: PracticalStateModel): PracticalStateModel {
    return state;
  }

  @Selector()
  static selectPagination(state: PracticalStateModel): ITablePagination {
    return state.pagination;
  }

  @Action(SetPracticals)
  setPracticals(ctx: StateContext<PracticalStateModel>, { practicals }: SetPracticals): void {
    ctx.setState(patch<PracticalStateModel>({ practicals }));
  }

  @Action(SetPractical)
  setPractical(ctx: StateContext<PracticalStateModel>, { practical }: SetPractical): void {
    ctx.setState(
      patch<PracticalStateModel>({
        practicals: updateItem<AdminPracticalInfoModal>((u) => u?.id === practical.id, practical),
      })
    );
  }

  @Action(AddPractical)
  addPractical(ctx: StateContext<PracticalStateModel>, { practical }: AddPractical): void {
    const state = ctx.getState();
    ctx.setState(
      patch<PracticalStateModel>({
        practicals: insertItem<AdminPracticalInfoModal>(practical, 0),
        pagination: patch<ITablePagination>({
          totalCount: state.pagination.totalCount + 1,
        }),
        totalCount: state.totalCount + 1,
      })
    );
  }

  @Action(PracticalSetPagination)
  setPagination(ctx: StateContext<PracticalStateModel>, { pagination }: PracticalSetPagination): void {
    ctx.setState(patch<PracticalStateModel>({ pagination }));
  }

  @Action(PracticalSetTotalFirstLoading)
  setTotalFirstLoading(
    ctx: StateContext<PracticalStateModel>,
    { firstLoad, totalCount }: PracticalSetTotalFirstLoading
  ): void {
    ctx.setState(patch<PracticalStateModel>({ firstLoad, totalCount }));
  }

  @Action(RemovePracticals)
  @ImmutableSelector()
  removePracticals(ctx: StateContext<PracticalStateModel>, { ids }: RemovePracticals): void {
    const state = ctx.getState();
    const practicals = state.practicals?.filter((u) => !ids.some((id) => u.id === id));
    ctx.setState(
      patch<PracticalStateModel>({
        practicals,
        totalCount: state.totalCount - ids.length,
      })
    );
  }
}
