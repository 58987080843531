import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ETimeFormat } from '@atl-libs-shared-pipes/time-passed-ago/interfaces/time-passed-ago';
import { ThreadDraftModel } from '@atlas-workspace/shared/models';

import deleteSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/threads/delete.svg';

@Component({
  selector: 'atl-list-of-threads-draft-item',
  templateUrl: './list-of-threads-draft-item.component.html',
  styleUrls: ['./list-of-threads-draft-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOfThreadsDraftItemComponent {

  @Input() set threadData(value: ThreadDraftModel) {
    this.thread = value;
  }
  @Input() public currentUser!: { [key: string]: string | number };
  @Input() public first!: boolean;
  @Input() public activeThreadId: number | null = null;

  @Output() public readonly openDraftEvent = new EventEmitter<ThreadDraftModel>();
  @Output() public readonly removeDraftEvent = new EventEmitter<number>();

  public passedTimeFormats = ETimeFormat;
  public thread!: ThreadDraftModel;

  public readonly dotDivider = ' · ';
  public readonly tooltipOpenDelay = 500;
  public readonly deleteSvg = deleteSvg;

  public openDraftThreadModal(thread: ThreadDraftModel): void {
    this.openDraftEvent.emit(thread);
  }

  public openRemoveDraft(id: number): void {
    this.removeDraftEvent.emit(id);
  }

}
