import { AdminPracticalInfoModal, ITablePagination } from '@atlas-workspace/shared/models';

export class SetPracticals {
  static readonly type = '[Practical-Info] save change practical-Info local';
  constructor(public practicals: AdminPracticalInfoModal[]) {}
}

export class SetPractical {
  static readonly type = '[Practical-Info] update change practical-Info local';
  constructor(public practical: AdminPracticalInfoModal) {}
}

export class AddPractical {
  static readonly type = '[Practical-Info] add practical-Info local';
  constructor(public practical: AdminPracticalInfoModal) {}
}

export class RemovePracticals {
  static readonly type = '[Practical-Info] remove practical-Info local';
  constructor(public ids: number[]) {}
}

export class PracticalSetPagination {
  static readonly type = '[Practical-Info] set pagination';
  constructor(public pagination: ITablePagination) {}
}

export class PracticalSetTotalFirstLoading {
  static readonly type = '[Practical-Info] set total first loading';
  constructor(public firstLoad: boolean, public totalCount: number) {}
}
