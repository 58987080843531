import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';

import { ReclamationClientMessagesComponent } from './reclamation-client-messages.component';

@NgModule({
  declarations: [ReclamationClientMessagesComponent],
  exports: [ReclamationClientMessagesComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedDirectivesModule],
})
export class ReclamationClientMessagesModule {}
