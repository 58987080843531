import {Injectable} from '@angular/core';
import {ImmutableSelector} from '@atlas-workspace/shared/models';
import {Action, Selector, State, StateContext} from '@ngxs/store';

import {defaultState} from '../default-state';
import {SetAnalytics} from './analytics.action';
import {AnalyticsStateModel} from './analytics.model';

@State({
  name: 'analyticsState',
  defaults: defaultState.analyticsState
})
@Injectable()
export class AnalyticsState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: AnalyticsStateModel): AnalyticsStateModel {
    return state;
  }

  @Action(SetAnalytics)
  setAnalytics(ctx: StateContext<AnalyticsStateModel>, {analytics}: SetAnalytics): void {
    ctx.setState({analytics});
  }
}
