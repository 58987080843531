<div
  class="modal-arrow"
  [ngClass]="{
    'modal-arrow-left': step === 3,
    'modal-arrow-right': step === totalSteps,
    'modal-arrow-right--nav': step === totalSteps && contractorOnboarding,
    'modal-arrow-right--contractor': step === totalSteps && contractorOnboarding
  }"
></div>
<div class="onboarding" tabindex="0">
  <ng-container *ngIf="!contractorOnboarding; else contractorOnboardingBlock">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="1">
        <img src="/assets/project-header-icon.svg" alt="Icon" />
        <h3 class="fz-16 fw-500 mt-16 lh-24">{{ 'Title.Project_header' | translate }}</h3>

        <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Project_page' | translate }}</div>
        <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
          <ng-container [ngTemplateOutlet]="currentStep"></ng-container>
          <button (click)="nextStep()" class="newest-button h-px-32 fz-14 fw-500 next-button">
            {{ 'Title.Next' | translate }}
          </button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <img src="/assets/star-icon.svg" alt="Icon" />
        <h3 class="fz-16 fw-500 mt-16 lh-24">{{ 'Title.Favourite_project' | translate }}</h3>

        <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Favourite_project' | translate }}</div>
        <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
          <ng-container [ngTemplateOutlet]="currentStep"></ng-container>
          <ng-container [ngTemplateOutlet]="navSteps"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="3">
        <img src="/assets/project-settings-icon.svg" alt="Icon" />
        <h3 class="fz-16 fw-500 mt-16 lh-24">{{ 'Title.Project_settings' | translate }}</h3>

        <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Project_settings' | translate }}</div>
        <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
          <ng-container [ngTemplateOutlet]="currentStep"></ng-container>
          <ng-container *ngIf="step < totalSteps; else navStepsDone">
            <ng-container [ngTemplateOutlet]="navSteps"></ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="4">
        <img src="/assets/heart-icon.svg" alt="Icon" />
        <h3 class="fz-16 fw-500 mt-16 lh-24">{{ 'Entity.Project_health' | translate }}</h3>

        <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Project_health' | translate }}</div>
        <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
          <ng-container [ngTemplateOutlet]="currentStep"></ng-container>
          <ng-container [ngTemplateOutlet]="navStepsDone"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #currentStep>
  <p class="fz-14 lh-24 text-black-810 lh-24">
    {{ step }} {{ 'Entity.Of' | translate }} {{ totalSteps }}
    <ng-container *ngIf="step !== totalSteps">
      ·
      <span (click)="skipAllSteps()" class="cursor-pointer underline">{{ 'Title.Skip_all' | translate }}</span>
    </ng-container>
  </p>
</ng-template>

<ng-template #navStepsDone>
  <div class="d-inline-flex">
    <button (click)="prevStep()" class="newest-button newest-button--outline h-px-32 mr-8 fz-14 fw-500">
      {{ 'Title.Previous' | translate }}
    </button>
    <button (click)="skipAllSteps()" class="newest-button h-px-32 fz-14 fw-500 next-button">
      {{ 'Shared.Button.Done' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #navSteps>
  <div class="d-inline-flex">
    <button (click)="prevStep()" class="newest-button newest-button--outline h-px-32 mr-8 fz-14 fw-500">
      {{ 'Title.Previous' | translate }}
    </button>
    <button (click)="nextStep()" class="newest-button h-px-32 fz-14 fw-500 next-button">
      {{ 'Title.Next' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #contractorOnboardingBlock>
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="1">
      <img src="/assets/project-header-icon.svg" alt="Icon" />
      <h3 class="fz-16 fw-500 mt-16 lh-24">{{ 'Title.Project_header' | translate }}</h3>

      <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Project_page' | translate }}</div>
      <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
        <ng-container [ngTemplateOutlet]="currentStep"></ng-container>
        <button (click)="nextStep()" class="newest-button h-px-32 fz-14 fw-500 next-button">
          {{ 'Title.Next' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="2">
      <img src="/assets/star-icon.svg" alt="Icon" />
      <h3 class="fz-16 fw-500 mt-16 lh-24">{{ 'Title.Favourite_project' | translate }}</h3>

      <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Favourite_project' | translate }}</div>
      <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
        <ng-container [ngTemplateOutlet]="currentStep"></ng-container>
        <ng-container *ngIf="contractorOnboarding; else normalUser">
          <ng-container [ngTemplateOutlet]="navStepsDone"></ng-container>
        </ng-container>
        <ng-template #normalUser>
          <ng-container [ngTemplateOutlet]="navSteps"></ng-container>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="3">
      <img src="/assets/heart-icon.svg" alt="Icon" />
      <h3 class="fz-16 fw-500 mt-16 lh-24">{{ 'Entity.Project_health' | translate }}</h3>

      <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Project_health' | translate }}</div>
      <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
        <ng-container [ngTemplateOutlet]="currentStep"></ng-container>
        <ng-container [ngTemplateOutlet]="navStepsDone"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
