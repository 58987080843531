import dayjs from 'dayjs';

export enum EMeetingAdminTab {
  General = 'Shared.Button.Details',
  Protocol = 'Entity.Protocol',
}

export enum EContractorHeaderTab {
  Members = 'Shared.Entity.Project_members',
  Contractors = 'Shared.Entity.Contractors',
}

export const meetingAdminItemsMenu = [
  {
    name: EMeetingAdminTab.General,
    id: 'cy-unit-tab-general',
    disabled: false,
    route: '',
  },
  {
    name: EMeetingAdminTab.Protocol,
    id: 'cy-meeting-detail-protocol',
    disabled: false,
    route: '',
    tooltip: 'Entity.Coming_soon',
    isShowTooltip: false,
  },
];

export const contractorHeaderMenu = [
  {
    name: EContractorHeaderTab.Members,
    disabled: false,
  },
  {
    name: EContractorHeaderTab.Contractors,
    disabled: false,
  },
];

export const tableModel = {
  title: {
    column: 'title',
    name: 'Entity.Meeting_name',
  },
  unit: {
    column: 'unit_identifier',
    name: 'Entity.Unit',
  },
  type: {
    column: 'meeting_type',
    name: 'Entity.Type',
  },
  status: {
    column: 'status',
    name: 'Entity.Status',
  },
  date: {
    column: 'date',
    name: 'Entity.Date_and_time',
  },
  assignee: {
    column: 'assignee',
    name: 'Entity.Assignee',
  },
  protocol: {
    column: 'protocols_template',
    name: 'Entity.Protocol',
  },
};

export const meetingModalInputs = {
  meetingTypeInput: {
    title: 'MeetingModal.meetingTypeInput.Title',
    placeholder: 'MeetingModal.meetingTypeInput.Placeholder',
    bindLabel: 'name',
    bindValue: 'id',
    customItemView: true,
    customLabelView: true,
    clearable: false,
    customNotFoundView: true,
    searchParams: ['name'],
  },
  protocolInput: {
    title: 'MeetingModal.protocolInput.Title',
    placeholder: 'MeetingModal.protocolInput.Placeholder',
    bindLabel: 'name',
    customItemView: true,
    customLabelView: true,
    clearable: false,
    searchParams: ['name'],
  },
  unitInput: {
    title: 'MeetingModal.unitInput.Title',
    placeholder: 'MeetingModal.unitInput.Placeholder',
    bindLabel: 'identifier',
    bindValue: 'id',
    customItemView: true,
    customLabelView: true,
    clearable: false,
    searchParams: ['identifier'],
  },
  visibleFor: {
    title: 'Title.Visible_for',
    placeholder: 'MeetingModal.unitInput.Placeholder',
    bindLabel: 'name',
    customItemView: true,
    customLabelView: true,
    clearable: false,
    searchParams: ['originalName'],
  },
  assigneeInput: {
    title: 'Shared.Entity.Seller',
    placeholder: 'MeetingModal.assigneeInput.Placeholder',
    bindLabel: 'originalName',
    bindValue: 'id',
    customItemView: true,
    customLabelView: true,
    clearable: false,
    searchParams: ['name'],
  },
  whenInput: {
    title: 'Shared.MeetingModal.WhenInput.Title',
    placeholder: 'MeetingModal.whenInput.Placeholder',
  },
  guestInput: {
    multiple: true,
    title: 'Shared.Entity.Contractor_appraiser_other',
    placeholder: 'MeetingModal.guestInput.Placeholder',
    bindLabel: 'name',
    bindValue: 'id',
    customItemView: true,
    customLabelView: true,
    clearable: false,
    searchParams: ['name', 'email'],
    multiLabel: true,
    editableSearchTerm: true,
    customNotFoundView: true,
  },
  message: {
    title: 'MeetingModal.message.Title',
    placeholder: 'MeetingModal.message.Placeholder',
    maxLengthString: 5000,
    maxLengthTextarea: 277,
  },
  invitationMessage: {
    title: 'MeetingModal.invitationMessage.Title',
    placeholder: 'MeetingModal.message.Placeholder',
    maxLengthString: 5000,
    maxLengthTextarea: 277,
  },
  invite: {
    title: 'Entity.Send_email_invite',
  },
};

export function findObjectArrayDifferences(arr1: any, arr2: any): any[] {
  const arr1Ids = arr1.map((obj: any) => obj.originalId);
  const arr2Ids = arr2.map((obj: any) => obj.id);

  return arr1Ids
    .filter((num: number) => !arr2Ids.includes(num))
    .concat(arr2Ids.filter((num: number) => !arr1Ids.includes(num)));
}

export const attendeesCases = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }];

export enum ETableViewList {
  Main = 'main',
  Union = 'union',
}

export interface ITableViewList {
  title: string;
  value: ETableViewList;
}

export const tableViewList = [
  { title: 'Title.All_meetings', value: ETableViewList.Main },
  { title: 'Title.Grouped_meetings', value: ETableViewList.Union },
];

export function getDatePickerFormat(startAt: string, endAt: string): { from: any, to: any } {
  const from = dayjs(startAt);
  const to = dayjs(endAt);
  return {
    from: {
      year: from.get('years'),
      month: from.get('months') + 1,
      day: from.get('date'),
      hour: from.get('hours'),
      min: from.get('minutes'),
    },
    to: {
      year: to.get('years'),
      month: to.get('months') + 1,
      day: to.get('date'),
      hour: to.get('hours'),
      min: to.get('minutes'),
    },
  };
}

export function arraysDifferent(array1: number[], array2: number[]): boolean {
  if (array1.length !== array2.length) return true;
  const difference1 = array1.some((item) => !array2.includes(item));
  const difference2 = array2.some((item) => !array1.includes(item));
  return difference1 || difference2;
}

export function nullSort(value: any[], firstKey: string, key: string): any[] {
  return value.sort((a, b) => {
    if (a[key] === firstKey && b[key] !== firstKey) {
      return -1;
    } else if (a[key] !== firstKey && b[key] === firstKey) {
      return 1;
    } else {
      return 0;
    }
  });
}
