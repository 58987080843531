import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BuyerModel,
  BuyerUpdateModel,
  CreateBuyerModel,
  ETablePagination,
  ICreateBuyerInterface,
  ICreateMember,
  ITablePagination,
  IUserRequest,
  MemberModel,
  PersonModel,
} from '@atlas-workspace/shared/models';
import { DataTableHelperService, PaginationUtil } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ProjectUsersService {
  public buyersPagination$ = new BehaviorSubject<ITablePagination | null>(null);

  constructor(private http: HttpClient, private tableService: DataTableHelperService) {}

  public getBuyers(projectId: string, search = '', sort = '', paginate?: ITablePagination): Observable<PersonModel[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http
      .get<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/persons`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          if (result.headers) {
            const pagination: ITablePagination = {
              currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
              pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
              totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
              totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
            };
            this.buyersPagination$.next(pagination);
          }
        }),
        map((res: any) => res.body.data?.persons),
        map((data: any[]) => plainToClass(PersonModel, data))
      );
  }

  public getBuyersList(
    projectId: string,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    filter?: string
  ): Observable<IUserRequest> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (filter) {
      params = params.append('user_type', filter);
    }
    return this.http
      .get<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/persons`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          if (result.headers) {
            const pagination: ITablePagination = {
              currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
              pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
              totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
              totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
            };
            this.buyersPagination$.next(pagination);
          }
        }),
        map((res: any) => res.body.data),
        map((data: any) => {
          return {
            value: plainToClass(PersonModel, data.persons as any[]),
            filter: data.user_types_counter,
          };
        })
      );
  }

  /**
   * Create Project Buyer
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Buyers/paths/~1api~1v1~1projects~1{project_id}~1buyers/post
   */
  public createBuyer(projectId: number, data: Partial<ICreateBuyerInterface>): Observable<BuyerModel> {
    const buyer = { buyer: plainToClass(CreateBuyerModel, data) };
    return this.http.post(`${environment.apiBaseUrl}api/v1/projects/${projectId}/buyers`, buyer).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(BuyerModel, x))
    );
  }

  public updateBuyer(projectId: number, id: number, data: any): Observable<BuyerModel> {
    const buyer = plainToClass(BuyerUpdateModel, data);
    return this.http.put(`${environment.apiBaseUrl}api/v1/projects/${projectId}/buyers/${id}`, { buyer }).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(BuyerModel, x))
    );
  }

  public resendBuyerInvitation(projectId: number, buyerId: number, unitId: number): Observable<string> {
    const params: HttpParams = new HttpParams().set('unit_id', String(unitId));
    return this.http
      .patch(`${environment.apiBaseUrl}api/v1/projects/${projectId}/buyers/${buyerId}/resend_invite`, null, { params })
      .pipe(
        map((res: any) => res?.message),
        map((data) => <string>data)
      );
  }

  public deleteBuyer(projectId: number, buyerId: number): Observable<string> {
    return this.http.delete(`${environment.apiBaseUrl}api/v1/projects/${projectId}/buyers/${buyerId}`).pipe(
      map((res: any) => res?.message),
      map((data) => <string>data)
    );
  }

  public getBuyer(projectId: number, buyerId: number): Observable<BuyerModel> {
    return this.http.get(`${environment.apiBaseUrl}api/v1/projects/${projectId}/buyers/${buyerId}`).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(BuyerModel, x))
    );
  }

  public transferBuyerOwnership(projectId: number, unitId: number, buyerId: number): Observable<string> {
    const params: HttpParams = new HttpParams().set('unit_id', String(unitId));
    return this.http
      .post(`${environment.apiBaseUrl}api/v1/projects/${projectId}/buyers/${buyerId}/transfer_ownership`, null, {
        params,
      })
      .pipe(
        map((res: any) => res?.message),
        map((data) => <string>data)
      );
  }

  public getMember(projectId: number, memberId: number): Observable<MemberModel> {
    return this.http.get(`${environment.apiBaseUrl}api/v1/projects/${projectId}/members_v2/${memberId}`).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(MemberModel, x))
    );
  }

  public createMember(projectId: number, member: ICreateMember): Observable<MemberModel> {
    return this.http.post(`${environment.apiBaseUrl}api/v1/projects/${projectId}/members_v2`, { member }).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(MemberModel, x))
    );
  }

  public updateMember(projectId: number, id: number, member: ICreateMember): Observable<MemberModel> {
    return this.http.put(`${environment.apiBaseUrl}api/v1/projects/${projectId}/members_v2/${id}`, { member }).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(MemberModel, x))
    );
  }

  public deleteMember(projectId: number, memberId: number): Observable<string> {
    return this.http.delete(`${environment.apiBaseUrl}api/v1/projects/${projectId}/members_v2/${memberId}`).pipe(
      map((res: any) => res?.message),
      map((data) => <string>data)
    );
  }

  public resendMemberInvitation(projectId: number, memberId: number): Observable<string> {
    return this.http
      .patch(`${environment.apiBaseUrl}api/v1/projects/${projectId}/members_v2/${memberId}/resend_invite`, null)
      .pipe(
        map((res: any) => res?.message),
        map((data) => <string>data)
      );
  }

  public multiResendInvite(projectId: string, ids: number[]): Observable<any> {
    const body = { ids };
    return this.http.post(`${environment.apiBaseUrl}api/v1/projects/${projectId}/persons/multiple_resend_invite`, body);
  }
}
