<div class="free-text">
  <div class="free-text__header d-flex">
    <div class="free-text__header-title">{{ title | translate }}</div>
    <div
      data-cy="cy-close-modal"
      (click)="closeModal()"
      class="button-icon black mr-24"
      [ngbTooltip]="'Alt.Close' | translate"
      [openDelay]="tooltipDelay"
      triggers="hover touchstart"
    >
      <img src="assets/close_newest_gray.svg" alt="Close the task" />
    </div>
  </div>

  <div class="free-text__body v-scrollbar">
    <p class="free-text__description lh-25" [innerText]="description"></p>
  </div>
</div>
