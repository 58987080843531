import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from '@atl-libs-shared-pipes/shared-pipes.module';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { TooltipComponent} from "@atlas-workspace/shared/ui";

import { MoveToModalV2Component } from './move-to-modal-v2.component';
import {FilterDocumentsPipe} from "@atlas-workspace/shared/pipes";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedPipesModule,
    TooltipComponent,
    SharedDirectivesModule,
    FilterDocumentsPipe
  ],
  declarations: [MoveToModalV2Component],
  exports: [MoveToModalV2Component],
})
export class MoveToModalV2Module {}
