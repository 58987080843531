import { Pipe, PipeTransform } from '@angular/core';
import { LayoutProductType } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'roolTooltipGenerator',
  standalone: true,
})
export class RoolTooltipGeneratorPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(type: LayoutProductType): string {
    const isIncrease = !!type.increaseTriggerProducts?.length;
    const triggerProducts = isIncrease ? type.increaseTriggerProducts : type.decreaseTriggerProducts;
    const triggerableProduct = triggerProducts[0];

    const action = isIncrease ? 'Shared.Title.Increased' : 'Shared.Title.Decreased';
    const triggerableProductType = triggerableProduct?.layoutProductTypeName;

    const amount =
      triggerableProduct?.quantityOrAreaSize ||
      `${triggerableProduct?.layoutProductTypeName} ${this.translateService.instant('Shared.Title.Amount_sqm')}`;

    return this.translateService.instant('Shared.Tooltip.Amount_dependable_tooltip', {
      affectAction: this.translateService.instant(action),
      triggerableProductType,
      amount,
    });
  }
}
