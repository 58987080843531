import { ITablePagination, ShortUpdateModel, UpdateModel } from '@atlas-workspace/shared/models';

export class SetUpdates {
  static readonly type = '[Updates] save change updates local';
  constructor(public updates: ShortUpdateModel[]) {}
}

export class SetUpdate {
  static readonly type = '[Updates] save change update local';
  constructor(public update: ShortUpdateModel | UpdateModel) {}
}

export class AddUpdate {
  static readonly type = '[Updates] add update local';
  constructor(public update: ShortUpdateModel | UpdateModel) {}
}

export class RemoveUpdate {
  static readonly type = '[Updates] remove update local';
  constructor(public ids: number[]) {}
}

export class UpdatesSetPagination {
  static readonly type = '[Updates] set pagination';
  constructor(public pagination: ITablePagination) {}
}

export class UpdatesSetTotalFirstLoading {
  static readonly type = '[Updates] set total first loading';
  constructor(public firstLoad: boolean, public totalCount: number) {}
}
