<div class="stat-card-dropdown task-stat card">
  <div class="task-stat__main">
    <span class="task-stat__name">{{ statName }} </span>
    <div
      class="stat-card-dropdown__value task-stat__value"
      [class.task-stat__value--active]="hasMenu"
      (click)="toggleMenu()"
      atlClickOutside
      (clickOutside)="closeMenu()"
    >
      <span>{{ statValue }}</span>
      <img
        *ngIf="hasMenu"
        class="stat-card-dropdown__control"
        [class.stat-card-dropdown__control--active]="isDisplayMenu"
        src="/assets/time_line_arrow.svg"
        alt="Arrow control"
      />

      <div class="stat-card-dropdown__container-menu" *ngIf="isDisplayMenu">
        <div class="stat-card-dropdown__container-menu-item" *ngFor="let item of listOfInfo" (click)="selectItem(item)">
          <span>{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
