import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AuthAdminService,
  FeatureFlagService,
  IndexedDbService,
  MixpanelService,
  ModalFacadeService,
  NetworkService,
  RouterStateService,
  ToasterService,
} from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';

import {
  AnalyticsState,
  DocumentsState,
  IllustrationsState,
  MeetingsState,
  OffersState,
  OptionsState,
  OptionUnitsState,
  PracticalsState,
  ProjectOverviewState, ReclamationsProjectState,
  UnitsState,
  UpdatesState,
} from '../app/store';

@UntilDestroy()
@Component({
  selector: 'atl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private isOnline = true;
  constructor(
    private router: Router,
    private modalFacadeService: ModalFacadeService,
    private featureFlagService: FeatureFlagService,
    private routerService: RouterStateService,
    private mixpanelService: MixpanelService,
    private readonly authService: AuthAdminService,
    private readonly network: NetworkService,
    private readonly toasterService: ToasterService,
    private readonly indexedDbService: IndexedDbService,
    private readonly translateService: TranslateService
  ) {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe(() => this.modalFacadeService.closeModal());
  }

  ngOnInit(): void {
    this.mixpanelService.init();
    this.initProjectChangeListener();
    if (this.authService.getUserInfo) {
      this.getFeatugeFlagsAccess();
    }
    this.networkStatus();
    this.indexedDbMessages();
  }

  private networkStatus(): void {
    this.network.isOnline.pipe(untilDestroyed(this)).subscribe((isOnline) => {
      if (this.isOnline !== isOnline) {
        this.isOnline = isOnline;
        if (this.isOnline) {
          this.toasterService.openSuccessToast(this.translateService.instant('Shared.Network.Online'));
          this.indexedDbService.sendOfflinePostRequestsToServer();
        } else {
          this.toasterService.openSuccessToast(this.translateService.instant('Shared.Network.Offline'));
        }
      }
    });
  }

  private indexedDbMessages(): void {
    this.indexedDbService.messageTransaction.pipe(untilDestroyed(this)).subscribe((value) => {
      const { success, message } = value;
      if (success) {
        this.toasterService.openSuccessToast(this.translateService.instant(message));
      } else {
        this.toasterService.openWarningToast(this.translateService.instant(message));
      }
    });
  }

  private initProjectChangeListener(): void {
    this.routerService
      .isChangeProject()
      .pipe(untilDestroyed(this))
      //! ToDO: fix this approach. This will reset the whole store to the default value on project change.
      .subscribe((isChange: boolean) => {
        if (isChange) {
          this.routerService.storeReset(
            UpdatesState,
            UnitsState,
            OptionUnitsState,
            OptionsState,
            OffersState,
            PracticalsState,
            DocumentsState,
            IllustrationsState,
            MeetingsState,
            ProjectOverviewState,
            AnalyticsState,
            ReclamationsProjectState
          );
        }
      });
  }

  private getFeatugeFlagsAccess(): void {
    this.featureFlagService.getFeatureFlags().pipe(take(1)).subscribe();
  }
}
