import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChangeRequestEventLogModal, EChangeRequestStatus } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';

interface IActivityListRender {
  date: string;
  user: string;
  message: string;
  status: EChangeRequestStatus;
}

@Component({
  selector: 'atl-change-request-activity',
  templateUrl: './change-request-activity.component.html',
  styleUrls: ['./change-request-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRequestActivityComponent implements OnInit {
  @Input() identifier!: string;
  @Input() logActivity!: ChangeRequestEventLogModal[];

  public activityListRender!: IActivityListRender[];

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.initActivityList();
  }

  private initActivityList(): void {
    this.activityListRender = this.logActivity
      .map((item: ChangeRequestEventLogModal, idx: number) => {
        const value: IActivityListRender = {
          date: item.createdAt,
          user: item.creator?.name,
          message: '',
          status: item.status,
        };

        if (idx === 0) {
          //TODO: future update
          // Admin: created new change request
          // Bayer: requested new change
          value.message = `<b>${item.creator?.name}</b>
${this.translate.instant('Shared.Change_Request.Activity.Pending')} <b>${this.identifier}</b>`;
          return value;
        }

        const prevStatus = this.logActivity[idx - 1].status;

        if (prevStatus !== item.status) {
          switch (item.status) {
            case EChangeRequestStatus.Offered:
              if (prevStatus === EChangeRequestStatus.OfferExpired) {
                value.message = `<b>${item.creator?.name}</b> ${this.translate.instant(
                  'Shared.Change_Request.Activity.Updated_date'
                )}`;
              } else {
                value.message = `<b>${item.creator?.name}</b> ${this.translate.instant(
                  'Shared.Change_Request.Activity.Created_offer'
                )}`;
              }
              break;
            case EChangeRequestStatus.OfferCanceled:
              value.message = `<b>${item.creator?.name}</b> ${this.translate.instant(
                'Shared.Change_Request.Activity.Cancelled_offer'
              )}`;
              break;
            case EChangeRequestStatus.OfferDeclined:
              value.message = `<b>${item.creator?.name}</b> ${this.translate.instant(
                'Shared.Change_Request.Activity.Declined_offer'
              )}`;
              break;
            case EChangeRequestStatus.OfferApproved:
              value.message = `<b>${item.creator?.name}</b> ${this.translate.instant(
                'Shared.Change_Request.Activity.Accepted_offer'
              )}`;
              break;
            case EChangeRequestStatus.New:
              value.message = this.getChangeStatusMsg(
                item.creator?.name,
                this.logActivity[idx - 1].localizedStatus,
                item.localizedStatus
              );
              break;
            case EChangeRequestStatus.InProgress:
              value.message = this.getChangeStatusMsg(
                item.creator?.name,
                this.logActivity[idx - 1].localizedStatus,
                item.localizedStatus
              );
              break;
            case EChangeRequestStatus.RequestDeclined:
              value.message = this.getChangeStatusMsg(
                item.creator?.name,
                this.logActivity[idx - 1].localizedStatus,
                item.localizedStatus
              );
              break;
          }
        }

        if (item.newResponsible) {
          value.message = `<b>${value.user}</b>
${this.translate.instant('Shared.Change_Request.Activity.Added_responsible', {value: item.newResponsible.name})}`;
        }

        return value;
      })
      .filter((item) => item.message.length)
      .reverse();
  }

  private getChangeStatusMsg(name: string, prevStatus: string, status: string): string {
    return `<b>${name}</b> ${this.translate.instant(
      'Shared.Change_Request.Activity.Changed_status'
    )} <b>${prevStatus}</b>
${this.translate.instant('Shared.Entity.To').toLowerCase()} <b>${status}</b>`;
  }
}
