import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UnitHelperService {

  private isListNeedUpdate$ = new Subject<boolean>();

  public isNeedUpdateList(): Observable<boolean> {
    return this.isListNeedUpdate$.asObservable();
  }

  public setUpdateList(val: boolean): void {
    this.isListNeedUpdate$.next(val);
  }
}
