import { Injectable } from '@angular/core';
import { ImmutableSelector, ProjectModel } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, insertItem, patch, updateItem } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import {
  AddProject,
  RemoveProject,
  SetHomeProjects,
  SetMyMeetings,
  SetMyProjects,
  SetMyTasks,
  SetProject,
  SetProjects
} from './projects.action';
import { ProjectsStateModel } from './projects.model';

@State({
  name: 'projectsState',
  defaults: defaultState.projectsState,
})
@Injectable()
export class ProjectsState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: ProjectsStateModel): ProjectsStateModel {
    return state;
  }

  @Action(SetProjects)
  setProjects(ctx: StateContext<ProjectsStateModel>, { projects, appendToArray }: SetProjects): void {
    ctx.setState(
      patch<ProjectsStateModel>({
        projects: appendToArray ? append<ProjectModel>(projects) : projects,
      }),
    );
  }

  @Action(SetMyProjects)
  setMyProjects(ctx: StateContext<ProjectsStateModel>, { myProjects }: SetMyProjects): void {
    ctx.setState(patch<ProjectsStateModel>({ myProjects }));
  }

  @Action(SetMyTasks)
  setMyTasks(ctx: StateContext<ProjectsStateModel>, { myTasks }: SetMyTasks): void {
    ctx.setState(patch<ProjectsStateModel>({ myTasks }));
  }

  @Action(SetMyMeetings)
  setMyMeetings(ctx: StateContext<ProjectsStateModel>, { myMeetings }: SetMyMeetings): void {
    ctx.setState(patch<ProjectsStateModel>({ myMeetings }));
  }

  @Action(SetProject)
  setProject(ctx: StateContext<ProjectsStateModel>, { project }: SetProject): void {
    ctx.setState(
      patch<ProjectsStateModel>({
        projects: updateItem<ProjectModel>((u) => u?.projectId === project.projectId, project),
      })
    );
  }

  @Action(AddProject)
  addProject(ctx: StateContext<ProjectsStateModel>, { project }: AddProject): void {
    ctx.setState(
      patch<ProjectsStateModel>({
        projects: insertItem<ProjectModel>(project, 0),
        myProjects: append<ProjectModel>([project]),
        projectsHomeList: append<ProjectModel>([project]),
      })
    );
  }

  @Action(SetHomeProjects)
  setHomeProjects(ctx: StateContext<ProjectsStateModel>, { projectsHomeList }: SetHomeProjects): void {
    ctx.setState(patch<ProjectsStateModel>({ projectsHomeList }));
  }

  @Action(RemoveProject)
  @ImmutableSelector()
  removeProject(ctx: StateContext<ProjectsStateModel>, { id }: RemoveProject): void {
    const state = ctx.getState();
    const projects = state.projects?.filter(p => p.projectId !== id);
    ctx.setState(
      patch<ProjectsStateModel>({
        projects
      })
    );
  }
}
