import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';

import { CustomIconComponent } from '../custom-icon/custom-icon.component';

@Component({
  selector: 'atl-button-select-dropdown',
  templateUrl: './button-select-dropdown.component.html',
  styleUrls: ['./button-select-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, CustomIconComponent, ClickOutsideDirective],
})
export class ButtonSelectDropdownComponent {
  @Input() icon!: string;
  @Input() mobileIcon!: string;
  @Input() disabled = false;
  @Input() enabledDropdown?: boolean;
  expanded = false;

  close(): void {
    this.expanded = false;
  }

  toggle(): void {
    if (this.enabledDropdown !== undefined) {
      this.expanded = this.enabledDropdown ? !this.expanded : false;
    } else if (!this.disabled) {
      this.expanded = !this.expanded;
    }
  }
}
