<div
  class="picker__wrap"
  #pickerWrapper
  (keydown.Tab)="onTabHandler($event, 1)"
  (keydown.shift.Tab)="onTabHandler($event, 0)"
  (click)="showPicker()"
>
  <div class="picker__input">
    <input
      name="datepicker"
      ngbDatepicker
      #datepicker='ngbDatepicker'
      [autoClose]="'outside'"
      navigation="none"
      [weekdays]="false"
      [outsideDays]="outsideDays"
      [contentTemplate]="content"
      [placement]="placement"
      (closed)="onBlur()"
      container="body"
      [datepickerClass]="customClass"
      class="datepicker-ngb"
      tabindex="-1"
    />
    <atl-input
      #atlInput
      class="newest-input--number"
      [class.active]="datepicker.isOpen()"
      (click)="pickerHandler()"
      (lockedTrigger)="isLocked($event)"
      [sweechLockOff]="!hasLocked"
      tabindex="-1"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [ngModel]="inputTitle"
      [readOnly]="true"
    ></atl-input>
  </div>

  <span
    *ngIf="inputButton"
    (click)="onInputButtonHandler($event)"
    class="picker__input-button"
  >
    {{inputButtonTitle | translate}}
  </span>


</div>

<ng-template #content>
  <div [class]="el?.nativeElement?.className" class="picker__data-picker">
    <div class="picker__time" [class.picker__time-single]="singleTime">
      <input
        #dateInput
        [(ngModel)]="inputValue"
        tabindex="-1"
        type="text"
        class="picker__time-date"
        [class.error]="dateInputError"
        [mask]="mask"
        (input)="inputDate(dateInput.value)"
        [placeholder]="inputPlaceholder | translate"
        (keydown.enter)="validateShowPicker()"
        [readOnly]="disabled"
      />
      <div *ngIf="singleTime" class="picker__divider"></div>
      <atl-time-select
        *ngIf="singleTime"
        (changeTine)="changeTine($event)"
        (validateShowPicker)="validateShowPicker()"
        [value]="selectTime"
        [disabled]="disabled"
        [singleTime]="singleTime"
        [currentDate]="min"
        [date]="selectDate"
        [timeIsCurrentDate]="timeIsCurrentDate"
      ></atl-time-select>
      <ng-container *ngIf="singleTime">
        <ng-container *ngTemplateOutlet="errorIcon"></ng-container>
      </ng-container>
      <ng-container *ngIf="!singleTime">
        <ng-container *ngIf="dateInputError">
          <ng-container *ngTemplateOutlet="errorIcon"></ng-container>
        </ng-container>
      </ng-container>
      <div *ngIf="!singleTime" class="picker__time-select">
        <atl-time-select
          (changeTine)="changeTine($event)"
          (validateShowPicker)="validateShowPicker()"
          [value]="selectTime"
          [disabled]="disabled"
          [singleTime]="singleTime"
        ></atl-time-select>
      </div>
    </div>
    <ngb-datepicker
      #dp
      [class.selected]="inputValue"
      class="datepicker-ngb"
      [ngModel]="inputValue"
      [firstDayOfWeek]="1"
      [navigation]="navigation"
      [weekdays]="0"
      (dateSelect)="selectedDate($event)"
      [outsideDays]="outsideDays"
      [minDate]="min"
      [maxDate]="max"
      [footerTemplate]="footerTemplate"
      [disabled]="disabled"
    ></ngb-datepicker>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="picker__footer">
    <span class="cursor-pointer fw-500 fz-14 lh-24 ml-8" (click)="clearValue()">
      {{ clearButtonLabel | translate }}
    </span>

    <button class="newest-button" (click)="setDate()" [disabled]="!inputValue && !selectTime">
      {{ 'Meeting_Data_Picker.Confirmation_Button' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #errorIcon>
  <img
    src="/assets/warning-circle.svg"
    alt="Drag"
    [ngbTooltip]="tooltipMsg | translate"
    placement="top-right"
    triggers="hover touchstart"
  />
</ng-template>
