import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FirmPrefixPipeModule, TruncatePipeModule } from '@atlas-workspace/shared/pipes';
import { RandomColorService } from '@atlas-workspace/shared/service';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-newest-user-info-view',
  templateUrl: './newest-user-info-view.component.html',
  styleUrls: ['./newest-user-info-view.component.scss'],
  standalone: true,
  imports: [CommonModule, FirmPrefixPipeModule, TruncatePipeModule, NgbTooltipModule],
})
export class NewestUserInfoViewComponent {
  @Input() set name(value: string) {
    if (value) {
      this._name = value;
      const { iconColor, iconBorderColor } = this.randomColorService.getUserColors(this._name, 55, 50, true);
      this.iconColor = iconColor;
      this.iconBorderColor = iconBorderColor;
    }
  }

  get name(): string {
    return this._name;
  }

  @Input() email!: string;

  public iconColor!: string;
  public iconBorderColor!: string;
  private _name!: string;
  public readonly memberLengthName = 30;
  public readonly tooltipDelay = 500;

  constructor(private randomColorService: RandomColorService) {}
}
