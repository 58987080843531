// eslint-disable-next-line max-len
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ConverterService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import SignaturePad, { PointGroup } from 'signature_pad';

@Component({
  selector: 'atl-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureModalComponent implements AfterViewInit {
  private readonly keyName = 'Escape';
  public readonly tooltipDelay = 500;

  @Output() private readonly passImage = new EventEmitter<{ svg: string; image: File }>();

  @ViewChild('canvas', { static: true }) canvas!: ElementRef;
  @ViewChild('content', { static: true }) contentRef!: ElementRef<HTMLElement>;

  signaturePad!: SignaturePad;

  public hasSignature = false;
  private redoStack: PointGroup[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private converterService: ConverterService
  ) {}

  ngAfterViewInit(): void {
    this.initSignaturePad();
    this.setCanvasDimensions();
  }

  onResize(): void {
    this.setCanvasDimensions();
  }

  private setCanvasDimensions(): void {
    const canvas = this.canvas.nativeElement;
    const { offsetWidth: width, offsetHeight: height } = this.contentRef.nativeElement;
    const data = this.signaturePad?.toData();

    canvas.width = width;
    canvas.height = height;

    if (data?.length) {
      this.signaturePad.clear();
      this.signaturePad.fromData(data);
    }

    this.cdr.markForCheck();
  }

  private initSignaturePad(): void {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, {
      minWidth: 2,
      maxWidth: 2,
    });

    this.signaturePad.addEventListener(
      'endStroke',
      () => {
        const padData = this.signaturePad.toData();
        this.hasSignature = !!padData.length;
        this.cdr.markForCheck();
      },
      { once: false }
    );
  }

  public saveDrawing(): void {
    const padData = this.signaturePad.toData();
    if (padData.length) {
      const paintSvg = this.signaturePad.toSVG();
      this.converterService
        .convertSignatureToImageFile(this.canvas.nativeElement)
        .pipe(take(1))
        .subscribe((file: File) => {
          this.passImage.next({ svg: paintSvg, image: file });
          this.closeModal();
        });
    }
  }

  public undo(): void {
    const lastStroke = this.signaturePad.toData().pop();
    if (lastStroke) {
      this.redoStack.push(lastStroke);
      this.signaturePad.fromData(this.signaturePad.toData());
    }
    this.updateSignature();
  }

  public redo(): void {
    const lastRedoStroke = this.redoStack.pop();
    if (lastRedoStroke) {
      this.signaturePad.toData().push(lastRedoStroke);
      this.signaturePad.fromData(this.signaturePad.toData());
    }
    this.updateSignature();
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  private updateSignature(): void {
    const data = this.signaturePad.toData();
    this.hasSignature = !!data.length;
    this.cdr.markForCheck();
  }

  @HostListener('document:keydown', ['$event'])
  detectKeyDown(event: KeyboardEvent): void {
    if (event.key === this.keyName) this.closeModal();
  }
}
