<div class="thread-attachments__header">
  <div class="fz-16 lh-24 fw-500 d-flex align-items-center">{{ 'Shared.Messages.Attachments' | translate }}</div>
  <div class="thread-attachments__header-actions">
    <atl-header-icon
      [icon]="isClient ? '/assets/close-icon-v2.svg' : '/assets/unit_close.svg'"
      (click)="close()"
      [ngbTooltip]="'Alt.Close' | translate"
      [openDelay]="tooltipDelay"
      triggers="hover touchstart"
      [class.client]="isClient"
    ></atl-header-icon>
  </div>
</div>

<ng-container *ngIf="!isLoading; else spinnerBlock">
  <div class="thread-attachments__form-wrapper v-scrollbar" *ngIf="isAttachments; else emptyState">
    <div class="thread-attachments__input-search-wr" [formGroup]="form">
      <img src="/assets/input_search_icon.svg" alt="Search icon" />
      <input
        class="global_input thread-attachments__input-search"
        type="text"
        tabindex="-1"
        formControlName="search"
        placeholder="{{ 'Shared.Messages.Attachments_search_placeholder' | translate }}"
        [class.active]="form.controls.search.value"
      />
      <div class="thread-attachments__input-search-close" (click)="onClearInput()">
        <img src="/assets/input_search_clear.svg" *ngIf="searchParam.length" alt="Clear icon" />
      </div>
    </div>

    <ng-container *ngIf="attachmentsGroups.length; else noSearchResult">
      <ng-container *ngFor="let group of attachmentsGroups">
        <div *ngIf="group.divider" class="thread-attachments__period-title capitalize">{{ group.divider }}</div>
        <div *ngFor="let attachment of group.attachments; let last = last" (atlInfiniteScroll)="loadMore(last)">
          <div class="thread-attachments__item">
            <atl-newest-document-card
              (click)="openFilePreview(attachment.file)"
              class="thread-attachments__item-full-width"
              [image]="attachment.file"
              [fileName]="attachment.file.name | truncate: maxNameLength"
              [fileType]="attachment.file.extension"
              [downloadLink]="attachment.file.fileName.downloadUrl"
              [isImage]="attachment.file.isImage"
              [sharedText]="
                ('Shared.Messages.Attachments_shared_by' | translate: { name: attachment.senderName }) +
                (attachment.createdAt | date: 'dd.MM.YYYY')
              "
              [hasRemove]="false"
              [useDropdownOptions]="!isClient"
              [closestDropdownOptionsSelector]="'.thread-attachments__form-wrapper'"
              [class.client]="isClient"
            >
            </atl-newest-document-card>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #emptyState>
  <section class="thread-attachments__empty flex-column-centered f-family-sfpro">
    <div class="thread-attachments__empty-icon flex-column-centered">
      <img src="/assets/paperclip_gray_thin.svg" alt="icon" />
    </div>
    <h2 class="fw-500 lh-24 fz-20 mt-16">
      {{ 'Shared.Messages.No_attachments_yet' | translate }}
    </h2>
    <p class="fw-400 lh-24 fz-16 mt-8" [class.text-black-810]="!isClient">
      {{ 'Shared.Messages.Empty_attachments_description' | translate }}
    </p>
  </section>
</ng-template>

<ng-template #noSearchResult>
  <div class="thread-attachments__no-result flex-column-centered f-family-sfpro">
    <img [src]="noResult.icon" class="filter-black-960" alt="icon" />
    <h2 class="fw-500 lh-16 fz-14 text-black-960 mt-16">{{ noResult.title | translate }}</h2>
    <p class="fw-400 lh-24 fz-14 mt-4">{{ noResult.description | translate }} "{{ searchParam | truncate: 40 }}"</p>
  </div>
</ng-template>

<ng-template #spinnerBlock>
  <div class="thread-attachments__list-spinner h-100 d-flex align-items-center justify-content-center">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>
