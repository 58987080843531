<div class="signature">
  <div class="signature__header d-flex align-items-center justify-content-between">
    <div>
      <h2 class="fz-16 lh-24">{{ 'Title.Signature' | translate }}</h2>
    </div>
    <div
      data-cy="cy-close-modal"
      (click)="closeModal()"
      class="button-icon black"
      [ngbTooltip]="'Alt.Close' | translate"
      [openDelay]="tooltipDelay"
      triggers="hover touchstart"
    >
      <img src="assets/close_newest_gray.svg" alt="Close the task" />
    </div>
  </div>

  <div class="signature__content" #content atlWindowResize (browserZoomLevel)="onResize()">
    <canvas #canvas class="signature__canvas"></canvas>
    <div class="signature__undo-block">
      <button
        class="button-black"
        (click)="redo()"
        [ngbTooltip]="'Title.Redo' | translate"
        [placement]="'left'"
        [triggers]="'mouseenter:mouseleave'"
      >
        <img src="/assets/undo-icon.svg" alt="icon" />
      </button>

      <button
        class="button-black"
        (click)="undo()"
        [ngbTooltip]="'Shared.Entity.Undo' | translate"
        [placement]="'left'"
        [triggers]="'mouseenter:mouseleave'"
      >
        <img src="/assets/redo-icon.svg" alt="icon" />
      </button>
    </div>
  </div>

  <div class="signature__footer d-flex align-items-center flex-end">
    <button (click)="closeModal()" class="newest-button button-gray mr-16">
      {{ 'Shared.Button.Cancel' | translate }}
    </button>
    <button [disabled]="!hasSignature" (click)="saveDrawing()" class="newest-button h-32 mr-16">
      {{ 'Shared.Button.Save' | translate }}
    </button>
  </div>
</div>
