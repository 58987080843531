import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { CustomCheckboxComponent } from '../custom-checkbox/custom-checkbox.component';

@Component({
  selector: 'atl-newest-checkbox',
  templateUrl: './newest-checkbox.component.html',
  styleUrls: ['./newest-checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class NewestCheckboxComponent extends CustomCheckboxComponent {}
