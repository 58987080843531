import { Injectable } from '@angular/core';
import { RouterSelectors } from '@atlas-workspace/shared/models';
import { Store } from '@ngxs/store';
import { StateReset, StateResetAll } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterStateService {
  private projectId!: string;

  constructor(private store: Store) {}

  isChangeProject(): Observable<boolean> {
    return this.store.select(RouterSelectors.params).pipe(
      filter((params) => !!params?.projectId),
      map((params) => {
        if (this.projectId) {
          if (this.projectId === params?.projectId) {
            return false;
          }
          this.projectId = params?.projectId;
          return true;
        } else {
          this.projectId = params?.projectId;
          return false;
        }
      })
    );
  }

  getProjectId(): string {
    return this.projectId;
  }

  getQuery(): any {
    return this.store?.snapshot().router.state.queryParams;
  }

  storeReset(...value: any): void {
    this.store.dispatch(
      new StateReset(
        ...value
      )
    );
  }

  resetAllState(): void {
    this.store.dispatch(new StateResetAll());
  }
}
