export const threadEditorTaggingParams = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY: '@',
  boxWidth: 240,
  maxBoxHeight: 274,
  verticalEditorOffset: 10,
  usersPerView: 5,
  innerBoxPaddingY: 16,
  gap: 4,
  boxItemHeight: 56,
  keySymbolWidth: 15,
  toolbarHeight: 32,
  clientCreationToolbarHeight: 30,
  borders: 2,
  setUsersPerView: function (n: number): void {
    this.usersPerView = n;
    this.maxBoxHeight = this.boxItemHeight * n + this.innerBoxPaddingY + this.gap * (n - 1) + this.borders;
  },
};
