import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[atlAutoHeightTextarea]',
  standalone: true
})
export class AutoHeightTextareaDirective {
  get focusInput(): boolean {
    return this._focusInput;
  }

  @Input() set focusInput(value: boolean) {
    this._focusInput = value;
    this.resize();
  }

  @Input() height = 32;
  private _focusInput = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener(':input')
  onInput(): void {
    this.resize();
  }

  @HostListener(':paste')
  onPaste(): void {
    this.resize();
  }

  ngAfterViewInit(): void {
    if (this.elementRef.nativeElement.scrollHeight) {
      this.resize();
    }
  }

  private resize(): void {
    if (this.focusInput) {
      this.elementRef.nativeElement.style.height = '0';
      this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
    } else {
      this.elementRef.nativeElement.style.height = `${this.height}px`;
    }
  }
}
