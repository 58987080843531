import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'atl-statistic-progress-card',
  templateUrl: './statistic-progress-card.component.html',
  styleUrls: ['./statistic-progress-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class StatisticProgressCardComponent {
  @Input() statValue!: number | string;
  @Input() showProgress = false;
  @Input() statName = '';
}
