import { Injectable } from '@angular/core';
import {
  ImmutableSelector,
  ITablePagination,
  ReclamationsModel,
} from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import {
  AddReclamationProject,
  ReclamationProjectSetPagination,
  ReclamationProjectSetTotalFirstLoading,
  RemoveReclamationProject,
  SetReclamationProject,
  SetReclamationsProject
} from './reclamations-project.action';
import {ReclamationsProjectStateModel} from './reclamations-project.model';

@State({
  name: 'reclamationsProjectState',
  defaults: defaultState.reclamationsProject,
})
@Injectable()
export class ReclamationsProjectState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: ReclamationsProjectStateModel): ReclamationsProjectStateModel {
    return state;
  }

  @Action(SetReclamationsProject)
  setReclamations(ctx: StateContext<ReclamationsProjectStateModel>, { reclamations }: SetReclamationsProject): void {
    ctx.setState(patch<ReclamationsProjectStateModel>({ reclamations }));
  }

  @Action(SetReclamationProject)
  seReclamation(ctx: StateContext<ReclamationsProjectStateModel>, { reclamation }: SetReclamationProject): void {
    ctx.setState(
      patch<ReclamationsProjectStateModel>({
        reclamations: updateItem<ReclamationsModel>((u) => u?.id === reclamation.id, reclamation),
      })
    );
  }

  @Action(AddReclamationProject)
  addReclamation(ctx: StateContext<ReclamationsProjectStateModel>, { reclamation }: AddReclamationProject): void {
    const state = ctx.getState();
    ctx.setState(
      patch<ReclamationsProjectStateModel>({
        reclamations: insertItem<ReclamationsModel>(reclamation, 0),
        pagination: patch<ITablePagination>({
          totalCount: state.pagination.totalCount + 1,
        }),
        totalCount: state.totalCount + 1,
      })
    );
  }

  @Action(ReclamationProjectSetPagination)
  setPagination(ctx: StateContext<ReclamationsProjectStateModel>, { pagination }: ReclamationProjectSetPagination): void {
    ctx.setState(patch<ReclamationsProjectStateModel>({ pagination }));
  }

  @Action(ReclamationProjectSetTotalFirstLoading)
  setTotalFirstLoading(
    ctx: StateContext<ReclamationsProjectStateModel>,
    { firstLoad, totalCount }: ReclamationProjectSetTotalFirstLoading
  ): void {
    ctx.setState(patch<ReclamationsProjectStateModel>({ firstLoad, totalCount }));
  }

  @Action(RemoveReclamationProject)
  @ImmutableSelector()
  removeReclamations(ctx: StateContext<ReclamationsProjectStateModel>, { ids }: RemoveReclamationProject): void {
    const state = ctx.getState();
    const reclamations = state.reclamations?.filter((u) => !ids.some((id) => u.id === id));
    ctx.setState(
      patch<ReclamationsProjectStateModel>({
        reclamations,
        totalCount: state.totalCount - ids.length,
      })
    );
  }
}
