import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';

@Component({
  selector: 'atl-newest-filter',
  templateUrl: './newest-filter.component.html',
  styleUrls: ['./newest-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
})
export class NewestFilterComponent {
  @Input() filterTitle!: string;
  @Input() activatedFilter = false;
  @Input() classNameException: string[] = [];
  @Output() private readonly closeEventHandler = new EventEmitter();

  public toggleFilter = false;

  public onToggleFilter(): void {
    this.toggleFilter = !this.toggleFilter;

    if (!this.toggleFilter) {
      this.closeEventHandler.emit();
    }
  }

  public onCloseFilter(event: MouseEvent): void {
    const { target } = event;

    const foundIndex = this.classNameException.findIndex((item) => item === (target as HTMLElement).className);

    if (foundIndex !== -1) {
      return;
    }

    this.toggleFilter = false;
    this.closeEventHandler.emit();
  }
}
