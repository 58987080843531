<div class="drop_down position-relative d-inline-block" atlClickOutside (clickOutside)="toggle(false)">
  <atl-header-icon
    (click)="toggle()"
    class="client"
    [class.active]="isExpanded"
    icon="/assets/dropdown-dot-2.svg"
    [ngbTooltip]="'Shared.Button.More' | translate"
    [openDelay]="tooltipDelay"
    triggers="hover touchstart"
  ></atl-header-icon>

  <div *ngIf="isExpanded" class="drop_down__expand position-absolute z-index-5 d-flex flex-column br-12">
    <div class="drop_down__expand-header d-flex align-items-center justify-content-start fz-12 lh-16 fw-400">
      {{ 'Entity.Created_on' | translate }} {{ createdAt | date:'d. MMM, y • h:mm' }}
    </div>

    <div
      (click)="copyLink()"
      class="drop_down__expand-button br-8 cursor-pointer d-flex align-items-center justify-content-start"
    >
      <img class="mr-8" src="/assets/unit_link.svg" alt="">
      <span class="fz-14 lh-24 fw-400">{{ 'Shared.Entity.Copy_link' | translate }}</span>
      <img
        class="drop_down__expand-button-command position-absolute d-none"
        src="/assets/shift-command.svg"
        alt="key icon"
      />
    </div>

    <div
      (click)="openTerms()"
      class="drop_down__expand-button br-8 cursor-pointer d-flex align-items-center justify-content-start"
    >
      <img class="mr-8" src="/assets/terms-conditions.svg" alt="">
      <span class="fz-14 lh-24 fw-400">{{ 'Shared.Terms.Terms_and_conditions' | translate }}</span>
    </div>
  </div>
</div>
