import { Injectable } from '@angular/core';
import {
  AddDocument,
  RemoveDocuments,
  SetDocument,
  SetDocuments,
  SetDocumentsPagination,
  SetDocumentsTotalFirstLoading,
} from '@atl-admin-app/store/documents/documents.action';
import { DocumentStateModel } from '@atl-admin-app/store/documents/documents.model';
import { DocumentModel, ImmutableSelector,ITablePagination } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';

import { defaultState } from '../default-state';

@State({
  name: 'documentsState',
  defaults: defaultState.documentsState,
})
@Injectable()
export class DocumentsState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: DocumentStateModel): DocumentStateModel {
    return state;
  }

  @Action(SetDocuments)
  setUnits(ctx: StateContext<DocumentStateModel>, { documents }: SetDocuments): void {
    ctx.setState(patch<DocumentStateModel>({ documents }));
  }

  @Action(AddDocument)
  addUnit(ctx: StateContext<DocumentStateModel>, { document }: AddDocument): void {
    const state = ctx.getState();
    ctx.setState(
      patch<DocumentStateModel>({
        documents: insertItem<DocumentModel>(document, 0),
        pagination: patch<ITablePagination>({
          totalCount: state.pagination.totalCount + 1,
        }),
        totalCount: state.totalCount + 1,
      })
    );
  }

  @Action(SetDocument)
  setUnit(ctx: StateContext<DocumentStateModel>, { document }: SetDocument): void {
    ctx.setState(
      patch<DocumentStateModel>({
        documents: updateItem<DocumentModel>((u) => u?.id === document.id, document),
      })
    );
  }

  @Action(SetDocumentsPagination)
  setPagination(ctx: StateContext<DocumentStateModel>, { pagination }: SetDocumentsPagination): void {
    ctx.setState(patch<DocumentStateModel>({ pagination }));
  }

  @Action(SetDocumentsTotalFirstLoading)
  setTotalFirstLoading(
    ctx: StateContext<DocumentStateModel>,
    { firstLoad, totalCount }: SetDocumentsTotalFirstLoading
  ): void {
    ctx.setState(patch<DocumentStateModel>({ firstLoad, totalCount }));
  }

  @Action(RemoveDocuments)
  @ImmutableSelector()
  removeUnits(ctx: StateContext<DocumentStateModel>, { ids }: RemoveDocuments): void {
    const state = ctx.getState();
    const documents = state.documents?.filter((u) => !ids.some((id) => u.id === id));
    ctx.setState(
      patch<DocumentStateModel>({
        documents,
        totalCount: state.totalCount - ids.length,
      })
    );
  }
}
