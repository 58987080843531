import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ItemableNamesPipe } from './itemable-names.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ItemableNamesPipe],
  exports: [ItemableNamesPipe],
})
export class ItemableNamesPipeModule {}
