<div class="request__wrapper" (atlCloseModalEsc)="closeModal()" atlWindowInnerHeight>
  <div class="request__header">
    <div class="request__header-title">
      <div *ngIf="isGroupScope; else defaultTitle">
        {{ 'Shared.Title.Change_requests_details' | translate }}
         - {{ changeRequest?.identifier | noProjectSegmentInChangeRequestIdentifier }}
      </div>
      <ng-template #defaultTitle>
        {{ 'Title.Change_request' | translate }}
        - {{ changeRequest?.identifier }}
      </ng-template>
    </div>
    <div class="request__header-action">
      <ng-container *ngIf="isGroupScope">
        <atl-detail-change-request-header-dropdown
          *ngIf="settings"
          [changeRequestId]="changeRequest.id"
          [createdAt]="changeRequest.createdAt"
          [settings]="settings"
          (oncopy)="showActionBanner(true)"
        ></atl-detail-change-request-header-dropdown>
      </ng-container>

      <atl-header-icon
        (click)="closeModal()"
        class="client"
        icon="/assets/close_newest_gray.svg"
        [ngbTooltip]="'Alt.Close' | translate"
        triggers="hover touchstart"
      ></atl-header-icon>
    </div>
  </div>

  <atl-simple-tabs
    class="tabs"
    [activeTab]="activeTab"
    [tabs]="navigationMenu"
    [withId]="true"
    [routeNavigation]="false"
    [useTooltip]="true"
    (selectingTab)="selectTab($event)"
  ></atl-simple-tabs>

  <div class="request__container v-scrollbar">
    <ng-container [ngSwitch]="activeTab">
      <ng-container *ngSwitchCase="requestTab.General">
        <ng-container [ngTemplateOutlet]="generalContent"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="requestTab.ClientCustomer">
        <ng-container [ngTemplateOutlet]="customerNotes"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container [ngTemplateOutlet]="offerContent"></ng-container>
      </ng-container>
    </ng-container>
  </div>

  <atl-action-message-banner
    *ngIf="showBanner"
    class="position-absolute z-index-3"
    [icon]="true"
    [closeButton]="false"
    [messageText]="bannerText"
    (closeEvent)="showActionBanner(false)"
  ></atl-action-message-banner>
</div>

<ng-template #generalContent>
  <atl-change-request-combined-offer-banner
    *ngIf="activeGroupOffer && (activeGroupOffer.status === offerSentStatus || activeGroupOffer.status === offerExpiredStatus)"
    class="ml-24 mt-24 mr-24"
    [offerStatus]="activeGroupOffer.status"
    (openGroupOffer)="openGroupOffer()"
  ></atl-change-request-combined-offer-banner>

  <div class="request__info" *ngIf="changeRequest">
    <!-- Description -->
    <div class="request__row">
      <div class="request__field" [class.group-change-request-scope]="isGroupReadonlyScope">
        <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />
        <span>{{ 'Shared.Reclamation.Input.Description.Label' | translate }}</span>
      </div>
      <div class="request__value description" [class.empty-val]="!changeRequest.description">
        <ng-container *ngIf="changeRequest.description; else emptyDescription">
          <atl-textarea-autoresize
            [(ngModel)]="changeRequest.description"
            [disabled]="true"
            [stringMode]="true"
            [placeholder]="'Shared.Entity.Add_description' | translate"
            [absolutePosition]="true"
            [autoresizeOffset]="2"
            [tabindex]="isGroupReadonlyScope ? -1 : 0"
            class="newest-textarea"
          >
          </atl-textarea-autoresize>
        </ng-container>
        <ng-template #emptyDescription>
          <span class="ml-8 lh-32">
            {{ 'Shared.Reclamation.No_description' | translate }}
          </span>
        </ng-template>
      </div>
    </div>

    <!--Status-->
    <div class="request__row">
      <div class="request__field" [class.group-change-request-scope]="isGroupReadonlyScope">
        <img [src]="'assets/spinner-gray.svg'" alt="icon" />
        <span>{{ 'Shared.Reclamation.Input.Status.Label' | translate }}</span>
      </div>
      <div class="request__value d-flex align-items-center">
        <div
          *ngIf="changeRequest?.eventLogs?.length"
          class="fz-14 lh-24 pl-8 text-black-960 ellipsis status"
          [ngClass]="'status__' + statusList[changeRequest.status]?.style"
        >
          {{ changeRequest.localizedStatus }}
        </div>

        <span
          *ngIf="!isGroupScope && changeRequest.status === statusKeys.Offered"
          class="request__details request__details-offered cursor-pointer fz-14 lh-24 fw-400 text-blue-980 ml-8"
          (click)="openActiveOfferTab()"
        >
          {{ 'Shared.Title.View_offer' | translate }}
          <img src="assets/arrow-newest-gray-down.svg" alt="arrow" />
        </span>

        <span
          *ngIf="changeRequest.status === statusKeys.RequestDeclined"
          class="request__details cursor-pointer fz-14 lh-24 fw-400 text-black-810 ml-8"
          (click)="showComment()"
        >
          {{ 'Shared.Reclamation.Details' | translate }}
          <img src="assets/arrow-newest-gray-down.svg" alt="arrow" />
        </span>
      </div>
    </div>

    <!--Attachments-->
    <div class="request__row">
      <div class="request__field" [class.group-change-request-scope]="isGroupReadonlyScope">
        <img [src]="'assets/paperclip_gray.svg'" alt="icon" />
        <span>{{ 'Shared.Reclamation.Input.Attachments.Label' | translate }}</span>
      </div>
      <div class="request__value" [class.empty-val]="!changeRequest.fileResources?.length">
        <atl-dropdown-input-file
          *ngIf="changeRequest.fileResources?.length; else noAttachments"
          class="request-detail-dropdown"
          (viewTempFilesEmitter)="openPreview($event)"
          [disabled]="true"
          [previewAttachment]="changeRequest.fileResources || []"
          [disableChangePosition]="true"
        ></atl-dropdown-input-file>
        <ng-template #noAttachments>
          <span class="lh-32">
            {{ 'Shared.Reclamation.No_attachments' | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="request__floor mt-24" *ngIf="floorPlanData">
    <atl-plan-drawing
      *ngIf="!isGroupReadonlyScope || isGroupReadonlyScope && onlyWithFloorPlanIndex !== null"
      [isGroupCreationScope]="isGroupScope"
      [isReadonlyFromGroupScope]="isGroupReadonlyScope"
      [displayFloors]="true"
      [unitId]="unitId"
      [floorPlan]="floorPlanData"
      [floorDrawVersions]="changeRequest.floorDrawVersions"
      [client]="true"
      [drawVersion]="true"
      [isAlternative]="false"
      [activeIndex]="onlyWithFloorPlanIndex || 0"
      [showOnlyWithFloorPlanIndex]="onlyWithFloorPlanIndex"
    ></atl-plan-drawing>
  </div>
</ng-template>

<ng-template #offerContent>
  <atl-offers-list
    [offers]="offers"
    [projectId]="projectId"
    [changeRequestId]="changeRequest.id"
    [unitId]="unitId"
    [changeRequest]="changeRequest"
    [openActiveOffer]="openActiveOffer"
    (updateOfferList)="updateStateOffer($event)"
    (changeStatus)="changeStatus($event)"
    [isAlLowBankId]="isAlLowBankId"
    [isDualView]="isDualView"
    [bankIdToggle]="bankIdToggle"
    [floorPlanData]="floorPlanData"
    [unit]="unit"
    [loadOffers]="loadOffers"
  ></atl-offers-list>
</ng-template>

<ng-template #customerNotes>
  <atl-change-request-customer-notes
    [threadViewComponent]="threadViewComponent"
    [changeRequest]="changeRequest"
    [unit]="unit"
    [disabled]="isGuest"
    [scope]="isGroupReadonlyScope ? eThreadScopes.ChangeRequestGroups : eThreadScopes.ChangeRequest"
  ></atl-change-request-customer-notes>
</ng-template>
