import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  EThreadScope,
  EUnitRoles,
  ITablePagination,
  IThreadFilterModel,
  IThreadInboxSubFoldersFilter,
  IUnitItem,
  ProjectModel,
  ThreadsFilterItemModel,
} from '@atlas-workspace/shared/models';
import { PaginationUtil, ProjectService, RestThreadsService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { EMPTY, of, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, expand, finalize, map, take, tap } from 'rxjs/operators';

import arrowIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow-down-sm.svg';
import filterIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/filter-button-icon.svg';

import { filterData } from '../../helpers/threads';

enum EThreadFiltersTypes {
  Units = 'units',
  Projects = 'projects',
}

@UntilDestroy()
@Component({
  selector: 'atl-list-of-threads-filter',
  templateUrl: './list-of-threads-filter.component.html',
  styleUrls: ['./list-of-threads-filter.component.scss'],
})
export class ListOfThreadsFilterComponent implements OnInit {
  @Input() firmId?: number;
  @Input() projectId?: number;
  @Input() scope!: EThreadScope;
  @Input() showProjectsFilter = false;

  @Input() set activeFilters(filters: { [key: string]: ThreadsFilterItemModel[] | IThreadInboxSubFoldersFilter }) {
    this.selectedFilters.projects = filters.projects ? (filters.projects as ThreadsFilterItemModel[]) : [];
    this.selectedFilters.units = filters.units ? (filters.units as ThreadsFilterItemModel[]) : [];
  }

  @Output() getFilteredThreadsEvent = new EventEmitter();

  filterDataList: IThreadFilterModel = cloneDeep(filterData);
  filterTypes = EThreadFiltersTypes;

  readonly filterIcon = filterIcon;
  readonly arrowIcon = arrowIcon;
  totalFiltersSelected = 0;
  defaultFilterItems = 5;
  readonly dotDivider = ' · ';
  readonly filterDropdownPosition = 'right';
  readonly dropdownContentTop = 64;
  readonly scopes: typeof EThreadScope = EThreadScope;
  filterExpandState: { [key: string]: boolean } = {
    projects: true,
    units: true,
  };
  showFilterCategory = {
    projects: true,
    units: true,
  };
  filterLabelTranslate = {
    projects: 'Threads.Filter_by_project',
    units: 'Threads.Filter_by_unit',
  };
  public search: FormControl = new FormControl('');
  readonly searchInterval = 500;
  readonly tooltipOpenDelay = 500;
  hideButtonTooltip = false;

  selectedFilters: Record<string, ThreadsFilterItemModel[]> = {};

  private paginate: ITablePagination = cloneDeep({...PaginationUtil.defaultPagination, pageItems: 200});

  public searchParam = '';

  constructor(
    private restThreadsService: RestThreadsService,
    public projectService: ProjectService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.scope !== EThreadScope.Global && !this.showProjectsFilter) {
      delete this.filterDataList.projects;
    }
    this.initFiltersList();
    this.updateTotalFiltersCounter();
    this.initSearchListener();
    if (this.scope === EThreadScope.Global || this.showProjectsFilter) {
      this.getProjects();
    }
    this.getUnitsList();
  }

  get allUnitsSelected(): boolean {
    return !!(
      this.filterDataList.units.length && this.filterDataList.units.length === this.selectedFilters.units?.length
    );
  }

  get allprojectsSelected(): boolean {
    return !!(
      this.filterDataList.projects.length &&
      this.filterDataList.projects.length === this.selectedFilters.projects?.length
    );
  }

  private getProjects(): void {
    this.projectService.projectsList$
      .pipe(
        take(1),
        switchMap((projectsList) => {
          if (this.projectService.isLoadedAllProjects) return of(projectsList);
          return this.projectService.getProjectsChunks(this).pipe(untilDestroyed(this));
        }),
        tap((projects) => this.handleIncomingProjects(projects)),
      )
      .subscribe();
  }

  private handleIncomingProjects(projects: ProjectModel[]): void {
    const projectsArr = this.transformProjectModel(projects);
    const selectedIds = this.selectedFilters.projects?.map((i) => i.id);
    projectsArr.forEach((item, index) => {
      if (selectedIds?.some((id) => id === item.id)) {
        projectsArr[index].selected = true;
      }
    });
    this.filterDataList.projects.push(...projectsArr);
    this.cdr.detectChanges();
  }

  private transformProjectModel(projects: ProjectModel[]): ThreadsFilterItemModel[] {
    return projects.map((p) => {
      return <ThreadsFilterItemModel>{
        id: p.projectId,
        title: p.name,
        selected: false,
      };
    });
  }

  private initFiltersList(): void {
    for (const filterCategory in this.filterDataList) {
      if (!this.selectedFilters[filterCategory]?.length) {
        this.selectedFilters[filterCategory] = [];
      }
    }
  }

  private transformUnitModel(units: IUnitItem[], isSelected = false): ThreadsFilterItemModel[] {
    const selectedUnitIds = this.selectedFilters?.units?.map((u) => u.id);
    return units.map((unit) => {
      return <ThreadsFilterItemModel>{
        id: unit.id,
        title: unit.identifier,
        mainBuyer: unit.buyers.find((buyer) => buyer.role === EUnitRoles.PrimaryOwner),
        selected: !isSelected ? !!selectedUnitIds?.some((id) => id === unit.id) : isSelected,
        projectName: unit.project?.name,
      };
    });
  }

  private getUnitsList(updateFilter = false, selected = false): void {
    let listCleared = false;
    const projectIds = this.selectedFilters.projects?.map((p) => {
      if (p.id) {
        return p.id.toString();
      }
      return '';
    });
    const prIds = this.scope !== EThreadScope.Global && !this.showProjectsFilter && this.projectId ? [this.projectId?.toString()] : projectIds;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.restThreadsService
      .getAllFirmUnits(prIds, this.searchParam, this.paginate)
      .pipe(
        untilDestroyed(this),
        expand((units) => {
          if (units.length === this.paginate.pageItems) {
            this.paginate.currentPage++;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return this.restThreadsService.getAllFirmUnits(prIds, this.searchParam, this.paginate);
          }
          return EMPTY;
        }),
        map((data) => this.transformUnitModel(data, selected)),
        finalize(() => {
          if (updateFilter) {
            this.selectedFilters.units = this.filterDataList.units.filter((u) => u.selected);
            this.getFilteredThreadsEvent.emit(this.selectedFilters);
            this.updateTotalFiltersCounter();
          }
        })
      )
      .subscribe((units) => {
        if (!listCleared) {
          this.filterDataList.units.length = 0;
          listCleared = true;
        }
        this.filterDataList.units.push(...units);
      });
  }

  handleButtonTooltip(value: boolean): void {
    this.hideButtonTooltip = value;
  }

  expandFilterCategory(title: string): void {
    this.filterExpandState[title] = !this.filterExpandState[title];
  }

  public returnZeroForPipe(): number {
    return 0;
  }

  private initSearchListener(): void {
    this.search.valueChanges
      .pipe(untilDestroyed(this), debounceTime(this.searchInterval), distinctUntilChanged())
      .subscribe((res: string) => {
        this.paginate.currentPage = 1;
        this.searchParam = res;
        this.getUnitsList();
      });
  }

  uncheckAllByCategory(filterCategory: EThreadFiltersTypes): void {
    this.checkAllCategoryItems(filterCategory, false);
  }

  checkAllCategoryItems(filterCategory: EThreadFiltersTypes, checked: boolean): void {
    // TODO: This is a temporary solution until the appropriate API is added
    queueMicrotask(() => {
      if (checked) {
        this.selectedFilters[filterCategory] = this.filterDataList[filterCategory].map((i) => {
          i.selected = true;
          return i;
        });
        if (filterCategory === EThreadFiltersTypes.Projects) {
          this.adjustRelativesUnits();
        }
      } else {
        this.selectedFilters[filterCategory] = [];
        this.filterDataList[filterCategory].forEach((item) => {
          item.selected = false;
        });
        if (filterCategory === EThreadFiltersTypes.Projects) {
          this.adjustRelativesUnits();
        }
        if (
          filterCategory === EThreadFiltersTypes.Units &&
          (this.scope === EThreadScope.Global || this.showProjectsFilter) &&
          this.selectedFilters.units.length === 0
        ) {
          this.filterDataList.projects.forEach((p) => (p.selected = false));
          this.adjustRelativesUnits();
        }
      }
      this.updateTotalFiltersCounter();
      this.getFilteredThreadsEvent.emit(this.selectedFilters);
    });
  }

  resetAllFilters(): void {
    for (const key in this.selectedFilters) {
      this.selectedFilters[key] = [];
      if (this.filterDataList[key]) {
        this.filterDataList[key] = this.filterDataList[key].map((item) => {
          item.selected = false;
          return item;
        });
      }
    }
    this.totalFiltersSelected = 0;
    this.getFilteredThreadsEvent.emit(this.selectedFilters);
  }

  setFiltersCount(value: number): void {
    this.defaultFilterItems = value > 1 ? 4 : 5;
  }

  updateSelectedFilterList(checked: boolean, filterCategory: EThreadFiltersTypes, item: ThreadsFilterItemModel): void {
    // TODO: This is a temporary solution until the appropriate API is added
    queueMicrotask(() => {
      const filterListForUpdate = this.selectedFilters[filterCategory];
      if (checked) {
        item.selected = true;
        filterListForUpdate.push(item);
        if (filterCategory === EThreadFiltersTypes.Projects) {
          this.adjustRelativesUnits();
        }
      } else {
        item.selected = false;
        if (filterCategory === EThreadFiltersTypes.Projects) {
          this.adjustRelativesUnits();
        }
        this.removeItemFromArray(filterListForUpdate, item);
        if (
          filterCategory === EThreadFiltersTypes.Units &&
          (this.scope === EThreadScope.Global || this.showProjectsFilter) &&
          this.selectedFilters.units.length === 0
        ) {
          this.filterDataList.projects.forEach((p) => (p.selected = false));
          this.adjustRelativesUnits();
        }
      }
      if (filterCategory !== EThreadFiltersTypes.Projects) {
        this.selectedFilters[filterCategory] = filterListForUpdate;
        this.getFilteredThreadsEvent.emit(this.selectedFilters);
        this.updateTotalFiltersCounter();
      }
    });
  }

  private adjustRelativesUnits(): void {
    this.paginate.currentPage = 1;
    this.selectedFilters.projects = this.filterDataList.projects.filter((p) => p.selected);
    this.getUnitsList(true, !!this.selectedFilters.projects.length);
  }

  removeItemFromArray(sourceArray: ThreadsFilterItemModel[], removableItem: ThreadsFilterItemModel): void {
    let index;
    for (let i = 0; i < sourceArray.length; i++) {
      if (sourceArray[i].id === removableItem.id) {
        index = i;
        sourceArray.splice(index, 1);
        --i;
      }
    }
  }

  private updateTotalFiltersCounter(): void {
    this.totalFiltersSelected = this.selectedFilters.units.length + (this.selectedFilters.projects?.length || 0);
  }

  public onClearInput(e: Event): void {
    e.stopImmediatePropagation();
    this.search.reset();
  }
}
