import {EChangeRequestTab, EChangeRequestTabId} from '@atlas-workspace/shared/models';

export const tableModel = {
  name: {
    name: 'Shared.Entity.Id',
    column: 'identifier',
  },
  subject: {
    name: 'Title.Price',
    column: 'offer_price',
  },
  create: {
    name: 'Entity.Status',
    column: 'status',
  },
  by: {
    name: 'Title.Date',
    column: 'creation_date',
  },
};

export const navigationMenuClient = [
  { name: EChangeRequestTab.General, id: EChangeRequestTabId.General, disabled: false, route: '' },
  { name: EChangeRequestTab.ClientCustomer, id: EChangeRequestTabId.ClientCustomer, disabled: false, route: '', counter: 0 },
  { name: EChangeRequestTab.Offer, id: EChangeRequestTabId.Offer, disabled: false, route: '' },
];
