import { Exclude, Expose, Transform, Type } from 'class-transformer';

import { ChangeRequestModel, ClientChangeRequestModel } from '../models/chenge-request.model';
import { FileModel } from '../models/file.model';
import { ImageModel } from '../models/image.model';
import { IUnitShort, UnitUserModel } from '../models/unit.model';

export enum EThreadScope {
  Global = 'global',
  Project = 'project',
  Unit = 'unit',
  Client = 'client',
  Reclamation = 'reclamation',
  ChangeRequest = 'change_request',
  InternalNotes = 'internal_notes',
  InternalNotesAdmins = 'internal_notes_admins',
  ReclamationThreadView = 'reclamation_thread_view',
  ChangeRequestMessagesView = 'change_request_messages_view',
  ChangeRequestGroups = 'change_request_groups',
}

export enum EThreadUserType {
  MainAdmin = 'MainAdmin',
  User = 'User',
}

export enum EThreadType {
  SingleThread = 'default',
  MultiThread = 'multi_message',
  InternalNotes = 'messageable_internal_notes',
  OnlyAdmins = 'messageable_only_admins'
}

export enum EThreadFolders {
  Drafts = 'drafts',
  Inbox = 'inbox',
  Done = 'done',
  Sent = 'sent',
  All = 'all',
}

export enum EThreadInboxSubFolders {
  Unread = 'unread',
  Unanswered = 'unanswered',
  Answered = 'answered',
}

export enum EThreadQuickFilterKey {
  MyThreads = 'myThreads',
  Default = 'default',
  MultiMessage = 'multiMessage',
  Reclamation = 'reclamation',
  ChangeRequest = 'changeRequest',
  Messageable = 'messageable'
}

export enum EThreadQuickFilterValue {
  MyThreads = 'my_threads',
  Default = 'default',
  MultiMessage = 'multi_message',
  Reclamation = 'reclamation',
  ChangeRequest = 'change_request',
  Messageable = 'messageable'
}

export enum EAttachableType {
  Floor = 'Floor',
  UnitFloor = 'UnitFloor',
  FloorVersion = 'FloorDrawVersion'
}


// * TODO: rename to appropriate name
export type ThreadType = `${EThreadFolders}`;

export type ThreadFoldersCounterType = {
  [key in EThreadFolders]: number;
};

export class ThreadInboxCounterModel {
  @Expose({ name: 'all' })
  all!: number;
  @Expose({ name: 'my_threads' })
  myThreads!: number;
  @Expose({ name: 'unanswered' })
  unanswered!: number;
  @Expose({ name: 'unread' })
  unread!: number;
  @Expose({ name: 'answered' })
  answered!: number;
}

export class ThreadQuickFilterAllModel {
  @Expose({ name: 'all' })
  all!: number;
  @Expose({ name: 'my_threads' })
  myThreads!: number;
}

export class ThreadQuickFilterCounterModel {
  [key: string]: ThreadQuickFilterAllModel;

  @Expose({ name: 'default' })
  @Type(() => ThreadQuickFilterAllModel)
  default!: ThreadQuickFilterAllModel;
  @Expose({ name: 'multi_message' })
  @Type(() => ThreadQuickFilterAllModel)
  multiMessage!: ThreadQuickFilterAllModel;
  @Expose({ name: 'messageable' })
  @Type(() => ThreadQuickFilterAllModel)
  messageable!: ThreadQuickFilterAllModel;
  @Expose({ name: 'my_threads' })
  @Type(() => ThreadQuickFilterAllModel)
  myThreads!: ThreadQuickFilterAllModel;
  @Expose({ name: 'reclamation' })
  @Type(() => ThreadQuickFilterAllModel)
  reclamation!: ThreadQuickFilterAllModel;
  @Expose({ name: 'change_request' })
  @Type(() => ThreadQuickFilterAllModel)
  changeRequest!: ThreadQuickFilterAllModel;
}

export class BatchCreateThreadsBodyModel {
  subject!: string;
  project_id!: number;
  text!: string;
  unit_ids!: number[];
  attachment_ids!: number[];
  mentions!: ThreadMentionUserModel[];
  send_via_sms?: boolean;
}

export class ThreadMentionModel {
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'member_id' })
  id!: number;
  @Expose({ name: 'member_type' })
  type!: EThreadUserType;
  @Expose({ name: 'name' })
  name?: string;
  @Expose({ name: 'email' })
  email?: string;
}

export class ThreadMentionUserModel {
  @Expose({ name: 'name' })
  identifier!: string;
  @Expose({ name: 'id' })
  member_id!: number;
  @Expose({ name: 'type' })
  member_type!: EThreadUserType;
  @Expose({ name: 'email' })
  email?: string;
}

export interface IMessageModelAttachmentsReq {
  attachable_id: number;
  attachable_type: EAttachableType;
}

export interface ThreadUserModel {
  id: number;
  name: string;
  email: string;
  type?: EThreadUserType;
}

export interface IThreadCreatorUser {
  name: string;
  type: EThreadUserType;
}

export interface IThreadUnit {
  id: number;
  identifier: string;
}

export interface IThreadProject {
  id: number;
  name: string;
}

export interface IThreadState {
  read: boolean;
  answered: boolean;
  done: boolean;
}

export class MessageState {
  @Expose({ name: 'read'})
  read!: boolean;
  @Expose({ name: 'delivered'})
  delivered!: boolean;
  @Expose({ name: 'id'})
  id!: number;
}

export class IThreadLastMessage {
  @Expose({ name: 'sender_name' })
  senderName!: string;
  @Expose({ name: 'sender_type' })
  senderType!: EThreadUserType;
  @Expose({ name: 'text' })
  text!: string;
  @Expose({ name: 'created_at' })
  createdAt!: number | string;
}

export class ThreadModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'subject' })
  subject!: string;
  @Expose({ name: 'reclamation_id' })
  reclamationId!: number | null;
  @Expose({ name: 'thread_type' })
  threadType!: EThreadType;
  @Expose({ name: 'units_count' })
  unitsCount!: number;
  @Expose({ name: 'unread_count' })
  unreadCount!: number;
  @Expose({ name: 'units' })
  units!: IThreadUnit[];
  @Expose({ name: 'project' })
  project!: IThreadProject;
  @Expose({ name: 'state' })
  state!: IThreadState;
  @Expose({ name: 'last_message' })
  @Type(() => IThreadLastMessage)
  lastMessage!: IThreadLastMessage | null;
  @Expose({ name: 'creator' })
  creator?: IThreadCreatorUser;
  @Expose({ name: 'archived_at' })
  archivedAt!: string | null;
  @Expose({ name: 'change_request_id' })
  changeRequestId?: number;
  @Expose({ name: 'new_thread' })
  newThread!: boolean;

  selected?: boolean;
  removed?: boolean;
}

export class ThreadViewCangeReqModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description!: string;
}

export class ThreadViewReclamationModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'category' })
  category!: string;
  @Expose({ name: 'type' })
  type!: string;
}

export class ThreadViewModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'subject' })
  subject!: string;
  @Expose({ name: 'reclamation_id' })
  reclamationId!: number | null;
  @Expose({ name: 'change_request_id' })
  changeRequestId!: number | null;
  @Expose({ name: 'units' })
  units!: IThreadUnit[];
  @Expose({ name: 'state' })
  state!: IThreadState;
  @Expose({ name: 'unread_count' })
  unreadCount!: number;
  @Expose({ name: 'thread_type' })
  threadType!: EThreadType;
  @Expose({ name: 'creator' })
  creator?: IThreadCreatorUser;
  @Expose({ name: 'change_request' })
  changeRequest?: ThreadViewCangeReqModel;
  @Expose({ name: 'reclamation' })
  reclamation?: ThreadViewReclamationModel;
}

export class UpdateMultiThreadUnitListResponseModel {
  id!: number;
  @Expose({ name: 'units_count' })
  unitsCount!: number;
  @Expose({ name: 'unit_ids' })
  unitIds!: number[];
}

export class MessageAttachmentsModel {
  @Expose({ name: 'attachable_id' })
  attachableId!: number;
  @Expose({ name: 'version_number' })
  versionNumber!: number;
  @Expose({ name: 'attachable_type' })
  attachableType!: EAttachableType;
}

export class ThreadMessageModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'text' })
  text!: string;
  @Expose({ name: 'thread_id' })
  threadId!: number;
  @Expose({ name: 'created_at' })
  createdAt!: number;
  @Expose({ name: 'mentions' })
  @Type(() => ThreadMentionModel)
  mentions!: ThreadMentionModel[];
  @Expose({ name: 'attachments' })
  @Type(() => ThreadAttachmentModel)
  attachments!: ThreadAttachmentModel[];
  @Expose({ name: 'sender' })
  sender!: ThreadUserModel;
  @Exclude()
  isSelf?: boolean;
  @Exclude()
  dayIdentifier?: string;
  // * clarify implementation of this field!
  @Exclude()
  isDeleted?: boolean;
  @Expose({ name: 'message_model_attachments' })
  @Type(() => MessageAttachmentsModel)
  messagesModelAttachments!: MessageAttachmentsModel[];
  @Expose({ name: 'read'})
  read!: boolean;
  @Expose({name: 'delivered'})
  delivered!: boolean;
}

export class ThreadAttachmentFilenameModel {
  @Expose({ name: 'content_type' })
  contentType!: string;
  @Expose({ name: 'download_url' })
  downloadUrl!: string;
  @Expose({ name: 'url' })
  url!: string;
}

export class ThreadAttachmentModel {
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'file_size' })
  size!: number | null;
  @Expose({ name: 'file_extension' })
  extension!: string | null;
  @Expose({ name: 'position' })
  position!: number;
  @Expose({ name: 'type' })
  type!: string; // WHAT KIND OF TYPES ("file") ????
  @Expose({ name: 'upload_date' })
  uploadDate!: string;
  @Expose({ name: 'filename' })
  @Type(() => ThreadAttachmentFilenameModel)
  fileName!: ThreadAttachmentFilenameModel;
  isImage?: boolean;
}

export class ThreadDraftMessageThreadModel {
  @Expose({ name: 'unit_ids' })
  unitIds?: number[];
  @Expose({ name: 'project_id' })
  projectId?: number;
  @Expose({ name: 'subject' })
  subject!: string;
  projectName?: string;
}
export class ThreadDraftMessageModel {
  @Expose({ name: 'text' })
  text!: string;
  @Expose({ name: 'attachment_ids' })
  attachmentIds!: number[];
  @Expose({ name: 'mentions' })
  @Type(() => ThreadMentionModel)
  mentions!: ThreadMentionModel[];
}
export class CreateDraftRequestBodyModel {
  @Expose({ name: 'project_id' })
  projectId?: number | null;
  @Expose({ name: 'unit_id' })
  unitId?: number | null;
  @Expose({ name: 'firm_id' })
  firmId?: number | null;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: string;
  @Expose({ name: 'message_thread' })
  @Type(() => ThreadDraftMessageThreadModel)
  messageThread!: ThreadDraftMessageThreadModel;
  @Expose({ name: 'message' })
  @Type(() => ThreadDraftMessageModel)
  message!: ThreadDraftMessageModel;
}
export class ThreadDraftParamsModel {
  @Expose({ name: 'message_thread' })
  @Type(() => ThreadDraftMessageThreadModel)
  messageThread!: ThreadDraftMessageThreadModel;
  @Expose({ name: 'message' })
  @Type(() => ThreadDraftMessageModel)
  message!: ThreadDraftMessageModel;
}
export class ThreadDraftUnitModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;

  selected?: boolean;
  type?: EThreadUnitSelectionType;

  constructor() {
    this.selected = false;
  }
}
export class ThreadDraftModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'project_id' })
  projectId?: number | null;
  @Expose({ name: 'firm_id' })
  firmId?: number | null;
  @Expose({ name: 'created_at' })
  createdAt!: number;
  @Expose({ name: 'message_thread' })
  @Type(() => ThreadDraftMessageThreadModel)
  messageThread!: ThreadDraftMessageThreadModel | null;
  @Expose({ name: 'text' })
  text!: string;
  @Expose({ name: 'params' })
  @Type(() => ThreadDraftParamsModel)
  params!: ThreadDraftParamsModel;
  @Expose({ name: 'units' })
  @Type(() => ThreadDraftUnitModel)
  units!: ThreadDraftUnitModel[] | null;
}

export class ThreadDraftInfoModel {
  @Expose({ name: 'params' })
  @Type(() => ThreadDraftParamsModel)
  params!: ThreadDraftParamsModel;
  @Expose({ name: 'attachments' })
  attachments!: (FileModel | ImageModel)[];
  @Expose({ name: 'units' })
  @Type(() => ThreadDraftUnitModel)
  units!: ThreadDraftUnitModel[] | null;
}

export class AllThreadAttachmentModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'sender_name' })
  senderName!: string;
  @Expose({ name: 'created_at' })
  createdAt!: number;
  @Expose({ name: 'file' })
  @Type(() => ThreadAttachmentModel)
  file!: ThreadAttachmentModel;
}

export enum EThreadUnitSelectionType {
  Unit = 'unit',
  UnitGroup = 'unit_group',
}

export class ThreadsFilterItemModel {
  id!: number;
  @Expose({ name: 'identifier' })
  title!: string;
  @Expose({ name: 'main_buyer' })
  mainBuyer?: IUnitMainBuyer;

  request_field?: string;
  value!: string;
  selected?: boolean;

  projectId?: number;
  projectName?: string;
  unitIds?: number[];

  counter?: number;
  key?: string;
  myThreads?: number;

  constructor() {
    this.selected = false;
  }
}

interface IUnitMainBuyer {
  id: number;
  name: string;
}

export interface OnSubmitEditorPropsType {
  message: string;
  documents: File[];
  mentions: ThreadMentionUserModel[];
}

export interface ICreateThreadForm {
  subject: string;
  body: string;
  document: File[];
}

export enum ECableChannelEvents {
  NewMessage = 'messages:new_message',
  ThreadUp = 'messages:message_thread_up',
  ThreadRemove = 'messages:remove_message_thread',
  RemoveMessage = 'messages:remove_message',
  RemoveUnreadMessage = 'messages:remove_unread_message',
  UpdateLastMessage = 'messages:update_last_message',
  StatusChanged = 'messages:messages_status_changed'
}

export interface ICableChannelEmit<T = Record<string, unknown>> {
  event: ECableChannelEvents;
  data: T;
}

export interface IThreadViewShortModel {
  id: ThreadModel['id'];
  project: ThreadModel['project'];
  state: ThreadModel['state'];
  removed?: ThreadModel['removed'];
}

export interface IThreadInboxSubFoldersFilter {
  [key: string]: boolean;
}

export interface IThreadFilterModel {
  [key: string]: ThreadsFilterItemModel[];
}

export interface IThreadExtraData {
  unit?: UnitUserModel;
  changeRequest?: ChangeRequestModel | ClientChangeRequestModel;
}

export class CableChannelUpdateLastMessageEventModel {
  @Expose({ name: 'message_thread_id' })
  messageThreadId!: number;
  @Expose({ name: 'message' })
  @Type(() => IThreadLastMessage)
  lastMessage!: IThreadLastMessage;
}

export class CableChannelRemoveThreadMessageThreadModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'units' })
  units!: { [key: string]: number }[];
}

export class CableChannelRemoveThreadEventModel {
  @Expose({ name: 'message_thread' })
  @Type(() => CableChannelRemoveThreadMessageThreadModel)
  messageThread!: CableChannelRemoveThreadMessageThreadModel;
  @Expose({ name: 'state' })
  state!: IThreadState;
  @Expose({ name: 'unread_count' })
  unreadCount!: number;
}

export class CableChannelRemoveUnreadThreadMessageModel {
  @Expose({ name: 'message_thread' })
  @Type(() => CableChannelRemoveThreadMessageThreadModel)
  messageThread!: CableChannelRemoveThreadMessageThreadModel;
}

export class CableChannelUpdateMessageStatusEventModel {
  @Expose({ name: 'message_thread_id' })
  messageThreadId!: number;
  @Expose({ name: 'messages' })
  @Type(() => MessageState)
  messages!: MessageState[];
}

export interface IDraftRemoveData {
  id: number;
  confirm: boolean;
}

export class ThreadUnitsWithGroupsModel {
  @Expose({ name: 'unit_id' })
  private unitId?: number | null;
  @Expose({ name: 'unit_identifier' })
  private unitIdentifier?: string | null;
  @Expose({ name: 'layout_type_id' })
  private layoutTypeId?: number | null;
  @Expose({ name: 'layout_type_name' })
  private layoutTypeName?: string | null;
  @Expose({ name: 'layout_type_units' })
  public units!: IUnitShort[];
  @Expose({ name: 'unit_buyer' })
  public unitBuyer!: { id: number, name: string, email: string } | null;
  @Expose({ name: 'id' })
  @Transform(({ obj }) => obj.layout_type_id ?? obj.unit_id)
  public id!: number;
  @Expose({ name: 'identifier' })
  @Transform(({ obj }) => obj.layout_type_name ?? obj.unit_identifier)
  public identifier!: string;
  @Expose({ name: 'type' })
  @Transform(({ obj }) => (obj.layout_type_id ? EThreadUnitSelectionType.UnitGroup : EThreadUnitSelectionType.Unit))
  public type!: EThreadUnitSelectionType;

  public selected!: boolean;

  constructor() {
    this.selected = false;
  }
}

export enum ThreadsReclamationFilters {
  All = 'all',
  MyReclamations = 'my_reclamations',
}

export type ThreadViewCounters = {
  [key in ThreadsReclamationFilters]: number;
};

export class ThreadsWithCounterModel {
  @Expose({ name: 'index_counter' })
  indexCounter!: ThreadViewCounters;
  @Expose({ name: 'message_threads' })
  @Type(() => ThreadModel)
  messageThreads!: ThreadModel[];
}

export class MentionUsersModel {
  @Expose({ name: 'character_type' })
  type!: string;
  @Expose({ name: 'character_id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'registered' })
  registered!: boolean;
}
