<div class="global-label">
  <div *ngIf="label" class="input-header">
    <span class="label"> {{ label }}</span>
    <p (click)="goToForgotPage()" *ngIf="showForgotLink" class="link">{{ 'Shared.Entity.Forgot' | translate }}</p>
    <atl-undo-changes
      (countdownFinished)="finishUndo()"
      (undoClick)="cancelUndo()"
      *ngIf="showUndo$ | async"
      [fieldName]="label"
      [undoTimer]="undoTimer$ | async"
    ></atl-undo-changes>
  </div>

  <div *ngIf="icon" class="global-label__icon">
    <img alt="Input icon" src="assets/{{ icon }}" />
  </div>

  <div class="contenteditable__wrapper">
    <input (focus)="focusEvent(contentEditableRef)" class="contenteditable__fake-input-layout global_input" />
    <div
      [class.global_input--clearable]="clearable && inputValue"
      [class.global_input--without-border]="isWithoutBorder"
      class="contenteditable__fake-div"
    >
      <span (click)="focusEvent(contentEditableRef)"><ng-content select="inputPrefixRef"></ng-content></span>
      <div
        #contentEditableRef
        (blur)="startTimer()"
        (input)="changeValue(contentEditableRef.innerText, contentEditableRef)"
        (keydown)="keydown($event)"
        (keyup.enter)="startTimer()"
        [disabled]="disabled"
        [ngModel]="inputValue"
        class="contenteditable__content"
        contenteditable
      ></div>
      <span (click)="focusEvent(contentEditableRef)"><ng-content select="inputSuffixRef"></ng-content></span>
    </div>
  </div>

  <span (click)="clearInput()" *ngIf="clearable && inputValue" class="input__clear-btn">×</span>
</div>
