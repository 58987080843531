import { Pipe, PipeTransform } from '@angular/core';
import { UnitsHelper } from '@atlas-workspace/shared/models';

@Pipe({
  name: 'atlUnitTypeTranslate',
  standalone: true,
})
export class UnitTypeTranslatePipe implements PipeTransform {
  transform(value: string): string {
    return UnitsHelper.unitTypes.find((type) => type.value === value)?.type || '';
  }
}
