import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileValidators } from '@atlas-workspace/shared/form';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  acceptedGlobalExtensions,
  DocumentModel,
  EFileType,
  EReclamationStatusKey,
  FileModel,
  IAttachedFile,
  ImageModel,
  IReclamationEventLog,
} from '@atlas-workspace/shared/models';
import { ReclamationClientService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';

@UntilDestroy()
@Component({
  selector: 'atl-reclamation-client-comment-modal',
  templateUrl: './reclamation-client-comment-modal.component.html',
  styleUrls: ['./reclamation-client-comment-modal.component.scss'],
})
export class ReclamationClientCommentModalComponent implements OnInit, OnDestroy {
  @Input() modalRef!: NgbModalRef;
  @Input() reclamationId!: number;
  @Input() onlyView!: boolean;
  @Input() reclamationEventLog?: IReclamationEventLog;
  @Input() status!: EReclamationStatusKey;
  @Output() declineEvent = new EventEmitter<boolean>();

  private unitId!: number;
  public isLoading = false;
  public form!: FormGroup;
  public readonly maxNameLength = 12;
  private readonly maxCommentLength = 300;
  public readonly maxAttachmentsAmount = 10;
  public readonly acceptedExtensions = acceptedGlobalExtensions;
  public modalInfo!: any;
  public fileResources: (FileModel | ImageModel)[] = [];
  public statusKeys = EReclamationStatusKey;

  constructor(
    private fb: FormBuilder,
    private modalHelpersService: ModalHelpersService,
    private reclamationService: ReclamationClientService,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.unitId = this.route.snapshot?.parent?.parent?.params?.unitId;

    this.initModalInfo();
    this.initForm();
  }

  private initModalInfo(): void {
    if (this.status === EReclamationStatusKey.Declined) {
      this.modalInfo = {
        title: this.onlyView
          ? this.translate.instant('Shared.Reclamation.Declinement_details')
          : this.translate.instant('Shared.Reclamation.Decline_reclamation'),
        button: this.translate.instant('Shared.Reclamation.Button.Decline'),
        status: this.status,
      };
    } else if (this.status === EReclamationStatusKey.Disputed) {
      this.modalInfo = {
        title: this.onlyView
          ? this.translate.instant('Shared.Reclamation.Disputed_details')
          : this.translate.instant('Shared.Reclamation.Dispute_reclamation'),
        button: this.translate.instant('Shared.Reclamation.Button.Dispute'),
        status: this.status,
      };
    }
  }

  private initForm(): void {
    if (this.onlyView && this.reclamationEventLog?.file_resources.length) {
      this.fileResources = this.reclamationEventLog?.file_resources.map((file) => {
        if (FileValidators.isImageExtension(file)) {
          file.type = EFileType.Image;
          file = plainToClass(ImageModel, file);
        } else {
          file.type = EFileType.File;
          file = plainToClass(FileModel, file);
        }
        return file;
      });
    }

    this.form = this.fb.group({
      reason: [
        {
          value: this.status === EReclamationStatusKey.Declined ? this.reclamationEventLog?.event_reason.name : '',
          disabled: true,
        },
        [],
      ],
      comment: [
        {
          value: this.onlyView ? this.reclamationEventLog?.comment : '',
          disabled: this.onlyView,
        },
        [Validators.required, Validators.maxLength(this.maxCommentLength)],
      ],
      files: [
        {
          value: this.onlyView && this.fileResources.length ? this.fileResources : [],
          disabled: this.onlyView,
        },
        [],
      ],
    });
  }

  private createFormData(): FormData {
    const formValue = this.form.getRawValue();
    const body = new FormData();

    body.append('reclamation_event_log[comment]', formValue.comment);

    formValue.files?.forEach((file: FileModel | ImageModel) => {
      const formFile: File = file as unknown as File;
      body.append('reclamation_event_log[file_resources_attributes][][filename]', formFile, formFile.name);
    });

    return body;
  }

  disputeReclamation(): void {
    this.createFormData();
    this.isLoading = true;
    this.reclamationService
      .changeReclamationStatus(this.unitId, this.reclamationId, EReclamationStatusKey.Disputed, this.createFormData())
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.modalRef.close();
          this.isLoading = false;
          this.declineEvent.emit();
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  openFilePreview(file: IAttachedFile): void {
    let document = null;
    if (this.onlyView) {
      document = file;
    } else {
      document = {
        fileExtension: file.format,
        fileSize: file.size,
        name: file.title,
        fileName: {
          downloadUrl: file.link,
          url: file.link,
        },
      };
    }
    this.modalHelpersService.previewDocument(document as DocumentModel);
  }

  ngOnDestroy(): void {
    this.fileResources = [];
    this.modalRef.close();
  }
}
