import { Injectable } from '@angular/core';
import { DocumentModel, IDocument, ITablePagination } from '@atlas-workspace/shared/models';
import { DataTableHelperService, ProjectDocumentsService } from '@atlas-workspace/shared/service';
import { Select, Store } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { defaultState } from '../default-state';
import {
  AddDocument,
  RemoveDocuments,
  SetDocument,
  SetDocuments,
  SetDocumentsPagination,
  SetDocumentsTotalFirstLoading,
} from './documents.action';
import { DocumentStateModel } from './documents.model';
import { DocumentsState } from './documents.state';

@Injectable({
  providedIn: 'root',
})
export class DocumentsFacadeService {
  @Select(DocumentsState.selectStateData) private _documents$!: Observable<DocumentStateModel>;

  constructor(
    protected documentsService: ProjectDocumentsService,
    private store: Store,
    private dataTableHelper: DataTableHelperService
  ) {}

  get documents(): Observable<DocumentStateModel> {
    return this._documents$.pipe(share());
  }

  public getDocuments(
    projectId: number,
    apiUrl: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
    folderId: string | null = ''
  ): Observable<DocumentModel[]> {
    return this.documentsService.getDocumentsState(projectId, apiUrl, sort, search, paginate, folderId).pipe(
      tap((returnData) => {
        const pagination = this.dataTableHelper.getPagination(returnData);

        if (this.store.snapshot().documentsState.firstLoad) {
          this.store.dispatch(new SetDocumentsTotalFirstLoading(false, pagination.totalCount));
        }

        this.setPagination(pagination);
      }),
      map((res) => res.body.data.documents),
      map((data: IDocument[]) => plainToClass(DocumentModel, data)),
      tap((returnData) => {
        if (this.store.snapshot().documentsState.pagination.currentPage === 1 && sort === 'name_asc' && !search) {
          this.setDocuments(returnData);
        }
      })
    );
  }

  setDocuments(documents: DocumentModel[]): void {
    this.store.dispatch(new SetDocuments(cloneDeep(documents)));
  }

  setChangedDocument(update: DocumentModel): void {
    this.store.dispatch(new SetDocument(update));
  }

  addCreatedDocument(document: DocumentModel): void {
    this.store.dispatch(new AddDocument(document));
  }

  removeDocumentsLocal(ids: DocumentModel[]): void {
    const idsArray = ids.map((x) => x.id);
    this.store.dispatch(new RemoveDocuments(idsArray));
  }

  setPagination(pagination: ITablePagination = defaultState.documentsState.pagination): void {
    this.store.dispatch(new SetDocumentsPagination(pagination));
  }
}
