<div class="task-stat card">
  <div class="task-stat__main">
    <span class="task-stat__name"> {{ statName }} </span>
    <span class="task-stat__value"> {{ statValue }} </span>
    <span class="task-stat__value element--mobile"> {{ statValue }} / {{ totalValue }}</span>
  </div>
  <div class="task-stat__chart" [class.checkbox-box]="isWithCheckbox">
    <ng-template [ngIf]="showPercents">
      <ng-content> </ng-content>
    </ng-template>
  </div>
</div>
