import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ClickOutsideDirective, FileDragNDropModule } from '@atlas-workspace/shared/directives';
import { ICroppingData } from '@atlas-workspace/shared/models';
import { ModalFacadeService, ToasterService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { INewestCoverImage } from '../../interface/newest-cover-image';
import { ImageCropModalComponent } from '../image-crop-modal/image-crop-modal.component';

@Component({
  selector: 'atl-newest-input-image',
  templateUrl: './newest-input-image.component.html',
  styleUrls: ['./newest-input-image.component.scss'],
  standalone: true,
  imports: [CommonModule, FileDragNDropModule, TranslateModule, ClickOutsideDirective],
})
export class NewestInputImageComponent {
  @Input() parameter!: string;
  @Output() croppedImageHandler = new EventEmitter<FormData | null>();

  @ViewChild('inputFileRef') inputFileRef!: ElementRef;

  private modalRef!: NgbModalRef;
  public imagesDefaultListOfMimeTypes = ['image/jpeg', 'image/png'];
  public imagePreview!: string | ArrayBuffer | null;
  private imageValue: INewestCoverImage = {
    originalImage: null,
    croppedImage: null,
  };
  public isShowModal = false;

  constructor(
    private modalFacadeService: ModalFacadeService,
    private toasterService: ToasterService,
    private translateService: TranslateService
  ) {}

  public async cropCurrentImage(): Promise<any> {
    const originalFile = this.imageValue.originalImage;
    this.openCropModal(originalFile, new Event('open'), this.imageValue.croppedImage?.cropperPosition).then(
      () => null,
      (reason) => {
        if (reason !== 'close' && reason !== 0) {
          this.imageValue.croppedImage = reason;
          this.generateImageUrl(reason.image);
          this.updateProjectImage(reason, originalFile as File);
        }
      }
    );
  }

  public cropNewImage(e: Event | DragEvent): void {
    const originalFile = (<DragEvent>e).dataTransfer?.files?.item(0) || (<HTMLInputElement>e.target).files?.item(0);
    this.inputFileRef.nativeElement.value = '';
    if (!originalFile || !this.imagesDefaultListOfMimeTypes.includes(originalFile.type)) {
      this.toasterService.openErrorToast(
        `${originalFile?.name} ${this.translateService.instant('Shared.Entity.Format_not_supported')}`
      );
      return;
    }
    this.openCropModal(originalFile, e).then(
      () => null,
      (reason) => {
        if (reason !== 'close' && reason !== 0) {
          this.imageValue.croppedImage = reason;
          this.imageValue.originalImage = originalFile;
          this.generateImageUrl(reason.image);
          this.updateProjectImage(reason, originalFile);
        }
      }
    );
  }

  generateImageUrl(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      this.imagePreview = reader.result;
    };
  }

  private updateProjectImage(data: ICroppingData, file: File): void {
    const formData = new FormData();
    formData.append(`${this.parameter}[cropping_data][width]`, data?.width);
    formData.append(`${this.parameter}[cropping_data][height]`, data?.height);
    formData.append(`${this.parameter}[cropping_data][imagePosition][x1]`, data?.imagePosition?.x1.toString());
    formData.append(`${this.parameter}[cropping_data][imagePosition][y1]`, data?.imagePosition?.y1.toString());
    formData.append(`${this.parameter}[cropping_data][imagePosition][x2]`, data?.imagePosition?.x2.toString());
    formData.append(`${this.parameter}[cropping_data][imagePosition][y2]`, data?.imagePosition?.y2.toString());
    formData.append(`${this.parameter}[cropping_data][cropperPosition][x1]`, data?.cropperPosition?.x1.toString());
    formData.append(`${this.parameter}[cropping_data][cropperPosition][y1]`, data?.cropperPosition?.y1.toString());
    formData.append(`${this.parameter}[cropping_data][cropperPosition][x2]`, data?.cropperPosition?.x2.toString());
    formData.append(`${this.parameter}[cropping_data][cropperPosition][y2]`, data?.cropperPosition?.y2.toString());
    formData.append(`${this.parameter}[preview]`, file);

    this.croppedImageHandler.emit(formData);
  }

  private openCropModal(file: File | null, event: Event, cropperPosition?: any): Promise<File> {
    this.modalRef = this.modalFacadeService.openModal(ImageCropModalComponent, {
      modalDialogClass: 'wider-modal',
      windowClass: 'custom-modal-main centered-modal-crop-image',
    });
    this.modalRef.componentInstance.originalImage = file;
    this.modalRef.componentInstance.event = event;
    this.modalRef.componentInstance.cropperPosition = cropperPosition || null;
    this.modalRef.componentInstance.newConfirmBtnPosition = true;
    this.modalRef.componentInstance.confirmBtnText = 'Title.Save_changes';

    return this.modalRef.result;
  }

  public onToggleMenu(): void {
    this.isShowModal = !this.isShowModal;
  }

  public onCloseMenu(): void {
    this.isShowModal = false;
  }

  public removeFile(): void {
    this.imageValue = {
      originalImage: null,
      croppedImage: null,
    };
    this.imagePreview = null;
    this.onCloseMenu();
    this.croppedImageHandler.emit(null);
  }
}
