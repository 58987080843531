import { ITablePagination, MeetingsCountersType, MeetingsModel } from '@atlas-workspace/shared/models';

export class SetMeetings {
  static readonly type = '[Meetings] save change meetings local';

  constructor(public meetings: MeetingsModel[]) {}
}

export class SetMeeting {
  static readonly type = '[Meetings] save change meeting local';

  constructor(public meeting: MeetingsModel) {}
}

export class AddMeeting {
  static readonly type = '[Meetings] add meeting local';

  constructor(public length?: number) {}
}

export class RemoveMeeting {
  static readonly type = '[Meetings] remove meeting local';

  constructor(public ids: number[]) {}
}

export class MeetingsSetPagination {
  static readonly type = '[Meetings] set pagination';

  constructor(public pagination: ITablePagination) {}
}

export class MeetingsSetTotalFirstLoading {
  static readonly type = '[Meetings] set total first loading';

  constructor(public firstLoad: boolean, public totalCount: number) {}
}

export class SetMeetingsCounters {
  static readonly type = '[Meetings] set counters';

  constructor(public counters: MeetingsCountersType) {}
}

export class SetMeetingsTodayCounter {
  static readonly type = '[Meetings] set today counter';

  constructor(public todayMeetingsCount: number) {}
}
