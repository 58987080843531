import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthAdminService } from '@atlas-workspace/shared/service';
import { Observable } from 'rxjs';

@Injectable()
export class AdminFirmIdInterceptor implements HttpInterceptor {
  constructor(private authAdminService: AuthAdminService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;
    if (!request.params.has('firm_id') && !(url.includes('amazonaws.com') || url.includes('api/v1/files/proxy'))) {
      if (this.authAdminService.firm?.firmId) {
        request = request.clone({
          params: request.params.set('firm_id', String(this.authAdminService.firm?.firmId)),
        });
      }
    }
    return next.handle(request);
  }
}
