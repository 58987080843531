import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  SetDateTimeModalComponent
} from "@atlas-admin/shared/components/set-date-time-modal/set-date-time-modal.component";
import { UnitRecipientsComponent } from '@atlas-admin/shared/components/unit-recipients/unit-recipients.component';
import { DataTableModule } from '@atlas-workspace/data-table';
import { BlockResizeListenerDirective, SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedFormModule, TimeDropdownComponent } from '@atlas-workspace/shared/form';
import { SimpleModalWrapperModule } from '@atlas-workspace/shared/modals';
import { JoinStringArrayPipeModule, SharedPipesModule, UnderscoreReplacerModule } from '@atlas-workspace/shared/pipes';
import { ReclamationCreateConfirmModule } from '@atlas-workspace/shared/reclamation';
import { PageHelperService } from '@atlas-workspace/shared/service';
import {
  ActionMessageBannerComponent,
  CustomIconComponent,
  FirmIconComponent, HeaderIconComponent,
  NotFoundModule, SharedTableComponent,
  SimpleTabsComponent,
  TableSortModule,
} from '@atlas-workspace/shared/ui';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SettingsModule } from '../settings/settings.module';
import { AdminLeftMenuComponent } from './components/admin-left-menu/admin-left-menu.component';
import { DeactivateUserComponent } from './components/deactivate-user/deactivate-user.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { NewestFooterModalComponent } from './components/newest-footer-modal/newest-footer-modal.component';
import { NewestHeaderModalComponent } from './components/newest-header-modal/newest-header-modal.component';
import { NewestRecipientsComponent } from './components/newest-recipients/newest-recipients.component';
import { ProfileResetPasswordComponent } from './components/profile-reset-password/profile-reset-password.component';
import { TaskStatusComponent } from './components/task-status/task-status.component';
import { TermsConditionsFormComponent } from './components/terms-conditions-form/terms-conditions-form.component';
import { ThreadFoldersModule } from './components/thread-folders/thread-folders.module';
import { TransferPermissionProjectComponent } from './components/transfer-permission-project/transfer-permission-project.component';
import { FirmService } from './services/firm-service/firm.service';

@NgModule({
  declarations: [
    HeaderComponent,
    DeactivateUserComponent,
    ProfileResetPasswordComponent,
    TransferPermissionProjectComponent,
    HeaderNavComponent,
    AdminLeftMenuComponent,
    TaskStatusComponent,
    NewestHeaderModalComponent,
    NewestFooterModalComponent,
    UnitRecipientsComponent,
    NewestRecipientsComponent,
    TermsConditionsFormComponent,
    SetDateTimeModalComponent,
  ],
  providers: [PageHelperService, FirmService],
  exports: [
    HeaderComponent,
    HeaderNavComponent,
    AdminLeftMenuComponent,
    TaskStatusComponent,
    NewestHeaderModalComponent,
    NewestFooterModalComponent,
    UnitRecipientsComponent,
    NewestRecipientsComponent,
    TermsConditionsFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedFormModule,
    SharedPipesModule,
    NgbCollapseModule,
    ReactiveFormsModule,
    SettingsModule,
    UnderscoreReplacerModule,
    SharedDirectivesModule,
    NgbTooltipModule,
    DataTableModule,
    TableSortModule,
    FormsModule,
    ThreadFoldersModule,
    SimpleModalWrapperModule,
    NotFoundModule,
    JoinStringArrayPipeModule,
    ReclamationCreateConfirmModule,
    TimeDropdownComponent,
    SimpleTabsComponent,
    SharedTableComponent,
    FirmIconComponent,
    CustomIconComponent,
    TimeDropdownComponent,
    HeaderIconComponent,
    ActionMessageBannerComponent,
    BlockResizeListenerDirective,
  ],
})
export class SharedModule {}
