<div class="loader">
  <div class="d-flex flex-column w-100">
    <div class="d-inline-flex align-items-center justify-content-between w-100 h-48">
      <div class="title fz-14 fw-500 lh-24 ml-16">{{ title }}...</div>
      <div class="close-icon cursor-pointer mr-16" (click)="close()">
          <img src="/assets/close_newest_gray.svg" alt="Close">
      </div>
    </div>

    <div class="loader__progress pt-12 h-40 d-flex align-items-center">
      <div class="loader__progress-bar" [style.width.%]="completedPercentage" [style.background-color]="getProgressBarColor()"></div>
      <p class="fz-12 fw-600 lh-16 text-black-810 ml-16 z-index-2">{{completedPercentage}}% <span class="lowercase">{{'Entity.Wishlist_status_completed' | translate}}</span></p>
    </div>

  </div>
</div>
