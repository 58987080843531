import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '@atl-libs-shared-pipes/shared-pipes.module';
import { DetectZoomModule, SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { ConfirmDropdownComponent, NewestTextEditorModule, SharedFormModule, TextEditorWideComponent } from '@atlas-workspace/shared/form';
import {
  CustomIconComponent,
  CustomTooltipModule, FirmIconComponent, MoreDropdownComponent,
  SimpleTabsComponent,
  UsersViewerComponent
} from '@atlas-workspace/shared/ui';
import {NgbActiveModal, NgbDropdownItem, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

import { ReclamationAdminActivitiesComponent } from '../reclamation-admin-activities/reclamation-admin-activities.component';
import { ReclamationAdminInternalNotesModule } from '../reclamation-admin-internal-notes/reclamation-admin-internal-notes.module';
import { ReclamationAdminMessagesModule } from '../reclamation-admin-messages/reclamation-admin-messages.module';
import { ReclamationAdminDetailComponent } from './reclamation-admin-detail.component';

@NgModule({
  declarations: [ReclamationAdminDetailComponent, ReclamationAdminActivitiesComponent],
  exports: [ReclamationAdminDetailComponent],
  imports: [
    CommonModule,
    SharedFormModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    NgbTooltipModule,
    DetectZoomModule,
    ReclamationAdminInternalNotesModule,
    ReclamationAdminMessagesModule,
    CustomTooltipModule,
    NewestTextEditorModule,
    ConfirmDropdownComponent,
    UsersViewerComponent,
    SharedPipesModule,
    TextEditorWideComponent,
    SimpleTabsComponent,
    CustomIconComponent,
    FirmIconComponent,
    MoreDropdownComponent,
    NgbDropdownItem
  ],
  providers: [NgbActiveModal],
})
export class ReclamationAdminDetailModule {}
