import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { ETermsConditionSettingsType, TermsConditionsDataModel } from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { map,Observable } from 'rxjs';

@Injectable()
export class TermsConditionsService {
  constructor(@Inject('ENVIRONMENT') private environment: IEnvironment, private readonly http: HttpClient) {}

  setReclamationTermsSettings(
    projectId: number,
    data: Partial<TermsConditionsDataModel>,
    type: ETermsConditionSettingsType
  ): Observable<TermsConditionsDataModel> {
    const fd = new FormData();
    if (data.termsTitle) fd.append('terms_conditions[name]', data.termsTitle);
    if (data.termsDescription) fd.append('terms_conditions[description]', data.termsDescription);
    if (data.termsCheckbox !== undefined)
      fd.append('terms_conditions[show_confirmation_checkbox]', data.termsCheckbox.toString());
    if (data.termsCheckboxLabel) fd.append('terms_conditions[confirmation_info]', data.termsCheckboxLabel);
    const params = new HttpParams().append('type', type);
    return this.http
      .patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/terms_conditions`, fd, { params })
      .pipe(
        map((res: any) => res.data),
        map((res) => plainToClass(TermsConditionsDataModel, res))
      );
  }
}
