import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  AtlCell,
  AtlCellDef,
  AtlColumnDef,
  AtlFooterCell,
  AtlFooterCellDef,
  AtlHeaderCell,
  AtlHeaderCellDef
} from './cell';
import { AtlTableComponent } from './data-table';
import { AtlFooterRow, AtlFooterRowDef, AtlHeaderRow, AtlHeaderRowDef, AtlRow, AtlRowDef } from './row';

const EXPORTED_DECLARATIONS = [
  // Table
  AtlTableComponent,
  // Template defs
  AtlHeaderRowDef,
  AtlRowDef,
  AtlColumnDef,
  AtlHeaderCellDef,
  AtlCellDef,
  AtlFooterCellDef,
  AtlFooterRowDef,
  // Cell directives
  AtlHeaderCell,
  AtlCell,
  AtlFooterCell,
  // Row directives
  AtlHeaderRow,
  AtlRow,
  AtlFooterRow
];

@NgModule({
  declarations: [...EXPORTED_DECLARATIONS],
  exports: [...EXPORTED_DECLARATIONS],
  imports: [CommonModule, CdkTableModule]
})
export class DataTableModule {}
