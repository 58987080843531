<div class="header d-flex align-items-center justify-content-between">
  <div>
    <h2 class="fz-16 lh-24 fw-500">{{ title | translate }}</h2>
  </div>
  <div
    class="preview__close-wrapper"
    [ngbTooltip]="'Alt.Close' | translate"
    [openDelay]="tooltipDelay"
    triggers="hover touchstart"
    placement="bottom"
  >
    <img (click)="closeModal()" class="cursor-pointer" src="/assets/close_newest_gray.svg" alt="Icon" />
  </div>
</div>

<div class="d-flex align-items-center justify-content-center v-scrollbar">
  <div class="preview__content" *ngIf="update">
    <h3 class="fz-24 fw-500 lh-32 mt-24">{{ update.name }}</h3>

    <div *ngIf="update.creator" class="d-inline-flex align-items-center mt-24">
      <atl-firm-icon class="mr-8" [isUser]="true" [model]="update.creator" [nameKey]="'name'"></atl-firm-icon>
      <p class="fw-600 fz-14 lh-24 mr-8">{{ update.creator?.name }}</p>
      <span class="text-black-810 fz-12 lh-16"> {{ update.createdAt | timePassed }}</span>
    </div>

    <div class="mt-24 text-black-810">
      <atl-shadow-dom-content class="heading-black empty-font-size">
        <div
          *ngIf="update.description"
          class="options-introduction__content"
          [innerHTML]="update.description | safeHtml"
        ></div>
      </atl-shadow-dom-content>
    </div>

    <div class="mt-12 d-flex flex-wrap">
      <ng-container *ngIf="!update?.id; else updateFiles">
        <atl-newest-document-card
          *ngFor="let file of update.fileResources; index as i"
          class="mr-12 mt-12 pointer-events-none"
          [fileName]="file.name | truncate: maxNameLength"
          [fileSize]="file.size"
          [fileType]="file.fileExtension"
          [hasRemove]="false"
          [hasDownload]="false"
        ></atl-newest-document-card>
      </ng-container>
      <ng-template #updateFiles>
        <atl-newest-document-card
          *ngFor="let file of unsavedFiles"
          class="mr-12 mt-12"
          [downloadLink]="file.fileName?.downloadUrl"
          [fileName]="file.name | truncate: maxNameLength"
          [fileSize]="file.size || file.fileSize"
          [fileType]="file.fileExtension"
          [hasRemove]="true"
          [hasDownload]="true"
          [class.pointer-events-none]="!file.id"
          (click)="onPreviewDocument(file)"
          (removeFileHandler)="removeFile(file)"
        ></atl-newest-document-card>
      </ng-template>
    </div>
  </div>
</div>
