<div class="reclamation__wrapper" (atlCloseModalEsc)="closeModal()">
  <div class="reclamation__header" [class.show-border]="disableOpenReclamation || isDualView">
    <div class="reclamation__header-title">
      {{
        'Shared.Reclamation.Reclamation_details'
          | translate: { title: reclamation ? (reclamation.identifier | afterSlash) : '' }
      }}
    </div>
    <div class="reclamation__header-action">
      <div class="gap-4">
        <ng-container *ngIf="!disableOpenReclamation">
          <ng-container *ngIf="showSwitchArrows">
            <atl-header-icon
              (click)="onChangeReclamation(positionControl.Next)"
              class="client"
              icon="/assets/arrow-gray-down.svg"
              [ngbTooltip]="'Shared.Alt.Next' | translate"
              [openDelay]="tooltipDelay"
              triggers="hover touchstart"
            ></atl-header-icon>
            <atl-header-icon
              (click)="onChangeReclamation(positionControl.Previous)"
              class="client"
              icon="/assets/arrow-gray-up.svg"
              [ngbTooltip]="'Shared.Alt.Prev' | translate"
              [openDelay]="tooltipDelay"
              triggers="hover touchstart"
            ></atl-header-icon>
          </ng-container>
        </ng-container>
        <div class="store-drop-down" (clickOutside)="close()" atlClickOutside>
          <atl-header-icon
            [class.active]="expanded"
            (click)="toggle()"
            class="client"
            icon="/assets/dropdown-dot-2.svg"
            [ngbTooltip]="'Shared.Button.More' | translate"
            [openDelay]="tooltipDelay"
            triggers="hover touchstart"
          ></atl-header-icon>
          <div *ngIf="expanded" class="store-drop-down__expand">
            <div (click)="copyUrl()" class="store-drop-down__expand-button">
              <span class="store-drop-down__icon-wrapper">
                <img src="/assets/unit_link.svg" alt="link option" />
              </span>
              <span>{{ 'Button.Copy_reclamation_link' | translate }}</span>
              <img
                class="store-drop-down__expand-button-command d-none"
                src="/assets/shift-command.svg"
                alt="key icon"
              />
            </div>
          </div>
        </div>
        <atl-header-icon
          (click)="closeModal()"
          class="client"
          icon="/assets/close_newest_gray.svg"
          [ngbTooltip]="'Alt.Close' | translate"
          [openDelay]="tooltipDelay"
          triggers="hover touchstart"
        ></atl-header-icon>
      </div>
    </div>
  </div>

  <div class="reclamation__tabs" [class.d-none]="disableOpenReclamation || isDualView">
    <atl-simple-tabs
      class="tabs"
      [activeTab]="activeTab"
      [tabs]="navigationMenu"
      [routeNavigation]="routeNavigation"
      [useTooltip]="true"
      (selectingTab)="selectAnotherTab($event)"
    ></atl-simple-tabs>
  </div>

  <ng-template [ngIf]="reclamationTabs.General === activeTab">
    <div class="reclamation v-scrollbar">
      <ng-container *ngIf="!reclamationLoading; else spinnerBock">
        <div class="reclamation__info" *ngIf="reclamation">
          <!-- Status -->
          <div class="reclamation__row">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.status.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.status.title | translate }}
              </span>
            </div>
            <div class="reclamation__value status">
              <div
                class="fz-14 lh-24 pl-8 text-black-960 ellipsis reclamation__status"
                [ngClass]="'reclamation__status-' + statusList[reclamation.status].style"
              >
                <span class="reclamation__status-indicator"></span>
                {{ reclamation.localizedStatus | translate }}
              </div>

              <div
                *ngIf="reclamation.status === statusKeys.Declined || reclamation.status === statusKeys.Disputed"
                class="reclamation__event-detail cursor-pointer fz-14 lh-24 ml-12"
                (click)="openCommentModal(true, reclamation.status)"
              >
                {{ 'Shared.Reclamation.Details' | translate }}
                <atl-custom-icon class="arrow-icon" [icon]="arrowRight"></atl-custom-icon>
              </div>
            </div>
          </div>
          <!-- Category -->
          <div class="reclamation__row">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.category.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.category.title | translate }}
              </span>
            </div>
            <div class="reclamation__value d-flex category" [class.empty-val]="!reclamation.type?.category?.name">
              {{ reclamation.type?.category?.name || ('Shared.Reclamation.No_category' | translate) }}
            </div>
          </div>
          <!-- Type -->
          <div class="reclamation__row">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.type.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.type.title | translate }}
              </span>
            </div>
            <div class="reclamation__value d-flex type" [class.empty-val]="!reclamation.type?.name">
              {{ reclamation.type?.name || ('Shared.Reclamation.No_type' | translate) }}
            </div>
          </div>
          <!-- Address -->
          <div class="reclamation__row" *ngIf="reclamation?.unit?.address">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.address.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.address.title | translate }}
              </span>
            </div>
            <div class="reclamation__value pt-4">
              <span class="fz-14 lh-24 word-break white-space-normal">{{ reclamation?.unit?.address }}</span>
            </div>
          </div>
          <!-- Description -->
          <div class="reclamation__row">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.description.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.description.title | translate }}
              </span>
            </div>
            <div class="reclamation__value description" [class.empty-val]="!reclamation.description">
              <ng-container *ngIf="reclamation.description; else emptyDescription">
                <atl-textarea-autoresize
                  class="newest-textarea reclamation-description"
                  [(ngModel)]="reclamation.description"
                  [stringMode]="true"
                  [autoresizeOffset]="2"
                  [tabindex]="0"
                  id="reclamation-description"
                  [disabled]="true"
                  [capitalize]="false"
                  [absolutePosition]="true"
                ></atl-textarea-autoresize>
              </ng-container>
              <ng-template #emptyDescription>
                <span>{{ 'Shared.Reclamation.No_description' | translate }}</span>
              </ng-template>
            </div>
          </div>
          <!-- Room -->
          <div class="reclamation__row d-none">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.room.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.room.title | translate }}
              </span>
            </div>
            <div class="reclamation__value" [class.empty-val]="!reclamation.room?.name">
              {{ reclamation.room?.name || ('Shared.Reclamation.No_room' | translate) }}
            </div>
          </div>
          <!-- Product -->
          <div class="reclamation__row d-none">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.product.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.product.title | translate }}
              </span>
            </div>
            <div class="reclamation__value" [class.empty-val]="!reclamation.wishlistItem">
              <ng-container *ngIf="reclamation.wishlistItem; else noProduct">
                <img
                  [src]="reclamation.wishlistItem?.preview?.filename?.url || 'assets/no_images.png'"
                  alt="product image"
                />
                {{ reclamation.wishlistItem?.name }}
              </ng-container>
              <ng-template #noProduct>
                {{ 'Shared.Reclamation.No_product' | translate }}
              </ng-template>
            </div>
          </div>
          <!-- Responsible -->
          <div class="reclamation__row">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.responsible.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.responsible.title | translate }}
              </span>
            </div>
            <div class="reclamation__value d-flex align-items-center" [class.empty-val]="!reclamation.responsible">
              <ng-container *ngIf="reclamation.responsible; else emptyResponsible">
                <atl-firm-icon
                  *ngIf="reclamation.responsible"
                  [model]="reclamation.responsible"
                  nameKey="name"
                  [isUser]="true"
                  [isMonochrome]="true"
                ></atl-firm-icon>
                {{ reclamation.responsible?.name }}
              </ng-container>
              <ng-template #emptyResponsible>
                {{ 'Shared.Reclamation.No_responsible' | translate }}
              </ng-template>
            </div>
          </div>
          <!-- Meeting -->
          <div class="reclamation__row" *ngIf="reclamation?.meeting?.meetingName">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.meeting.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.meeting.title | translate }}
              </span>
            </div>
            <div class="reclamation__value">
              {{ reclamation.meeting?.meetingName }}
            </div>
          </div>
          <!-- Deadline -->
          <div class="reclamation__row">
            <div class="reclamation__field">
              <img [src]="fieldsHelper.deadline.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.deadline.title | translate }}
              </span>
            </div>
            <div class="reclamation__value" [class.empty-val]="!reclamation.dueDate">
              {{ (reclamation.dueDate | date: 'dd.MM.YYYY') || ('Shared.Reclamation.No_due_date' | translate) }}
            </div>
          </div>

          <!-- Attachments -->
          <div class="reclamation__row">
            <div class="reclamation__field attachments">
              <img [src]="fieldsHelper.attachments.icon" alt="icon" />
              <span class="reclamation__field-text">
                {{ fieldsHelper.attachments.title | translate }}
              </span>
            </div>
            <div class="reclamation__value" [class.empty-val]="!reclamation.fileResources?.length">
              <atl-dropdown-input-file
                *ngIf="reclamation.fileResources.length; else noAttachments"
                class="reclamation-detail-dropdown"
                [placeholder]="'Shared.Entity.Add_attachments' | translate"
                [disabled]="true"
                [disableChangePosition]="true"
                [previewAttachment]="reclamation.fileResources"
                (viewTempFilesEmitter)="openPreview($event)"
              ></atl-dropdown-input-file>
              <ng-template #noAttachments>
                {{ 'Shared.Reclamation.No_attachments' | translate }}
              </ng-template>
            </div>
          </div>
        </div>

        <div *ngIf="reclamation?.floor">
          <atl-plan-mark [client]="true" [floorPlan]="floorPlanData" [mark]="mark" [disabled]="true"></atl-plan-mark>
        </div>
      </ng-container>
    </div>

    <atl-ngb-modal-footer *ngIf="!reclamationLoading && reclamation.status === statusKeys.CustomerReview">
      <div class="w-100 d-flex justify-content-end">
        <button
          (click)="openCommentModal(false, statusKeys.Disputed)"
          class="newest-button-spinner button-red"
          [disabled]="isLoading || disabledForGuest"
          data-cy="approve-reclamation-button"
          type="button"
        >
          {{ 'Shared.Reclamation.Button.Dispute' | translate }}
        </button>
        <button
          [disabled]="disabledForGuest"
          (click)="changeReclamationStatusRequest(statusKeys.Fixed)"
          class="newest-button newest-button-spinner ml-16"
          [ngClass]="{ 'loading pointer-events-none': isLoading }"
          data-cy="approve-reclamation-button"
          type="button"
        >
          {{ 'Shared.Reclamation.Button.Fixed' | translate }}
        </button>
      </div>
    </atl-ngb-modal-footer>
  </ng-template>

  <ng-template [ngIf]="reclamationTabs.Messages === activeTab">
    <atl-reclamation-client-messages
      [threadViewComponent]="threadViewComponent"
      [reclamation]="reclamation"
      [disabled]="disabledForGuest"
    ></atl-reclamation-client-messages>
  </ng-template>
</div>

<ng-template #spinnerBock>
  <div class="reclamation__list-spinner h-100 d-flex align-items-center justify-content-center">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>
