<div
  atlFileDragDrop
  class="input-file__wrapper"
  (dragleave)="dragLeave($event)"
  (drop)="dropFile($event)"
>
  <span class="input-file__label" (drag)="dropFile($event)">
    {{ 'Entity.Drop_upload_files' | translate }}
  </span>
</div>
