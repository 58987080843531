import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';
import { ISelectTime, ITimeList } from '@atlas-workspace/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { TimeSelectComponent } from "../time-select/time-select.component";

@Component({
  selector: 'atl-time-dropdown',
  templateUrl: './time-dropdown.component.html',
  styleUrls: ['./time-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeDropdownComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, ClickOutsideDirective, NgxMaskDirective, TranslateModule]
})
export class TimeDropdownComponent extends TimeSelectComponent implements ControlValueAccessor, OnInit {

  @Input() readonly title = '';

  public onChange!: (value: ISelectTime | ITimeList | null) => void;
  public onTouched!: () => void;

  ngOnInit(): void {
    this.fromList = this.timeList();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: ISelectTime | null): void {
    if (!value) {
      this.fromValue = '';
      this.timeFrom = undefined;
    }
  }

  selectFrom(event: Event, time: ITimeList): void {
    this.onTouched();
    event.stopPropagation();
    this.fromValue = time.value;
    this.timeFrom = time;
    this.showFromList = false;
    this.onChange(time);
  }
}
