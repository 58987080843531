import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'atl-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements OnDestroy {
  isOpen = false;
  timeoutId: any;

  @Input() public content = '';
  @Input() public readonly openDelay = 0;
  @Input() public position = 'left';

  public onMouseEnter(): void {
    clearTimeout(this.timeoutId);
    if (this.openDelay > 0) {
      this.timeoutId = setTimeout(() => {
        this.isOpen = true;
      }, this.openDelay);
    } else {
      this.isOpen = true;
    }
  }

  public onMouseLeave(): void {
    clearTimeout(this.timeoutId);
    this.isOpen = false;
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
