<div class="reclamation-info__wrapper" *ngIf="reclamation" [class.expanded]="expanded">
  <div class="reclamation-info__button cursor-pointer" (click)="toggle()">
    <div class="reclamation-info__button-title mr-8 lh-24 fz-14 fw-500">
      {{ 'Entity.Reclamation_summary' | translate }}
    </div>
    <img class="reclamation-info__button-icon" src="/assets/arrow-gray-down.svg" alt="arrow" />
  </div>
  <ng-container *ngIf="expanded">
    <form class="reclamation-info__data" [formGroup]="form">
      <div class="reclamation-info__field">
        <div class="reclamation-info__field-title">{{ 'Shared.Reclamation.Input.Category.Label' | translate }}</div>
        <div class="reclamation-info__field-value">
          <atl-drop-down-v2
            [disabled]="reclamation?.archived"
            class="multiple-select-component category"
            [class.invalid]="!form.controls.description.value.length && !form.controls.category.value.length"
            formControlName="category"
            [listOfItems]="categories"
            [bindLabel]="'name'"
            [placeholder]="'Shared.Reclamation.Input.Category.Placeholder'"
            [notFoundText]="'Shared.Entity.Not_found'"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
            [createNotFound]="false"
          >
            <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <span>{{ item?.name }}</span>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('category', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>
      <div class="reclamation-info__field">
        <div class="reclamation-info__field-title">{{ 'Shared.Reclamation.Input.Type.Label' | translate }}</div>
        <div class="reclamation-info__field-value">
          <atl-drop-down-v2
            class="multiple-select-component type"
            [class.invalid]="form.controls.category.value.length && !form.controls.type.value.length"
            formControlName="type"
            [listOfItems]="types"
            [bindLabel]="'name'"
            [placeholder]="'Shared.Reclamation.Input.Type.Placeholder'"
            [notFoundText]="'Shared.Entity.Not_found'"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
            [createNotFound]="false"
            [disabled]="reclamation?.archived"
          >
            <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <span>{{ item?.name }}</span>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('type', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>
      <div class="reclamation-info__field">
        <div class="reclamation-info__field-title">{{ 'Shared.Reclamation.Input.Description.Label' | translate }}</div>
        <div class="reclamation-info__field-value">
          <atl-textarea-autoresize
            formControlName="description"
            class="newest-textarea reclamation-description"
            [stringMode]="true"
            [autoresizeOffset]="2"
            [placeholder]="'Shared.Reclamation.Input.Description.Placeholder' | translate"
            [maxLength]="500"
            [tabindex]="0"
            (blurEvent)="updateReclamation()"
            id="reclamation-description"
            [disabled]="reclamation?.archived"
            [class.disabled]="reclamation?.archived"
            [class.invalid]="!form.controls.description.value.length && !form.controls.category.value.length"
            [absolutePosition]="true"
          ></atl-textarea-autoresize>
        </div>
      </div>
    </form>
  </ng-container>
</div>
