import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {EChangeRequestStatus} from "@atlas-workspace/shared/models";

@Component({
  selector: 'atl-change-request-combined-offer-banner',
  template: `
    <img class="position-absolute" src="assets/currency-dollar-filled.svg" alt="icon">
    <p class="fz-16 lh-24 fw-500">{{ titleText | translate }}</p>
    <p class="fz-16 lh-24 fw-400">{{ descriptionText | translate }}</p>
    <button class="mt-8 fw-600 p-0" (click)="openGroupOffer.emit()">
      {{ 'Change_Request.Client.Offer.See_offer' | translate }}
    </button>
  `,
  styles: [`
    :host {
      display: block;
      border-radius: 8px;
      padding: 12px 12px 12px 32px;
      position: relative;
      background-color: var(--color-gray-815);
      img {
        top: 16px;
        left: 8px;
      }
      p {
        color: var(--color-blue-1300);
      }
      button {
        border: none;
        background-color: transparent;
        color: var(--color-blue-982);
      }
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRequestCombinedOfferBannerComponent {
  @Input() offerStatus!: EChangeRequestStatus;
  @Output() openGroupOffer = new EventEmitter<void>();

  get titleText(): string {
    return this.offerStatus === EChangeRequestStatus.OfferExpired
      ? 'Shared.Change_request.Offer_expired'
      : 'Change_Request.Client.Offer.Offers_received';
  }

  get descriptionText(): string {
    return this.offerStatus === EChangeRequestStatus.OfferExpired
      ? 'Change_Request.Client.Offer.Offers_expired_desc'
      : 'Change_Request.Client.Offer.Offers_received_desc';
  }
}
