import { EUnitEventLogType, IUnitAnalyticsFilterItemModel } from "@atlas-workspace/shared/models";

export const filterTypes: IUnitAnalyticsFilterItemModel[] = [
  {
    name: 'Entity.Unit',
    value: EUnitEventLogType.Unit,
    selected: false
  },
  {
    name: 'Entity.Members',
    value: EUnitEventLogType.Members,
    selected: false
  },
  {
    name: 'Entity.Options',
    value: EUnitEventLogType.Options,
    selected: false
  },
  {
    name: 'Entity.Documents',
    value: EUnitEventLogType.Documents,
    selected: false
  },
  {
    name: 'Entity.Tasks',
    value: EUnitEventLogType.Tasks,
    selected: false
  },
  {
    name: 'Title.Surveys',
    value: EUnitEventLogType.Surveys,
    selected: false
  },
  {
    name: 'Title.Meetings',
    value: EUnitEventLogType.Meetings,
    selected: false
  },
  {
    name: 'Title.Reclamations',
    value: EUnitEventLogType.Reclamations,
    selected: false
  },
  {
    name: 'Title.Change_requests',
    value: EUnitEventLogType.ChangeRequests,
    selected: false
  }
];