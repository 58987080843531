import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReclamationsCounter {
  private readonly count$: BehaviorSubject<number> = new BehaviorSubject(0);
  readonly count = this.count$.asObservable();

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private readonly http: HttpClient,
  ) {}

  getCount(projectId: number): Observable<number> {
    return this.http
      .get<{
        data: number;
      }>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/reclamations/unread_reclamations_count`)
      .pipe(
        map((res) => res.data),
        tap((count: number) => {
          this.count$.next(count);
        }),
      );
  }

  resetCounter(): void {
    !this.count$.value || this.count$.next(0);
  }
}
