import {Injectable} from '@angular/core';

const CACHE = {
  name: 'Application Cache', version: '1'
};

@Injectable()
export class CacheUtilsService {
  setAllUrls(urls: string[]): void {
    caches.open(CACHE.name + CACHE.version)
      .then(async (cache) => {
        return urls.map(async (url) => {
          const res = await cache.match(url);
          if (!res) {
            return cache.add(url).catch();
          }
        }).filter(i => i);
      }).catch();
  }

  setUrl(url: string): void {
    caches.open(CACHE.name + CACHE.version)
      .then(async (cache) => {
        return cache.match(url).then(res => {
          if (!res) {
            return cache.add(url).catch();
          }
        });
      }).catch();
  }
}
