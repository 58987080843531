<!--
  ******************************************************
  @Cypress
  - interaction with send button
  ******************************************************
-->
<form
  [formGroup]="form"
  class="thread__editor-form"
  [ngClass]="isClient ? 'br-16' : 'br-8'"
  [class.fixed-editor-heigth]="loadingEditor"
>
  <atl-mentions-text-editor
    #editorElementRef
    #editorComponentRef
    class="thread__editor flex-grow-1"
    [class.active-style-settings-toolbar]="true"
    [class.client-editor]="isClient"
    [control]="form.controls.body"
    [apiKey]="apiKey"
    [config]="editorInitConfig"
    [projectId]="thread?.projectId"
    [units]="thread?.units"
    [mentionUsersPerView]="mentionUsersPerView"
    [scope]="scope"
    [accessTag]="accessTag"
    (getContentHandler)="cd.detectChanges()"
    (submitHandler)="submit()"
    (mentionHandler)="setBodyValueAfterMention($event)"
    (isContentEmpty)="checkIsButtonDisabled($event)"
    (setFile)="setFileToDocuments($event)"
    [disabled]="disabled"
    [threadId]="thread?.id"
  ></atl-mentions-text-editor>

  <atl-text-editor-multi-file-upload
    [class.client-file-upload]="isClient"
    class="thread__file-upload"
    #fileRef
    #fileComponent
    [removeTitle]="'File'"
    [format]="acceptedExtensions"
    [maxUploadedFiles]="maxUploadedFiles"
    [maxUploadedFilesSize]="maxUploadedFilesSize"
    [multiple]="true"
    [autoSave]="true"
    [showPreview]="true"
    [enforceSelection]="droppedFiles"
    formControlName="document"
    (fileDeletion)="updateIframeFiles()"
    (fileAddition)="updateIframeFiles()"
    (previewImageHandler)="previewImageHandler($event)"
  >
    <ng-container ngProjectAs="otherPanel">
      <div class="d-flex align-items-center">
        <atl-titled-checkbox
          *ngIf="showSendSMS"
          class="mr-16"
          (changeEvent)="setSendSMS($event)"
          [checkboxSize]="16"
          [checkboxLabel]="'Threads.Also_send_as_SMS' | translate"
        >
        </atl-titled-checkbox>
        <div
          [ngbTooltip]="'Threads.Guest_create_message_tooltip' | translate"
          placement="top"
          container="body"
          tooltipClass="tooltip-to-left"
          [disableTooltip]="(isClient && (isSubmitEnabled || !disabled)) || !isClient"
          triggers="hover touchstart"
        >
          <button
            [disabled]="(!isSubmitEnabled && floorPlanSendDisabled) || disabled"
            class="newest-button newest-button-sm thread__send-button"
            data-cy="editor-send-button"
            (click)="submit($event)"
            [ngbTooltip]="isMac ? macTooltip : othersOsTooltip"
            [disableTooltip]="(!isSubmitEnabled && floorPlanSendDisabled) || disabled || isMobileView"
            triggers="hover touchstart"
            [class.br-32]="isClient"
          >
            <atl-custom-icon class="mr-8" [icon]="threadSendIcon"></atl-custom-icon>
            <span>{{ 'Shared.Button.Send' | translate }}</span>
          </button>
        </div>
      </div>
    </ng-container>
  </atl-text-editor-multi-file-upload>

  <atl-floor-plan-cards
    #floorPlan
    [floorPlanItems]="floorPlanItems"
    (removeFloorPlan)="removeFloor($event)"
  ></atl-floor-plan-cards>
</form>

<ng-template #macTooltip>
  <div>
    <div class="mb-4 white-space-nowrap">{{ 'Shared.Entity.Send_message' | translate }}</div>
    <div class="d-flex align-items-center justify-content-center">
      <span class="mr-4 tooltip-key"><img src="/assets/cmd-white-icon.svg" alt="cmd" /></span>
      <span class="tooltip-key">Enter</span>
    </div>
  </div>
</ng-template>
<ng-template #othersOsTooltip>
  <div>
    <div class="mb-4 white-space-nowrap">{{ 'Shared.Entity.Send_message' | translate }}</div>
    <div>
      <span class="mr-4 tooltip-key">Ctrl</span>
      <span class="tooltip-key">Enter</span>
    </div>
  </div>
</ng-template>
