import { CdkColumnDef } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { ESortDirection } from '@atlas-workspace/shared/models';

import sortDescIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/sort-active.svg';
import sortAscIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/sort-asc.svg';

// TODO: sorting logic will be changed and applied new logic using a directive
@Component({
  selector: 'atl-header-cell[atl-sort-header], th[atl-header-cell][atl-sort-header], th',
  templateUrl: 'table-sort.component.html',
  styleUrls: ['./table-sort.component.scss'],
  exportAs: 'matSortHeader',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSortComponent implements OnInit {
  @Input() sortable = true;
  @Input() sortParam = '';
  @Input() asDropdown = false;
  @Input('atl-sort-header')
  get id(): string {
    return this._id;
  }

  set id(val: string) {
    this._id = val;
  }

  private _id!: string;

  public readonly sortDescIcon = sortDescIcon;
  public readonly sortAscIcon = sortAscIcon;

  @Output() sortEvent: EventEmitter<string> = new EventEmitter();

  public activeId!: string;

  constructor(@Optional() private columnDef: CdkColumnDef) {}

  ngOnInit(): void {
    if (!this.id && this.columnDef) {
      const sortName = this.columnDef.name.replace('last active', 'last_activity');
      this.id = sortName + ESortDirection.Asc;
    }
  }

  get arrowDirection(): string {
    return this.activeId?.includes(ESortDirection.Asc) ? 'desc' : 'asc';
  }

  public handleSort(event: Event): void {
    if (this.asDropdown || !this.sortable) {
      return;
    }
    if (event.target instanceof HTMLInputElement) return;
    if (this.id?.includes(ESortDirection.Asc)) {
      this.id = this.id.replace(ESortDirection.Asc, ESortDirection.Desc);
    } else if (this.id?.includes(ESortDirection.Desc)) {
      this.id = this.id.replace(ESortDirection.Desc, ESortDirection.Asc);
    }

    this.activeId = this.id;
    this.sortEvent.emit(this.activeId);
  }
}
