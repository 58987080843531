import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  acceptedGlobalExtensions,
  acceptedVideoExtensions,
  DocumentUrlModel,
  ICreateFDV,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import * as mime from 'mime';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, of, Subject, switchMap, takeWhile } from 'rxjs';
import {delay, filter, finalize, map, mergeMap, tap, toArray} from 'rxjs/operators';

import { FileHelper } from '../../helpers/file';
import { ToasterService } from '../toaster/toaster.service';

export interface IParsFileDrop {
  file?: File;
  isFile: boolean;
  path: string;
  name?: string;
  size?: number;
  type?: string;
  extension: string | null;
  fileUrl: string;
  nameLengthError: boolean;
  sizeError: boolean;
  fileNestingError: boolean;
  extensionError: boolean;
  loaded: number;
  isUploading: boolean;
  droppedIndex?: number;
}

@Injectable({
  providedIn: 'root',
})
export class UploadS3Service {
  private readonly maxUploadDirectory = 1e10;
  private readonly maxSize = 3e7;
  private readonly nesting = 99;
  private readonly closeTimeout = 5000;

  private readonly uploadData$ = new Subject<IParsFileDrop[] | null>();
  private readonly cancelUpload$ = new Subject<boolean>();
  private readonly finalizeUpload$ = new Subject<true>();
  private readonly acceptedExtensions = [acceptedGlobalExtensions, acceptedVideoExtensions].join(', ');

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private readonly toasterService: ToasterService
  ) {}

  get uploadData(): Observable<IParsFileDrop[] | null> {
    return this.uploadData$.asObservable();
  }

  clearUploadData(): void {
    this.uploadData$.next(null);
  }

  cancelUpload(): void {
    this.cancelUpload$.next(true);
  }

  get cancelUploadHandler(): Observable<any> {
    return this.cancelUpload$.asObservable();
  }

  get finalizeUploadHandler(): Observable<boolean> {
    return this.finalizeUpload$.asObservable().pipe(delay(this.closeTimeout));
  }

  finalizeUpload(): void {
    this.finalizeUpload$.next(true);
  }

  initUpload(files: NgxFileDropEntry[], patentId?: number): Observable<ICreateFDV[]> {
    const beforeUnloadHandler = (event: Event) => {
      event.preventDefault();
      event.returnValue = true;
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    // @ts-ignore
    return of(...files).pipe(
      mergeMap((item, index) => {
        if (!(item.fileEntry as FileSystemFileEntry)?.file || item.relativePath.includes('.DS_Store')) return  of(item).pipe(map(value => this.fileListCreator(false, item.relativePath, index)))
        return new Observable<File>((observer) => {
          const resolve = (file: File) => {
            observer.next(file);
            observer.complete();
          };
          (item.fileEntry as FileSystemFileEntry)?.file(resolve);
        }).pipe(
          map((file) => {
            return this.fileListCreator(!!file.type, file.webkitRelativePath || item.relativePath, index, file);
          })
        );
      }),
      toArray(),
      filter((value) => this.validMaxUploadDirectory(value)),
      tap((value) => {
        this.uploadData$.next(value.filter(i => i.isFile));
      }),
      map((value) =>
        value.filter((v) => !v.extensionError || !v.fileNestingError || !v.nameLengthError || !v.sizeError)
      ),
      switchMap((value) => value),
      mergeMap((item) => {
        if (!item.isFile) return of(item);
        return this.generateDocumentUrlsS3(item).pipe(
          tap((event: HttpEvent<any>) => {
            if (event?.type === HttpEventType.UploadProgress) {
              item.loaded = event.loaded;
            }
          }),
          filter((event: any) => event?.type === HttpEventType.Response),
          map(() => item)
        );
      }, 5),
      filter((item) => item.isUploading),
      toArray(),
      map((value: IParsFileDrop[]) => {
        return value.sort((a, b) => (a.droppedIndex || 0) - (b.droppedIndex || 0));
      }),
      map((value) => this.treeCreator(value, patentId)),
      finalize(() => {
        window.removeEventListener('beforeunload', beforeUnloadHandler);
        this.finalizeUpload();
      })
    );
  }

  public generateDocumentUrlsS3(item: IParsFileDrop): Observable<any> {
    const fd = new FormData();
    fd.append('filenames[]', item!.file!.name);
    return this.http
      .post<{ data: DocumentUrlModel[] }>(
        `${this.environment.apiBaseUrl}api/v1/files/generate_multiple_presigned_urls`,
        fd
      )
      .pipe(
        map((res: any) => res.data),
        map((data: DocumentUrlModel[]) => plainToClass(DocumentUrlModel, data)),
        tap((data: DocumentUrlModel[]) => {
          item.fileUrl = data[0].publicUrl;
        }),
        switchMap((data) => {
          return this.uploadFileToS3(data[0], item!.file!).pipe(takeWhile(() => item.isUploading));
        })
      );
  }

  uploadFileToS3(url: DocumentUrlModel, file: File): Observable<any> {
    const fileType = file.type ? file.type : FileHelper.getFileTypeByExtension(file);
    const headers = new HttpHeaders({ ['Content-Type']: fileType });
    return this.http.put(url.uploadUrl, file, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  private treeCreator(value: IParsFileDrop[], parentId?: number): ICreateFDV[] {
    const tree: ICreateFDV[] = [];
    for (const item of value) {
      if (!item.sizeError && !item.fileNestingError && !item.nameLengthError) {
        const pathArr = item.path.split('/');
        pathArr.pop();

        if (pathArr.length) {
          this.directoryCreator(tree, item, pathArr, parentId);
        } else if (item.isFile) {
          const folder: ICreateFDV = {
            filename_remote_url: item.fileUrl,
            fileName: item.name,
            fileSize: item.size,
            fileExtension: item.extension as string,
            children: [],
          };
          if (parentId) {
            folder.parent_id = parentId;
          }
          tree.push(folder);
        }
      }
    }

    return tree;
  }

  private directoryCreator(tree: ICreateFDV[], item: IParsFileDrop, pathArr: string[], parentId?: number): void {
    if (!pathArr.length) return;

    let idx = -1;
    idx = tree.findIndex((t) => t.title === pathArr[0]);
    if (idx === -1) {
      const folder: ICreateFDV = {
        title: pathArr[0],
        children: [],
      };
      if (parentId) {
        folder.parent_id = parentId;
      }
      tree.push(folder);
      idx = tree.length - 1;
    }

    const newPath = pathArr.slice(1);
    if (newPath.length) {
      this.directoryCreator(tree[idx].children, item, newPath);
    } else if (!newPath.length && item.isFile) {
      tree[idx].children.push({
        filename_remote_url: item.fileUrl,
        fileName: item.name,
        fileSize: item.size,
        fileExtension: item.extension as string,
        children: [],
      });
    }
  }

  private validMaxUploadDirectory(value: IParsFileDrop[]): boolean {
    return (
      value.reduce((acc: number, val: IParsFileDrop) => {
        if (val.isFile) {
          acc += val?.size || 0;
        }
        return acc;
      }, 0) <= this.maxUploadDirectory
    );
  }

  private checkFileExtensionValidity(file: File): boolean {
    if (
      file.type &&
      (this.acceptedExtensions.includes(String(mime.getExtension(file.type))) ||
        this.acceptedExtensions.includes(file.type))
    ) {
      return true;
    } else {
      const ext = file.name.split('.');
      return this.acceptedExtensions.includes(ext[ext.length - 1].toLowerCase());
    }
  }

  private fileListCreator(isFile: boolean, path: string, droppedIndex?: number, file?: File): IParsFileDrop {
    const ext = file?.name.split('.') || '';
    return {
      file,
      isFile,
      path,
      droppedIndex,
      name: file?.name,
      size: file?.size,
      type: file?.type,
      extension: isFile ? ext[ext.length - 1].toLowerCase() : null,
      fileUrl: '',
      nameLengthError: file ? file.name.length > 150 : false,
      sizeError: file ? file.size > this.maxSize : false,
      fileNestingError: path.split('/').length - 1 > this.nesting,
      extensionError: file ? !this.checkFileExtensionValidity(file) : false,
      loaded: 0,
      isUploading: true,
    };
  }
}
