import { Injectable } from '@angular/core';
import {
  CombinedMeetingsFilters,
  ITablePagination,
  MeetingModel,
  MeetingsCountersType,
  MeetingsFilterParamType,
  MeetingsModel,
  MeetingsResponseModel,
} from '@atlas-workspace/shared/models';
import { DataTableHelperService } from '@atlas-workspace/shared/service';
import { Select, Store } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { MeetingsService } from '../../modules/projects/components/specific-project-wrapper/services/meetings.service';
import { defaultState } from '../default-state';
import {
  AddMeeting,
  MeetingsSetPagination,
  MeetingsSetTotalFirstLoading,
  RemoveMeeting,
  SetMeeting,
  SetMeetings,
  SetMeetingsCounters,
  SetMeetingsTodayCounter,
} from './meetings.action';
import { MeetingsStateModel } from './meetings.model';
import { MeetingsState } from './meetings.state';

@Injectable({
  providedIn: 'root',
})
export class MeetingsFacadeService {
  @Select(MeetingsState.selectStateData) private _meetings$!: Observable<MeetingsStateModel>;

  constructor(
    private store: Store,
    private dataTableHelper: DataTableHelperService,
    private meetingsService: MeetingsService
  ) {}

  get meetings$(): Observable<MeetingsStateModel> {
    return this._meetings$.pipe(share());
  }

  getMeetings(
    projectId: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
    filter?: CombinedMeetingsFilters
  ): Observable<MeetingsResponseModel> {
    return this.meetingsService.getMeetingsState(projectId, sort, search, paginate, filter).pipe(
      tap((returnData) => {
        const pagination = this.dataTableHelper.getPagination(returnData);

        if (this.store.snapshot().meetingsState.firstLoad) {
          this.store.dispatch(new MeetingsSetTotalFirstLoading(false, pagination.totalCount));
        }

        this.setPagination(pagination);
      }),
      map((res) => res.body?.data),
      map((data: MeetingsResponseModel) => plainToClass(MeetingsResponseModel, data)),
      tap((returnData) => {
        if (this.store.snapshot().meetingsState.pagination.currentPage === 1 && sort === 'default' && !search) {
          this.setMeetings(returnData.meetings);
          this.setMeetingsCounters(returnData.counters);
          this.setMeetingsTodayCounter(returnData.todayMeetingsCount);
        }
      })
    );
  }

  setMeetings(meetings: MeetingsModel[]): void {
    this.store.dispatch(new SetMeetings(cloneDeep(meetings)));
  }

  setMeetingsCounters(counters: MeetingsCountersType): void {
    this.store.dispatch(new SetMeetingsCounters(cloneDeep(counters)));
  }

  setMeetingsTodayCounter(todayMeetingsCount: number): void {
    this.store.dispatch(new SetMeetingsTodayCounter(cloneDeep(todayMeetingsCount)));
  }

  setChangeMeeting(meeting: MeetingModel): void {
    this.store.dispatch(new SetMeeting(meeting as unknown as MeetingsModel));
  }

  addCreateMeeting(length?: number): void {
    this.store.dispatch(new AddMeeting(length));
  }

  removeMeetingLocal(ids: number[]): void {
    this.store.dispatch(new RemoveMeeting(ids));
  }

  setPagination(pagination: ITablePagination = defaultState.meetingsState.pagination): void {
    this.store.dispatch(new MeetingsSetPagination(pagination));
  }
}
