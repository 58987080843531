import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminCompanyModel, IAdminCompany } from '@atlas-workspace/shared/models';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ICompanyUniversal } from '../interface/company.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private _company = new BehaviorSubject<AdminCompanyModel | undefined>(undefined);

  constructor(private http: HttpClient) {}

  getCompanyInfo(companyId?: number): Observable<AdminCompanyModel[]> {
    return this.http.get<{ data: { firms: IAdminCompany[] } }>(environment.apiBaseUrl + 'api/v1/firms').pipe(
      map((res) => res.data.firms),
      map((firms) => plainToClass(AdminCompanyModel, firms)),
      // ToDO: double check, looks like redundunt logic
      tap((company) => {
        if (company) {
          const val = company.filter((c) => c.id === companyId)[0];
          this._company.next(val);
        }
      })
    );
  }

  // ToDO: double check, looks like redundunt logic
  get companyInfo$(): Observable<AdminCompanyModel | undefined> {
    return this._company.asObservable();
  }

  get currentCompany(): AdminCompanyModel | undefined {
    return this._company.value;
  }

  /**
   * @Cypress
   */
  updateCompanyInfo(companyId: string, body: ICompanyUniversal): Observable<IAdminCompany> {
    return this.http.put<{ data: IAdminCompany }>(environment.apiBaseUrl + 'api/v1/firms/' + companyId, body).pipe(
      map((res) => res.data),
      map((firms) => plainToClass(AdminCompanyModel, firms)),
      tap((company) => {
        if (company) {
          this._company.next(company);
        }
      })
    );
  }

  getListCurrencies(): Observable<string[]> {
    return this.http
      .get<{ data: { currencies: string[] } }>(environment.apiBaseUrl + 'api/v1/currencies')
      .pipe(map((res) => res.data.currencies));
  }
}
