import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnitModel } from '@atlas-workspace/shared/models';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'atl-combine-pricing-modal',
  templateUrl: './combine-pricing-modal.component.html',
  styleUrls: ['./combine-pricing-modal.component.scss'],
})
export class CombinePricingModalComponent implements OnInit {
  @Input() public modalRef!: NgbModalRef;
  @Input() public title!: '';
  @Input() public description!: '';
  @Input() public priceLabel!: '';
  @Input() public setButton!: '';
  @Input() public name?: string;
  @Input() public price?: number;
  @Input() public priceOnly = false;
  @Input() public separatorLimit = '100000000000';
  @Input() public mask = 'separator.0';

  public form!: FormGroup;
  public changedForm: string[] = []

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = new FormGroup({
      name: new FormControl(this.name, this.priceOnly ? [] : Validators.required),
      price: new FormControl(this.price, Validators.required),
    });
    this.formListener();
  }

  private formListener(): void {
    const keys: string[] = Object.keys(this.form.getRawValue());
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value => {
      this.changedForm =  keys.reduce((acc: string[], key: string) => {
        if (key === 'name' && !this.priceOnly && value[key] !== this.name) {
          acc.push(key);
        }

        if (key === 'price' && +value[key] !== this.price) {
          acc.push(key);
        }
        return acc;
      }, []);
    }));
  }

  closeModal(): void {
    this.modalRef.dismiss();
  }

  public setPrice(): void {
    const value = this.form.value;
    this.modalRef.dismiss(value);
  }
}
