import { Injectable } from '@angular/core';
import { ImmutableSelector, ITablePagination, ShortUpdateModel, UpdateModel } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import {
  AddUpdate,
  RemoveUpdate,
  SetUpdate,
  SetUpdates,
  UpdatesSetPagination,
  UpdatesSetTotalFirstLoading,
} from './updates.action';
import { UpdatesStateModel } from './updates.model';

@State({
  name: 'updatesState',
  defaults: defaultState.updatesState,
})
@Injectable()
export class UpdatesState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: UpdatesStateModel): UpdatesStateModel {
    return state;
  }

  @Action(SetUpdates)
  setUnits(ctx: StateContext<UpdatesStateModel>, { updates }: SetUpdates): void {
    ctx.setState(patch<UpdatesStateModel>({ updates }));
  }

  @Action(SetUpdate)
  setUnit(ctx: StateContext<UpdatesStateModel>, { update }: SetUpdate): void {
    ctx.setState(
      patch<UpdatesStateModel>({
        updates: updateItem<ShortUpdateModel | UpdateModel>((u) => u?.id === update.id, update),
      })
    );
  }

  @Action(AddUpdate)
  addUnit(ctx: StateContext<UpdatesStateModel>, { update }: AddUpdate): void {
    const state = ctx.getState();
    ctx.setState(
      patch<UpdatesStateModel>({
        updates: insertItem<ShortUpdateModel | UpdateModel>(update, 0),
        pagination: patch<ITablePagination>({
            totalCount: state.pagination.totalCount + 1,
          }),
        totalCount: state.totalCount + 1,
      })
    );
  }

  @Action(UpdatesSetPagination)
  setPagination(ctx: StateContext<UpdatesStateModel>, { pagination }: UpdatesSetPagination): void {
    ctx.setState(patch<UpdatesStateModel>({ pagination }));
  }

  @Action(UpdatesSetTotalFirstLoading)
  setTotalFirstLoading(
    ctx: StateContext<UpdatesStateModel>,
    { firstLoad, totalCount }: UpdatesSetTotalFirstLoading
  ): void {
    ctx.setState(patch<UpdatesStateModel>({ firstLoad, totalCount }));
  }

  @Action(RemoveUpdate)
  @ImmutableSelector()
  removeUnits(ctx: StateContext<UpdatesStateModel>, { ids }: RemoveUpdate): void {
    const state = ctx.getState();
    const updates = state.updates?.filter((u) => !ids.some((id) => u.id === id));
    ctx.setState(
      patch<UpdatesStateModel>({
        updates,
        totalCount: state.totalCount - ids.length,
      })
    );
  }
}
