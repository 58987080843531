import {ITime} from '@atlas-workspace/shared/models';

export function getTimeString(time?: ITime): string {
  if (Number(time?.hour) >= 0 && Number(time?.min) >= 0) {
    const hour = Number(time?.hour) < 10 ? `0${time?.hour}` : time?.hour;
    const minute = time?.min === 0
      ? '00'
      : Number(time?.min) < 10 ? `0${time?.min}` : time?.min;
    return `${hour}:${minute}`;
  } else {
    return '';
  }
}
