<div ngbDropdown [autoClose]="true" class="round-dropdown">
  <button ngbDropdownToggle class="round-dropdown__toggle" type="button" id="round-dropdown">
    <span class="round-dropdown__toggle-value">
      <img *ngIf="icon" [src]="icon" alt="icon">
       <ng-content select="[value]"></ng-content>
    </span>
    <img class="round-dropdown__toggle-arrow" src="/assets/arrow_down_option_store.svg" alt="arrow">
  </button>
  <div ngbDropdownMenu [attr.aria-labelledby]="'round-dropdown'">
    <ng-content select="[search]"></ng-content>
    <div  class="d-flex flex-column min-h-0 w-100 h-100 v-scrollbar">
      <ng-content select="[item]"></ng-content>
      <ng-content select="[empty]"></ng-content>
    </div>
  </div>
</div>
