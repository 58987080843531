<div (click)="handleUpload()" *ngIf="!imagePreview; else imageView" class="input-file__wrapper">
  <ng-container [ngTemplateOutlet]="uploadInputRef"></ng-container>
  <ng-container [ngTemplateOutlet]="dragFilesRef"></ng-container>
</div>

<ng-template #imageView>
  <div 
    class="input-file__preview input-file__container"
    atlFileDragDrop
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (dragover)="handleDragOver($event)"
    (drop)="handleDrop($event)"
    [class.draggable]="isDraggedOver"
    [class.disabled]="disabled"
    tabindex="0"
  >
    <img class="input-file__preview-image" [src]="imagePreview" alt="image preview" />
    <div class="input-file__preview-icon-wr delete">
      <img
      (click)="clear()"
      class="input-file__preview-icon"
      [class.disabled]="disabled"
      src="assets/trash-gray.svg"
      alt="remove icon"
    />
    </div>
  </div>
</ng-template>

<ng-template #uploadInputRef>
  <input
    #uploadInput
    (change)="selectedFile($event)"
    [multiple]="multiple"
    [accept]="inputAcceptString"
    [disabled]="disabled"
    class="file-input"
    hidden
    id="file-input"
    data-cy="cy-input-file"
    type="file"
  />
</ng-template>

<ng-template #dragFilesRef let-asDraggableGallery="asDraggableGallery">
  <div
    atlFileDragDrop
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (dragover)="handleDragOver($event)"
    (drop)="handleDrop($event)"
    [class.draggable]="isDraggedOver"
    class="input-file__container"
    [class.disabled]="disabled"
    tabindex="0"
  >
    <ng-container *ngIf="!asDraggableGallery">
      <ng-container *ngIf="!customTitle; else customTitleEl">
        <span class="input-file__title">
          {{ 'Shared.Description.Drag_file_or' | translate }}
          <span class="input-file__title--underline">{{ 'Shared.Entity.Browse_files' | translate }}</span>
          {{ 'Shared.Description._to_upload_floorplan' | translate }}
        </span>
      </ng-container>
      <ng-template #customTitleEl>
        <span class="input-file__title">
          <ng-content></ng-content>
        </span>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
