<div atlClickOutside (clickOutside)="onCloseFilter($event)" class="newest-filter__wrapper">
  <div
    class="newest-filter__button"
    [class.newest-filter__button--opened]="toggleFilter"
    [class.newest-filter__button--activated]="activatedFilter"
    (click)="onToggleFilter()"
  >
    <ng-content select="newest-filter-icon"></ng-content>
    <span>{{ filterTitle }}</span>
  </div>

  <div *ngIf="toggleFilter" class="newest-filter__content-wrapper">
    <ng-content></ng-content>
  </div>
</div>
