import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DetectZoomModule } from '@atlas-workspace/shared/directives';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from "@ngx-translate/core";

import { DefaultModalModule } from '../default-modal/default-modal.module';
import { SignatureModalComponent } from './signature-modal.component';

@NgModule({
  declarations: [SignatureModalComponent],
  exports: [SignatureModalComponent],
  imports: [CommonModule, DefaultModalModule, ReactiveFormsModule, NgbTooltipModule, DetectZoomModule, TranslateModule],
})
export class SignatureModalodule {}
