import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  IBatchUnitInvitingMembers,
  ITablePagination,
  ITransferMember,
  IUnitMember,
  IUnitMemberUpdate,
  UnitMemberModel,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthAdminService } from '../auth/auth-admin.service';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';

export interface IOutputMemberInformation {
  listOfMembers: UnitMemberModel[];
  totalPages: string | null;
  totalMembers: string | null;
}

@Injectable()
export class UsersService {
  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private authAdminService: AuthAdminService,
    private tableService: DataTableHelperService
  ) {}

  public getUsers(
    projectId: string,
    unitId: string,
    search = '',
    sort = '',
    paginate?: Partial<ITablePagination>
  ): Observable<IOutputMemberInformation> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const { body, headers } = response;
          const totalPages = headers.get('total-pages');
          const totalMembers = headers.get('total-count');
          return { ...body, totalPages, totalMembers };
        }),
        map(({ data: { members }, totalPages, totalMembers }) => {
          return { listOfMembers: plainToClass(UnitMemberModel, members as IUnitMember[]), totalPages, totalMembers };
        })
      );
  }

  public getUsersOnly(unitId: string, registered = false): Observable<ITransferMember[]> {
    let params: HttpParams = new HttpParams();
    if (registered) {
      params = params.set('show_only_registered', String(registered));
    }
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/units/${unitId}/only_members`, { params })
      .pipe(map((res: any) => <ITransferMember[]>res.data.members));
  }

  public createUnitUser(projectId: string, unitId: string, user: IUnitMemberUpdate): Observable<UnitMemberModel> {
    return this.http
      .post<{ data: IUnitMember }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members/invite`,
        user
      )
      .pipe(
        map((res) => res.data),
        map((data) => plainToClass(UnitMemberModel, data))
      );
  }

  public sendInvitation(unitId: number, emails: string[]): Observable<string> {
    const invites = {
      emails: emails,
    };
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/units/${unitId}/members`, invites)
      .pipe(map((res: any) => <string>res?.message));
  }

  public sendInvitationsToUnits(projectId: number, ids: number[]): Observable<string> {
    const fd = new FormData();
    ids.forEach((id: number) => {
      fd.append('unit_ids[]', String(id));
    });

    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/members_v2/multiple_resend_invite`, fd)
      .pipe(map((res: any) => <string>res?.message));
  }

  public resendUnitInvite(projectId: string, unitId: string, memberId: number): Observable<string> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    return this.http
      .patch(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members/${memberId}/resend_invite`,
        null,
        { params }
      )
      .pipe(map((res: any) => <string>res?.message));
  }

  public deleteUnitUser(projectId: string, unitId: string, memberId: number): Observable<string> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    return this.http
      .delete(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members/${memberId}`, {
        params,
      })
      .pipe(map((res: any) => <string>res?.message));
  }

  public editUnitUser(
    projectId: string,
    unitId: string,
    memberId: number,
    member: Partial<IUnitMemberUpdate>
  ): Observable<UnitMemberModel> {
    return this.http
      .put(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members/${memberId}`, member)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitMemberModel, data))
      );
  }

  public transferOwnership(
    projectId: string,
    unitId: string,
    memberId: number | null,
    user: { member_id: number | null } | null
  ): Observable<string> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    return this.http
      .post(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members/${memberId}/transfer_ownership`,
        user,
        { params }
      )
      .pipe(map((res: any) => <string>res?.message));
  }

  public transferMultiOwnership(
    projectId: string,
    unitId: string,
    owners: ITransferMember[],
    members: ITransferMember[]
  ): Observable<string> {
    const body = {
      units_users_attributes: [...this.memberBody(owners, 'primary_owner'), ...this.memberBody(members, 'member')],
    };
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/multiple_update_roles`, body)
      .pipe(map((res: any) => <string>res?.message));
  }

  private memberBody(members: ITransferMember[], role: string): { user_id: number; user_role: string }[] {
    return members.map((member) => ({
      user_id: member.id,
      user_role: role,
    }));
  }

  public changeOwnership(projectId: number, unitId: number, user: { name: string; email: string, skip_sending_email: boolean, phone?: string }): Observable<string> {
    const body = {
      member: user,
    };
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    return this.http
      .post(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members/change_ownership`,
        body,
        { params }
      )
      .pipe(map((res: any) => <string>res?.message));
  }

  /**
   * Invite List of Unit Members
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Members/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1units~1%7Bunit_id%7D~1members~1batch_invite/post
   */
  public batchCreateUnitUsers(
    projectId: string,
    unitId: string,
    members: IBatchUnitInvitingMembers[]
  ): Observable<UnitMemberModel[]> {
    return this.http
      .post<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members/batch_invite`, {
        members: members,
      })
      .pipe(
        map((res) => res.data),
        map((data) => plainToClass<UnitMemberModel, UnitMemberModel[]>(UnitMemberModel, data))
      );
  }
}
