<div class="preview-document">
  <div class="preview-document__wrap">
    <div class="preview-document__header">
      <div class="d-flex align-items-center min-w-0">
        <span class="d-block ellipsis fz-16 lh-24 fw-500">{{ document.title }}</span>
      </div>
      <div class="d-flex align-items-center">
        <div *ngIf="fileType === fileTypes.Image" class="preview-document__zoom">
          <div class="d-flex align-items-center">
            <span class="ml-8 fz-14 lh-24 fw-500"></span>

            <div
              #dropdownMenu="ngbDropdown"
              ngbDropdown
              container="body"
              [placement]="['bottom-left']"
              dropdownClass="zoom-menu"
              class="dropdown-folder"
            >
              <span ngbDropdownToggle id="zoomDropdown" class="preview-document__zoom-toggle">
                {{ scaleImage }}%
                <img
                  class="zoom-arrow"
                  [class.active]="dropdownMenu._open"
                  src="assets/arrow-gray-down.svg"
                  alt="arrow"
                />
              </span>
              <div ngbDropdownMenu aria-labelledby="zoomDropdown">
                <div class="preview-document__zoom-dropdown">
                  <span
                    *ngFor="let item of zoomItems"
                    ngbDropdownItem
                    (click)="setZoom(item)"
                    class="preview-document__zoom-items"
                    >{{ item }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <span (click)="zoomHandler(-1)" class="preview-document__zoom-button">
              <img src="assets/minus-gray-12.svg" alt="minus" />
            </span>
            <span (click)="zoomHandler(1)" class="preview-document__zoom-button">
              <img src="assets/plus-gray-12.svg" alt="plus" />
            </span>
          </div>
        </div>
        <atl-header-icon
          icon="/assets/info-icon-16.svg"
          (click)="detailHandler()"
          [ngbTooltip]="'Shared.Button.Document_Details' | translate"
          container="body"
          [openDelay]="openTooltipDelay"
          triggers="hover touchstart"
          [class.active]="showDetail"
        ></atl-header-icon>
        <atl-header-icon
          *ngIf="!fullScreen"
          icon="/assets/full-screen.svg"
          (click)="openFullScreenModal()"
          [ngbTooltip]="'Button.full_screen' | translate"
          container="body"
          [openDelay]="openTooltipDelay"
          triggers="hover touchstart"
        ></atl-header-icon>
        <atl-header-icon
          *ngIf="fullScreen"
          icon="/assets/normal-screen.svg"
          (click)="closeModal()"
          [ngbTooltip]="'Button.Return' | translate"
          container="body"
          [openDelay]="openTooltipDelay"
          triggers="hover touchstart"
        ></atl-header-icon>
        <atl-header-icon
          *ngIf="isClosed && !fullScreen"
          icon="/assets/close-icon.svg"
          (click)="closeHandler()"
          [ngbTooltip]="'Alt.Close' | translate"
          container="body"
          [openDelay]="openTooltipDelay"
          triggers="hover touchstart"
        ></atl-header-icon>
      </div>
    </div>
    <div #parent class="preview-document__content">
      <ng-container *ngIf="previewLoading">
        <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
      </ng-container>
      <ng-container [ngSwitch]="fileType">
        <ng-container *ngSwitchCase="fileTypes.Image">
          <div class="preview-document__content-image-container scrollbar scrollbar--hidden">
            <img
              [atlImageResize]="parent"
              [resizeOnDeps]="fullScreen"
              [resizeDebounceOnDeps]="showDetail"
              [zoomScale]="scaleImage"
              (load)="contentLoaded()"
              [class.d-none]="previewLoading"
              [class.preview-document__content-image]="!preventImageTransition"
              [src]="document.fileName.url"
              alt="image"
            />
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="fileTypes.File">
          <ngx-doc-viewer
            *ngIf="previewType !== pdfViewerType"
            class="preview-document__content-file"
            [class.d-none]="previewLoading"
            [url]="document.fileName.url"
            [viewer]="previewType"
            (loaded)="contentLoaded()"
          ></ngx-doc-viewer>
          <pdf-viewer
            *ngIf="previewType === pdfViewerType"
            class="preview-document__content-file"
            [src]="document.fileName.url"
            [render-text]="true"
            [original-size]="false"
            (after-load-complete)="contentLoaded()"
          ></pdf-viewer>
        </ng-container>
        <ng-container *ngSwitchCase="fileTypes.Video">
          <ng-container [ngTemplateOutlet]="notSupported"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="fileTypes.Unknown">
          <ng-container [ngTemplateOutlet]="notSupported"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="preview-document__sidebar" [class.active]="showDetail">
    <div class="preview-document__sidebar-header">
      <span class="fz-16 lh-24 fw-500">{{ 'Shared.Button.Details' | translate }}</span>
      <div
        #dropdownMenu="ngbDropdown"
        ngbDropdown
        container="body"
        [placement]="['bottom-right', 'top-right']"
        dropdownClass="fdv-folder-menu"
        class="dropdown-folder preview-document__sidebar-header-action"
      >
        <atl-header-icon
          ngbDropdownToggle
          id="previewDetail"
          class="preview-document__sidebar-header-toggle"
          icon="assets/dropdown-dot-2.svg"
          [class.active]="dropdownMenu._open"
        ></atl-header-icon>
        <div ngbDropdownMenu aria-labelledby="previewDetail">
          <atl-fdv-tree-menu
            [folderMenu]="true"
            [copyItem]="true"
            [downloadItem]="true"
            [moveItem]="isMove"
            (delete)="deleteHandler(document)"
            (download)="downloadHandler(document)"
            (copy)="copyHandler(document)"
            (rename)="renameHandler(document)"
            (move)="moveHandler(document)"
          ></atl-fdv-tree-menu>
        </div>
      </div>
    </div>
    <div class="preview-document__sidebar-detail">
      <span class="preview-document__sidebar-title d-block ellipsis">{{ document.title }}</span>
      <span class="preview-document__sidebar-user d-block ellipsis">
        {{ 'Shared.Entity.Updated_by' | translate }} {{ document?.updaterName }}
      </span>
      <div class="preview-document__sidebar-row mt-16">
        <span class="preview-document__sidebar-row-title">{{ 'Entity.Edited' | translate }}</span>
        <span class="preview-document__sidebar-content">{{ document.updatedAt | date: 'dd.MM.yyyy' }}</span>
      </div>
      <div class="preview-document__sidebar-row">
        <span class="preview-document__sidebar-row-title">{{ 'Shared.Entity.Uploaded' | translate }}</span>
        <span class="preview-document__sidebar-content">{{ document.createdAt | date: 'dd.MM.yyyy' }}</span>
      </div>
      <div class="preview-document__sidebar-row">
        <span class="preview-document__sidebar-row-title">{{ 'Attributes.Type.Size' | translate }}</span>
        <span class="preview-document__sidebar-content">{{ document!.fileSize | filesize }}</span>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="spinner-wrapper position-absolute w-100 h-100 z-index-2">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>

<ng-template #notSupported>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <img src="assets/not-supported-file.svg" alt="video-empty" />
    <p class="fz-20 lh-24 fw-500 mt-16 mb-8">{{ 'Documents.v3.Files.Not_support.Title' | translate }}</p>
    <p class="fz-16 lh-24 text-black-810">{{ 'Documents.v3.Files.Not_support.description' | translate }}</p>
  </div>
</ng-template>
