import {CommonModule} from "@angular/common";
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { IUnitAnalyticsFilterItemModel } from '@atlas-workspace/shared/models';
import { NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import  {TranslateModule } from "@ngx-translate/core";
import { cloneDeep } from 'lodash';

// @ts-ignore
import arrowIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow-down-sm.svg';
// @ts-ignore
import filterIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/filter-button-icon.svg';

import {CheckboxComponent} from "../checkbox/checkbox.component";
import {InputDateRangeComponent} from "../input-date-range/input-date-range.component";
import { filterTypes } from '../unit-analytics/filter-data';

@Component({
  selector: 'atl-unit-analytics-filter',
  templateUrl: './unit-analytics-filter.component.html',
  styleUrls: ['./unit-analytics-filter.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, InputDateRangeComponent, CheckboxComponent, NgbTooltipModule],
})
export class UnitAnalyticsFilterComponent {
  @ViewChild('dateRangePicker') dateRangePicker!: InputDateRangeComponent;
  @Output() private readonly filterDataEvent = new EventEmitter<{type: string[], date: { from: string; to: string | null } }>();

  filterData = {
    date: [] as {from: string; to: string | null }[],
    type: cloneDeep(filterTypes),
  };

  readonly filterIcon = filterIcon;
  readonly arrowIcon = arrowIcon;
  totalFiltersSelected = 0;
  defaultFilterItems = 5;
  readonly dotDivider = ' · ';
  readonly dropdownContentTop = 64;
  filterExpandState: { [key: string]: boolean } = {
    date: true,
    type: true,
  };
  showFilterCategory = {
    date: true,
    type: true,
  };
  filterLabelTranslate = {
    date: 'Shared.Entity.Filter_by_date',
    type: 'Shared.Entity.Filter_by_type',
  };
  readonly tooltipOpenDelay = 500;
  hideButtonTooltip = false;

  public date: { from: string; to: string | null } | null = null;

  resetAllFilters(): void {
    this.filterData.type = cloneDeep(filterTypes);
    this.resetDateEvent();
  }

  handleButtonTooltip(value: boolean): void {
    this.hideButtonTooltip = value;
  }

  expandFilterCategory(val: string): void {
    this.filterExpandState[val] = !this.filterExpandState[val];
  }

  resetDateEvent(): void {
    this.filterData.date = [];
    this.saveFilter();
  }

  dateSelect(date: { from: string; to: string | null }): void {
    this.date = date;
    this.filterData.date = [this.date];
    this.dateRangePicker?.blur();
    this.setDateFilter();
  }

  private setDateFilter(): void {
    if (!this.date) return;
    this.filterData.date = [this.date];
    this.saveFilter();
  }

  updateSelectedFilterList(checked: boolean, index: number): void {
    this.filterData.type[index].selected = checked;
    this.saveFilter();
  }

  public saveFilter(): void {
    const typesArr = this.filterData.type?.filter((x: IUnitAnalyticsFilterItemModel) => x.selected).map((x: IUnitAnalyticsFilterItemModel) => x.value);
    this.filterDataEvent.emit({type: typesArr, date: this.filterData.date[0] });
  }
}
