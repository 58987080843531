import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ITablePagination, IThreadUnit, UnitUserModel } from '@atlas-workspace/shared/models';
import { PaginationUtil, RestThreadsService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-thread-view-recipients',
  templateUrl: './thread-view-recipients.component.html',
  styleUrls: ['./thread-view-recipients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreadViewRecipientsComponent implements OnInit {
  @Input() modalRef!: NgbModalRef;
  @Input() projectId!: number;
  @Input() set unitList(list: IThreadUnit[]) {
    this.selectedUnits = cloneDeep(list);
  }
  @Output() saveChangesEmitter = new EventEmitter<IThreadUnit[]>();

  private readonly searchControlDebounceTime = 300;
  public readonly tooltipOpenDelay = 400;

  public search: FormControl = new FormControl('');
  public searchParam: string | undefined;
  public units!: UnitUserModel[];
  public selectedUnits: IThreadUnit[] = [];
  public sortParam = 'default';
  public pagination!: ITablePagination;
  public $scrollToTop = new Subject<boolean>();

  constructor(private restThreadsService: RestThreadsService, private cd: ChangeDetectorRef) {
    this.pagination = { ...PaginationUtil.defaultPagination };
  }

  ngOnInit(): void {
    this.initSearchValueChangesHandler();
    this.getUnits();
  }

  private initSearchValueChangesHandler(): void {
    this.search.valueChanges
      .pipe(
        debounceTime(this.searchControlDebounceTime),
        distinctUntilChanged(),
        untilDestroyed(this),
        tap((term) => (this.searchParam = term))
      )
      .subscribe(() => this.getUnits());
  }

  private getUnits(p = { ...PaginationUtil.defaultPagination }, expandCurrentPage = false): void {
    this.restThreadsService
      .getUnitsForCreation(this.projectId, this.searchParam, p, this.sortParam)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (expandCurrentPage) {
          const isSelectedAllVisibleUnits = this.units.filter((u) => u.selected).length === this.units.length;
          this.units.push(
            ...data[0]
              .filter((el) => !this.units.find((u) => u.id === el.id))
              .map((el) => {
                el.selected = isSelectedAllVisibleUnits ? true : !!this.selectedUnits.find((u) => u.id === el.id);
                if (isSelectedAllVisibleUnits && !this.selectedUnits.find((u) => u.id === el.id)) {
                  this.selectedUnits.push(el);
                }
                return el;
              })
          );
        } else {
          this.$scrollToTop.next(true);
          this.units = data[0].map((el) => {
            el.selected = !!this.selectedUnits.find((u) => u.id === el.id);
            return el;
          });
        }
        this.pagination = data[1];
        this.cd.detectChanges();
      });
  }

  public getNextChunk(last: boolean): void {
    if (!last || this.units.length >= this.pagination.totalCount) {
      return;
    }
    if (this.pagination && this.pagination.currentPage < this.pagination.totalPages) {
      this.pagination.currentPage++;
      this.getUnits(this.pagination, true);
    }
  }

  public sort(sortQuery: string): void {
    this.sortParam = sortQuery;
    this.getUnits();
  }

  public checkAll(checked: boolean): void {
    this.units.forEach((item) => (item.selected = checked));
    this.selectedUnits = checked ? this.units : [];
  }

  public checkRow(row: UnitUserModel): void {
    row.selected = !row.selected;
    const indx = this.selectedUnits.findIndex((u) => u.id === row.id);
    row.selected ? indx !== -1 || this.selectedUnits.push(row) : indx === -1 || this.selectedUnits.splice(indx, 1);
  }

  public saveChanges(): void {
    this.saveChangesEmitter.next(
      this.selectedUnits.map((u) => ({
        id: u.id,
        identifier: u.identifier,
      }))
    );
  }
}
