import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EThreadScope, ThreadType } from '@atlas-workspace/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThreadsGuard  {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const threadTypes: Array<ThreadType> =
      route.data.type !== EThreadScope.ReclamationThreadView
        ? ['inbox', 'done', 'drafts', 'sent']
        : ['inbox', 'done', 'drafts', 'sent', 'all'];
    if (!threadTypes.includes(route.params.list_type)) {
      let path = state.url.replace(route.params.list_type, 'inbox');
      if (route.params.id) {
        path = path.replace('/' + route.params.id, '');
      }
      void this.router.navigateByUrl(path);
    }

    return true;
  }
}
