import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INewestToggleMenu } from '@atlas-workspace/shared/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-toggle-control',
  templateUrl: './toggle-control.component.html',
  styleUrls: ['./toggle-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class ToggleControlComponent {
  @Input() activeTab!: string;
  @Input() itemsMenu!: INewestToggleMenu[];
  @Output() changeItemMenuHandler = new EventEmitter<string>();

  public selectItemMenu(value: INewestToggleMenu): void {
    this.activeTab = value.id;
    this.changeItemMenuHandler.emit(this.activeTab);
  }
}
