import { ModelSettings } from '../models/project-settings.model';

export enum EMenuAction {
  Profile = 'profile_action',
  Settings = 'setting_action',
}

export interface IMenu {
  title: string;
  icon: string;
  route?: string;
  counter?: number;
  disableRedirect?: boolean;
  actionName?: EMenuAction;
  id?: string | number;
  access?: keyof ModelSettings;
  highlighted?: boolean;
  comingSoon?: boolean;
}

export interface ISettingsMenu extends Partial<IMenu> {
  name: string;
  route: string;
  disabled: boolean;
  tooltip?: string;
  counter?: number;
  accessTag?: string;
  totalCount?: number | string;
  displayNewMark?: boolean;
  kind?: string;
  queryParams?: { [key: string]: string };
}

export interface IFavoritesMenu {
  icon: string;
  projects: IFavoriteProject[];
}

export interface ICustomMenu {
  title: string;
  route: string;
  disabled?: boolean;
}

export interface IFavoriteProject {
  id: number;
  name: string;
  iconColor?: string;
}

export interface IThreadHeaderMenu {
  title: string;
  icon: string;
  route?: string;
  counter?: number;
  disableRedirect?: boolean;
  id?: string;
  key: 'inbox' | 'drafts' | 'done' | 'sent';
}
