import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {defaultSwiperModalOptions} from "@atlas-workspace/shared/directives";
import { FileValidators } from '@atlas-workspace/shared/form';
import {
  FloorPlanVersionModalComponent, ImageModalV2Component,
  ModalFacadeService, ModalHelpersService,
} from '@atlas-workspace/shared/modals';
import {
  ChangeRequestModel,
  ClientChangeRequestModel, DocumentModel,
  EPlatform,
  EThreadScope,
  EThreadUserType, fileUploaderImageTypes,
  ThreadAttachmentModel,
  ThreadMessageModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import {FileHelper} from '@atlas-workspace/shared/service';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import {cloneDeep} from "lodash";

import messagesUnread from '!!raw-loader?!@atlas-workspace/shared/assets/lib/messages-unread.svg';
import deleteSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/threads/delete.svg';

@Component({
  selector: 'atl-thread-message',
  templateUrl: './thread-message.component.html',
  styleUrls: ['./thread-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreadMessageComponent implements OnInit {
  @Input() message!: ThreadMessageModel;
  @Input() isSameSender = false;
  @Input() afterDelimiter = false;
  @Input() threadHasOnlyOneMessage = false;
  @Input() public scope!: EThreadScope;
  @Input() public isFirst!: boolean;

  @Input() public changeRequest!: ChangeRequestModel | ClientChangeRequestModel;
  @Input() public unit!: UnitUserModel;

  @Output() public readonly remove = new EventEmitter<number>();
  @Output() public readonly unread = new EventEmitter();

  public readonly deleteSvg = deleteSvg;
  public readonly messagesUnread = messagesUnread;
  public readonly userTypes = EThreadUserType;
  public readonly scopeTypes = EThreadScope;
  public readonly tooltipOpenDelay = 400;

  public platform!: EPlatform;
  public platformTypes = EPlatform;
  private openedAttachmentIdOptionSet: Set<number> = new Set();

  constructor(
    @Inject(WEB_PLATFORM_TYPE) private platformType: EPlatform,
    private modalHelpersService: ModalHelpersService,
    private modalFacadeService: ModalFacadeService
  ) {
    this.platform = this.platformType;
  }

  ngOnInit(): void {
    if (this.message?.attachments.length) {
      this.message.attachments = this.message.attachments.map((i) => {
        if (i.extension) i.type = FileHelper.handleFileType(i.extension);

        i.isImage = FileValidators.isImage(i);
        return i;
      });
    }
  }

  openFilePreview(file: ThreadAttachmentModel): void {
    if (!fileUploaderImageTypes.includes(file.extension as string)) {
      const document: Partial<DocumentModel> = {
        fileExtension: <any>file.extension,
        fileSize: <number>file.size,
        title: file.name,
        fileName: {
          downloadUrl: file.fileName.downloadUrl,
          url: file.fileName.url,
        },
        createdAt: file.uploadDate
      };
      this.modalHelpersService.previewDocument(document as DocumentModel, true, true);
    } else {
      const modalClass =
        this.platformType === EPlatform.WEB_CLIENT ? 'document-preview-modal client' : 'document-preview-modal';

      const list = this.message.attachments.filter(a => a.extension && fileUploaderImageTypes.includes(a.extension));
      const idx = list.findIndex(i => i.id === file.id);
      const modalRef = this.modalFacadeService.openModal(
        ImageModalV2Component,
        {
          centered: true,
          windowClass: 'full-screen-modal--document',
          modalDialogClass: modalClass,
        }
      );
      modalRef.componentInstance.slides = list;
      modalRef.componentInstance.isClient = this.platform === this.platformTypes.WEB_CLIENT;
      modalRef.componentInstance.isFloorPlan = false;
      modalRef.componentInstance.index = idx;
      modalRef.componentInstance.getSubtitleProperty = 'uploadDate';
      modalRef.componentInstance.swiperConfig = cloneDeep(defaultSwiperModalOptions);
      modalRef.componentInstance.modalRef = modalRef;
    }
  }

  showVersionFloorPlan(version: number | undefined): void {
    if (!version) return;
    const modalRef = this.modalFacadeService.openModal(FloorPlanVersionModalComponent, {
      centered: true,
      windowClass: 'full-screen-modal floor-plan-modal',
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.changeRequest = this.changeRequest;
    modalRef.componentInstance.unit = this.unit;
    modalRef.componentInstance.version = version;
  }

  public setOpenedOption([isOpened, fileid]: [boolean, number]): void {
    isOpened ? this.openedAttachmentIdOptionSet.add(fileid) : this.openedAttachmentIdOptionSet.delete(fileid);
  }

  public get hasOpenedAttachmentOptions(): boolean {
    return Boolean(this.openedAttachmentIdOptionSet.size);
  }
}
