import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { TitledCheckboxModule } from '@atlas-workspace/shared/form';
import { TruncatePipeModule } from '@atlas-workspace/shared/pipes';
import { SharedTranslateForChildModule } from '@atlas-workspace/shared/translate';

import { CreateThreadProjectsModalComponent } from './create-thread-projects-modal.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedTranslateForChildModule,
    TitledCheckboxModule,
    SharedDirectivesModule,
    TruncatePipeModule
  ],
  declarations: [CreateThreadProjectsModalComponent],
  exports: [CreateThreadProjectsModalComponent],
})
export class CreateThreadProjectsModalModule {}
