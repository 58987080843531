<div (clickOutside)="close()" atlClickOutside class="button-expanded-dropdown__wrapper">
  <button [disabled]="disabled" class="button-expanded-dropdown__button">
    <span class="button-expanded-dropdown__button-text" (click)="close()">
      <ng-content></ng-content>
    </span>
    <span
      class="button-expanded-dropdown__button-icon cursor-pointer"
      [ngClass]="{
        'expanded': expanded,
        'enabled-dropdown': enabledDropdown !== undefined && enabledDropdown,
        'disabled-dropdown': enabledDropdown !== undefined && !enabledDropdown,
      }"
      data-cy="cy-expanded-dropdown-button-icon"
      (click)="toggle()"
    >
      <atl-custom-icon class="expanded-icon" [class.expanded]="expanded" [icon]="icon" [size]="16"></atl-custom-icon>
      <atl-custom-icon *ngIf="mobileIcon" class="expanded-mobile" [icon]="mobileIcon" [size]="16"></atl-custom-icon>
    </span>
  </button>
  <div *ngIf="expanded" class="button-expanded-dropdown__dropdown-content">
    <ng-content select="dropdownContent"></ng-content>
  </div>
</div>
