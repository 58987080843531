import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CapitalLetterDirective,
  ILockedTrigger,
  LockFieldDirective,
  NoEmojiDirective,
  PreventDoubleSpaceDirective,
  ReplaceZeroDirective,
} from '@atlas-workspace/shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { BehaviorSubject, of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { UndoCHangeslModule } from '../undo-changes/undo-changes.module';
import { EPlatform } from '@atlas-workspace/shared/models';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';

enum EInputType {
  Password = 'password',
  Text = 'text',
}

@Component({
  selector: 'atl-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    UndoCHangeslModule,
    ReplaceZeroDirective,
    CapitalLetterDirective,
    PreventDoubleSpaceDirective,
    NoEmojiDirective,
    NgxMaskDirective,
    LockFieldDirective,
  ],
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() private paddingRight = 30;
  @Input() clearable = false;
  @Input() disabled = false;
  @Input() displayShowIcon = false;
  @Input() hasUndo = false;
  @Input() icon = '';
  @Input() isWithoutBorder = false;
  @Input() label!: string;
  @Input() mask = undefined;
  @Input() maxFractionDigits: string | number = 0;
  @Input() maxIntegerDigits: string | number = Infinity;
  @Input() placeholder = '';
  @Input() separatorLimit: string | undefined;
  @Input() showForgotLink = false;
  @Input() suffix = '';
  @Input() thousandSeparator = ',';
  @Input() type = 'text';
  @Input() focus = false;
  @Input() addPrefix = false;
  @Input() stringPrefix = '';
  @Input() loading = false;
  @Input() capitalize = false;
  @Input() isBlurChange = false;
  @Input() saveComma = false;
  @Input() allowNegativeNumbers = false;
  @Input() initDecimalMarker = false;
  @Input() decimalMarker: string | string[] = '.';
  @Input() readOnly = false;
  @Input() isAutoResize = false;
  @Input() surveyResize = false;
  @Input() maxWidthResize = 500;
  @Input() minWidthResize = 300;
  @Input() maxLength = 256;
  @Input() priceMode = false;
  @Input() readonly replaceZero = false;
  @Input() readonly hasDoubleSpace = true;
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  @Input() ID: any;
  @Input() sweechLockOff = false;
  @Input() tabindex?: number;
  @Input() skipPlaceholderTranslate = false;

  @Output() readonly blurHandler = new EventEmitter<unknown>();
  @Output() readonly focusHandler = new EventEmitter<unknown>();
  @Output() readonly lockedTrigger = new EventEmitter<ILockedTrigger>();

  public width!: number;
  public showPasswordToggle?: boolean;
  public inputHover = false;
  public inputFocus = false;

  undoDuration = 5;
  undoTimer$ = new BehaviorSubject(0);

  public platform!: EPlatform;
  public platformTypes = EPlatform;

  constructor(
    @Inject(WEB_PLATFORM_TYPE) protected platformType: EPlatform,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.platform = this.platformType;
  }

  @Input() inputValue!: string | number;
  private initValue!: string | number;
  private protocol = /^(http)/i;

  public onChange!: (value: string | number) => void;
  public onTouched!: () => void;

  public showUndo$ = new BehaviorSubject(false);

  @ViewChild('input') inputElement!: ElementRef;

  @ViewChild('hiddenText') set hiddenText(value: ElementRef) {
    if (value) {
      this._hiddenText = value;
      if (this.isAutoResize) {
        setTimeout(() => {
          this.calculationWidth();
        });
      }
    }
  }

  get hiddenText(): ElementRef {
    return this._hiddenText;
  }

  get iconName(): string {
    const name = this.showPasswordToggle ? 'show' : 'hide';
    return `password_${name}.svg`;
  }

  private _hiddenText!: ElementRef;

  changeValue(value: string | number): void {
    if (this.addPrefix && (value as string)?.length > 0 && (value as string)?.length <= 3) {
      return;
    }
    if (this.addPrefix && (value as string)?.length > 3 && !this.protocol.test(value as string)) {
      value = this.stringPrefix + value;
    }
    this.inputValue = value;
    if (this.hasUndo) {
      this.undoTimer$.next(0);
      this.showUndo$.next(true);
    } else {
      value = this.clearValue(value);
      if (this.type === 'number' && value) {
        value = (value as string).replace(/\s/g, '');
        value = Number(value);
      }
      this.onChange(value);
      if (this.inputElement && this.priceMode) {
        const end = this.inputElement.nativeElement.selectionStart + 1;
        this.inputElement.nativeElement.setSelectionRange(end, end);
      }
      if (!this.isBlurChange) {
        this.onTouched();
      }
    }
    this.cdr.detectChanges();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string | number): void {
    if (this.type === 'number' && value) {
      value = Number(value);
    }
    this.initValue = value || '';

    this.inputValue = value || '';
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  // ToDO: should be removed from the most reusable component!
  goToForgotPage(): Promise<boolean> {
    return this.router.navigateByUrl('auth/forgot-pass');
  }

  finishUndo(): void {
    this.showUndo$.next(false);
    this.initValue = this.inputValue;
    this.onChange(this.inputValue);
  }

  cancelUndo(): void {
    this.undoTimer$.next(0);
    this.showUndo$.next(false);
    this.inputValue = this.initValue;
  }

  isLocked(value: ILockedTrigger): void {
    this.lockedTrigger.emit(value);
  }

  checkFocus(): void {
    this.focusHandler.emit();
    this.inputFocus = true;
  }

  startTimer(): void {
    if (this.hasUndo) {
      this.onTouched();
      this.undoTimer$.next(this.undoDuration);
    }
  }

  toggleDisplayingPassword(): void {
    this.showPasswordToggle = this.type === EInputType.Password;
    this.type = this.type === EInputType.Password ? EInputType.Text : EInputType.Password;
  }

  clearInput(): void {
    this.inputValue = '';
    this.changeValue(this.inputValue);
  }

  private clearValue(value: string | number): string | number {
    if (this.separatorLimit && !this.saveComma) {
      value = String(value).replace(/,/g, '');
      this.inputValue = value;
    }
    if (this.suffix) {
      value = String(value).replace(this.suffix, '');
    }
    return value;
  }

  ngAfterViewInit(): void {
    if (this.focus) {
      this.inputElement.nativeElement.focus();
    } else {
      of([])
        .pipe(take(1), delay(100))
        .subscribe(() => this.inputElement.nativeElement.blur());
    }
  }

  private calculationWidth(): void {
    this.cdr.detectChanges();
    const { offsetWidth, clientWidth } = this.hiddenText.nativeElement;
    const { value } = this.inputElement.nativeElement;
    const summaryWidth = offsetWidth + this.paddingRight;
    if (this.surveyResize) {
      if (value?.length) {
        if (clientWidth < this.maxWidthResize) {
          this.width = clientWidth;
        } else {
          this.width = this.maxWidthResize;
        }
      } else {
        this.width = this.maxWidthResize;
      }
    } else {
      if (summaryWidth < this.maxWidthResize) {
        this.width = Math.max(this.minWidthResize, summaryWidth);
      } else {
        this.width = this.maxWidthResize;
      }
    }
    this.cdr.detectChanges();
  }

  onBlur(): void {
    this.inputFocus = false;
    this.blurHandler.emit();
    if (this.hasOnlySpaces(this.inputValue)) {
      this.clearInput();
    }
    if (this.initValue !== this.inputValue && this?.onTouched) {
      this.onTouched();
    }
    if (this.surveyResize) {
      const { offsetWidth } = this.hiddenText.nativeElement;
      const { value } = this.inputElement.nativeElement;
      if (value?.length) {
        if (offsetWidth < this.maxWidthResize) {
          this.width = offsetWidth;
        } else {
          this.width = this.maxWidthResize;
        }
      } else {
        this.width = this.maxWidthResize;
      }
    }
  }

  private hasOnlySpaces(value: string | number): boolean {
    if (typeof value === 'number') {
      return String(value).trim() === '';
    }

    return value?.trim() === '';
  }

  public resizeInput(): void {
    if (this.isAutoResize) {
      this.calculationWidth();
    }
  }

  public iconActiveHandler(active: boolean): void {
    this.inputHover = active;
  }
}
