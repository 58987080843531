import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { CommentTextAreaComponent } from '../comment-textarea/comment-textarea.component';

@Component({
  selector: 'atl-textarea-autoresize',
  templateUrl: './textarea-autoresize.component.html',
  styleUrls: ['./textarea-autoresize.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaAutoresizeComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaAutoresizeComponent extends CommentTextAreaComponent {
  @Input() maxLength = Infinity;
  @Input() capitalize = true;
  @Input() stringMode = false;
  @Input() autoresizeOffset?: number;
  @Input() tabindex = 0;
  @Input() fixedPosition = false;
  @Input() maxHeight = Infinity;
  @Input() absolutePosition = false;

  @Output() private readonly blurEvent = new EventEmitter<string>();

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }

  enter(e: Event): void {
    e.stopPropagation();
  }

  onBlur(value: string): void {
    this.onTouched();
    this.blurEvent.emit(value);
  }

  onFocus(event: boolean): void {
    this.focused = event;
    if (this.isFirefox && !this.inputValue) {
      this.firefoxOnFocusResolver();
    }
  }

  private get isFirefox(): boolean {
    return navigator.userAgent.toLowerCase().includes('firefox');
  }

  private firefoxOnFocusResolver(): void {
    this.writeValue('\xA0');
    of(null)
      .pipe(delay(0))
      .subscribe(() => this.writeValue(''));
  }
}
