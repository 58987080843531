import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import  {ReactiveFormsModule } from "@angular/forms";
import { NgbModalFooterComponent, NgbModalHeaderComponent } from "@atlas-workspace/shared/ui";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from '@ngx-translate/core';

import { InputComponent } from "../input/input.component";
import { IconListPopupComponent } from './components/icon-list-popup/icon-list-popup.component';
import { ProductTypeModalComponent } from "./product-type-modal.component";

@NgModule({
  declarations: [ProductTypeModalComponent, IconListPopupComponent],
  exports: [ProductTypeModalComponent, IconListPopupComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbModalHeaderComponent,
    NgbModalFooterComponent,
    InputComponent
  ],
})
export class ProjectOnboardingModalModule {}
