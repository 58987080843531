import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileValidators } from '@atlas-workspace/shared/form';
import {
  AdminPracticalInfoModal,
  ETablePagination,
  FileModel,
  IAdminPracticalInfo,
  IFormPracticalInfo,
  IFormUpdate,
  ImageModel,
  ITablePagination,
} from '@atlas-workspace/shared/models';
import { DataTableHelperService, PaginationUtil } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PracticalInfoService {
  private practicalPagination$ = new BehaviorSubject<ITablePagination | null>(null);

  constructor(private http: HttpClient, private tableService: DataTableHelperService) {}

  /**
   * @note Get Practical Infos for the project
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1practical_infos/get
   */
  public getPracticalInfoList(
    projectId: number,
    sort = '',
    search = '',
    paginate?: ITablePagination
  ): Observable<AdminPracticalInfoModal[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http
      .get<any>(environment.apiBaseUrl + `/api/v1/projects/${projectId}/practical_infos`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
          this.practicalPagination$.next(pagination);
        }),
        map((res) => res.body.data.practical_infos),
        map((data: IAdminPracticalInfo[]) => plainToClass(AdminPracticalInfoModal, data))
      );
  }

  /**
   * @note Get Practical Infos for the project
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1practical_infos/get
   */
  public getPracticalInfoState(
    projectId: string,
    sort = '',
    search = '',
    paginate?: ITablePagination
  ): Observable<any> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http.get<any>(environment.apiBaseUrl + `/api/v1/projects/${projectId}/practical_infos`, {
      params,
      observe: 'response',
    });
  }

  /**
   * @note Delete Practical Info
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1practical_infos~1%7Bid%7D/delete
   */
  public removePracticalInfo(projectId: number, id: number): Observable<unknown> {
    return this.http.delete(`${environment.apiBaseUrl}api/v1/projects/${projectId}/practical_infos/${id}`);
  }

  /**
   * @note Batch Practical Info Deletion
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1practical_infos~1batch_destroy/delete
   */
  public removeBatchPracticalInfo(projectId: string, ids: number[]): Observable<unknown> {
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => {
      params = params.append('ids[]', id.toString());
    });

    return this.http.delete(`${environment.apiBaseUrl}api/v1/projects/${projectId}/practical_infos/batch_destroy`, {
      params,
    });
  }

  /**
   * @note Update Practical Info
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1practical_infos~1%7Bid%7D/patch
   */
  public patchPracticalInfo(
    projectId: number,
    id: number,
    formControls: IFormPracticalInfo,
    removeFiles: (FileModel | ImageModel | File)[],
    draft: boolean | undefined,
    unitIds: number[],
    layoutTypeIds: number[]
  ): Observable<AdminPracticalInfoModal> {
    const createBody = this.createFormData(formControls, draft, unitIds, layoutTypeIds);
    const removeBody = this.removeFormData(formControls, draft, removeFiles);
    return this.http
      .patch(`${environment.apiBaseUrl}api/v1/projects/${projectId}/practical_infos/${id}`, createBody)
      .pipe(
        mergeMap(() =>
          this.http.patch(`${environment.apiBaseUrl}api/v1/projects/${projectId}/practical_infos/${id}`, removeBody)
        ),
        map((res: any) => res.data),
        map((practicalInfo) => plainToClass(AdminPracticalInfoModal, practicalInfo))
      );
  }

  /**
   * @note Create Practical Info
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1practical_infos/post
   */
  public createPracticalInfo(
    projectId: number,
    formControls: IFormUpdate,
    draft: boolean | undefined,
    unitIds: number[],
    layoutTypeIds: number[]
  ): Observable<AdminPracticalInfoModal> {
    const body = this.createFormData(formControls, draft, unitIds, layoutTypeIds);
    return this.http.post(`${environment.apiBaseUrl}api/v1/projects/${projectId}/practical_infos`, body).pipe(
      map((res: any) => res.data),
      map((practicalInfo) => plainToClass(AdminPracticalInfoModal, practicalInfo))
    );
  }

  get pagination$(): Observable<ITablePagination | null> {
    return this.practicalPagination$.asObservable();
  }

  createFormData(
    formControls: IFormPracticalInfo,
    isDraft: boolean | undefined,
    unitIds: number[],
    layoutTypeIds: number[]
  ): FormData {
    const body = new FormData();

    if (unitIds?.length) {
      unitIds.forEach((unit) => {
        body.append('practical_info[units_ids][]', unit.toString());
      });
    } else {
      body.append('practical_info[units_ids][]', '');
    }

    if (layoutTypeIds?.length) {
      layoutTypeIds.forEach((unit) => {
        body.append('practical_info[layout_types_ids][]', unit.toString());
      });
    } else {
      body.append('practical_info[layout_types_ids][]', '');
    }

    body.append('practical_info[name]', formControls.name);
    body.append('practical_info[description]', formControls.text);
    body.append('practical_info[draft]', (isDraft ?? '').toString());
    formControls.document?.forEach((file: Partial<FileModel | ImageModel>) => {
      if (!file.id) {
        const formFile: File = file as File;
        if (FileValidators.isImage(formFile)) {
          body.append('practical_info[images_attributes][][filename]', formFile, formFile.name);
        } else {
          body.append('practical_info[file_resources_attributes][][filename]', formFile, formFile.name);
        }
      }
    });

    return body;
  }

  removeFormData(
    formControls: IFormPracticalInfo,
    isDraft: boolean | undefined,
    removeFiles: (FileModel | ImageModel | File)[] = []
  ): FormData {
    const body = new FormData();
    body.append('practical_info[name]', formControls.name);
    body.append('practical_info[description]', formControls.text);
    body.append('practical_info[draft]', (isDraft ?? '').toString());
    removeFiles.forEach((file: Partial<FileModel | ImageModel>) => {
      if (file.id) {
        const attr = FileValidators.isImage(file) ? 'images_attributes' : 'file_resources_attributes';
        body.append(`practical_info[${attr}][][id]`, file.id.toString());
        body.append(`practical_info[${attr}][][_destroy]`, 'true');
      }
    });
    return body;
  }

  /**
   * @note Create Practical Info (reuse template)
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1practical_infos/post
   */
  public createFromTemplate(projectId: number, templateId: number): Observable<AdminPracticalInfoModal> {
    const fd = new FormData();
    fd.append('practical_info[template_id]', String(templateId));
    return this.http
      .post<{ data: AdminPracticalInfoModal }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/practical_infos`, fd)
      .pipe(
        map((res) => res.data),
        map((data: AdminPracticalInfoModal) => plainToClass(AdminPracticalInfoModal, data))
      );
  }
}
