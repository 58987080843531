/* eslint-disable */
import {
  CdkCell,
  CdkCellDef,
  CdkColumnDef,
  CdkFooterCell,
  CdkFooterCellDef,
  CdkHeaderCell,
  CdkHeaderCellDef
} from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { ElementRef } from '@angular/core';

@Directive({
  selector: '[atlCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: AtlCellDef }]
})
export class AtlCellDef extends CdkCellDef {}

@Directive({
  selector: '[atlHeaderCellDef]',
  providers: [{ provide: CdkHeaderCellDef, useExisting: AtlHeaderCellDef }]
})
export class AtlHeaderCellDef extends CdkHeaderCellDef {}

@Directive({
  selector: '[atlFooterCellDef]',
  providers: [{ provide: CdkFooterCellDef, useExisting: AtlFooterCellDef }]
})
export class AtlFooterCellDef extends CdkFooterCellDef {}

@Directive({
  selector: '[atlColumnDef]',
  inputs: ['sticky'],
  providers: [
    { provide: CdkColumnDef, useExisting: AtlColumnDef },
    { provide: 'MAT_SORT_HEADER_COLUMN_DEF', useExisting: AtlColumnDef }
  ]
})
export class AtlColumnDef extends CdkColumnDef {
  /** Unique name for this column. */
  @Input('atlColumnDef')
  get name(): string {
    return this._name;
  }
  set name(name: string) {
    this._setNameInput(name);
    this._setCyAttribute(name);
  }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super();
  }

  /**
   * Add "mat-column-" prefix in addition to "cdk-column-" prefix.
   * In the future, this will only add "mat-column-" and columnCssClassName
   * will change from type string[] to string.
   * @docs-private
   */
  protected _updateColumnCssClassName() {
    super._updateColumnCssClassName();
    this._columnCssClassName!.push(`atl-column-${this.cssClassFriendlyName}`);
  }

  private _setCyAttribute(identifier: string): void {
    if (!this.elementRef?.nativeElement?.childNodes.length) return;
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-cy-sort', identifier);
  }
}

@Directive({
  selector: 'atl-header-cell, th[atl-header-cell]',
  host: {
    class: 'atl-header-cell',
    role: 'columnheader'
  }
})
export class AtlHeaderCell extends CdkHeaderCell {}

@Directive({
  selector: 'atl-footer-cell, td[atl-footer-cell]',
  host: {
    class: 'atl-footer-cell',
    role: 'gridcell'
  }
})
export class AtlFooterCell extends CdkFooterCell {}

@Directive({
  selector: 'atl-cell, td[atl-cell]',
  host: {
    class: 'atl-cell',
    role: 'gridcell'
  }
})
export class AtlCell extends CdkCell { }
