import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { base64ToFile, ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { CropperPosition } from 'ngx-image-cropper/lib/interfaces/cropper-position.interface';
import { BehaviorSubject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

@Component({
  selector: 'atl-image-crop-modal',
  templateUrl: './image-crop-modal.component.html',
  styleUrls: ['./image-crop-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ImageCropModalComponent implements OnInit {
  @Input() event!: Event;
  @Input() originalImage: File | null | undefined;
  @Input() cropperPosition: CropperPosition | undefined;
  @Input() aspectRatio = 16 / 9;
  @Input() maintainAspectRatio = true;
  @Input() allowMoveImage  = false;
  @Input() newConfirmBtnPosition = false;
  @Input() confirmBtnText = 'Shared.Button.Save';
  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;

  readonly spinnerImage = 'assets/spinner.svg';
  croppedImage: string | null | undefined;
  imageChangedEvent!: Event;
  transform: ImageTransform = {};
  scale = 1;
  public loading$ = new BehaviorSubject(false);
  public cropper: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };
  public readonly tooltipDelay = 500;

  constructor(private activeModal: NgbActiveModal, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.fileChangeEvent(this.event);
    this.initLoadingListener();
  }

  imageLoaded(): void {
    this.imageCropper.cropperReady.subscribe(
      () => {
        if (this.cropperPosition) {
          this.cropper.x1 = +this.cropperPosition.x1;
          this.cropper.x2 = +this.cropperPosition.x2;
          this.cropper.y1 = +this.cropperPosition.y1;
          this.cropper.y2 = +this.cropperPosition.y2;
          this.imageCropper.cropper = { ...this.cropper };
        }
      }
    );
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  closeModal(close = true): void {
    if (close) {
      this.activeModal.dismiss('close');
      return;
    }
    this.loading$.next(true);
    this.cdr.markForCheck();
  }

  zoomChange(value: string): void {
    this.scale = +value;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  decrementZoom(): void {
    if (this.scale > 0.1) {
      this.scale = this.scale - 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale,
      };
    }
  }

  incrementZoom(): void {
    if (this.scale < 1.9) {
      this.scale = this.scale + 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale,
      };
    }
  }

  private initLoadingListener(): void {
    this.loading$
      .pipe(
        untilDestroyed(this),
        delay(50),
        filter((x) => x)
      )
      .subscribe(async () => {
        const event = await this.imageCropper.crop('base64');
        this.croppedImage = event?.base64;
        const width = event?.width;
        const height = event?.height;
        const cropperPosition = event?.cropperPosition;
        const imagePosition = event?.imagePosition;
        if (this.croppedImage) {
          const image = base64ToFile(this.croppedImage);
          this.activeModal.dismiss({ image, width, height, cropperPosition, imagePosition });
        }
      });
  }
}
