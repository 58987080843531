import { Injectable, NgZone } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';
import { Observable } from 'rxjs';

import { Channel } from './brodcust-facade.type';

// @see https://github.com/pubkey/broadcast-channel
@Injectable({
  providedIn: 'root',
})
export class BroadcastFacadeService {
  constructor(private ngZone: NgZone) {}

  public postMessagetoChannel(channelName: Channel, message: any): void {
    const channel = new BroadcastChannel(channelName);
    channel.postMessage(message);
  }

  public getMessagesByChannel(channelName: Channel): Observable<unknown> {
    const channel = new BroadcastChannel(channelName);
    return new Observable((subject) => {
      channel.onmessage = (msg): void => {
        this.ngZone.run(() => {
          subject.next(msg);
        });
      };
    });
  }
}
