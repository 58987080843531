import { Component, Input } from '@angular/core';
import {CommonModule} from "@angular/common";
import {ClickOutsideDirective} from "@atlas-workspace/shared/directives";

@Component({
  selector: 'atl-button-expanded-dropdown',
  templateUrl: './button-expanded-dropdown.component.html',
  styleUrls: ['./button-expanded-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective]
})
export class ButtonExpandedDropdownComponent {
  @Input() icon!: string;
  @Input() mobileIcon!: string;
  @Input() isButton = false;
  @Input() disabled = false;
  expanded = false;

  close(): void {
    this.expanded = false;
  }

  toggle(): void {
    if (!this.isButton) {
      this.expanded = !this.expanded;
    }
  }

  toggleIcon(): void {
    if (this.isButton) {
      this.expanded = !this.expanded;
    }
  }
}
