import { CdkDragEnd, CdkDragStart, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {FloorModel, IMark, IMarkItems, MarkPosition, UnitFloorModel} from '@atlas-workspace/shared/models';
import { PlanMarkService } from '@atlas-workspace/shared/service';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

@Component({
  selector: 'atl-image-mark',
  templateUrl: './image-mark.component.html',
  styleUrls: ['./image-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DragDropModule],
})
export class ImageMarkComponent implements AfterViewInit {
  @Input() set mark(item: IMark | undefined) {
    this.markItems = [];
    this._marks = item;
    this.markListen();
  }
  @Input() editor = false;
  @Input() imageUrl?: string;
  @Input() floorId!: number;
  @Input() floorNumber!: number;
  @Input() floorType!: UnitFloorModel | FloorModel;
  @Input() multiplePins = false;
  @Input() disableDrag = true;
  @Input() lastOrder?: number;
  @Output() setMark = new EventEmitter<IMark>();
  @Output() private readonly existingMarkClick = new EventEmitter<IMarkItems>();

  public markItems: IMarkItems[] = [];
  public width!: number;
  public height!: number;
  private heightContainer?: number;
  private markBlock!: number;
  private _marks?: IMark;
  public isDragging = false;
  private dragStartX = 0;
  private dragStartY = 0;
  private offsetX = 0;
  private offsetY = 0;

  private currentMark!: IMarkItems;
  private readonly respositionDelay = 150;
  private readonly firstLoadDelay = 500;

  @ViewChild('imageWrap') imageWrapperRef!: ElementRef;
  @ViewChild('image') imgRef!: ElementRef;

  @HostListener('window:orientationchange')
  onOrientationChange(): void {
    of(true)
      .pipe(take(1), delay(this.respositionDelay))
      .subscribe(() => {
        this.initImage(true);
      });
  }

  constructor(private cdr: ChangeDetectorRef, private planMarkService: PlanMarkService) {}

  ngAfterViewInit(): void {
    if (this.imageWrapperRef) {
      of(true)
        .pipe(take(1), delay(this.firstLoadDelay))
        .subscribe(() => {
          this.initImage();
        });
    }
  }

  public markTrackBy(index: number, item: IMarkItems): number {
    return item.order;
  }

  public clickOnExistingMark(v: IMarkItems): void {
    if (this.isDragging || !this.multiplePins) return;
    this.existingMarkClick.emit(v);
  }

  public onMark($event: MouseEvent): void {
    if (this.width && this.height && !this.isDragging) {
      const x = Math.round(($event.offsetX * 100) / this.width);
      const y = Math.round(($event.offsetY * 100) / this.height);
      this.setMark.emit({
        mark: [{ x, y, ...(typeof this.lastOrder === 'number' ? {order: this.lastOrder + 1} : {}) }],
        floorId: this.floorId,
        floorNumber: this.floorNumber,
        floorType: this.floorType
      });
    }
  }

  public onMarkerDragStart(event: CdkDragStart, marker: IMarkItems): void {
    if (this.disableDrag) {
      return;
    }
    this.isDragging = true;
    this.currentMark = marker;
    this.dragStartX = event.source.getFreeDragPosition().x;
    this.dragStartY = event.source.getFreeDragPosition().y;
    this.offsetX = parseFloat(this.currentMark.left) - this.dragStartX;
    this.offsetY = parseFloat(this.currentMark.top) - this.dragStartY;
  }

  private initImage(reloadImg: boolean = false): void {
    const el: HTMLDivElement = this.imageWrapperRef.nativeElement;
    this.heightContainer = el.clientHeight;
    if (reloadImg) {
      this.loadImage(this.imgRef.nativeElement);
    }
  }

  public onDragEnd(e: CdkDragEnd): void {
    if (this.disableDrag) {
      return;
    }
    of(null)
      .pipe(delay(100))
      .subscribe(() => {
        this.isDragging = false;
        if (this.currentMark && this._marks && this.width && this.height) {
          const mark = this._marks.mark[this.currentMark.order - 1];
          const dropPoint = e.source.getFreeDragPosition();
          const { x: dropX, y: dropY } = this.calculateDropPercentage(dropPoint);
          const newMark: MarkPosition = {
            x: mark.x + dropX,
            y: mark.y + dropY,
            position: this.currentMark.order - 1,
          };
          this.planMarkService.setMarkPosition(newMark);
        }
      });
  }

  private calculateDropPercentage(dropPoint: { x: number; y: number }): { x: number; y: number } {
    return {
      x: (dropPoint.x * 100) / this.width,
      y: (dropPoint.y * 100) / this.height,
    };
  }

  public loadImage(image: HTMLImageElement): void {
    if (this.heightContainer) {
      const cof = this.heightContainer / +image.naturalHeight;
      this.height = this.heightContainer;
      this.width = Math.floor(+image.naturalWidth * cof);
      this.markBlock = (32 * 100) / this.height;
      this.markListen();
    } else {
      this.initImage(true);
    }
  }

  private markListen(): void {
    if (!this._marks) return;
    this.markItems = [];
    this._marks?.mark?.forEach((mark, i: number) => {
      const order = mark.order || i + 1;
      if (this.width && this.height) {
        const left = mark.x + '%';
        const top = mark.y - this.markBlock + '%';
        const width = 32;
        const height = 32;
        const x = mark.x;
        const y = mark.y;
        this.markItems.push({ order, top, left, width, height, x, y });
        this.cdr.detectChanges();
      }
    });
  }
}
