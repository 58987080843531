import { HttpClient, HttpParams } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {IEnvironment} from "@atlas-workspace/shared/environments";
import {
  FileModel,
  IFormPracticalInfo, imageJpegMime, imageJpgMime,
  ImageModel, imagePngMime,
  ITablePagination, PracticalInfoTemplateModal,
} from '@atlas-workspace/shared/models';
import {AuthAdminService, DataTableHelperService} from '@atlas-workspace/shared/service';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PracticalTemplateService {
  constructor(
    private http: HttpClient,
    private tableService: DataTableHelperService,
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private authAdminService: AuthAdminService,
  ) {
  }

  /**
   * @note Get Practical Info templates for the firm
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info-Template/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1practical_info_templates/get
   */
  getTemplates(
    search: string,
    sort: string,
    paginationData: ITablePagination,
    notDrafts = false
  ): Observable<{ value: PracticalInfoTemplateModal[], pagination: ITablePagination }> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginationData);
    if (notDrafts) {
      params = params.append('not_drafts', String(notDrafts));
    }
    return this.http.get<{ data: { practical_info_templates: PracticalInfoTemplateModal[] } }>(
      `${this.environment.apiBaseUrl}/api/v1/firms/${this.authAdminService.firm?.firmId}/practical_info_templates`,
      {
        params,
        observe: 'response'
      }).pipe(
      map((res) => this.tableService.transformResponse<PracticalInfoTemplateModal>(res, PracticalInfoTemplateModal, 'practical_info_templates'))
    );
  }

  /**
   * @note Create Practical Info Template
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info-Template/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1practical_info_templates/post
   */
  createTemplates(body: IFormPracticalInfo, draft: boolean): Observable<PracticalInfoTemplateModal> {
    const createBody = this.createFormData(body, draft);
    return this.http.post(`${this.environment.apiBaseUrl}/api/v1/firms/${this.authAdminService.firm?.firmId}/practical_info_templates`, createBody).pipe(
      map((res: any) => res.data),
      map((practicalInfo) => plainToClass(PracticalInfoTemplateModal, practicalInfo))
    );
  }

  /**
   * @note Update Practical Info Template
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info-Template/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1practical_info_templates~1%7Bid%7D/patch
   */
  updateTemplates(id: number, body: IFormPracticalInfo, removeFiles: (FileModel | ImageModel | File)[], draft: boolean): Observable<PracticalInfoTemplateModal> {
    const createBody = this.createFormData(body, draft);
    const removeBody = this.removeFormData(body, draft, removeFiles);
    return this.http.patch(`${this.environment.apiBaseUrl}/api/v1/firms/${this.authAdminService.firm?.firmId}/practical_info_templates/${id}`, createBody).pipe(
      mergeMap(() => this.http.patch(`${this.environment.apiBaseUrl}/api/v1/firms/${this.authAdminService.firm?.firmId}/practical_info_templates/${id}`, removeBody)),
      map((res: any) => res.data),
      map((practicalInfo) => plainToClass(PracticalInfoTemplateModal, practicalInfo))
    );
  }

  /**
   * @note Show Practical Info Template data
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info-Template/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1practical_info_templates~1%7Bid%7D/get
   */
  getTemplate(id: number): Observable<PracticalInfoTemplateModal> {
    return this.http.get(`${this.environment.apiBaseUrl}/api/v1/firms/${this.authAdminService.firm?.firmId}/practical_info_templates/${id}`).pipe(
      map((res: any) => res.data),
      map((practicalInfo) => plainToClass(PracticalInfoTemplateModal, practicalInfo))
    );
  }

  /**
   * @note Batch destroy Practical Info Templates
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Practical-Info-Template/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1practical_info_templates~1batch_destroy/delete
   */
  destroyTemplates(ids: number[]): Observable<any> {
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => (params = params.append('ids[]', id.toString())));
    return this.http.delete(`${this.environment.apiBaseUrl}/api/v1/firms/${this.authAdminService.firm?.firmId}/practical_info_templates/batch_destroy`, {params});
  }

  private createFormData(
    formControls: IFormPracticalInfo,
    isDraft: boolean | undefined,
  ): FormData {
    const body = new FormData();

    body.append('practical_info_template[name]', formControls.name);
    body.append('practical_info_template[description]', formControls.text);
    body.append('practical_info_template[draft]', (isDraft ?? '').toString());
    body.append('practical_info_template[user_type]', 'investor');
    formControls.document?.forEach((file: Partial<FileModel | ImageModel>) => {
      if (!file.id) {
        const formFile: File = file as File;
        if (this.isImage(formFile)) {
          body.append('practical_info_template[images_attributes][][filename]', formFile, formFile.name);
        } else {
          body.append('practical_info_template[file_resources_attributes][][filename]', formFile, formFile.name);
        }
      }
    });

    return body;
  }

  private removeFormData(
    formControls: IFormPracticalInfo,
    isDraft: boolean | undefined,
    removeFiles: (FileModel | ImageModel | File)[] = []
  ): FormData {
    const body = new FormData();
    body.append('practical_info_template[name]', formControls.name);
    body.append('practical_info_template[description]', formControls.text);
    body.append('practical_info_template[draft]', (isDraft ?? '').toString());
    removeFiles.forEach((file: Partial<FileModel | ImageModel>) => {
      if (file.id) {
        const attr = this.isImage(file) ? 'images_attributes' : 'file_resources_attributes';
        body.append(`practical_info_template[${attr}][][id]`, file.id.toString());
        body.append(`practical_info_template[${attr}][][_destroy]`, 'true');
      }
    });
    return body;
  }

  isImage(file: Partial<File | FileModel | ImageModel>): boolean {
    if (file.type) {
      return (
        file.type === imageJpgMime || file.type === imageJpegMime || file.type === imagePngMime || file.type === 'image'
      );
    }
    return false;
  }
}
