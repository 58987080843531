<div ngbDropdown placement="bottom-right" (openChange)="isDropdownOpened = $event">
  <button
    ngbDropdownToggle
    type="button"
    class="dropdown__report-button newest-button newest-button-sm button-gray"
    [class.opened]="isDropdownOpened"
    [id]="id"
    [ngbTooltip]="'Shared.Entity.Download' | translate"
    [openDelay]="500"
    triggers="hover touchstart"
  >
    <atl-custom-icon [icon]="downloadIconNew" [size]="16"></atl-custom-icon>
  </button>
  <div ngbDropdownMenu [attr.aria-labelledby]="id">
    <ng-template ngFor let-item [ngForOf]="items">
      <div
        ngbDropdownItem
        class="cursor-pointer"
        [class.pointer-events-none]="item.commingSoon"
        (click)="reportOn.emit(item)"
      >
        <img [src]="item.icon" class="mr-8" alt="icon" />
        <div>
          <p class="fz-14 lh-24 fw-400 text-black-960">{{ item.labelTranslateKey | translate }}</p>
          <p *ngIf="item.commingSoon" class="coming_soon fz-12 lh-16 fw-400">{{ 'Entity.Coming_soon' | translate }}</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
