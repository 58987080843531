<div class="message-wrapper__container" [ngClass]="reclamationScope || ''" atlWindowInnerHeight>
  <!-- ADD tabs -->
  <div class="message-wrapper__tabs">
    <atl-simple-tabs
      class="tabs"
      [activeTab]="activeTab"
      [tabs]="navigationMenu"
      [routeNavigation]="false"
      (selectingTab)="selectAnotherTab($event)"
    ></atl-simple-tabs>
  </div>
  <ng-template #threadView></ng-template>
</div>
