import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'noProjectSegmentInChangeRequestIdentifier',
  standalone: true,
})
export class NoProjectSegmentInChangeRequestIdentifierPipe implements PipeTransform {
  transform(value: unknown): string | unknown {
    if (typeof value !== 'string') {
      return value;
    }
    return value?.replace(
      /^([A-Z0-9]+-)\d+\/(\d+)$/, (_, prefix, secondPart) => `${prefix}${secondPart.padStart(4, '0')}`
    ) || value;
  }
}
