import {
  EChangeRequestStatus,
  EChangeRequestsView,
  EChangeRequestTab,
  EChangeRequestTabId,
  EGroupView,
  IChangeRequestFilter,
  IChangeRequestUnitsViewFilter,
  ISettingsMenu,
  IUser
} from '@atlas-workspace/shared/models';
import {TranslateService} from "@ngx-translate/core";
import {DropdownItem} from "@atlas-workspace/shared/form";

/**
 * @Cypress
 */
export function navigationMenu(advanceMode = false): ISettingsMenu[] {
  const internalItemName = advanceMode ? 'Title.Internal_discussion' : EChangeRequestTab.Internal;
  return [
    { name: EChangeRequestTab.General, id: EChangeRequestTabId.General, disabled: false, route: '' },
    { name: EChangeRequestTab.Customer, id: EChangeRequestTabId.Customer, disabled: true, route: '', counter: 0 },
    { name: internalItemName, id: EChangeRequestTabId.Internal, disabled: false, route: '', counter: 0 },
    { name: EChangeRequestTab.Notes, id: EChangeRequestTabId.Notes, disabled: false, route: '' },
    ...(!advanceMode ? [{ name: EChangeRequestTab.Offer, id: EChangeRequestTabId.Offer, disabled: false, route: '' }] : [])
  ];
}

export const navigationAdvanceMenu = [
  { name: EChangeRequestTab.General, id: EChangeRequestTabId.General, disabled: false, route: '' },
  { name: EChangeRequestTab.Offer, id: EChangeRequestTabId.Offer, disabled: false, route: '' }
];

export interface IUpdateTable {
  activeOfferId?: number | null;
  activeOfferPrice?: number | null;
  localizedStatus?: string;
  status?: EChangeRequestStatus;
  description?: string;
  responsible?: Partial<IUser> | null;
}

export enum EChangeRequestFormKey {
  Descriptions = 'description',
  Responsible = 'responsible',
  Attachments = 'attachments',
}

export enum EChangeRequestPrefillFormKey {
  Id = 'id',
  Descriptions = 'description',
  Price = 'price',
  VersionNumber = 'versionNumber',
  FileResources = 'fileResources',
}

export function getChangeRequestsViewList(trs: TranslateService): DropdownItem[] {
  return [
    {
      key: EChangeRequestsView.List,
      title: trs.instant('Title.List_view'),
      name: '',
      selected: true,
    },
    {
      key: EChangeRequestsView.Unit,
      title: trs.instant('Title.Unit_view'),
      name: '',
    },
    {
      key: EChangeRequestsView.Message,
      title: trs.instant('Title.Message_view'),
      name: '',
    }
  ]
}

export function getGroupViewList(trs: TranslateService): DropdownItem[] {
  return [
    {
      key: EGroupView.List,
      title: trs.instant('Title.List_view'),
      name: '',
      selected: true,
    },
    {
      key: EGroupView.Floor,
      title: trs.instant('Title.Floor_plan_view'),
      name: '',
    },
  ]
}

export const noSearchStateTranslates = {
  icon: 'assets/glass-no-result.svg',
  title: 'ProjectList.Empty_State.No_results',
  description: 'Projects.Search.Not_Found',
} as const;

export const emptyStateTranslates = {
  noAvailable: {
    title: 'Change_Request.Empty.No_available_date.Title',
    description: 'Change_Request.Empty.No_available_date.Description'
  },
  availableNoStart: {
    title: 'Change_Request.Empty.Available_no_start.Title',
    description: 'Change_Request.Empty.Available_no_start.Description'
  },
  availableStart: {
    title: 'Change_Request.Empty.Available_start.Title',
    description: 'Change_Request.Empty.Available_start.Description'
  },
  availableEnd: {
    title: 'Change_Request.Empty.Available_close_date.Title',
    description: 'Change_Request.Empty.Available_close_date.Description'
  }
} as const;

export const initialFilterState: IChangeRequestFilter = {
  units: [],
  status: [],
  responsible: [],
  date: null,
};

export const initialUnitsViewFilterState: IChangeRequestUnitsViewFilter = {
  only: false,
};
