<section
  data-cy="cy-unit-selection-drop-down"
  class="thread__units"
  tabindex="0"
>
  <div class="thread__units-tabs">
    <atl-simple-tabs
      class="newest-tabs"
      [activeTab]="activeTab"
      [tabs]="navMenu"
      [routeNavigation]="false"
      (selectingTab)="selectTab($event)"
    ></atl-simple-tabs>
  </div>

  <div class="v-scrollbar">
    <div (click)="checkAll()" class="thread__units-item cursor-pointer br-4">
      <div class="thread__units-item-identifier">
        <atl-titled-checkbox
          (changeEvent)="checkAll()"
          [ngModel]="activeTab === navMenu[0].name ? unitsSelected : unitGroupSelected"
          [checkedPartially]="activeTab === navMenu[0].name ? (unitsSelected < allUnits.length) : (unitGroupSelected < allUnitGroup.length)"
        ></atl-titled-checkbox>
        <span class="fz-14 lh-24 fw-400 ellipsis">{{ (activeTab === navMenu[0].name ? 'Entity.All_units' : 'Entity.All_unit_groups') | translate }}</span>
      </div>
    </div>
    <ng-container
      *ngIf="(searchableList.length && isSearchable) || !isSearchable || isLoading; else noSearchResultTemplate"
    >
      <ng-container>
        <div
          *ngFor="let item of loopList; index as i; let last = last"
          (atlInfiniteScroll)="getNextPage(last)"
          [threshold]="0.9"
          (click)="check(item)"
          class="thread__units-item cursor-pointer br-4"
        >
          <div class="thread__units-item-identifier ellipsis">
            <atl-titled-checkbox (changeEvent)="check(item)" [ngModel]="item.selected"></atl-titled-checkbox>
            <div class="d-flex flex-column">
              <span class="d-block fz-14 lh-24 fw-400 ellipsis">{{ item.identifier }}</span>
              <span *ngIf="item.unitBuyer" class="d-block ellipsis fz-12 lh-16 text-black-810">{{item.unitBuyer?.name}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #noSearchResultTemplate>
      <div class="mt-4">
        <span class="fz-14 lh-24 fw-400 ml-8">
          {{ 'Shared.No_search_result' | translate }} "{{ search | truncate: 40 }}"
        </span>
      </div>
    </ng-template>
  </div>

  <footer class="d-flex align-items-center justify-content-between p-8 lowercase">
    <span *ngIf="!isUnitGroupSelected" class="counter fz-14 lh-24 fw-400">
      {{ selectedCount }} <span class="mobile-counter">{{ selectedCount | i18nPlural: unitPluralMap | translate }}</span>
      {{ 'Entity.Selected' | translate }}
    </span>
    <span *ngIf="isUnitGroupSelected" class="counter fz-14 lh-24 fw-400">
      {{ selectedCount }}    <span class="mobile-counter">{{ selectedCount | i18nPlural: unitGroupPluralMap | translate }}</span>
      {{ 'Entity.Selected' | translate }}
    </span>
    <div class="d-flex">
      <button class="newest-button button-gray newest-button-sm ml-12" (click)="clearAll()" [disabled]="!selectedCount">
        {{ 'Shared.Entity.Clear_all' | translate }}
      </button>
    </div>
  </footer>
</section>
