import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LineBreakPipe } from './line-break.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [LineBreakPipe],
  exports: [LineBreakPipe],
})
export class LineBreakPipeModule {}
