import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TextEditorModalComponent } from '../text-editor-modal/text-editor-modal.component';
import { CommonModule } from '@angular/common';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';

@Component({
  selector: 'atl-text-editor-wide',
  templateUrl: './text-editor-wide.component.html',
  styleUrls: ['./text-editor-wide.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, EditorModule, ReactiveFormsModule]
})
export class TextEditorWideComponent extends TextEditorModalComponent {
  @Input() public valControl!: AbstractControl;
}
