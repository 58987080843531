import {HomeMeetingModel, IMyTasksInfos, ProjectModel} from '@atlas-workspace/shared/models';

export class SetProjects {
  static readonly type = '[Projects] save the list of projects';
  constructor(public projects: ProjectModel[], public appendToArray: boolean) {}
}

export class SetMyProjects {
  static readonly type = '[Projects] save the list of my projects';
  constructor(public myProjects: ProjectModel[]) {}
}

export class SetProject {
  static readonly type = '[Projects] save single project';
  constructor(public project: ProjectModel) {}
}

export class SetMyTasks {
  static readonly type = '[Projects] save the list of my tasks';
  constructor(public myTasks: IMyTasksInfos) {}
}

export class SetMyMeetings {
  static readonly type = '[Projects] save the list of my meetings';
  constructor(public myMeetings: HomeMeetingModel[]) {}
}

export class AddProject {
  static readonly type = '[Projects] add new project';
  constructor(public project: ProjectModel) {}
}

export class RemoveProject {
  static readonly type = '[Projects] remove project';
  constructor(public id: number) {}
}

export class SetHomeProjects {
  static readonly type = '[Projects] save the list projects on home page';
  constructor(public projectsHomeList: ProjectModel[]) {}
}
