import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';
import { ISelectTime, ITimeList } from '@atlas-workspace/shared/models';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { DateAdapterService } from '../../services/date-adapter.service';
import { getTimeString } from '../meeting-data-picker/helpers/data-picker.helper';

@Component({
  selector: 'atl-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, ClickOutsideDirective, NgxMaskDirective, TranslateModule],
})
export class TimeSelectComponent implements ControlValueAccessor, OnInit {
  public mask = 'Hh:m0';
  public fromList!: ITimeList[];
  public toList: ITimeList[] = [];
  public showFromList = false;
  public showToList = false;
  public fromValue = '';
  public toValue = '';
  public readonly startValue = '08:00';

  protected timeFrom?: ITimeList;
  private timeTo?: ITimeList;
  private timeValue!: ISelectTime;
  private readonly hours = '';
  private readonly minute = '';
  private readonly minInt = 15;
  private readonly dayMinute = 24 * 60 - this.minInt;
  private readonly hrsInt = 60;
  private readonly hoursKey = 'Shared.Time_select.Hours';
  private readonly minuteKey = 'Shared.Time_select.Minutes';
  private readonly yOffset = -10;

  @ViewChild('from') fromRef!: ElementRef;
  @ViewChild('to') toRef!: ElementRef;

  @Input() readonly placeholder = '--:--';
  @Input() disabled = false;
  @Input() set value(value: ISelectTime) {
    this.timeValue = value;
    this.timeValue ? this.writeValue(value) : this.clear();
  }
  @Input() availabilitySettings = false;
  @Input() formflow = false;
  @Input() readonly singleTime = false;
  @Input() timeIsCurrentDate = true;
  @Output() changeTine = new EventEmitter<ISelectTime>();
  @Output() validateShowPicker = new EventEmitter();

  @Input() private readonly currentDate!: NgbDateStruct;
  @Input() set date(selectedDate: NgbDateStruct | undefined) {
    this.selectedDate = selectedDate;
    if (this.timeIsCurrentDate) {
      this.dateIsSelected = this.isCurrentDate;
    }
  }
  private selectedDate?: NgbDateStruct;
  public dateIsSelected = false;

  public onChange!: (value: ISelectTime | ITimeList | null) => void;
  public onTouched!: () => void;

  constructor(private readonly translateService: TranslateService, private readonly dateAdapter: DateAdapterService) {
    this.hours = translateService.instant(this.hoursKey);
    this.minute = translateService.instant(this.minuteKey);
  }

  ngOnInit(): void {
    this.fromList = this.timeList();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: ISelectTime | null): void {
    if (!value) {
      this.clear();
      return;
    }
    this.timeValue = value;
    this.fromValue = getTimeString(this.timeValue.from);
    this.timeFrom = { value: this.fromValue, ...this.timeValue.from };
    this.toValue = getTimeString(this.timeValue?.to);
    this.timeTo = { value: this.toValue, ...this.timeValue?.to };
    this.initToList();
  }

  get isCurrentDate(): boolean {
    return this.dateAdapter.isCurrentDate(this.currentDate, this.selectedDate);
  }

  get minTime(): { hour: number; min: number } {
    return this.dateAdapter.currentTime();
  }

  private clear(): void {
    this.fromValue = '';
    this.timeFrom = undefined;
    this.toValue = '';
    this.timeTo = undefined;
    this.toList = [];
  }

  public close(): void {
    this.showFromList = false;
    this.showToList = false;
  }

  filterTimeList(item: ITimeList, value: string[]): boolean {
    const timeList = item.value.split(':');
    return !value[1]?.length
      ? timeList[0].includes(value[0])
      : timeList[0].includes(value[0]) && timeList[1].includes(value[1]);
  }

  findClosestValue(inputMinutes: number): number {
    const minutesMultiplesOf15: number[] = Array.from({ length: 4 }, (_, index) => index * 15);

    return minutesMultiplesOf15.reduce((previous, current) =>
      Math.abs(current - inputMinutes) < Math.abs(previous - inputMinutes) ? current : previous
    );
  }

  public inputFrom(value = ''): void {
    const valPars = value.split(':');
    this.fromList = this.timeList().filter((t) => this.filterTimeList(t, valPars));
    if (value.length === 5) {
      const nearestMin = this.findClosestValue(+valPars[1]);
      const time = {
        hour: +valPars[0],
        min: +nearestMin,
        value: value.slice(0, -2) + (nearestMin < 10 ? '0' + nearestMin : nearestMin),
      };
      this.fromValue = time.value;
      this.timeFrom = time;
      if (!this.singleTime) {
        this.setToList();
      } else {
        this.changeTine.emit({ from: time });
      }
    }
  }

  public inputTo(value = ''): void {
    const valPars = value.split(':');
    this.toList = this.getToList().filter((t) => this.filterTimeList(t, valPars));
    if (value.length === 5) {
      let nearestMin: number | undefined = this.findClosestValue(+valPars[1]);

      if (
        this.timeFrom &&
        (+valPars[0] < Number(this.timeFrom?.hour) ||
          (+valPars[0] === Number(this.timeFrom?.hour) && nearestMin <= Number(this.timeFrom?.min)))
      ) {
        const list = this.getToList();
        valPars[0] = Number(list[0].hour).toString();
        valPars[1] = Number(list[0].min).toString();
        value = list[0].value;
        nearestMin = list[0].min;
      }

      const time: ITimeList = {
        hour: +valPars[0],
        min: Number(nearestMin),
        value: value.slice(0, -2) + (Number(nearestMin) < 10 ? '0' + nearestMin : nearestMin),
      };

      this.toValue = time.value;
      this.timeTo = time;
      this.timeHandler();
      this.showToList = false;
    }
  }

  private scrollList(el: HTMLUListElement): void {
    of(true)
      .pipe(take(1), delay(0))
      .subscribe(() => {
        el.childNodes.forEach((li: ChildNode) => {
          if ((li as HTMLLIElement).classList?.contains('select')) {
            const y = (li as HTMLLIElement).offsetTop + this.yOffset;
            el.scrollTo({ top: y });
          }
        });
      });
  }

  public focusFormList(show: boolean, el: HTMLUListElement): void {
    if (this.disabled) return;
    this.showFromList = show;
    this.showToList = false;
    this.scrollList(el);
  }

  public focusToList(show: boolean, el: HTMLUListElement): void {
    if (this.disabled) return;
    this.showFromList = false;
    if (this.toList.length) {
      this.showToList = show;
      this.scrollList(el);
    } else {
      this.fromRef.nativeElement.focus();
    }
  }

  public enterFrom(focus = true): void {
    if (this.fromList.length === 1) {
      this.fromValue = this.fromList[0].value;
      this.timeFrom = this.fromList[0];
      this.setToList();
      this.showFromList = false;
      if (focus) {
        this.toRef.nativeElement.focus();
      }
      this.inputFrom();
      this.timeHandler();
    }
    this.validateShowPicker.emit();
  }

  public enterTo(): void {
    if (this.toList.length === 1) {
      this.toValue = this.toList[0].value;
      this.timeTo = this.toList[0];
      this.showToList = false;
      this.inputTo();
      this.timeHandler();
    }
    this.validateShowPicker.emit();
  }

  public selectFrom(event: Event, time: ITimeList): void {
    event.stopPropagation();
    this.fromValue = time.value;
    this.timeFrom = time;
    this.showFromList = false;
    if (!this.singleTime) {
      this.setToList();
    } else {
      this.timeHandler();
    }
  }

  public selectTo(event: Event, time: ITimeList): void {
    event.stopPropagation();
    this.toValue = time.value;
    this.timeTo = time;
    this.timeHandler();
    this.showToList = false;
  }

  private getToList(): ITimeList[] {
    return this.timeList(this.timeFrom, true);
  }

  private initToList(): void {
    this.toList = this.getToList();
    if (this.timeFrom && this.timeTo) {
      const fromMin = Number(this.timeFrom?.hour) * 60 + Number(this.timeFrom?.min);
      const numTo = Number(this.timeTo?.hour) * 60 + Number(this.timeTo?.min);
      const toMin = numTo === 0 ? 24 * 60 : numTo;
      if (toMin <= fromMin) {
        if (!this.toList.length) {
          this.toValue = '';
          this.timeTo = undefined;
          return;
        }
        this.toValue = this.toList[0].value;
        this.timeTo = this.toList[0];
      }
    }
  }

  private setToList(): void {
    this.initToList();
    this.timeHandler();
    this.toRef.nativeElement.focus();
  }

  private timeHandler(): void {
    if (this.singleTime) {
      if (this.timeFrom) {
        const from = { hour: this.timeFrom.hour, min: this.timeFrom.min };
        this.changeTine.emit({ from });
        if (this.formflow) {
          this.onChange({ from });
          this.onTouched();
        }
      }
    } else {
      if (this.timeTo && this.timeFrom) {
        const from = { hour: this.timeFrom.hour, min: this.timeFrom.min };
        const to = { hour: this.timeTo.hour, min: this.timeTo.min };
        this.changeTine.emit({ from, to });
        if (this.formflow) {
          this.onChange({ from, to });
          this.onTouched();
        }
      }
    }
  }

  protected timeList(init: ITimeList = { hour: 0, min: 0, value: '' }, to = false): ITimeList[] {
    const initToMin = Math.floor(Number(init.min) / this.minInt) * this.minInt;
    const start = Number(init.hour) * this.hrsInt + initToMin + (to ? this.minInt : 0);
    const list: ITimeList[] = [];
    const offsetCalc = Number(init.min) - initToMin;
    for (let i = start; i <= this.dayMinute; i += this.minInt) {
      const time = this.getTime(i);

      const des = to
        ? i - start === 0
          ? this.getTimeDes(offsetCalc)
          : this.getTimeDes(i - start + offsetCalc)
        : undefined;
      list.push({ value: `${getTimeString(time)}`, des, ...time });
    }
    return list;
  }

  private getTime(i: number): { hour: number; min: number } {
    return { hour: Math.trunc(i / this.hrsInt), min: i % this.hrsInt };
  }

  private getTimeDes(i: number): string {
    const t = i + this.minInt;
    const h = Math.floor(t / this.hrsInt);
    const m = t - h * this.hrsInt;
    const hStr = h ? `${h} ${this.hours}` : '';
    const mStr = m ? `${m} ${this.minute}` : '';
    return `${hStr} ${mStr}`;
  }
}
