<ng-container *ngIf="isEditMode; else ViewMode">
  <atl-offer-detail
    [editOffer]="editOffer"
    (back)="updateOffer($event)"
    (updateOffer)="updateOffer($event, true)"
    [changeRequest]="changeRequest"
    [unitId]="unitId"
    [isDualView]="isDualView"
    [isAlLowBankId]="isAlLowBankId"
    [bankIdToggle]="bankIdToggle"
    [floorPlanData]="floorPlanData"
    [unit]="unit"
  ></atl-offer-detail>
</ng-container>

<ng-template #ViewMode>
  <div class="offers">
    <ng-container *ngIf="loadOffers; else loader">
      <div *ngIf="offers?.length" class="offers__form-control">
        <span class="fz-20 lh-24 fw-500">{{ 'Shared.Title.Offer' | translate }}</span>
      </div>

      <div class="offers__table-wrapper" *ngIf="offers?.length; else noOffers">
        <atl-shared-table class="gl-shared-table--lv offers__table">
          <ng-container ngProjectAs="shared-table-header">
            <th
              atlColumnDef="title"
              class="shared-table__header-cell w42"
              atl-sort-header
              (sortEvent)="sort($event)"
              [sortParam]="sortParam"
            >
              <div>{{ 'Title.Offer_name' | translate }}</div>
            </th>
            <th
              atlColumnDef="price"
              class="shared-table__header-cell w19 border-right"
              atl-sort-header
              (sortEvent)="sort($event)"
              [sortParam]="sortParam"
            >
              <span>
                {{ 'Title.Price' | translate }}
              </span>
            </th>
            <th
              atlColumnDef="expiration_date"
              class="shared-table__header-cell w19"
              atl-sort-header
              (sortEvent)="sort($event)"
              [sortParam]="sortParam"
            >
              <span> {{ 'Shared.Title.Due_date' | translate }} </span>
            </th>
            <th
              atlColumnDef="status"
              class="shared-table__header-cell w20"
              atl-sort-header
              (sortEvent)="sort($event)"
              [sortParam]="sortParam"
            >
              <span> {{ 'Shared.Entity.Status' | translate }} </span>
            </th>
          </ng-container>

          <ng-container ngProjectAs="shared-table-body">
            <tr
              *ngFor="let offer of offers; index as i; let last = last"
              (atlInfiniteScroll)="getNextPage(last)"
              [threshold]="0.9"
              (click)="openDetail(offer)"
            >
              <td class="shared-table__body-cell w42 position-relative">
                <div class="d-flex align-items-center">
                  <div class="gl-ellipsis-table-wr">
                    <span
                      #title
                      [ngbTooltip]="offer.title"
                      [disableTooltip]="title.clientWidth >= title.scrollWidth"
                      container="body"
                      placement="right"
                      triggers="hover touchstart"
                      class="d-block ellipsis"
                      >{{ offer.title }}
                    </span>
                  </div>
                </div>
              </td>
              <td class="shared-table__body-cell w19 cursor-pointer border-right white-space-nowrap">
                {{ offer.price | customDecimal }},-
              </td>
              <td class="shared-table__body-cell w19 white-space-nowrap">
                {{ offer.expirationDate | changeFormatDate: '.' | date: 'MMM d, y' }}
              </td>
              <td class="shared-table__body-cell w20">
                <span
                  *ngIf="offer.status"
                  class="gl-ellipsis-table-item status"
                  [ngClass]="'status__' + statusClasses[offer.status].style"
                >
                  {{ offer.localizedStatus }}
                </span>
              </td>
            </tr>
          </ng-container>
        </atl-shared-table>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #noOffers>
  <div class="offers__empty">
    <img class="mb-16" src="assets/offers_empty_state.svg" alt="image" />
    <h2 class="fz-20 lh-24 fw-500 mb-8">{{ 'Description.No_offer' | translate }}</h2>
    <p class="offers__empty-desc fz-16 lh-24">
      {{ 'Description.Offer.Client.Empty' | translate }}
    </p>
  </div>
</ng-template>

<ng-template #loader>
  <div class="h-100 d-flex align-items-center justify-content-center">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>
