<div class="offer-detail v-scrollbar">
  <form [formGroup]="form" class="offer-detail__controls">
    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/spinner-gray.svg'" alt="icon" />
        <span>{{ 'Shared.Reclamation.Input.Status.Label' | translate }}</span>
      </div>
      <div class="offer-detail__control-input d-flex align-items-center">
        <span
          *ngIf="offer.status"
          class="gl-ellipsis-table-item status ml-8"
          [ngClass]="'status__' + statusClasses[offer.status].style"
        >
          {{ offer.localizedStatus }}
        </span>
      </div>
    </div>
    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/calendar-gray.svg'" alt="icon" />
        <span class="ellipsis">{{ 'Title.Offer_expiration' | translate }}</span>
      </div>
      <div class="offer-detail__control-input">
        <atl-inline-date-time-picker
          formControlName="expirationDate"
          [dateValue]="offer.expirationDate"
          [minDate]="currentDate"
          [showFooter]="false"
          [placeholder]="'Shared.Reclamation.Input.Deadline.Placeholder' | translate"
        ></atl-inline-date-time-picker>
      </div>
    </div>
    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/attachment_gray.svg'" alt="icon" />
        {{ 'Shared.Reclamation.Input.Attachments.Label' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-dropdown-input-file
          *ngIf="offer.fileResources?.length; else noAttachments"
          formControlName="fileResources"
          dragGroup="fileResources"
          [newOnly]="true"
          [autoSave]="true"
          (viewTempFilesEmitter)="openPreview($event)"
        ></atl-dropdown-input-file>
        <ng-template #noAttachments>
          <span class="empty-input">
            {{ 'Shared.Reclamation.No_attachments' | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </form>


  <div class="offer-detail__request-list">
    <span class="fz-16 lh-24 fw-600 mb-16 pl-12">
      {{ 'Shared.Entity.Summary' | translate }}
    </span>

    <div class="d-flex flex-column align-items-center w-100">
      <div
        *ngFor="let request of offer.subOffers"
        class="offer-detail__accordion"
        [class.open]="request.open"
      >
        <div class="header">
          <span class="header__title">
            {{ request.changeRequest.identifier | noProjectSegmentInChangeRequestIdentifier }}
          </span>

          <span class="cost mr-8">{{ request.price | customDecimal }},-</span>

          <span class="header__arrow" [class.open]="request.open" (click)="request.toggle()">
            <img src="/assets/arrow-gray-down.svg" alt="arrow" />
          </span>
        </div>
        <div class="main">
          <label class="main__label mt-4">{{ 'Shared.Reclamation.Input.Description.Label' | translate }}</label>
          <p class="main__description v-scrollbar small">
            {{ request.description }}
          </p>
          <label class="main__label mt-16">
            {{ 'Shared.Change_request.Combine_Offer.Label.Floor_plan_version' | translate }}
          </label>
          <span class="main__description">
            {{ 'Shared.Entity.Version' | translate }} {{ request.versionNumber }}
          </span>
          <div class="mt-16">
            <atl-document-drag-and-drop
              [(ngModel)]="request.fileResources"
              [disabled]="true"
              [newestViewImage]="true"
              [label]="'Shared.Messages.Attachments'"
              [format]="acceptedExtensions"
              [asUploadButton]="true"
              [maxUploadedFiles]="5"
              [multiple]="true"
              (filePreviewHandler)="subOfferPreview($event, request)"
            >
            </atl-document-drag-and-drop>
          </div>
        </div>
      </div>
    </div>
    <div class="offer-detail__header">
      <div class="offer-detail__total">
        <span class="offer-detail__total-title">
          {{ 'Shared.Change_request.Combine_Offer.Total_changes' | translate }}
        </span>
        <span class="cost mr-53 fw-500"> {{ offer.subOffersTotalPrice | customDecimal }},- </span>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Auth.Create.Price.Mark-up.Input.VAT.Title' | translate }} ({{ offer.pricingComponents.vat}}%)
        </span>
        <span class="cost mr-53"> {{ offer.computedPricingComponents.vat | customDecimal }},- </span>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Input.Mark-up' | translate }} ({{ offer.pricingComponents.markup.value }}%)
        </span>
        <span class="cost mr-8"> {{ offer.computedPricingComponents.markup | customDecimal }},- </span>
        <atl-custom-checkbox
          [(ngModel)]="offer.pricingComponents.markup.includeInTotal"
          [disabled]="true"
          sizeInput="small"
          uniqID="detailMarkup"
          class="newest-checkbox"
        ></atl-custom-checkbox>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Shared.Change_request.Combine_Offer.Calculation_cost' | translate }}
        </span>
        <span class="cost mr-8"> {{ offer.pricingComponents?.calculationCost?.value | customDecimal }},- </span>

        <atl-custom-checkbox
          [(ngModel)]="offer.pricingComponents.calculationCost.includeInTotal"
          [disabled]="true"
          sizeInput="small"
          uniqID="detailCalculationCost"
          class="newest-checkbox"
        ></atl-custom-checkbox>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Shared.Change_request.Combine_Offer.Administration_costs' | translate }}
        </span>
        <span class="cost mr-8"> {{ offer.pricingComponents?.administrationCost?.value | customDecimal }},- </span>

        <atl-custom-checkbox
          [(ngModel)]="offer.pricingComponents.administrationCost.includeInTotal"
          [disabled]="true"
          sizeInput="small"
          uniqID="detailAdministrationCost"
          class="newest-checkbox"
        ></atl-custom-checkbox>
      </div>
      <ng-container *ngFor="let item of offer.pricingComponents?.additionalCosts; index as i">
        <div class="header mt-8">
          <span class="header__title d-flex min-w-0">
            <span
              #costName
              class="d-block ellipsis"
              [ngbTooltip]="item.name"
              [disableTooltip]="costName.offsetWidth >= costName.scrollWidth"
              triggers="hover touchstart"
            >
              {{ item.name }}
            </span>
          </span>
          <span class="cost mr-53"> {{ item.value | customDecimal }},- </span>
        </div>
      </ng-container>
      <div class="offer-detail__total border-top mt-8">
        <span class="offer-detail__total-title">
          {{ 'Shared.Title.Total_price' | translate }}
        </span>
        <span class="cost fw-500 mr-53"> {{ offer.price | customDecimal }},- </span>
      </div>
    </div>
  </div>
</div>
<atl-ngb-modal-footer>
  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="d-flex align-items-center">
      <span *ngIf="offer.status !== statusKeys.OfferApproved" (click)="confirmRemoveOffer()" class="d-flex align-items-center fit-content fz-16 lh-24 text-red-830 cursor-pointer">
        <img class="mr-8" src="/assets/trash-attachment-red.svg" alt="icon">
          {{'Button.Delete_offer' | translate}}
      </span>
    </div>
    <div class="d-flex align-items-center">
      <button *ngIf="offer.status !== statusKeys.OfferCanceled" (click)="confirmCancelOffer()" class="newest-button button-red">
        {{'Button.Cancel_offer' | translate}}
      </button>
      <ng-container *ngIf="offer.status !== statusKeys.Offered">
        <button
          *ngIf="offer.status === statusKeys.OfferCanceled || offer.status === statusKeys.OfferDeclined"
          (click)="duplicateOffers()"
          class="newest-button ml-16"
        >
          {{'Button.Duplicate' | translate}}
        </button>
        <button *ngIf="offer.status === statusKeys.OfferExpired" (click)="saveOffer()" [disabled]="buttonDisable" class="newest-button ml-16">
          {{'Shared.Title.Save_changes' | translate}}
        </button>
      </ng-container>
    </div>
  </div>
</atl-ngb-modal-footer>
