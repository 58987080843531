<div class="card-container__wrapper">
  <ng-content select="cc-icon"></ng-content>
  <div class="card-container__content">
    <ng-content select="cc-info"></ng-content>
    <ng-content select="occ-control"></ng-content>
  </div>
  <div class="card-container__text-free">
    <ng-content select="cc-freetext"></ng-content>
  </div>
</div>
