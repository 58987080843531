<!--
  ******************************************************
  @Cypress
  - interaction with form controls
  - interaction with submit buttons
  ******************************************************
-->
<div atlZoom>
  <atl-ngb-modal-header
    (closeModalEvent)="modalRef.dismiss()"
    [title]="'Button.New_reclamation'"
  ></atl-ngb-modal-header>

  <div
    class="reclamation-create__container"
    [class.v-scrollbar]="!categoryRef.isOpen && !typeRef.isOpen && !responsibleRef.isOpen && !contractorsField.isOpen"
  >
    <form class="reclamation-create__form" [formGroup]="form">
      <!-- Category -->
      <div class="reclamation-create__control">
        <div class="reclamation-create__control-title">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
            'Shared.Reclamation.Input.Category.Label' | translate
          }}
        </div>
        <div class="reclamation-create__control-input">
          <atl-drop-down-v2
            #categoryRef
            class="multiple-select-component category"
            data-cy="protocol-reclamation-create-category"
            formControlName="category"
            [listOfItems]="categories"
            [bindLabel]="'name'"
            [placeholder]="'Shared.Reclamation.Input.Category.Placeholder'"
            [notFoundText]="'Shared.Entity.Not_found'"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
            [createNotFound]="false"
            [searchable]="false"
          >
            <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <span>{{ item?.name }}</span>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (mousedown)="onRemoveSelectedItem('category', item)"
                  />
                  <div
                    class="tooltip-info"
                    *ngIf="
                      activeRule &&
                      activeRule.reclamationCategory?.id &&
                      !activeRule.reclamationType?.id &&
                      activeRule.comment
                    "
                  >
                    <atl-custom-tooltip [content]="activeRule.comment" [openDelay]="tooltipDelay">
                      <img
                        class="tooltip-info__img ml-4 position-relative"
                        src="/assets/warning-circle-blue.svg"
                        alt="Warning"
                      />
                    </atl-custom-tooltip>
                  </div>
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>

      <!-- Type -->
      <div class="reclamation-create__control">
        <div class="reclamation-create__control-title">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Type.Label' | translate }}
        </div>
        <div class="reclamation-create__control-input">
          <atl-drop-down-v2
            #typeRef
            class="multiple-select-component type"
            data-cy="protocol-reclamation-create-type"
            formControlName="type"
            [listOfItems]="types"
            [bindLabel]="'name'"
            [placeholder]="'Shared.Reclamation.Input.Type.Placeholder'"
            [notFoundText]="'Shared.Entity.Not_found'"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
            [createNotFound]="false"
            [searchable]="false"
          >
            <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <span>{{ item?.name }}</span>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (mousedown)="onRemoveSelectedItem('type', item)"
                  />
                  <div
                    class="tooltip-info"
                    *ngIf="
                      activeRule &&
                      activeRule.reclamationCategory?.id &&
                      activeRule.reclamationType?.id &&
                      activeRule.comment
                    "
                  >
                    <atl-custom-tooltip [content]="activeRule.comment" [openDelay]="tooltipDelay">
                      <img
                        class="tooltip-info__img ml-4 position-relative"
                        src="/assets/warning-circle-blue.svg"
                        alt="Warning"
                      />
                    </atl-custom-tooltip>
                  </div>
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>

      <!-- Desctiption -->
      <div class="reclamation-create__control align-items-start">
        <div class="reclamation-create__control-title position-relative">
          <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />{{
            'Shared.Reclamation.Input.Description.Label' | translate
          }}
        </div>
        <div class="reclamation-create__control-input z-index-3">
          <atl-textarea-autoresize
            formControlName="description"
            class="newest-textarea"
            data-cy="protocol-reclamation-create-description"
            [stringMode]="true"
            [autoresizeOffset]="2"
            [placeholder]="'Shared.Reclamation.Input.Description.Placeholder' | translate"
            [maxLength]="500"
            [tabindex]="0"
            id="reclamation-description"
            [absolutePosition]="true"
          ></atl-textarea-autoresize>
        </div>
      </div>

      <!-- Responsible -->
      <div class="reclamation-create__control">
        <div class="reclamation-create__control-title">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
            'Shared.Reclamation.Input.Responsible.Label' | translate
          }}
        </div>
        <div class="reclamation-create__control-input">
          <atl-drop-down-v2
            #responsibleRef
            class="multiple-select-component"
            data-cy="protocol-reclamation-create-responsible"
            formControlName="responsible"
            [listOfItems]="users"
            [bindLabel]="'name'"
            [searchParams]="['name', 'email']"
            [placeholder]="'Shared.Reclamation.Input.Responsible.Placeholder'"
            [notFoundText]="'Shared.Entity.Not_found'"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
          >
            <ng-template atlInputLabelTemplate let-item="item" let-index="index">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <div
                    class="multiple-select-user-card-icon"
                    [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                  >
                    <span>{{ item?.name | firmPrefix }}</span>
                  </div>
                  <span
                    class="ellipsis"
                    [ngbTooltip]="item.name"
                    [openDelay]="tooltipDelay"
                    [placement]="index === 0 ? 'bottom' : 'top'"
                    [disableTooltip]="item.name.length < longNameTruncateProtocol"
                    container="body"
                    triggers="hover touchstart"
                    >{{ item?.name | truncate: longNameTruncateProtocol }}</span
                  >
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('responsible', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
            <ng-template atlInputTemplate let-item="item" let-selected="selected" let-index="index">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <div
                    class="multiple-select-user-card-icon"
                    [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                  >
                    <span>{{ item?.name | firmPrefix }}</span>
                  </div>
                  <div>
                    <p
                      class="reclamation-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                      [class.selected]="selected"
                      [ngbTooltip]="item.name"
                      [openDelay]="tooltipDelay"
                      [placement]="index === 0 ? 'bottom' : 'top'"
                      container="body"
                      triggers="hover touchstart"
                    >
                      {{ item?.name | truncate: longNameTruncateProtocol }}
                    </p>
                    <p
                      class="reclamation-dropdown-user-email fz-12 lh-16 fw-400 ellipsis"
                      [ngbTooltip]="item.email"
                      [openDelay]="tooltipDelay"
                      [placement]="index === 0 ? 'bottom' : 'top'"
                      container="body"
                      triggers="hover touchstart"
                    >
                      {{ item?.email | truncate: longEmailTruncateProtocol }}
                    </p>
                  </div>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('responsible', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>

      <!-- Contractor -->
      <div class="reclamation-create__control">
        <div class="reclamation-create__control-title">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
          <span class="d-block ellipsis" [ngbTooltip]="'Shared.Reclamation.Input.Contractor.Label' | translate">{{
            'Shared.Reclamation.Input.Contractor.Label' | translate
          }}</span>
          <img
            [ngbTooltip]="'Reclamation.Contractor.Info_tooltip' | translate"
            [openDelay]="tooltipDelay"
            placement="bottom"
            triggers="hover touchstart"
            class="reclamation-create__control-info ml-8"
            src="assets/infos_icon.svg"
            alt="icon"
          />
        </div>
        <div class="reclamation-create__control-input">
          <atl-confirm-dropdown
            #contractorsField
            formControlName="contractor"
            data-cy="protocol-reclamation-create-contractor"
            [listOfItems]="contractors"
            [searchKey]="['name', 'email', 'companyName']"
            confirmTitle="Button.Confirm_contractor"
            [bindLabel]="'id'"
            [useCustomButtonVal]="true"
            [allowEmptyVal]="true"
            [placeholder]="'Shared.Reclamation.Input.Responsible.Placeholder' | translate"
            [customClass]="'contractor-dropdown--protocol'"
            [useBody]="false"
            [placement]="['bottom']"
          >
            <ng-template atlInputLabelTemplate let-item="item" let-selected="selected" let-index="index">
              <div class="multiple-select-user-card-wrapper contractor-width">
                <div
                  class="w-100 d-flex justify-content-between"
                  *ngIf="!item?.firstKeyContact"
                  data-cy-type="contractor"
                >
                  <div class="d-flex w-50 mr-16 min-w-0">
                    <atl-firm-icon
                      *ngIf="item"
                      [model]="item"
                      [isUser]="true"
                      [isDropdown]="true"
                      nameKey="name"
                      class="mr-8"
                    >
                    </atl-firm-icon>
                    <p
                      class="reclamation-create-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                      [class.selected]="selected"
                      [ngbTooltip]="item?.name"
                      [openDelay]="tooltipDelay"
                      [placement]="index === 0 ? 'bottom' : 'top'"
                      [disableTooltip]="item?.name?.length < longNameTruncate"
                      triggers="hover touchstart"
                    >
                      {{ item?.name | truncate: longNameTruncate }}
                    </p>
                  </div>
                  <div class="d-flex flex-shrink-0 w-50">
                    <span class="fz-14 lh-24 fw-400 text-black-810 d-block ellipsis">
                      {{ item?.companyName }}
                    </span>
                    <img
                      class="multiple-select-user-card-remove"
                      src="/assets/multiple_remove_icon.svg"
                      alt="Remove icon"
                      (click)="onRemoveSelectedItem('contractor', item)"
                    />
                  </div>
                </div>
                <div *ngIf="item?.firstKeyContact" data-cy-type="contractor-company">
                  <img class="company-icon mr-8" src="assets/bag-gray.svg" alt="icon" />
                  <div>
                    <p
                      class="reclamation-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                      [class.selected]="selected"
                      [ngbTooltip]="item?.name"
                      [openDelay]="tooltipDelay"
                      [placement]="index === 0 ? 'bottom' : 'top'"
                      [disableTooltip]="item?.name?.length < longNameTruncate"
                      triggers="hover touchstart"
                    >
                      {{ item?.name | truncate: longNameTruncate }}
                    </p>
                  </div>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('contractor', item)"
                  />
                </div>
                <img
                  *ngIf="selected"
                  class="item-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
            <ng-template atlInputTemplate let-item="item" let-index="index">
              <div class="multiple-select-user-card-wrapper">
                <div *ngIf="!item?.firstKeyContact">
                  <atl-firm-icon
                    *ngIf="item"
                    [model]="item"
                    [isUser]="true"
                    [isDropdown]="true"
                    nameKey="name"
                    class="mr-4"
                  >
                  </atl-firm-icon>
                  <span
                    class="ellipsis"
                    [ngbTooltip]="item?.name"
                    [openDelay]="tooltipDelay"
                    [placement]="index === 0 ? 'bottom' : 'top'"
                    [disableTooltip]="item?.name?.length < longNameTruncate"
                    triggers="hover touchstart"
                  >
                    {{ item?.name | truncate: longNameTruncate }}
                    <span *ngIf="item?.companyName" class="text-black-810" data-cy-label
                      >· {{ item?.companyName }}</span
                    >
                  </span>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('contractor', item)"
                  />
                </div>
                <div *ngIf="item?.firstKeyContact">
                  <span
                    class="ellipsis"
                    [ngbTooltip]="item?.name"
                    [openDelay]="tooltipDelay"
                    [placement]="index === 0 ? 'bottom' : 'top'"
                    [disableTooltip]="item?.name?.length < longNameTruncate"
                    triggers="hover touchstart"
                    data-cy-label
                    >{{ item?.name | truncate: longNameTruncate }}</span
                  >
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('contractor', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-confirm-dropdown>
        </div>
      </div>

      <!-- Due_date -->
      <div class="reclamation-create__control">
        <div class="reclamation-create__control-title">
          <img [src]="'assets/calendar-gray.svg'" alt="icon" />{{
            'Shared.Reclamation.Input.Deadline.Label' | translate
          }}
        </div>
        <div class="reclamation-create__control-input lh-32 fz-14">
          <atl-inline-date-time-picker
            formControlName="dueDate"
            [minDate]="today"
            [dateValue]="defaultDueDate"
            [placeholder]="'Shared.Reclamation.Input.Deadline.Placeholder' | translate"
          >
          </atl-inline-date-time-picker>
        </div>
      </div>
      <!-- Attachments -->
      <div class="reclamation-create__control">
        <div class="reclamation-create__control-title">
          <img [src]="'assets/attachment_gray.svg'" alt="icon" />{{
            'Shared.Reclamation.Input.Attachments.Label' | translate
          }}
        </div>
        <div class="reclamation-create__control-input lh-32 fz-14">
          <atl-dropdown-input-file
            data-cy="protocol-reclamation-create-attachments"
            [hiddenDropdown]="categoryRef.isOpen || typeRef.isOpen || responsibleRef.isOpen || contractorsField.isOpen"
            [imagePreview]="false"
            [placeholder]="'Shared.Entity.Add_attachments' | translate"
            [tooltipPlacement]="'bottom'"
            [newOnly]="true"
            [autoSave]="true"
            [format]="acceptedExtensions"
            (addNewFilesToExisting)="setAttachmentCount($event)"
            (addCreateNewFilesToExisting)="tempFileUpload($event)"
            [isLoading]="isLoadingFiles"
            (updateFilePositionsEmitter)="updateFilePositions($event)"
            (viewTempFilesEmitter)="openPreview($event)"
            (deleteTempFile)="removeTempFile($event)"
          ></atl-dropdown-input-file>
        </div>
      </div>
    </form>
  </div>

  <atl-ngb-modal-footer>
    <div class="w-100 d-flex justify-content-end">
      <button (click)="modalRef.dismiss()" class="newest-button button-gray mr-16" type="button">
        {{ 'Button.Cancel' | translate }}
      </button>
      <button
        (click)="createReclamation()"
        class="newest-button newest-button-spinner"
        [ngClass]="{ 'loading pointer-events-none': isLoading }"
        [disabled]="form.invalid || fileLoading || isDraftLoading || isLoadingFiles"
        data-cy="create-protocol-reclamation-button"
        type="button"
      >
        {{ 'Shared.Reclamation.Button.Create' | translate }}
      </button>
    </div>
  </atl-ngb-modal-footer>
</div>
