import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EPlatform } from '@atlas-workspace/shared/models';
import { FileLoadService, ModalFacadeService, RandomColorService } from '@atlas-workspace/shared/service';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { ConstructionModalComponent } from '../construction-modal/construction-modal.component';
import { NewestConfirmModalComponent } from '../newest-confirm-modal/newest-confirm-modal.component';

@Component({
  selector: 'atl-admin-full-screen',
  templateUrl: './admin-full-screen.component.html',
  styleUrls: ['./admin-full-screen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminFullScreenComponent extends ConstructionModalComponent implements OnInit {
  @Input() messageView = true;
  @Input() removeTitle = 'Shared.Entity.Delete_photo';
  @Input() removeDescription = 'Shared.Description.Remove_photo_text';
  @Output() removeImage = new EventEmitter<number>();
  @Output() addComment = new EventEmitter<{ comment: string; index: number }>();
  @Output() removeComment = new EventEmitter<{ commentId: number | undefined; index: number }>();
  @Output() updateComment = new EventEmitter<{ comment: string; index: number }>();
  public showComment = false;
  public editMode = false;
  public updated = true;
  public textareaForm!: FormGroup;
  public readonly maxCommentLength = 500;
  public loaded = false;
  public readonly translates = {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    buttonDelete: this.translateService.instant('Shared.Button.Delete'),
    buttonEdit: this.translateService.instant('Shared.Button.Edit'),
    buttonCancel: this.translateService.instant('Button.Cancel'),
    buttonPost: this.translateService.instant('Shared.Button.Post'),
    addCommentError: this.translateService.instant('Shared.Input.Add_comment.Error', { value: this.maxCommentLength }),
    now: this.translateService.instant('Shared.Entity.Now'),
  };
  public readonly tooltipDelay = 500;
  public isClient = false;

  constructor(
    private modalFacadeService: ModalFacadeService,
    private translateService: TranslateService,
    protected randomColorService: RandomColorService,
    private readonly fileLoadService: FileLoadService,
    @Inject(WEB_PLATFORM_TYPE) private platformType: EPlatform
  ) {
    super(randomColorService);
  }

  ngOnInit(): void {
    if (this.slides[this.index].comment && !this.slides[this.index].fileResources?.length) {
      this.showComment = true;
    }
    this.isClient = this.platformType === EPlatform.WEB_CLIENT;
    this.initForm();
  }

  public onCopyImage(): void {
    this.fileLoadService.copyFileFromModel({
      url: this.headerData.downloadLink!,
      name: this.headerData.title!,
    });
  }

  onLoad(): void {
    this.loaded = true;
  }

  private initForm(): void {
    this.textareaForm = new FormGroup({
      comment: new FormControl(null, [Validators.required, Validators.maxLength(this.maxCommentLength)]),
    });
  }

  closeModal(): void {
    this.modalFacadeService.closeModal();
  }

  deleteImage(): void {
    const deleteModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    deleteModalRef.componentInstance.title = this.translateService.instant(this.removeTitle);
    deleteModalRef.componentInstance.description = this.translateService.instant(this.removeDescription);
    deleteModalRef.componentInstance.button.text = this.translateService.instant('Shared.Button.Delete');
    deleteModalRef.componentInstance.icon = 'basket.svg';
    deleteModalRef.componentInstance.passConfirm.pipe(take(1)).subscribe(() => {
      this.removeImage.emit(this.index);
    });
  }

  toggleComment(): void {
    this.showComment = !this.showComment;
    super.updateSwiper();
  }

  cancelComment(): void {
    this.updated = true;
    const initComment = this.slides[this.index].comment?.body ?? null;
    this.textareaForm.controls.comment.setValue(initComment);
    if (this.editMode) {
      this.editMode = false;
    }
  }

  postComment(): void {
    if (this.textareaForm.invalid) return;
    const comment = this.textareaForm.controls.comment.value;
    if (this.editMode) {
      this.updateComment.emit({ comment: comment, index: this.index });
      this.editMode = false;
      this.slides[this.index].comment = null;
      this.updated = false;
      return;
    }
    this.addComment.emit({ comment: comment, index: this.index });
    this.editMode = false;
    this.updated = false;
  }

  editComment(): void {
    this.textareaForm.controls.comment.setValue(this.slides[this.index].comment?.body);
    this.editMode = true;
  }

  deleteComment(): void {
    const deleteModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    const deleteWord = this.translateService.instant('Shared.Button.Delete');
    const commentWord = this.translateService.instant('Shared.Entity.Comment');
    deleteModalRef.componentInstance.title = `${deleteWord} ${commentWord}?`;
    deleteModalRef.componentInstance.description = this.translateService.instant(
      'Description.Delete_smth_without_undone',
      {
        value: commentWord,
      }
    );
    deleteModalRef.componentInstance.button.text = deleteWord;
    deleteModalRef.componentInstance.icon = 'basket.svg';
    deleteModalRef.componentInstance.passConfirm.pipe(take(1)).subscribe(() => {
      this.removeComment.emit({ commentId: this.slides[this.index].comment?.id, index: this.index });
      this.textareaForm.controls.comment.setValue(null);
      deleteModalRef.close();
    });
  }
}
