import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AdminFirmIdInterceptor } from '@atl-admin-app/interceptors/admin-firm-id.interceptor';
import { TemplatesService } from '@atl-admin-app/modules/profile/service/templates.service';
import { UpdatesService } from '@atl-admin-app/modules/projects/components/specific-project-wrapper/services/updates/updates.service';
import { SharedModalsModule } from '@atlas-workspace/shared/modals';
import { CustomRouterStateSerializer, EPlatform } from '@atlas-workspace/shared/models';
import {
  CableService,
  DatepickerI18nService,
  SentryService,
  SharedServiceModule,
} from '@atlas-workspace/shared/service';
import { SharedTranslateForRootModule, WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { AdminErrorHandlerInterceptor, PwaInterceptor, TranslateInterceptor } from '@atlas-workspace/shared/ui';
import { environment } from '@environment-admin';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { DragulaModule } from 'ng2-dragula';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { register } from 'swiper/element/bundle';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OverviewService } from './modules/projects/components/specific-project-wrapper/components/overview/services/overview.service';
import { TaskService } from './modules/projects/components/specific-project-wrapper/components/tasks/service/task.service';
import { TimelineService } from './modules/projects/components/specific-project-wrapper/components/timeline/service/timeline.service';
import { CompanyService } from './modules/settings/services/company.service';
import { SharedModule } from './modules/shared/shared.module';

register();

const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: ',',
  decimalMarker: '.',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    SharedServiceModule,
    SharedModalsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    DragulaModule.forRoot(),
    SharedTranslateForRootModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsIdAdmin),
    NgxGoogleAnalyticsRouterModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ServiceWorkerModule.register('service-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately',
    }),
    HammerModule,
  ],
  providers: [
    {
      provide: WEB_PLATFORM_TYPE,
      useFactory: (): string => EPlatform.WEB_ADMIN,
    },
    {
      provide: ErrorHandler,
      useFactory: (): SentryService => {
        return new SentryService(environment.dns, environment.production);
      },
    },
    { provide: 'ENVIRONMENT', useValue: environment },
    CompanyService,
    ToastrService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PwaInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdminErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdminFirmIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TranslateInterceptor,
      multi: true,
    },
    provideEnvironmentNgxMask(maskConfig),
    TaskService,
    TemplatesService,
    UpdatesService,
    { provide: NgbDatepickerI18n, useClass: DatepickerI18nService },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    CableService,
    TimelineService,
    OverviewService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  exports: [SharedTranslateForRootModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
