<!--
  ******************************************************
  @Cypress
  - interaction with floor plan image
  - interaction with Create Alternative button
  - interaction with Create Alternative dropdown buttons
  - interaction with simple-tabs
  ******************************************************
-->
<ng-container *ngIf="!loading">
  <div class="plan-drawing__wrapper" *ngIf="!displayFloors; else multipleFloorsBlock">
    <div *ngIf="client || previewPlan" class="plan-drawing__title fz-20 fw-500" [class.mb-16]="!description">
      {{ 'Shared.Entity.Floor_plan' | translate }}
    </div>
    <span *ngIf="description" class="d-block fz-16 lh-24 text-black-810 mt-8">
      {{ description | translate }}
    </span>
    <ng-container *ngIf="floorPlan?.length && previewPlan; else setPlanMark">
      <span *ngIf="client" class="mb-16 text-black-810 fz-16 lh-24 mt-8">
        {{ floorsBlocksDescription | translate }}</span
      >
      <div
        class="plan-drawing__plan"
        *ngIf="!displayFloors; else multipleFloorsBlock"
        [class.mt-8]="client"
        [class.cursor-pointer]="previewPlan"
      >
        <div class="image-plan__img-wrapper">
          <img [src]="previewPlan" alt="image" data-cy="image-plan-el" />
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #setPlanMark>
  <ng-container *ngIf="floorPlan?.length; else noPlan">
    <div
      class="plan-drawing__plan"
      *ngIf="previewPlan; else noFloorPlan"
      [class.mt-8]="client"
      [class.cursor-pointer]="previewPlan"
    >
      <div class="image-plan__img-wrapper">
        <img [src]="previewPlan" alt="image" data-cy="image-plan-el" />
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #noPlan>
  <div class="plan-drawing__warn">
    <p *ngIf="client || !hasUnitAccess">{{ 'Shared.Mark.Message.Floor_plan_empty' | translate }}</p>
  </div>
</ng-template>

<ng-template #multipleFloorsBlock>
  <ng-container *ngIf="isGroupCreationScope && client; else defaultFloorHeaders">
    <p *ngIf="!initialTempRequest && !isReadonlyFromGroupScope" class="group-creation-scope-desc fz-16 lh-24 fw-400 pt-24">
      {{ 'Change_Request.Client.Empty.No_floor_plan.Description_2' | translate }}
    </p>
    <p *ngIf="initialTempRequest || isReadonlyFromGroupScope" class="group-creation-scope-desc fz-20 lh-24 fw-500 pt-24">
      {{ 'Entity.Floor_plan' | translate }}
    </p>
  </ng-container>

  <ng-template #defaultFloorHeaders>
    <div class="mt-14 fz-20 fw-500 plan-drawing__floor-title">
      {{ 'Shared.Entity.Floor_plan' | translate }}
    </div>
    <span *ngIf="description" class="d-block fz-16 lh-24 mt-8" [class.text-black-810]="!client">
    {{ description | translate }}
  </span>
    <span *ngIf="isEditFlow && isDescriptionView" class="d-block fz-16 lh-24 mt-8" [class.text-black-810]="!client">
    {{ floorsBlocksDescription | translate }}
  </span>
  </ng-template>

  <ng-container *ngIf="drawVersion && !isReadonlyFromGroupScope">
    <div class="plan-drawing__version h-scrollbar mt-16" data-cy="plan-drawing-version">
      <atl-simple-tabs
        [class.client-tabs]="client"
        class="no-border"
        *ngIf="tabListVersion?.length"
        [activeTab]="activeTabVersion"
        [tabs]="tabListVersion"
        [routeNavigation]="false"
        (selectingTab)="selectTabVersion($event)"
      ></atl-simple-tabs>

      <div
        *ngIf="isAlternative"
        #dropdown
        #dropdownRef="ngbDropdown"
        ngbDropdown
        [autoClose]="'outside'"
        [display]="'dynamic'"
        class="plan-drawing__dropdown ml-16 mb-4"
        [placement]="['bottom', 'top']"
        container="body"
      >
        <span
          ngbDropdownToggle
          id="dropdown-1"
          data-cy="create-alternative-toggle"
          class="plan-drawing__version-btn d-flex align-items-center fz-14 lh-24 fw-500 text-black-810 cursor-pointer"
          [class.disabled]="btnDisable"
        >
          <span *ngIf="btnDisable" class="custom-spinner custom-spinner-12 mr-4"></span>
          <img *ngIf="!btnDisable" class="mr-4" src="assets/plus-gray-12.svg" alt="icon" />
          {{ 'Shared.Entity.Create_alternative' | translate }}
        </span>
        <div
          ngbDropdownMenu
          [attr.aria-labelledby]="'dropdown-1'"
          class="plan-drawing__dropdown-dropdown"
          [style.width]="dropdown.clientWidth + 'px'"
        >
          <div class="plan-drawing__dropdown-list">
            <div
              (click)="floorPlanVersionModal(dropdownRef)"
              data-cy="create-alternative-toggle-from-scratch"
              class="plan-drawing__dropdown-item"
            >
              {{ 'Shared.Entity.From_scratch' | translate }}
            </div>
            <div
              (click)="floorPlanVersionModal(dropdownRef, true)"
              data-cy="create-alternative-toggle-reuse-version"
              class="plan-drawing__dropdown-item"
            >
              {{ 'Shared.Entity.Reuse_version' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isGroupCreationScope && navList?.length > 1">
    <atl-simple-tabs
      [class.client-tabs]="client"
      [withId]="true"
      [activeId]="activeId"
      [tabs]="navList"
      [routeNavigation]="false"
      (selectingTab)="selectTab($event)"
      class="d-block mt-16"
    ></atl-simple-tabs>
  </ng-container>
  <div
    class="plan-drawing__plan"
    [ngClass]="{
      'cursor-default': !floorPlan?.length,
      'cursor-pointer': previewPlan,
      'disabled': btnDisable,
      'group-creation-scope': isGroupCreationScope
    }"
  >
    <ng-container *ngIf="!client">
      <div *ngIf="creator" class="d-flex w-100 mt-16 mb-16" style="height: 26px">
        <span class="plan-drawing__creator fz-12 lh-16 ml-16 mr-16">
          <span class="d-block ellipsis">{{ 'Entity.By' | translate }} {{ creator.name }}&nbsp;</span>·<span class="flex-shrink-0">&nbsp;{{ creator.date | date: 'dd.MM.YYYY' }}</span>
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="floorPlan?.length; else noFloorPlan">
      <ng-container *ngIf="previewPlan; else noFloorPlan">
        <div
          #parentImage
          class="image-plan__img-wrapper w-100 d-flex align-items-center justify-content-center position-relative"
          [class.loading]="loadImage"
          (click)="selectFloorPlanModal(previewPlan)"
        >
          <img
            [atlImageResize]="parentImage"
            (load)="loadingImage()"
            [src]="previewPlan"
            alt="image"
            data-cy="image-plan-el"
          />
          <div class="loader">
            <span class="custom-spinner custom-spinner-48"></span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #noFloorPlan>
  <div class="d-flex flex-column justify-content-center empty-plan text-align-center">
    <p class="fz-14 lh-24 mt-16" [class.text-black-810]="!client">
      {{ 'Shared.Mark.Message.Floor_plan_empty' | translate }}
    </p>
  </div>
</ng-template>
