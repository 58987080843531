import {CommonModule} from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'atl-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomCheckboxComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class CustomCheckboxComponent {
  @Input() checked = true;
  @Input() disabledState = false;
  @Input() text!: string;
  @Input() uniqID!: string;
  @Input() sizeInput: 'medium' | 'small' | 'xs' = 'medium';
  @Output() toggleCheckBox = new EventEmitter();

  onChange: any = () => {
    //
  };

  onTouched: any = () => {
    //
  };

  constructor(private cdr: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.checked = !!value;
    this.cdr.detectChanges();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledState = isDisabled;
  }

  toggle(event?: Event): void {
    event?.stopPropagation();
    if (this.disabledState) {
      return;
    }
    this.checked = !this.checked;
    this.toggleCheckBox.emit(this.checked);
    this.onChange(this.checked);
    this.onTouched();
    this.cdr.detectChanges();
  }

  public compDetectChanges(): void {
    this.cdr.detectChanges();
  }
}
