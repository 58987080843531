import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationModalComponent implements OnInit {
  @Input() title!: string;
  @Input() description!: string;
  @Input() buttonName = 'Ok';
  @Input() autoClose = true;
  constructor(private ngbActiveModal: NgbActiveModal) {}
  private readonly autoCloseTime = 5000;

  public closeModal(): void {
    this.ngbActiveModal.close();
  }

  ngOnInit(): void {
    if (this.autoClose) {
      setTimeout(() => {
        this.closeModal();
      }, this.autoCloseTime);
    }
  }
}
