<div
  class="thread thread__draft"
  [class.thread__active]="activeThreadId && +activeThreadId === +thread.id"
  (click)="openDraftThreadModal(thread)"
>
  <div class="thread__icon">
    <atl-firm-icon
      *ngIf="currentUser"
      [model]="currentUser"
      nameKey="fullName"
      [isUser]="true"
      [ngbTooltip]="thread.lastMessage?.senderName"
      [placement]="first ? 'bottom' : 'top'"
      [openDelay]="tooltipOpenDelay"
      triggers="hover touchstart"
      [isMonochrome]="true"
    ></atl-firm-icon>
  </div>
  <div class="thread__data">
    <div class="thread__data-subject-wrapper">
      <span
        *ngIf="thread.params?.messageThread?.subject; else noSubject"
        #subject
        class="thread__data-subject lh-16 fz-14 fw-500 ellipsis"
        [ngbTooltip]="threadDraftSubjectTemplate"
        placement="bottom"
        triggers="hover touchstart"
        [disableTooltip]="subject.offsetWidth >= subject.scrollWidth"
        [openDelay]="tooltipOpenDelay"
      >
        <ng-template [ngTemplateOutlet]="threadDraftSubjectTemplate"></ng-template>
      </span>
      <ng-template #threadDraftSubjectTemplate>{{
        thread.reclamationId
          ? ('Shared.Entity.Reclamation_short' | translate) + dotDivider + thread.params?.messageThread?.subject
          : thread.params?.messageThread?.subject
      }}</ng-template>
      <span class="thread__data-date fz-12 lh-16 ml-8 capitalize">{{
        thread.createdAt | timePassedAgo: passedTimeFormats.Short | async
      }}</span>
    </div>
    <div class="thread__data-unit-wrapper">
      <div
        *ngIf="thread?.units?.length; else noUnits"
        #unitsRow
        class="thread__data-unit lh-16 fz-14 mt-4 ellipsis pr-16"
        [ngbTooltip]="thread.units.length > 1 ? ('Threads.Multi_message' | translate) : thread.units[0].identifier"
        placement="bottom"
        triggers="hover touchstart"
        [disableTooltip]="unitsRow.offsetWidth >= unitsRow.scrollWidth"
        [openDelay]="tooltipOpenDelay"
      >
        {{ thread.units.length > 1 ? ('Threads.Multi_message' | translate) : thread.units[0].identifier }}
      </div>
      <div class="thread__data-remove" (click)="$event.stopPropagation(); openRemoveDraft(thread.id)">
        <atl-custom-icon [icon]="deleteSvg"></atl-custom-icon>
      </div>
    </div>
    <div class="thread__data-message lh-16 fz-14 mt-4 ellipsis" *ngIf="thread.params?.message?.text; else noMessage">
      <span [innerHTML]="thread.params?.message?.text ? (thread.params?.message?.text | removeHtmlTag) : '—'"></span>
    </div>
  </div>
</div>

<ng-template #noSubject>
  <span class="fz-14 lh-16 fw-500 text-gray-890">{{ 'Shared.Threads.No_subject' | translate }}</span>
</ng-template>

<ng-template #noUnits>
  <span class="fz-14 lh-20 text-gray-890 pr-16">{{ 'Shared.Threads.No_recipient' | translate }}</span>
</ng-template>

<ng-template #noMessage>
  <span class="fz-14 lh-20 text-gray-890">{{ 'Shared.Threads.No_message' | translate }}</span>
</ng-template>
