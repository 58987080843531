import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedFormModule } from '@atlas-workspace/shared/form';

import { ReclamationMessagesInfoComponent } from './reclamation-messages-info.component';

@NgModule({
  declarations: [ReclamationMessagesInfoComponent],
  exports: [ReclamationMessagesInfoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedDirectivesModule,
    SharedFormModule
  ],
})
export class ReclamationMessagesInfoModule {}
