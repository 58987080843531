import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileValidators } from '@atlas-workspace/shared/form';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  acceptedGlobalExtensions,
  DocumentModel,
  EFileType,
  EReclamationStatusKey,
  FileModel,
  IAttachedFile,
  ImageModel,
  IReclamationEventLog,
  IReclamationReason,
} from '@atlas-workspace/shared/models';
import { ReclamationAdminService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';

@UntilDestroy()
@Component({
  selector: 'atl-reclamation-admin-decline',
  templateUrl: './reclamation-admin-decline.component.html',
  styleUrls: ['./reclamation-admin-decline.component.scss'],
})
export class ReclamationAdminDeclineComponent implements OnInit, OnDestroy {
  @Input() public projectId!: number;
  @Input() modalRef!: NgbModalRef;
  @Input() reclamationId!: number;
  @Input() onlyView!: boolean;
  @Input() reclamationEventLog?: IReclamationEventLog;
  @Input() status!: EReclamationStatusKey;
  @Input() force = false;
  @Output() declineEvent = new EventEmitter<boolean>();

  public isLoading = false;
  public form!: FormGroup;
  public readonly maxNameLength = 12;
  private readonly maxCommentLength = 300;
  private readonly timeoutDelay = 0;
  public readonly maxAttachmentsAmount = 10;
  readonly acceptedExtensions = acceptedGlobalExtensions;
  public reasonsList!: IReclamationReason[];
  public modalInfo!: any;
  public statusKeys = EReclamationStatusKey;
  public fileResources: (FileModel | ImageModel)[] = [];

  constructor(
    private fb: FormBuilder,
    private modalHelpersService: ModalHelpersService,
    private reclamationService: ReclamationAdminService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initModalInfo();
    this.initForm();
    this.getDeclineReasons();
  }

  private initModalInfo(): void {
    if (this.status === EReclamationStatusKey.Declined) {
      this.modalInfo = {
        title: this.onlyView
          ? this.translate.instant('Shared.Reclamation.Declinement_details')
          : this.translate.instant('Shared.Reclamation.Decline_reclamation'),
        button: this.translate.instant('Shared.Reclamation.Button.Decline'),
        status: this.status,
      };
    } else if (this.status === EReclamationStatusKey.Disputed) {
      this.modalInfo = {
        title: this.onlyView
          ? this.translate.instant('Shared.Reclamation.Disputed_details')
          : this.translate.instant('Shared.Reclamation.Dispute_reclamation'),
        button: this.translate.instant('Shared.Reclamation.Button.Dispute'),
        status: this.status,
      };
    }
  }

  private initForm(): void {
    if (this.onlyView && this.reclamationEventLog?.file_resources.length) {
      this.fileResources = this.reclamationEventLog?.file_resources.map((file) => {
        if (FileValidators.isImageExtension(file)) {
          file.type = EFileType.Image;
          file = plainToClass(ImageModel, file);
        } else {
          file.type = EFileType.File;
          file = plainToClass(FileModel, file);
        }
        return file;
      });
    }

    this.form = this.fb.group({
      reason: [
        {
          value:
            this.onlyView && this.status === EReclamationStatusKey.Declined
              ? this.reclamationEventLog?.event_reason.name
              : null,
          disabled: this.onlyView,
        },
        this.status === EReclamationStatusKey.Declined ? [Validators.required] : [],
      ],
      comment: [
        {
          value: this.onlyView ? this.reclamationEventLog?.comment : '',
          disabled: this.onlyView,
        },
        Validators.maxLength(this.maxCommentLength),
      ],
      files: [
        {
          value: this.onlyView && this.fileResources.length ? this.fileResources : [],
          disabled: this.onlyView,
        },
        [],
      ],
    });
  }

  private createFormData(): FormData {
    const formValue = this.form.getRawValue();
    const body = new FormData();
    const reasonId = this.reasonsList.filter((i) => i.name === formValue.reason && i.id)?.[0]?.id;

    if (this.status === EReclamationStatusKey.Declined) {
      if (reasonId) {
        body.append('reclamation_event_log[event_reason_id]', reasonId);
      } else {
        body.append('reclamation_event_log[event_reason_attributes][name]', formValue.reason);
      }
    }

    body.append('reclamation_event_log[comment]', formValue.comment);

    formValue.files?.forEach((file: FileModel | ImageModel) => {
      const formFile: File = file as unknown as File;
      body.append('reclamation_event_log[file_resources_attributes][][filename]', formFile, formFile.name);
    });

    return body;
  }

  changeStatusReclamation(status: EReclamationStatusKey): void {
    this.createFormData();
    this.isLoading = true;
    this.reclamationService
      .changeReclamationStatus(this.projectId, this.reclamationId, status, this.force, this.createFormData())
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.modalRef.close();
          this.isLoading = false;
          this.declineEvent.emit();
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  getDeclineReasons(): void {
    this.reclamationService
      .getDeclineReasons()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.reasonsList = res;
        if (this.onlyView) {
          setTimeout(() => {
            this.form.get('reason')?.setValue(this.reclamationEventLog?.event_reason.name);
          }, this.timeoutDelay);
        }
      });
  }

  openFilePreview(file: IAttachedFile): void {
    let document = null;
    if (this.onlyView) {
      document = file;
    } else {
      document = {
        fileExtension: file.format,
        fileSize: file.size,
        name: file.title,
        fileName: {
          downloadUrl: file.link,
          url: file.link,
        },
      };
    }
    this.modalHelpersService.previewDocument(document as DocumentModel);
  }

  public createReasonFromSearch(e: string): void {
    const reasonObj = { name: e };
    this.reasonsList.push(reasonObj);
    this.form.get('reason')?.setValue(e);
  }

  ngOnDestroy(): void {
    this.fileResources = [];
    this.modalRef.close();
  }
}
