<div *ngIf="uploadData?.length" class="loader" [class.d-none]="!showLoader">
  <div class="d-flex flex-column w-100" style="max-height: inherit;">
    <div class="d-inline-flex align-items-center justify-content-between w-100 h-48 flex-shrink-0">
      <div class="title fz-14 fw-500 lh-24 ml-16">{{'Documents.Uploading_amount_items' | translate: {value: totalItems} }}</div>
      <div class="d-flex align-items-center">
        <div class="close-icon cursor-pointer d-none">
          <img (click)="showLoadList()" class="arrow" [class.active]="showList" src="/assets/arrow-gray-up.svg" alt="Arrow">
        </div>
        <div class="close-icon cursor-pointer mr-8">
          <img (click)="close()" src="/assets/close_newest_gray.svg" alt="Close">
        </div>
      </div>
    </div>

    <div class="loader__progress pt-12 d-flex align-items-center justify-content-between flex-shrink-0">
      <div class="loader__progress-bar" [style.width.%]="totalProgress"></div>
      <p class="fz-12 fw-600 lh-16 text-black-810 ml-16 z-index-2">
        {{totalProgress}}% <span class="lowercase">{{'Entity.Uploaded' | translate}}...</span>
      </p>
      <span *ngIf="totalProgress < 100" (click)="cancelUpload()" class="fz-12 lh-16 fw-600 text-red-830 cursor-pointer z-index-2 mr-16">
        {{'Button.Cancel_upload' | translate}}
      </span>
    </div>

    <div class="loader__upload-list v-scrollbar small d-none" [class.show]="showList">
      <div *ngFor="let item of uploadData" class="loader__upload-item">
        <div class="item__icon">
          <ng-container *ngIf="item.sizeError || item.extensionError || item.fileNestingError || item.nameLengthError; else icon">
            <img src="assets/warning-red-24.svg" alt="warning">
          </ng-container>
          <ng-template #icon>
            <img class="extension-icon" [src]="item.extension | iconByType" alt="Folder icon" />
          </ng-template>
        </div>
        <div class="item__file">
          <span class="fz-14 lh-24 d-block ellipsis">{{item.name}}</span>
          <span class="fz-12 lh-16">{{item.loaded | filesize}}/{{item.size | filesize}}</span>
        </div>
        <div class="item__action">
          <ng-container *ngIf="!(item.sizeError || item.extensionError || item.fileNestingError || item.nameLengthError); else action">
            <ng-container *ngIf="item.loaded !== item.size; else complete">
              <img width="12px" height="12px" class="spinner" src="assets/spinner-blue.svg" alt="spinner">
            </ng-container>
            <ng-template #complete>

            </ng-template>
          </ng-container>
          <ng-template #action>

          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
