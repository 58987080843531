<div class="offer-detail v-scrollbar">
  <div class="offer-detail__header fw-500 fz-14 lh-24 text-black-810 cursor-pointer" (click)="backOffersList()">
    <img class="mr-4" src="/assets/arrow-left-mobile.svg" alt="back" />
    {{ 'Shared.Offers.All_offers' | translate }}
  </div>

  <form [formGroup]="form">
    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />
        {{ 'Input.Offer_title.Label' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-input
          formControlName="title"
          class="newest-input--number"
          [placeholder]="'Input.Contact_name.Placeholder'"
          [readOnly]="true"
        ></atl-input>
      </div>
    </div>

    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />
        {{ 'Input.Description.Label' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-textarea-autoresize
          class="newest-textarea"
          [stringMode]="true"
          [autoresizeOffset]="2"
          formControlName="description"
          [placeholder]="'Shared.Reclamation.Input.Description.Placeholder' | translate"
        ></atl-textarea-autoresize>
      </div>
    </div>

    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/hash.svg'" alt="icon" />
        {{ 'Entity.Price' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-input
          class="newest-input--number"
          formControlName="price"
          mask="separator.0"
          separatorLimit="100000000000"
          thousandSeparator=" "
          type="text"
          [readOnly]="true"
        ></atl-input>
      </div>
    </div>

    <div class="offer-detail__control">
      <div
        class="offer-detail__control-title"
        [ngbTooltip]="'Title.Offer_expiration' | translate"
        triggers="hover touchstart"
      >
        <img [src]="'assets/calendar-gray.svg'" alt="icon" />
        <span class="ellipsis">{{ 'Title.Offer_expiration' | translate }}</span>
      </div>
      <div class="offer-detail__control-input">
        <atl-inline-date-time-picker
          #datePicker
          formControlName="expirationDate"
          [dateValue]="offer.expirationDate"
          [minDate]="currentDate"
        ></atl-inline-date-time-picker>
      </div>
    </div>

    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/spinner-gray.svg'" alt="icon" />
        <span>{{ 'Shared.Reclamation.Input.Status.Label' | translate }}</span>
      </div>
      <div class="offer-detail__control-input d-flex">
        <span
          *ngIf="offer.status"
          class="gl-ellipsis-table-item status ml-8"
          [ngClass]="'status__' + statusClasses[offer.status].style"
        >
          {{ offer.localizedStatus }}
        </span>

        <span
          *ngIf="offer.status === statusKeys.OfferDeclined"
          (click)="showComment()"
          class="offer-detail__details cursor-pointer fz-14 lh-24 fw-400 text-black-810 ml-8"
        >
          {{ 'Shared.Reclamation.Details' | translate }}
          <img src="assets/arrow-newest-gray-down.svg" alt="arrow" />
        </span>
      </div>
    </div>

    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/attachment_gray.svg'" alt="icon" />
        {{ 'Shared.Reclamation.Input.Attachments.Label' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-dropdown-input-file
          *ngIf="offer.fileResources?.length; else noAttachments"
          formControlName="fileResources"
          dragGroup="fileResources"
          [newOnly]="true"
          [autoSave]="true"
          (viewTempFilesEmitter)="openPreview($event)"
        ></atl-dropdown-input-file>
        <ng-template #noAttachments>
          <span class="empty-input">
            {{ 'Shared.Reclamation.No_attachments' | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </form>

  <div class="mt-24">
    <atl-plan-drawing
      [displayFloors]="true"
      [unitId]="unitId"
      [isEditFlow]="false"
      [drawVersion]="false"
      [floorPlan]="floorPlanData"
      [floorType]="unit?.hasOwnFloorPlans ? floorType.UnitFloor : floorType.Floor"
      [floorDrawVersions]="changeRequest?.floorDrawVersions"
      [isAlternative]="false"
      [activeVersion]="offer.versionNumber"
    ></atl-plan-drawing>
  </div>

  <atl-action-message-banner
    class="action-message-banner_component change-request"
    *ngIf="isShowMessageBanner"
    [messageText]="messageBannerText"
    [closeButton]="false"
    [icon]="true"
    (closeEvent)="hideMessageBanner()"
  ></atl-action-message-banner>
</div>
<div class="offer-detail__footer">
  <div>
    <ng-container *ngIf="!(offer.status === statusKeys.OfferCanceled || offer.status === statusKeys.OfferApproved)">
      <span
        (click)="removeOffer()"
        class="remove fz-16 lh-24 fw-500 text-red-830 cursor-pointer d-flex align-items-center"
      >
        <img class="mr-8" src="assets/trash-attachment-red.svg" alt="icon" />
        {{ 'Button.Delete_offer' | translate }}
      </span>
    </ng-container>
  </div>
  <div class="d-flex align-items-center">
    <ng-container *ngIf="offer.status === statusKeys.OfferDeclined">
      <button (click)="confirmCancelOffer()" class="button-red mr-16">
        {{ 'Button.Cancel_offer' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="offer.status === statusKeys.OfferCanceled">
      <button class="global-button global-button--red fz-16 br-32 mr-16" (click)="removeOffer()">
        {{ 'Shared.Button.Delete' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="offer.status === statusKeys.OfferDeclined || offer.status === statusKeys.OfferCanceled">
      <button *ngIf="!disabledNewOffer" [disabled]="requestIsDeclined" (click)="setDuplicate()" class="newest-button">
        {{ 'Button.Duplicate' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="!(offer.status === statusKeys.OfferDeclined || offer.status === statusKeys.OfferCanceled)">
      <button (click)="confirmCancelOffer()" class="button-red" [class.mr-16]="offer.status === statusKeys.OfferExpired">
        {{ 'Button.Cancel_offer' | translate }}
      </button>
      <atl-button-loader
        *ngIf="offer.status === statusKeys.OfferExpired"
        (click)="saveOffer()"
        [disabled]="buttonDisable"
        [newest]="true"
        [loading]="isLoading"
        label="Shared.Title.Save_changes"
      ></atl-button-loader>
    </ng-container>
  </div>
</div>
