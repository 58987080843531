import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { TitledCheckboxModule } from '@atlas-workspace/shared/form';
import { TruncatePipeModule } from '@atlas-workspace/shared/pipes';
import { SharedTranslateForChildModule } from '@atlas-workspace/shared/translate';
import { SimpleTabsComponent } from "@atlas-workspace/shared/ui";

import { CreateThreadUnitsModalComponent } from './create-thread-units-modal.component';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedTranslateForChildModule,
		TitledCheckboxModule,
		SharedDirectivesModule,
		TruncatePipeModule,
    SimpleTabsComponent
	],
  declarations: [CreateThreadUnitsModalComponent],
  exports: [CreateThreadUnitsModalComponent],
})
export class CreateThreadUnitsModalModule {}
