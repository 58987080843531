import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DateAdapterService {
  readonly delimiter = '.';
  readonly format = 'DD.MM.YYYY';

  fromModel(value: string | null): NgbDateStruct | null {
    const currentDay = new Date();
    if (value?.length) {
      const date = value.split(this.delimiter);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return { day: currentDay.getDay(), month: currentDay.getMonth(), year: currentDay.getFullYear() };
  }

  toModelYYYYMMDD(date: NgbDateStruct): string {
    return date
      ? `${date.year}-${+date.month < 10 ? '0' : ''}${+date.month}-${+date.day < 10 ? '0' : ''}${+date.day}`
      : '';
  }

  toModel(date: NgbDateStruct): string {
    if (!date || date.year == null || date.month == null || date.day == null) {
      return '';
    }
    const day = +date.day < 10 ? '0' + +date.day : date.day.toString();
    const month = +date.month < 10 ? '0' + +date.month : date.month.toString();
    return `${day}.${month}.${date.year}`;
  }

  transformDate(
    date: string,
    asInteger = false
  ): { day: number | string; month: number | string; year: number | string } {
    const dateArr = dayjs(date).format('DD/MM/YYYY').split('/');
    return !asInteger
      ? { day: dateArr[0], month: dateArr[1], year: dateArr[2] }
      : { day: +dateArr[0], month: +dateArr[1], year: +dateArr[2] };
  }

  currentDate(): string {
    return dayjs().format(this.format);
  }

  currentTime(): {hour: number, min: number} {
    return {hour: dayjs().hour(), min: dayjs().minute()};
  }

  isCurrentDate(currentDate: NgbDateStruct, selectedDate?: NgbDateStruct): boolean {
    if (!selectedDate) return false;
    return this.isSameDateStruct(currentDate, selectedDate) || this.isBeforeDateStruct(currentDate, selectedDate);
  }

  isSameDateStruct(currentDate: NgbDateStruct, selectedDate?: NgbDateStruct): boolean {
    if (!selectedDate) return false;
    const current =  dayjs(this.toModelYYYYMMDD(currentDate));
    const selected =  dayjs(this.toModelYYYYMMDD(selectedDate));
    return current.isSame(selected);
  }

  isBeforeDateStruct(currentDate: NgbDateStruct, selectedDate?: NgbDateStruct): boolean {
    if (!selectedDate) return false;
    const current =  dayjs(this.toModelYYYYMMDD(currentDate));
    const selected =  dayjs(this.toModelYYYYMMDD(selectedDate));
    return selected.isBefore(current);
  }

  isBeforeDate(current: string, date: string): boolean {
    return (
      dayjs(current, this.format).isSame(dayjs(date, this.format)) ||
      dayjs(current, this.format).isBefore(dayjs(date, this.format))
    );
  }

  getToString(date: NgbDateStruct): string | null {
    if (!date?.day || !date?.month || !date?.year) return null;
    const transform = `${date.year}-${+date.month < 10 ? '0' : ''}${+date.month}-${+date.day < 10 ? '0' : ''}${+date.day}`;
    return  new Date(transform).toISOString();
  }

  isSameDay(current: string, date: string): boolean {
    return !dayjs(current).diff(date, 'day');
  }

  convertToFormat(date: string, currentFormat: string, targetFormat: string): string {
    return dayjs(date, currentFormat).format(targetFormat);
  }
}
