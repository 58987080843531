<div class="confirm-modal" (atlCloseModalEsc)="closeModal()" tabindex="0">
  <div class="confirm-modal__header d-flex">
    <div
      class="confirm-modal__header-title text-black-960 ellipsis"
      #titleRef
      [placement]="'bottom'"
      [ngbTooltip]="title"
      [disableTooltip]="titleRef.offsetWidth >= titleRef.scrollWidth"
      [openDelay]="tooltipDelay"
      triggers="hover touchstart"
    >
      {{ title }}
    </div>
    <atl-header-icon
      (click)="closeModal()"
      class="client"
      icon="/assets/close_newest_gray.svg"
      [ngbTooltip]="'Alt.Close' | translate"
      [disableTooltip]="previewMode"
      [openDelay]="tooltipDelay"
      triggers="hover touchstart"
    ></atl-header-icon>
  </div>

  <div class="confirm-modal__body v-scrollbar" [class.show-controls]="showControls">
    <p class="confirm-modal__description lh-24 text-black-960 word-break">{{ description.toString() | translate }}</p>
    <div *ngIf="showCheckbox" class="d-inline-flex align-items-center">
      <atl-titled-checkbox
        (ngModelChange)="changeEvent($event)"
        [ngModel]="isConfirm"
        [checkboxSize]="22"
        [checkboxLabel]="checkboxLabel | translate"
        class="mt-16"
      >
      </atl-titled-checkbox>
    </div>
  </div>

  <div *ngIf="showControls" class="d-flex confirm-modal__footer">
    <button (click)="closeModal()" class="newest-button button-gray fz-16 br-32">
      {{ 'Button.Cancel' | translate }}
    </button>
    <button
      (click)="confirm()"
      type="submit"
      class="ml-16 newest-button newest-button-spinner"
      [disabled]="showCheckbox && !isConfirm"
      [ngbTooltip]="'Shared.Entity.Mark_to_proceed' | translate"
      [disableTooltip]="!showCheckbox || isConfirm"
      [tooltipClass]="'tooltip-to-right'"
      placement="top-right"
      triggers="hover touchstart"
    >
      {{ 'Shared.Button.Confirm_NO_Approve' | translate }}
    </button>
  </div>
</div>
