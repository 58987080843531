<div class="message-wrapper__container" [ngClass]="reclamationScope || ''" atlWindowInnerHeight>
  <ng-template #threadView></ng-template>

  <atl-reclamation-messages-info
    *ngIf="!reclamation.archived"
    [reclamation]="reclamation"
    [projectId]="projectId"
    [isContractor]="isContractor"
  ></atl-reclamation-messages-info>
</div>
