import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FloorDrawVersionModel, IFloorItemVersionList, IFloorVersionList } from '@atlas-workspace/shared/models';

import removeSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/close-white-8.svg';

import { CustomIconComponent } from '../custom-icon/custom-icon.component';

@Component({
  selector: 'atl-floor-plan-cards',
  templateUrl: './floor-plan-cards.component.html',
  styleUrls: ['./floor-plan-cards.component.scss'],
  standalone: true,
  imports: [CommonModule, CustomIconComponent],
})
export class FloorPlanCardsComponent {
  public removeIcon = removeSvg;

  @Input() public floorPlanItems!: IFloorVersionList[];

  @Output() private readonly removeFloorPlan = new EventEmitter<IFloorVersionList[]>();

  remove(floor: IFloorItemVersionList): void {
    floor.selected = !floor.selected;
    this.removeFloorPlan.emit(this.floorPlanItems);
  }

  trackByFn: (i: number, item: FloorDrawVersionModel) => number = (i: number, item: FloorDrawVersionModel): number =>
    item.id;
}
