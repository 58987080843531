import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileValidators } from '@atlas-workspace/shared/form';
import {
  FileModel,
  IFormUpdate,
  ImageModel, IPutUpdateProps,
  ITablePagination,
  IUpdate,
  UpdateModel
} from '@atlas-workspace/shared/models';
import { DataTableHelperService } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { EMPTY, Observable, of } from 'rxjs';
import { delay, expand, filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class UpdatesService {

  constructor(private http: HttpClient, private tableService: DataTableHelperService) {}

  public getUpdates(projectId: string, id: number): Observable<UpdateModel[]> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        update_id: id.toString(),
      },
    });
    return of(null).pipe(
      delay(5000),
      mergeMap(() =>
        this.http.get<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/updates/short_index`, {
          params,
        })
      ),
      map((res) => res.data.updates),
      map((data: IUpdate[]) => plainToClass(UpdateModel, data)),
      expand((updates) => {
        if (updates[0].previewInProgress) {
          return this.getUpdates(projectId, id);
        }
        return EMPTY;
      }),
      filter((updates: UpdateModel[]) => !updates[0].previewInProgress)
    );
  }

  public getUpdatesState(projectId: string, search = '', sort = '', paginate?: ITablePagination): Observable<any> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http.get<any>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/updates/short_index`, {
      params,
      observe: 'response',
    });
  }

  public getUpdate(projectId: number, id: number): Observable<UpdateModel> {
    return this.http
      .get<{ data: UpdateModel }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/updates/${id}`)
      .pipe(
        map((res) => res.data),
        map((data: UpdateModel) => plainToClass(UpdateModel, data))
      );
  }

  public putUpdate(
    { projectId, updateId, formControls, draft, unitIds, addTypes, removeTypes, sendSms, removeFiles } : IPutUpdateProps
  ): Observable<UpdateModel> {
    const createBody = this.createFormData(formControls, draft, unitIds, addTypes, sendSms);
    const removeBody = this.removeFormData(formControls, draft, removeTypes, removeFiles);
    return this.http.put(`${environment.apiBaseUrl}api/v1/projects/${projectId}/updates/${updateId}`, createBody).pipe(
      mergeMap(() =>
        this.http.put(`${environment.apiBaseUrl}api/v1/projects/${projectId}/updates/${updateId}`, removeBody)
      ),
      map((res: any) => res.data),
      map((update) => plainToClass(UpdateModel, update))
    );
  }

  public createUpdate(
    projectId: number,
    formControls: IFormUpdate,
    draft: boolean | undefined,
    unitIds: number[],
    layoutTypeIds: number[],
    sendSms: boolean
  ): Observable<UpdateModel> {
    const body = this.createFormData(formControls, draft, unitIds, layoutTypeIds, sendSms);
    return this.http.post(`${environment.apiBaseUrl}api/v1/projects/${projectId}/updates`, body).pipe(
      map((res: any) => res.data),
      map((update) => plainToClass(UpdateModel, update))
    );
  }

  public removeUpdates(projectId: number, ids: number[]): Observable<unknown> {
    const httpOptions = {
      params: new HttpParams(),
      body: { ids: ids },
    };
    return this.http.delete(`${environment.apiBaseUrl}api/v1/projects/${projectId}/updates/batch_destroy`, httpOptions);
  }

  createFormData(
    formControls: IFormUpdate,
    isDraft: boolean | undefined,
    unitIds: number[],
    addTypes: number[],
    sendSms: boolean
  ): FormData {
    const body = new FormData();
    if (unitIds?.length) {
      unitIds.forEach((unit) => {
        body.append('update[units_ids][]', unit.toString());
      });
    } else {
      body.append('update[units_ids][]', '');
    }

    if (addTypes?.length) {
      addTypes.forEach((type) => {
        body.append('update[updates_layout_types_attributes][][layout_type_id]', type.toString());
      });
    }

    body.append('update[name]', formControls.name);
    body.append('update[user_type]', formControls.type);
    body.append('update[description]', formControls.text);
    body.append('update[published_at_in_timezone]', formControls.published);
    body.append('update[draft]', (isDraft ?? '').toString());
    body.append('update[send_via_sms]', sendSms.toString());

    const image = formControls.document.filter((item) => {
      const formFile: File = item as File;
      return FileValidators.isImage(formFile);
    });

    image.forEach((file: Partial<FileModel | ImageModel>, i: number) => {
      if (file?.id) {
        body.append(`update[images_attributes][${i}][id]`, file.id.toString());
      } else {
        body.append(`update[images_attributes][${i}][filename]`, file as File, file.name);
      }
      body.append(`update[images_attributes][${i}][position]`, (i + 1).toString());
    });

    const files = formControls.document.filter((item) => {
      const formFile: File = item as File;
      return !FileValidators.isImage(formFile);
    });

    files.forEach((file: Partial<FileModel | ImageModel>, i: number) => {
      if (file?.id) {
        body.append(`update[file_resources_attributes][${i}][id]`, file.id.toString());
      } else {
        body.append(`update[file_resources_attributes][${i}][filename]`, file as File, file.name);
      }
      body.append(`update[file_resources_attributes][${i}][position]`, (i + 1).toString());
    });

    return body;
  }

  removeFormData(
    formControls: IFormUpdate,
    isDraft: boolean | undefined,
    removeTypes: number[],
    removeFiles: (FileModel | ImageModel | File)[] = []
  ): FormData {
    const body = new FormData();
    body.append('update[name]', formControls.name);
    body.append('update[description]', formControls.text);
    body.append('update[user_type]', formControls.type);
    body.append('update[draft]', (isDraft ?? '').toString());

    if (removeTypes?.length) {
      removeTypes.forEach((type) => {
        body.append('update[updates_layout_types_attributes][][layout_type_id]', type.toString());
        body.append('update[updates_layout_types_attributes][][_destroy]', 'true');
      });
    }

    removeFiles.forEach((file: Partial<FileModel | ImageModel>) => {
      if (file.id) {
        const attr = FileValidators.isImage(file) ? 'images_attributes' : 'file_resources_attributes';
        body.append(`update[${attr}][][id]`, file.id.toString());
        body.append(`update[${attr}][][_destroy]`, 'true');
      }
    });
    return body;
  }

  public getBuyersTemplates(
    firmId: number,
    sort = '',
    search = '',
    page = 1,
    perPage = 100
  ): Observable<{ total: number; template: UpdateModel[] }> {
    let params: HttpParams = new HttpParams();
    params = params.set('per_page', String(perPage));
    params = params.set('page', String(page));
    params = params.set('sort_by', sort);
    params = params.set('search', search);
    return this.http
      .get<any>(`${environment.apiBaseUrl}api/v1/firms/${firmId}/update_templates`, { params, observe: 'response' })
      .pipe(
        mergeMap((returnData) => {
          const pagination = this.tableService.getPagination(returnData);
          return of(returnData).pipe(
            map((res) => res.body.data.update_templates),
            map((data: UpdateModel[]) => plainToClass(UpdateModel, data)),
            map((data) => ({ total: pagination.totalCount, template: data }))
          );
        })
      );
  }

  public postUpdateFromtemplate(projectId: number, templateId: number): Observable<UpdateModel> {
    const fd = new FormData();
    fd.append('update[update_template_id]', String(templateId));
    return this.http
      .post<{ data: UpdateModel }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/updates`, fd)
      .pipe(
        map((res) => res.data),
        map((data: UpdateModel) => plainToClass(UpdateModel, data))
      );
  }
}
