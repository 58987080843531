<div class="schedule-button" [class.loading]="loading">
  <button (click)="firstHandler()" [disabled]="disabled" type="button" class="schedule-button__first">
    {{ title | translate }}
  </button>
  <button
    (click)="secondHandler()"
    [disabled]="disabled"
    type="button"
    class="schedule-button__second"
    [ngbTooltip]="tooltipTitle | translate"
    [openDelay]="tooltipDelay"
    tooltipClass="schedule-tooltip"
    placement="top"
    triggers="hover touchstart"
  >
    <img [src]="icon" alt="icon" />
  </button>
  <span *ngIf="loading" class="schedule-button__spinner">
    <img class="spinner" src="assets/spinner-blue.svg" alt="Spinner" />
  </span>
</div>
