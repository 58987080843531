import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from '@atl-libs-shared-pipes/shared-pipes.module';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import {
  DefaultDropdownModule,
  NgbModalFooterComponent,
  NgbModalHeaderComponent,
} from '@atlas-workspace/shared/ui';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from "@ngx-translate/core";

import { MoveToModalV3Component } from './move-to-modal-v3.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    DefaultDropdownModule,
    NgbModalModule,
    NgbModalFooterComponent,
    NgbModalHeaderComponent,
    TranslateModule
  ],
  declarations: [MoveToModalV3Component],
  exports: [MoveToModalV3Component],
})
export class MoveToModalV3Module {}
