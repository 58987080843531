<div class="time-dropdown">
  <span *ngIf="title" class="time-dropdown__label">{{title | translate}}</span>
  <div class="time-dropdown__input" atlClickOutside (clickOutside)="close()">
    <input
      #from

      [(ngModel)]="fromValue"
      type="text"
      [mask]="mask"
      (focus)="focusFormList(true, fromListEl)"
      (input)="inputFrom(from.value)"
      (keydown.enter)="enterFrom()"
      (keydown.tab)="enterFrom(false)"
      [readOnly]="disabled"
      [placeholder]="placeholder | translate"
    />
    <ul #fromListEl class="time-dropdown__input-select v-scrollbar small" [class.show]="showFromList">
      <li
        *ngFor="let time of fromList"
        (click)="selectFrom($event, time)"
        [class.select]="time.value === fromValue || time.value === startValue"
        [class.disabled]="dateIsSelected ? (time.hour * 60 + time.min) < (minTime.hour * 60 + minTime.min) : false"
      >
        {{ time.value }}
      </li>
    </ul>
    <img *ngIf="!showFromList" class="time-dropdown__icon" src="assets/clock-16.svg" alt="icon">
  </div>
</div>
