<atl-default-modal [headerData]="headerData" [showTitle]="showTitle" 
  [hasSeparator]="hasSeparator">
  <swiper-container
    atlSwiper
    [config]="swiperConfig"
    [slides]="slides"
    init="false"
    class="swiper-container h-100"
    (indexChanged)="onIndexChange($event)"
  >
    <swiper-slide *ngFor="let slide of slides" class="swiper-slide">
      <div class="images">
        <ng-container *ngIf="!(slide.name | isRaw); else emptyRaw">
          <img [src]="slide.fileName.url" alt="image" class="slide" />
        </ng-container>
      </div>
    </swiper-slide>
  </swiper-container>
</atl-default-modal>

<ng-template #emptyRaw>
  <div class="full-screen-modal__empty-image">
    <img src="assets/no_images.png" alt="raw-empty" />
  </div>
</ng-template>
