import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedFormModule, TitledCheckboxModule } from '@atlas-workspace/shared/form';
import { ListOfThreadsDraftItemModule, ListOfThreadsItemModule } from '@atlas-workspace/shared/threads';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ThreadFoldersComponent } from './components/thread/thread-folders.component';
import { ThreadFoldersMobileComponent } from './components/thread-folders-mobile/thread-folders-mobile.component';
import {CustomIconComponent} from "@atlas-workspace/shared/ui";

@NgModule({
  imports: [
    CommonModule,
    SharedFormModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    RouterModule,
    ListOfThreadsDraftItemModule,
    ListOfThreadsItemModule,
    SharedDirectivesModule,
    TitledCheckboxModule,
    FormsModule,
    CustomIconComponent
  ],
  declarations: [ThreadFoldersComponent, ThreadFoldersMobileComponent],
  exports: [ThreadFoldersComponent, ThreadFoldersMobileComponent],
})
export class ThreadFoldersModule {}
