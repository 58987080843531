/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WhitespaceValidator } from '@atlas-workspace/shared/form';
import {
  acceptedGlobalExtensions, ECategoryStatus, ECommonAreaPropertyType,
  FileModel,
  FloorModel,
  ImageModel,
  IMark,
  PreloadedFile,
  ProductModel,
  ReclamationSettingsModel,
  ReclamationsModel,
  TReclamationsModelFormGroup,
  UnitFloorModel,
  UnitModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import { ModalFacadeService, ReclamationClientService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, take } from 'rxjs/operators';
import { ReclamationCreateConfirmComponent } from '../reclamation-create-confirm/reclamation-create-confirm.component';
import { plainToClass } from 'class-transformer';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';

interface ISearch {
  term: string;
}

interface IWrapFile {
  filename: FileModel | ImageModel;
  position: number;
}

@UntilDestroy()
@Component({
  selector: 'atl-reclamation-client-create',
  templateUrl: './reclamation-client-create.component.html',
  styleUrls: ['./reclamation-client-create.component.scss'],
})
export class ReclamationClientCreateComponent implements OnInit {
  @Input() modalRef!: NgbModalRef;
  @Input() isLoading: boolean = false;
  @Input() showBanner: boolean = false;

  @Output() private readonly createReclamationEvent = new EventEmitter<Partial<ReclamationsModel>>();
  @Output() public readonly hideBannerEvent = new EventEmitter();

  public form!: FormGroup;
  public today: Date = new Date();
  public projectId!: number;
  public unitId!: number;

  public fileLoading = false;
  public attachmentsCount = 0;
  public attachmentsAddedCount = 0;

  public units: UnitModel[] = [];
  public categories: any[] = []; // type to be announced
  public types: any[] = []; // type to be announced
  public rooms: any[] = []; // type to be announced
  public products: Partial<ProductModel>[] = [];
  readonly acceptedExtensions = acceptedGlobalExtensions;
  public floorPlanData!: UnitFloorModel[] | FloorModel[] | undefined;
  public mark: IMark[] = [];
  public currentUnit!: UnitUserModel;
  public conditionModalOpened = false;
  public settings!: ReclamationSettingsModel;

  public preloadedFiles: PreloadedFile[] = [];
  public isLoadingFiles = false;

  public readonly descriptionMaxLength = 500;
  public readonly tooltipDelay = 500;
  private categoryStatus!: ECategoryStatus;

  constructor(
    private fb: FormBuilder,
    private reclamationService: ReclamationClientService,
    private route: ActivatedRoute,
    private modalFacadeService: ModalFacadeService,
    protected modalHelpersService: ModalHelpersService
  ) {}

  ngOnInit(): void {
    this.projectId = this.route.snapshot?.parent?.parent?.params.projectId;
    this.unitId = this.route.snapshot?.parent?.parent?.params.unitId;

    this.getSettings();

    this.initForm();
    this.initFormListeners();
    this.getUnitDetails();
  }

  private getSettings(): void {
    this.reclamationService
      .getReclamationSettings(this.projectId)
      .pipe(take(1))
      .subscribe((settings) => {
        this.settings = settings;
        if (this.settings.termsConditions) {
          this.confirmCreateModal();
        }
      });
  }

  private confirmCreateModal(): void {
    const confirmModalRef = this.modalFacadeService.openModal(ReclamationCreateConfirmComponent, {
      centered: true,
      windowClass: 'confirm-condition-modal client-radius',
    });
    confirmModalRef.componentInstance.modalRef = confirmModalRef;

    this.conditionModalOpened = true;

    confirmModalRef.componentInstance.title = this.settings.termsConditionsData.termsTitle;
    confirmModalRef.componentInstance.description = this.settings.termsConditionsData.termsDescription;
    confirmModalRef.componentInstance.checkboxLabel = this.settings.termsConditionsData.termsCheckboxLabel;
    confirmModalRef.componentInstance.showCheckbox = this.settings.termsConditionsData.termsCheckbox;

    confirmModalRef.componentInstance.confirmPass.pipe(untilDestroyed(this)).subscribe((isConfirm: boolean) => {
      this.conditionModalOpened = false;
      if (isConfirm) {
        confirmModalRef.close();
      } else {
        this.modalFacadeService.closeModal();
      }
    });

    confirmModalRef.dismissed.pipe(untilDestroyed(this)).subscribe(() => {
      this.conditionModalOpened = false;
      this.modalRef.close();
    });
  }

  public initForm(): void {
    this.form = this.fb.group(<TReclamationsModelFormGroup>{
      description: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(500), WhitespaceValidator.noWhiteSpaceV2]],
      category: [{ value: [], disabled: false }, [Validators.required]],
      type: [{ value: [], disabled: false }, [Validators.required]],
      room: [{ value: [], disabled: false }],
      product: [{ value: [], disabled: false }],
      reported: [{ value: this.today, disabled: true }],
      attachments: [[], []],
      floorId: [null, [Validators.required]],
      floorType: ['', []],
      pointX: [null, []],
      pointY: [null, []],
    });
  }

  private initFormListeners(): void {
    this.form
      .get('category')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((v: any[]) => {
        if (v.length) {
          this.getTypes(v[0].id);
          this.form.get('type')?.enable({ emitEvent: false });
          this.form.get('type')?.setValue([], { emitEvent: false });
        } else {
          this.form.get('type')?.disable();
          this.types = [];
          this.form.get('type')?.setValue([]);
        }
      });
  }

  private getUnitDetails(): void {
    this.reclamationService
      .getUnitDetails(this.projectId, this.unitId)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.currentUnit = data;
        this.categoryStatus = this.currentUnit.propertyType === ECommonAreaPropertyType.CommonArea
          ? ECategoryStatus.Common : ECategoryStatus.Private;
        this.getCategories();
        this.getRooms(data);
        this.getFloorPlan(data);
      });
  }

  private getCategories(): void {
    this.reclamationService
      .getCategories(this.projectId, this.categoryStatus)
      .pipe(untilDestroyed(this))
      .subscribe((categories) => {
        this.categories = categories;
      });
  }

  private getTypes(categoryId: number): void {
    this.reclamationService
      .getTypes(this.projectId, categoryId, this.categoryStatus)
      .pipe(untilDestroyed(this))
      .subscribe((types) => {
        this.types = types;
        if (this.types.length === 1) this.form.get('type')?.setValue(this.types);
      });
  }

  private getRooms(unit_user: UnitUserModel): void {
    this.rooms = [];
    if (!unit_user.layoutType) return;
    this.rooms = unit_user.rooms!;
  }

  private getFloorPlan(unit_user: UnitUserModel): void {
    this.floorPlanData = [];
    this.floorPlanData = unit_user.mixinFloors;

    const isFloorPlanExist = !!this.floorPlanData?.length && this.floorPlanData.filter((i) => i.plan).length;

    if (!isFloorPlanExist) {
      this.form.get('floorId')?.setValidators([]);
    }
  }

  onSearchProducts(search: ISearch): void {
    this.reclamationService
      .getProducts(this.projectId, this.unitId, search.term)
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((products) => {
        this.products = products || [];
      });
  }

  public onRemoveSelectedItem(control: string, item: any, compareProp: string = 'id'): void {
    const items: any[] = this.form.get(control)?.value;
    const foundIndex = items.findIndex((a) => a[compareProp] === item[compareProp]);
    if (foundIndex !== -1) {
      items.splice(foundIndex, 1);
      this.form.get(control)?.setValue([...items]);
    }
  }

  private createFormData(): FormData {
    const formValue: Partial<TReclamationsModelFormGroup> = this.form.getRawValue();

    const body = new FormData();

    body.append('reclamation[description]', formValue.description);
    body.append('reclamation[type_id]', formValue.type[0]?.id || '');
    body.append('reclamation[room_id]', formValue.room[0]?.id || '');
    body.append('reclamation[wishlist_item_id]', formValue.product[0]?.id || '');

    if (formValue.floorId && formValue.floorType) {
      body.append('reclamation[floor_id]', formValue.floorId);
      body.append('reclamation[floor_type]', formValue.floorType);
      body.append('reclamation[point_x]', formValue.pointX);
      body.append('reclamation[point_y]', formValue.pointY);
    }

    formValue.attachments?.forEach((file: PreloadedFile) => {
      body.append('reclamation[file_resources_attributes][][filename_remote_url]', file.link);
      body.append('reclamation[file_resources_attributes][][position]', file.position.toString());
    });

    return body;
  }

  public createReclamation(): Subscription {
    this.isLoading = true;
    return this.reclamationService
      .createProjectReclamation(this.unitId, this.createFormData())
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.createReclamationEvent.emit(response);
          this.modalRef.close();
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  removeExistingFile(index: number): void {
    this.form.get('attachments')?.value.splice(index, 1);
    this.updateFormAttachmentsPos();
  }

  updateFilePositions(files: (FileModel | ImageModel)[]): void {
    const newestFiles: PreloadedFile[] = [];
    const oldFiles = this.form.get('attachments')?.value ?? [];
    files.forEach((f: FileModel | ImageModel, index: number) => {
      const foundIndex = oldFiles.findIndex((file: PreloadedFile) => file.title === f.name);
      if (~foundIndex) {
        oldFiles[foundIndex].position = index + 1;
        newestFiles.push(oldFiles[foundIndex]);
      }
    });
    this.form.get('attachments')?.setValue(newestFiles);
  }

  updateFormAttachmentsPos(): void {
    let arr = this.form.get('attachments')?.value.map((file: IWrapFile, index: number) => {
      return {
        filename: file.filename,
        position: index + 1,
      };
    });
    this.form.get('attachments')?.setValue(arr);
  }

  setAttachmentCount(e: File[]): void {
    this.attachmentsCount = e.length;

    if (this.attachmentsCount === this.attachmentsAddedCount) {
      this.fileLoading = false;
      this.attachmentsAddedCount = 0;
    }
  }

  public setMark(markArr: IMark[]): void {
    this.mark = markArr;
    this.form.patchValue({
      floorId: markArr.length ? markArr[0].floorId : null,
      floorType: markArr.length ? markArr[0].floorType : null,
      pointX: markArr.length ? markArr[0].mark[0].x : null,
      pointY: markArr.length ? markArr[0].mark[0].y : null,
    });
  }

  public openPreview(index: number): void {
    const files = this.form.controls.attachments.value;
    this.modalHelpersService.previewDocument({
      ...files[index],
      fileExtension: files[index].format,
      name: files[index].title,
      createdAt: files[index].expiredAt,
      fileName: {
        url: files[index].link,
        downloadUrl: files[index].link,
      },
    });
  }

  public removeTempFile(index: number): void {
    this.preloadedFiles.splice(index, 1);
  }

  public tempFileUpload(files: File[]): void {
    this.isLoadingFiles = true;
    const requests = files.map((file) => {
      return this.reclamationService.generateDocumentUrlsS3(file);
    });
    zip(...requests)
      .pipe(
        mergeMap((values) => {
          const uploads = values.map((value, index) => {
            this.preloadedFiles.push(this.buildFile(value[0].publicUrl, files[index], index + 1));
            return this.reclamationService.uploadFileToS3(value[0], files[index]);
          });
          return zip(...uploads);
        }),
        take(1)
      )
      .subscribe(() => {
        this.form.get('attachments')?.setValue(this.preloadedFiles);
        this.isLoadingFiles = false;
      });
  }

  private buildFile(url: string, file: File, index: number): PreloadedFile {
    return plainToClass(PreloadedFile, {
      id: null,
      format: file.type,
      expired_at: new Date(),
      link: url,
      size: file.size,
      title: file.name,
      position: index,
    });
  }
}
