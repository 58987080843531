import { DocumentModel, ITablePagination } from '@atlas-workspace/shared/models';

export class SetDocuments {
  static readonly type = '[Documents] save the list of documents';

  constructor(public documents: DocumentModel[]) {}
}

export class AddDocument {
  static readonly type = '[Documents] add new document';

  constructor(public document: DocumentModel) {}
}

export class SetDocument {
  static readonly type = '[Documents] save change document local';

  constructor(public document: DocumentModel) {}
}

export class RemoveDocuments {
  static readonly type = '[Documents] remove documents local';

  constructor(public ids: number[]) {}
}

export class SetDocumentsPagination {
  static readonly type = '[Documents] set pagination';

  constructor(public pagination: ITablePagination) {}
}

export class SetDocumentsTotalFirstLoading {
  static readonly type = '[Documents] set total first loading';

  constructor(public firstLoad: boolean, public totalCount: number) {}
}
