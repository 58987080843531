import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GuestsModel, MembersModel } from '@atlas-workspace/shared/models';
import { AuthAdminService } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GuestsService {
  constructor(private http: HttpClient, private authAdminService: AuthAdminService) {}

  public getGuests(sort = 'default'): Observable<GuestsModel[]> {
    return this.http
      .get<{ data: { guests: GuestsModel[] } }>(
        `${environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/guests?sort_by=${sort}`
      )
      .pipe(
        map((res) => res.data.guests),
        map((data) => plainToClass(GuestsModel, data))
      );
  }

  public getGuest(memberId: number): Observable<MembersModel> {
    return this.http
      .get(`${environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/guests/${memberId}`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(MembersModel, data))
      );
  }

  public updateGuest(guest: any, memberId: number): Observable<MembersModel> {
    return this.http
      .put(`${environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/guests/${memberId}`, { guest })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(MembersModel, data))
      );
  }

  public resendInvite(memberId: number): Observable<any> {
    return this.http.patch(
      `${environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/guests/${memberId}/resend_invite`,
      null
    );
  }

  public transferOwnership(members: any): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}api/v1/projects/transfer_ownership`, { members });
  }
}
