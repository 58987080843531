import {CommonModule} from "@angular/common";
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormsModule} from "@angular/forms";
import {SharedFormModule} from "@atlas-workspace/shared/form";
import {ModalHelpersService} from "@atlas-workspace/shared/modals";
import {
  acceptedGlobalExtensions,
  CreatedCombinedOfferModel,
  EChangeRequestStatus,
  offerStatusList,
  SubOfferModel,
} from '@atlas-workspace/shared/models';
import {NgbModalHeaderComponent} from "@atlas-workspace/shared/ui";
import {NgbModalRef, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'atl-sub-offer-detail-modal',
  templateUrl: './sub-offer-detail-modal.component.html',
  styleUrls: ['./sub-offer-detail-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, SharedFormModule, FormsModule, NgbTooltipModule, NgbModalHeaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubOfferDetailModalComponent implements OnInit {
  @Input() offer!: CreatedCombinedOfferModel;
  @Input() modalRef!: NgbModalRef;
  @Input() identifier!: string;

  public form!: FormGroup;
  public title = '';
  public readonly statusClasses = offerStatusList;
  public readonly acceptedExtensions = acceptedGlobalExtensions;
  public readonly statusKeys = EChangeRequestStatus;

  constructor(
    private readonly modalHelpersService: ModalHelpersService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.title = this.translateService.instant('Change_request.Combine_Offer.Total_unit', {identifier: this.identifier});
  }

  closeModal(): void {
    this.modalRef.dismiss();
  }

  public openPreview(index: number): void {
    const files = this.offer.fileResources ?? [];
    this.modalHelpersService.previewImages(files, index);
  }

  public subOfferPreview(index: number, offer: SubOfferModel): void {
    this.modalHelpersService.previewImages(offer.fileResources, index);
  }
}
