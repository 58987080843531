import {
  ERecDiscussionTabId,
  ERecDiscussionTabName,
  EReclamationStatus,
  EReclamationStatusKey,
  EReclamationView,
  EUnitReclamationView,
  ISettingsMenu,
} from '@atlas-workspace/shared/models';

import floorPlanViewIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/plan-icon.svg';
import tableViewIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/table-icon-new.svg';

export interface DropdownItem {
  [key: string]: any;
  name: string;
  title: string;
  selected?: boolean;
}

export enum INavMenuId {
  General = 'rec-tab-general',
  Internal = 'rec-tab-internal-notes',
  Customer = 'rec-tab-customer-dialogue',
  Notes = 'rec-tab-notes',
}

export const navigationMenu: ISettingsMenu[] = [
  { name: 'Shared.Reclamation.Details', id: INavMenuId.General, disabled: false, route: '', counter: 0 },
  { name: 'Entity.Customer_dialogue', id: INavMenuId.Customer, disabled: false, route: '', counter: 0 },
  { name: 'Reclamation.Tab.Discussion', id: INavMenuId.Internal, disabled: false, route: '', counter: 0 },
  { name: 'Shared.Entity.Notes', id: INavMenuId.Notes, disabled: false, route: '', counter: 0 },
];

export const navigationDiscussionMenu = [
  {
    name: ERecDiscussionTabName.CompanyName,
    id: ERecDiscussionTabId.CompanyName,
    disabled: false,
    route: '',
    counter: 0,
  },
  {
    name: ERecDiscussionTabName.AdminsOnly,
    id: ERecDiscussionTabId.AdminsOnly,
    disabled: false,
    route: '',
    counter: 0,
  },
];

export const navigationClientMenu = [
  { name: 'Entity.General', id: INavMenuId.General, disabled: false, route: '', counter: 0 },
  { name: 'Title.Messages', id: INavMenuId.Customer, disabled: false, route: '', counter: 0 },
];

export const inputHelper = {
  description: {
    icon: 'assets/text-align-left-gray.svg',
    title: 'Shared.Reclamation.Input.Description.Label',
  },
  category: {
    icon: 'assets/dropdown-circle.svg',
    title: 'Shared.Reclamation.Input.Category.Label',
  },
  type: {
    icon: 'assets/dropdown-circle.svg',
    title: 'Shared.Reclamation.Input.Type.Label',
  },
  room: {
    icon: 'assets/dropdown-circle.svg',
    title: 'Shared.Reclamation.Input.Room.Label',
  },
  product: {
    icon: 'assets/dropdown-circle.svg',
    title: 'Shared.Reclamation.Input.Product.Label',
  },
  responsible: {
    icon: 'assets/dropdown-circle.svg',
    title: 'Shared.Reclamation.Input.Responsible.Label',
  },
  meeting: {
    icon: 'assets/dropdown-circle.svg',
    title: 'Shared.Reclamation.Input.Meeting.Label',
  },
  reported: {
    icon: 'assets/calendar-gray.svg',
    title: 'Shared.Reclamation.Input.Reported.Label',
  },
  deadline: {
    icon: 'assets/calendar-gray.svg',
    title: 'Shared.Reclamation.Input.Deadline.Label',
  },
  status: {
    icon: 'assets/spinner-gray.svg',
    title: 'Shared.Reclamation.Input.Status.Label',
  },
  attachments: {
    icon: 'assets/paperclip_gray.svg',
    title: 'Shared.Reclamation.Input.Attachments.Label',
  },
  address: {
    icon: 'assets/map_pin_control.svg',
    title: 'Input.Address.Label',
  },
};

export const unitViewModeMenu: DropdownItem[] = [
  {
    title: 'Title.Table_view',
    icon: tableViewIcon,
    name: '',
    key: EUnitReclamationView.TableView,
  },
  {
    title: 'Title.Floor_plan_view',
    icon: floorPlanViewIcon,
    name: '',
    key: EUnitReclamationView.FloorPlanView,
  },
];

export const reclamationViewModeMenu: DropdownItem[] = [
  {
    title: 'Title.Table_view',
    name: '',
    key: EReclamationView.Reclamations,
  },
  {
    title: 'Title.Unit_view',
    name: '',
    key: EReclamationView.Units,
  },
  {
    title: 'Title.Message_view',
    name: '',
    key: EReclamationView.Threads,
  },
];

export const createReclamationStatusList = [
  { title: EReclamationStatus.Pending, value: EReclamationStatusKey.Pending },
  { title: EReclamationStatus.InProgress, value: EReclamationStatusKey.InProgress },
];
