import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {
  acceptedGlobalExtensions,
  AdminProjectModel,
  FileModel, FloorModel,
  IFloorDrawToSave,
  IFloorType, IGroupChangeRequestsList,
  ImageModel, IUnitMainBuyer, IWrapFile,
  UnitFloorModel,
  UnitUserModel
} from "@atlas-workspace/shared/models";
import {
  AuthAdminService,
  LockFieldService,
} from "@atlas-workspace/shared/service";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {EChangeRequestFormKey} from "@atlas-workspace/change-requests";
import {DecimalPipe} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: 'atl-group-change-request-modal',
  templateUrl: './group-change-request-modal.component.html',
  styleUrls: ['./group-change-request-modal.component.scss',],
})
export class GroupChangeRequestModalComponent implements OnInit, OnDestroy {
  @Input() modalRef!: NgbModalRef;
  @Input() projectId!: string;
  @Input() unit!: UnitUserModel;
  @Input() users: AdminProjectModel[] = [];
  @Input() editMode = false;
  @Input() editData!: IGroupChangeRequestsList;
  @Input() activeFloorId!: number;

  @Output() readonly remove = new EventEmitter();

  public fileLoading = false;
  public form!: FormGroup;
  public readonly acceptedExtensions = acceptedGlobalExtensions;
  public readonly descriptionMaxLength = 500;
  public readonly changeRequestFormKey = EChangeRequestFormKey;
  public previewPlan = '';

  public floorPlanData!: (UnitFloorModel | FloorModel)[] | any;
  public isLoading = false;
  public viewerUsers!: IUnitMainBuyer[];

  public readonly floorType = IFloorType;
  public readonly nameTruncate = 30;
  public readonly tooltipDelay = 500;

  private formChanged = false;
  private attachmentChanged = false;
  private drawingChanged = false;

  public drawingData: IFloorDrawToSave[] = [];
  public title = 'Button.New_change_requests';

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthAdminService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly lockFieldService: LockFieldService,
    private readonly number: DecimalPipe,
    private readonly translateService: TranslateService,
  ) {}

  public closeModal(): void {
    this.modalRef.dismiss();
  }

  ngOnInit(): void {
    this.getFloorPlan(this.unit);
    this.setViewerUsers();
    this.initForm();
    const currentUserId = this.authService.getUserInfo?.id;
    const currentUserIndex = this.users.findIndex((u) => u.id == currentUserId);
    if (currentUserId && currentUserIndex != -1) {
      this.form.get('responsible')?.setValue([this.users[currentUserIndex]]);
    }

    if (this.editMode) {
      this.title = this.translateService.instant('Title.Change_request_detail') + ' - ' + this.number.transform(this.editData.order, '2.0-0');
      this.form.patchValue({
        description: this.editData.data.description,
        responsible: this.editData.data.responsible,
        attachments: this.editData.data.attachments,
      });
      this.updateFloorPlanDraw(this.editData.drawingData);
      this.initChangeForm();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.lockFieldService.setLocked(false);
  }

  public get disabledSaveButton(): boolean {
    return !(this.formChanged || this.attachmentChanged || this.drawingChanged);
  }

  public setViewerUsers(): void {
    const mainPrimaryOwner = this.unit.mainPrimaryOwner;
    const primaryOwners = this.unit.primaryOwners;
    this.viewerUsers = [
      ...(mainPrimaryOwner ? [mainPrimaryOwner] : []),
      ...(primaryOwners ? primaryOwners.filter(({ id }) => id !== mainPrimaryOwner?.id) : []),
    ];
  }

  public removeChangeRequest(): void {
    this.remove.emit();
  }

  public updateFloorPlanDraw(v: IFloorDrawToSave[]): void {
    this.drawingData = v;
    this.drawingData.forEach((data) => {
      const floorIndex = this.floorPlanData?.findIndex((x: UnitFloorModel) => x.id === data.floor_id);
      if (floorIndex !== -1) {
        this.floorPlanData[floorIndex].plan.fullImage.url = data.filename_remote_url;
      }
    });
    this.drawingChanged = true;
    this.cdr.markForCheck();
  }

  public redirectToUnit(): void {
    const url = `/base-layout/projects/specific_project/${this.projectId}/units/view/units?unitId=${this.unit.id}`;
    void this.router.navigateByUrl(url);
  }

  public onRemoveSelectedItem(control: string, item: any, compareProp = 'id'): void {
    const items = this.form.get(control)?.value;
    const foundIndex = items.findIndex((a: any) => a[compareProp] === item[compareProp]);
    if (foundIndex !== -1) {
      items.splice(foundIndex, 1);
      this.form.get(control)?.setValue([...items]);
    }
  }

  public removeExistingFile(index: number): void {
    this.form.get('attachments')?.value.splice(index, 1);
    this.updateFormAttachmentsPos();
    this.attachmentChanged = true;
  }

  public updateFilePositions(files: (FileModel | ImageModel)[]): void {
    const filesArr = files.map((f: FileModel | ImageModel, index: number) => {
      return {
        filename: f,
        position: index + 1,
      };
    });
    this.form.get('attachments')?.setValue(filesArr);
    this.attachmentChanged = true;
  }

  public createRequest(): void {
    const form = this.form.value;
    this.modalRef.dismiss({data: form, drawingData: this.drawingData});
  }

  public saveRequest(): void {
    this.editData.data = this.form.value;
    this.editData.drawingData = this.drawingData;
    this.modalRef.dismiss(this.editData);
  }

  private updateFormAttachmentsPos(isOffer = false): void {
    const control = !isOffer ? 'attachments' : 'offerAttachments';
    const arr = this.form.get(control)?.value.map((file: IWrapFile, index: number) => {
      return {
        filename: file.filename,
        position: index + 1,
      };
    });
    this.form.get(control)?.setValue(arr);
  }

  private getFloorPlan(unit_user: UnitUserModel): void {
    this.drawingData = [];
    this.floorPlanData = [];
    if (unit_user.hasOwnFloorPlans) {
      this.floorPlanData = unit_user.unitFloors;
    } else {
      this.floorPlanData = unit_user.floors;
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      description: ['', Validators.maxLength(800)],
      responsible: [[]],
      attachments: [[], []],
    });
  }

  private initChangeForm(): void {
    const keys = Object.keys(this.form.getRawValue());

    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      const changeData = keys.reduce((acc, key) => {
        if (key === EChangeRequestFormKey.Descriptions && value[key] !== this.editData.data.description) {
          acc = {...acc, [EChangeRequestFormKey.Descriptions]: value[key]};
        }
        if (key === EChangeRequestFormKey.Responsible&& value[key]?.[0]?.id !== this.editData.data.responsible?.[0]?.id) {
          acc = {...acc, [EChangeRequestFormKey.Responsible]: value[key]};
        }
        if (key === EChangeRequestFormKey.Attachments ) {
          acc = {...acc, [EChangeRequestFormKey.Attachments]: value[key]};
        }
        return acc;
      }, {});

      this.formChanged = !!Object.keys(changeData).length;
    });
  }
}
