import { CommonModule } from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {EDocumentType, FdvModel, IMoveToRequest} from "@atlas-workspace/shared/models";
import {
  FdvBreadcrumbsComponent, HeaderIconComponent,
  NgbModalFooterComponent,
  NgbModalHeaderComponent,
} from "@atlas-workspace/shared/ui";
import {NgbModalRef, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'atl-newest-move-to-modal',
  templateUrl: './newest-move-to-modal.component.html',
  styleUrls: ['./newest-move-to-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FdvBreadcrumbsComponent, NgbTooltipModule, NgbModalFooterComponent, NgbModalHeaderComponent, HeaderIconComponent],
})
export class NewestMoveToModalComponent implements OnInit {

  @Input() fdvList!: FdvModel[];
  @Input() currentFolderId?: number;
  @Input() movingItems!: FdvModel[];
  @Input() modalRef!: NgbModalRef;
  @Input() unitIdentifier!: string;

  public currentFolder?: FdvModel;
  public title!: string;
  public selectFolder?: FdvModel;

  public readonly documentType = EDocumentType;
  public readonly tooltipDelay = 500;

  constructor(private readonly translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.setDisabledFolder(this.fdvList);
    this.currentFolder = this.searchFolderInTree(this.fdvList, this.currentFolderId);
    if (this.currentFolder) {
      this.setDisabledFolder(this.currentFolder.children);
    }
    this.title = this.getTitle();
  }

  closeModal(body?: IMoveToRequest): void {
    this.modalRef.dismiss(body);
  }

  get folderList(): FdvModel[] {
    return this.currentFolder?.children
      ? this.filterList(this.currentFolder.children)
      : this.filterList(this.fdvList);
  }

  private filterList(list: FdvModel[]): FdvModel[] {
    return list.filter(item => item.documentType === this.documentType.Folder);
  }

  getTitle(): string {
    return  this.movingItems?.length > 1
      ? this.translateService.instant('Shared.Entity.Items').toLowerCase()
      : `"${this.movingItems?.[0]?.title}`
  }

  public backToParentFolder(id: number): void {
    this.currentFolder = this.searchFolderInTree(this.fdvList, id);
    this.selectFolder =  this.currentFolder;
  }

  public selectFolderHandler(item: FdvModel): void {
    this.selectFolder = item;
  }

  public openFolder(item: FdvModel): void {
    this.currentFolder = item;
    this.selectFolder =  this.currentFolder;
    this.setDisabledFolder(this.currentFolder.children);
  }

  public backToRoot(): void {
    this.currentFolder = undefined;
    this.selectFolder = undefined;
  }

  public get disabledMoveIn(): boolean {
    const patentId = this.movingItems[0].parents[this.movingItems[0].parents.length - 1]?.id;
    if (this.selectFolder) {
      return this.selectFolder.id === patentId;
    }
    return this.currentFolder
      ? this.currentFolder.id === patentId
      : !this.movingItems[0].parents.length;
  }

  public moveSelectedToFolder(): void {
    const body: IMoveToRequest = {
      folder_id: this.selectFolder ? this.selectFolder.id : (this.currentFolder?.id || null),
      ids: this.movingItems.map(item => item.id)
    };
    this.closeModal(body);
  }

  private searchFolderInTree(tree: FdvModel[], id?: number): FdvModel | undefined {
    for (const folder of tree) {
      if (folder.id === id) {
        return folder;
      }
      const found = this.searchFolderInTree(folder.children, id);
      if (found) {
        return found;
      }
    }
    return undefined;
  }

  private setDisabledFolder(items: FdvModel[]): void {
    items.forEach(item => item.disabled = this.movingItems.some(i => i.id === item.id));
  }
}
