<!--
  ******************************************************
  @Cypress
  - interaction with thread subject
  ******************************************************
-->
<div
  class="thread"
  [ngClass]="scope"
  [class.thread__active]="activeThreadId && +activeThreadId === +thread.id"
  (click)="goToThreadView(thread.id)"
>
  <div class="thread__icon">
    <img *ngIf="!thread.state.read" src="/assets/dot.svg" alt="unread" class="thread__unread-indicator" />
    <atl-firm-icon
      *ngIf="thread.lastMessage || thread.creator"
      [model]="thread.lastMessage ? thread.lastMessage : thread.creator"
      [nameKey]="thread.lastMessage ? 'senderName' : 'name'"
      [isUser]="true"
      [ngbTooltip]="thread.lastMessage?.senderName"
      [placement]="first ? 'bottom' : 'top'"
      [openDelay]="tooltipOpenDelay"
      triggers="hover touchstart"
      [isMonochrome]="true"
    ></atl-firm-icon>
  </div>
  <div class="thread__data">
    <div class="thread__data-subject-wrapper">
      <ng-container *ngIf="scope !== scopes.Unit; else unitListSubject">
        <span
          #subject
          class="thread__data-subject lh-16 fz-14 fw-500 ellipsis"
          data-cy="thread-subject"
          [ngbTooltip]="thread.subject"
          placement="bottom"
          [disableTooltip]="subject.offsetWidth >= subject.scrollWidth"
          [openDelay]="tooltipOpenDelay"
          triggers="hover touchstart"
        >
          {{ thread.reclamationId ? this.scope + (thread.subject | afterSlash) : thread.subject }}
        </span>
      </ng-container>
      <span class="thread__data-date fz-12 lh-16 ml-8 capitalize">{{
        thread.lastMessage?.createdAt | timePassedAgo: passedTimeFormats.Short | async
      }}</span>
    </div>
    <div
      *ngIf="thread.units.length; else noUnits"
      #unitsRow
      class="thread__data-unit lh-16 fz-14 mt-4 ellipsis"
      [ngbTooltip]="threadsUnitsTemplate"
      placement="bottom"
      [disableTooltip]="unitsRow.offsetWidth >= unitsRow.scrollWidth"
      [openDelay]="tooltipOpenDelay"
      triggers="hover touchstart"
    >
      <ng-template [ngTemplateOutlet]="threadsUnitsTemplate"></ng-template>
    </div>
    <ng-template #threadsUnitsTemplate>
      <ng-template [ngIf]="scope === scopes.Global || showProjectsFilter">{{
        thread.lastMessage ? thread.lastMessage.senderName : thread.creator.name
      }}</ng-template>
      <ng-template [ngIf]="scope === scopes.Unit">
        {{ thread.lastMessage ? thread.lastMessage.senderName : thread.creator.name }}
      </ng-template>
      <ng-template [ngIf]="scope !== scopes.Global && !showProjectsFilter && scope !== scopes.Unit">{{
        (thread.units.length > 1 ? ('Threads.Multi_message' | translate) : thread.units[0].identifier) +
          dotDivider +
          (thread.lastMessage ? thread.lastMessage.senderName : thread.creator.name)
      }}</ng-template>
    </ng-template>
    <div class="thread__data-message lh-16 fz-14 mt-4 ellipsis">
      <span [innerHTML]="thread.lastMessage?.text ? (thread.lastMessage?.text | removeHtmlTag) : '—'"></span>
    </div>
    <div class="thread__data-tags d-flex flex-wrap">
      <div
        *ngIf="(thread.reclamationId || thread.changeRequestId) && scope !== scopes.Unit"
        class="thread__data-type fz-12 lh-16 mt-8 br-4"
      >
        {{ '#' + ((thread.reclamationId ? 'Shared.Entity.Reclamation' : 'Title.Change_request') | translate) }}
      </div>
      <div *ngIf="scope === scopes.Global || showProjectsFilter" class="thread__data-project br-4 mt-8">
        <div
          class="ellipsis fw-400 lh-16 fz-12"
          #projectRow
          [ngbTooltip]="threadProjectNameTemplate"
          [disableTooltip]="projectRow.offsetWidth >= projectRow.scrollWidth"
          [openDelay]="tooltipOpenDelay"
          placement="bottom"
          triggers="hover touchstart"
        >
          <ng-template [ngTemplateOutlet]="threadProjectNameTemplate"></ng-template>
        </div>
      </div>
    </div>
    <ng-template #threadProjectNameTemplate>
      {{ thread.project.name + (thread.units.length === 1 ? dotDivider + thread.units[0].identifier : '') }}
    </ng-template>
  </div>
</div>

<ng-template #noUnits>
  <span class="fz-14 lh-20 text-gray-890 pr-16">{{ 'Shared.Threads.No_recipient' | translate }}</span>
</ng-template>

<ng-template #unitListSubject>
  <span
    #unitSubject
    class="thread__data-subject lh-16 fz-14 fw-500 ellipsis"
    data-cy="thread-subject"
    [ngbTooltip]="thread.subject"
    placement="bottom"
    [disableTooltip]="unitSubject.offsetWidth >= unitSubject.scrollWidth"
    [openDelay]="tooltipOpenDelay"
    triggers="hover touchstart"
  >
  {{ 
    thread.reclamationId ? ('Shared.Entity.Reclamation_short' | translate) + dotDivider + (thread.subject | afterSlash) : 
    thread.changeRequestId ? ('Shared.Entity.Change_request_short' | translate) + dotDivider + (thread.subject | afterSlash) : 
    thread.subject 
  }}
  </span>
</ng-template>