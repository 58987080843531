import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { EReclamationScope, EThreadScope, ReclamationsModel } from '@atlas-workspace/shared/models';

import warningIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/warning-icon-tringle.svg';

@Component({
  selector: 'atl-reclamation-admin-messages',
  templateUrl: './reclamation-admin-messages.component.html',
  styleUrls: ['./reclamation-admin-messages.component.scss'],
})
export class ReclamationAdminMessagesComponent implements OnInit {
  @Input() public readonly projectId!: number;
  @Input() public threadViewComponent!: Type<any>;
  @Input() public reclamation!: ReclamationsModel;
  @Input() public reclamationScope!: EReclamationScope;
  @Input() public disabled = false;
  @Input() public isContractor = false;

  @ViewChild('threadView', { static: true, read: ViewContainerRef })
  private vcRef!: ViewContainerRef;
  public readonly warningIcon = warningIcon;

  private componentRef!: ComponentRef<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.initThreadViewComponent();
  }

  private initThreadViewComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.threadViewComponent);
    this.componentRef = this.vcRef.createComponent(componentFactory);
    this.componentRef.instance.scope = EThreadScope.Reclamation;
    this.componentRef.instance._threadId = this.reclamation.messageThreadId;
    this.componentRef.instance.isModal = true;
    this.componentRef.instance.disabled = this.reclamation.archived;
  }
}
