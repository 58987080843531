<atl-ngb-modal-header [title]="headerTitle" (closeModalEvent)="closeModal()"></atl-ngb-modal-header>

<div class="template-preview__wrapper">
  <div class="template-preview__wrapper-title">
    <div>
      <img class="mr-8" src="assets/text-align-left-gray.svg" alt="icon" />
      <span class="fz-14 lh-24 text-black-810 ellipsis">{{ inputTitle | translate }}</span>
    </div>
    <span
      #title
      class="fz-14 lh-24 ellipsis"
      [ngbTooltip]="template.name"
      [openDelay]="tooltipOpenDelay"
      [disableTooltip]="title.offsetWidth >= title.scrollWidth"
      container="body"
      triggers="hover touchstart"
      >
      {{ template.name }}
    </span>
  </div>
  <div class="template-preview__wrapper-content v-scrollbar">
    <atl-shadow-dom-content class="lh-24 fz-14">
      <div [innerHTML]="template.description | safeHtml"></div>
    </atl-shadow-dom-content>
  </div>
</div>

<div class="template-preview__button">
  <button (click)="useTemplate()" class="newest-button">{{ 'Button.Use_template' | translate }}</button>
</div>
