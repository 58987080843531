import { Injectable } from '@angular/core';
import { AdminPracticalInfoModal, IAdminPracticalInfo, ITablePagination } from '@atlas-workspace/shared/models';
import { DataTableHelperService } from '@atlas-workspace/shared/service';
import { Select, Store } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { PracticalInfoService } from '../../modules/projects/components/specific-project-wrapper/components/practical-info/service/practical-info.service';
import { defaultState } from '../default-state';
import {
  AddPractical,
  PracticalSetPagination,
  PracticalSetTotalFirstLoading,
  RemovePracticals,
  SetPractical,
  SetPracticals,
} from './practicals.action';
import { PracticalStateModel } from './practicals.model';
import { PracticalsState } from './practicals.state';

@Injectable({
  providedIn: 'root',
})
export class PracticalsFacadeService {
  @Select(PracticalsState.selectStateData) private _practicals!: Observable<PracticalStateModel>;

  constructor(
    private practicalInfoService: PracticalInfoService,
    private store: Store,
    private dataTableHelper: DataTableHelperService
  ) {}

  get practicals$(): Observable<PracticalStateModel> {
    return this._practicals.pipe(share());
  }

  getPracticals(
    projectId: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination
  ): Observable<AdminPracticalInfoModal[]> {
    return this.practicalInfoService.getPracticalInfoState(projectId, sort, search, paginate).pipe(
      tap((returnData) => {
        const pagination = this.dataTableHelper.getPagination(returnData);

        if (this.store.snapshot().practicalsState.firstLoad) {
          this.store.dispatch(new PracticalSetTotalFirstLoading(false, pagination.totalCount));
        }

        this.setPagination(pagination);
      }),
      map((res) => res.body.data.practical_infos),
      map((data: IAdminPracticalInfo[]) => plainToClass(AdminPracticalInfoModal, data)),
      tap((returnData) => {
        if (this.store.snapshot().practicalsState.pagination.currentPage === 1 && sort === 'default' && !search) {
          this.setPracticals(returnData);
        }
      })
    );
  }

  setPracticals(practicals: AdminPracticalInfoModal[]): void {
    this.store.dispatch(new SetPracticals(cloneDeep(practicals)));
  }

  setChangePractical(practical: AdminPracticalInfoModal): void {
    this.store.dispatch(new SetPractical(practical));
  }

  addCreatePractical(practicals: AdminPracticalInfoModal): void {
    this.store.dispatch(new AddPractical(cloneDeep(practicals)));
  }

  removePracticalsLocal(ids: number[]): void {
    this.store.dispatch(new RemovePracticals(ids));
  }

  setPagination(pagination: ITablePagination = defaultState.updatesState.pagination): void {
    this.store.dispatch(new PracticalSetPagination(pagination));
  }
}
