import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {
  AdminProjectModel, ChangeRequestGroupListModel, ChangeRequestGroupModel, ChangeRequestModel,
  FloorModel,
  ICreationList,
  IMark,
  IMarkItems,
  ISettingsMenu,
  UnitFloorModel,
} from "@atlas-workspace/shared/models";
import {
  ChangeRequestService,
  ModalFacadeService,
} from '@atlas-workspace/shared/service';
import {ThreadViewComponent} from "@atlas-workspace/shared/threads";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {take} from "rxjs/operators";

import {DetailChangeRequestComponent} from "../detail-change-request/detail-change-request.component";

@UntilDestroy()
@Component({
  selector: 'atl-group-mark-floor-plan-modal',
  templateUrl: './group-mark-floor-plan-modal.component.html',
  styleUrls: ['./group-mark-floor-plan-modal.component.scss'],
  providers: [ChangeRequestService]
})
export class GroupMarkFloorPlanModalComponent implements OnInit {
  @Input() modalRef!: NgbModalRef;
  @Input() projectId!: string;
  @Input() users: AdminProjectModel[] = [];
  @Input() unitId!: number;
  @Input() unitIdentifier!: number;
  @Input() floorPlanData?: UnitFloorModel[] | FloorModel[];
  @Input() floorTabActiveId!: number;
  @Input() navList!: ISettingsMenu[];
  @Input() floorsMarkData: ICreationList[] = [];
  @Input() changeRequestGroups!: ChangeRequestGroupModel[];

  @Output() private readonly updateTable = new EventEmitter();

  public title = '';

  public mark!: IMark;
  public previewPlan?: string;


  constructor(
    private readonly router: Router,
    private readonly modalFacadeService: ModalFacadeService,
    private readonly translateService: TranslateService,
    private readonly changeRequestService: ChangeRequestService,
  ) {}

  ngOnInit(): void {
    this.title = this.translateService.instant('Shared.Title.Change_requests_details') + ' - ' + this.unitIdentifier;
    this.activePreviewData();
  }

  public closeModal(): void {
    this.modalRef.dismiss();
  }

  public selectTab(event: { itemId: number }): void {
    this.floorTabActiveId = event.itemId;
    this.activePreviewData();
  }

  private activePreviewData(): void {
    const floor = this.floorsMarkData.find(item => item.floorPlanId === this.floorTabActiveId);
    if (floor) {
      this.previewPlan = floor.floorPlanData.plan?.fullImage?.url;
      this.mark = {...floor.markData};
    }
  }

  public unitRedirect(): void {
    const url = `/base-layout/projects/specific_project/${this.projectId}/units/view/units?unitId=${this.unitId}`;
    void this.router.navigateByUrl(url);
  }

  public detailMark(value: IMarkItems): void {
    const data = this.changeRequestGroups.reduce((acc:  ChangeRequestGroupListModel[], group) => {
      const request = group.changeRequests.find(c => c.order === value.order);
      if (request) {
        acc.push(request);
      }
      return acc;
    }, []);
    if (data.length) {
      this.getChangeRequestDetails(data[0]);
    }
  }

  public getChangeRequestDetails(changeRequest: ChangeRequestGroupListModel): void {
    const id = changeRequest.id
    this.changeRequestService
      .getChangeRequestDetails(this.projectId.toString(), id.toString())
      .pipe(take(1))
      .subscribe((data) => {
        this.openDetailRequestModal(data, changeRequest?.order);
      });
  }

  public openDetailRequestModal(req: ChangeRequestModel, order?: number): void {
    const modalRef = this.modalFacadeService.openModal(DetailChangeRequestComponent, {
      windowClass: 'change-request-group change-request-detail',
      backdropClass: 'transparent-overlay'
    });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.unitId = req.unit.id;
    modalRef.componentInstance.changeRequest = req;
    modalRef.componentInstance.threadViewComponent = ThreadViewComponent;
    modalRef.componentInstance.showDownload = false;
    modalRef.componentInstance.advanceMode = true;
    modalRef.componentInstance.order = order;

    modalRef.componentInstance.updateTable.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateTable.emit();
    });
  }
}
